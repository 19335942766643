<template>
  <main class="dealflow-tabs mt-8 flex flex-1 flex-col min-w-0 mr-10">
    <div class="main-title flex justify-between">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Collections</h1>
      <button
        class="flex items-center border border-borderBlack rounded text-xs leading-[15.41px] font-medium font-Rubik px-4 py-3"
        @click="openModal"
      >
        <svg
          class="h-4 w-4 text-black"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 4v16m8-8H4"/>
        </svg>
        <span class="ml-2">New Collection</span>
      </button>
    </div>
    <div
      :class="!dataloaded ? 'flex items-center justify-center' : ''"
      class=""
    >

      <div class="mt-20" v-if="!dataloaded">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(241, 242, 243); display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="translate(26.666666666666668,26.666666666666668)">
            <rect x="-20" y="-20" width="40" height="40" fill="#000000">
              <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.3s"></animateTransform>
            </rect>
          </g>
          <g transform="translate(73.33333333333333,26.666666666666668)">
            <rect x="-20" y="-20" width="40" height="40" fill="#636363">
              <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.2s"></animateTransform>
            </rect>
          </g>
          <g transform="translate(26.666666666666668,73.33333333333333)">
            <rect x="-20" y="-20" width="40" height="40" fill="#e1e1e1">
              <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="0s"></animateTransform>
            </rect>
          </g>
          <g transform="translate(73.33333333333333,73.33333333333333)">
            <rect x="-20" y="-20" width="40" height="40" fill="#545454">
              <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.1s"></animateTransform>
            </rect>
          </g>
        </svg>
      </div>

      <div
        v-else
        :class="!collections.length > 0? 'flex items-center justify-center' : ''"
        class=" mb-12"
      >
        <div class="flex flex-col mt-20" v-if="!collections.length > 0">
          <div class="flex justify-center">
            <img src="../assets/collectionpage.svg"/>
          </div>
          <p class="mt-4 font-Rubik text-[13px] text-center leading-[20px] text-greyText">You are yet to create a collection</p>
        </div>
        <div v-else class="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-5 mt-10">
          <div
            v-for="collection in collections"
            :key="collection.id"
            @click="viewList(collection.id)"
            class="flex flex-col h-[254px] p-6 border rounded-[6px] border-gray-200 hover:cursor-pointer">
            <h3 class=" text-[20px] leading-[23.7px] font-Rubik">{{ collection.collection_name }}</h3>
            <div class="mt-3">
              <span class="inline-flex items-center px-3 py-2 rounded-full text-[12px] leading-[14.22px] font-Rubik bg-faintGrey text-gray-800">
                {{ collection.companies_count }} {{ collection.companies_count == 0 ? "Companies" : collection.companies_count > 1 ? "Companies" : "Company" }}
              </span>
            </div>
            <p class="mt-4 text-sm font-Rubik text-greyText">{{ truncateString(collection.collection_description) }}</p>
            <div class="flex flex-row items-center mt-8">
              <div class="mr-2 h-9 w-9 bg-coolGray-100 rounded-full p-[2px]">
                <img class="rounded-full" :src="`https://origins-files-production.s3.us-east-2.amazonaws.com/admin/${collection.created_by.profile_image}`"/>
              </div>
              <div class="flex flex-col font-Rubik text-[11px] leading-[13.04px]">
                <span class="">Created by {{ collection.created_by.first_name }}</span>
                <span class=""> {{getDate(collection.created_at)}} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal v-show="data.modalOpen">
        <div class="bg-white rounded-[2px] p-4 relative w-[480px]">
          <template v-if="!data.collection">
            <button @click.prevent="closeModal" class="close absolute right-[10px] top-[10px]">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0D0D0D"/>
            </svg>
            </button>
            <h3 class="font-medium font-Rubik leading-[18.96px] text-black mb-[8px]">
              Create new Collection
            </h3>
            <hr />
            <div class="role mt-6 mb-6">
              <label
                class="font-Rubik text-greyText text-sm font-normal"
                for="collection_name"
              >
                Collection Name
              </label>
              <div class="relative">
                <input
                  id="collection_name"
                  name="collection_name"
                  type="text"
                  maxlength="400"
                  class="px-4 py-2 mt-2 font-Rubik text-black text-sm w-full border-[#DDE2E4] focus:outline-none focus:ring-0 placeholder-gray-400 rounded-[6px]"
                  placeholder=""
                  style="transition: 'all .15s ease'"
                  v-model="data.collection_name"
                />
                <span class="block w-max absolute right-0 font-Rubik font-light text-[10px] leading-[14.22px]">{{`${data.collection_name.length}`}}</span>
              </div>
            </div>
            <div class="role mt-6 mb-6">
              <label
                class="
                  font-Rubik
                  text-greyText text-[14px]
                  leading-[16.59px]
                  font-normal
                "
                for="collection_description"
              >
                Collection description
              </label>

              <div class="relative">
                <textarea
                  id="collection_description"
                  name="collection_description"
                  type="text"
                  rows="2"
                  maxlength="400"
                  class="px-4 py-2 mt-2 font-Rubik text-black text-sm w-full border-[#DDE2E4] focus:outline-none focus:ring-0 placeholder-gray-400 rounded-[6px]"
                  placeholder=""
                  style="transition: 'all .15s ease'"
                  v-model="data.collection_description"
                />
                <span class="block w-max absolute right-0 font-Rubik font-light text-[10px] leading-[14.22px]">{{`${data.collection_description.length} of 400`}}</span>
              </div>
            </div>
            <div class="w-full flex items-center justify-end">
              <button :disabled="!data.collection_name || !data.collection_description" @click="createCollection" class="mt-3 flex bg-black text-white rounded-[4px] text-xs font-Rubik px-4 py-3 outline-none focus:outline-none disabled:opacity-50">
                <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white">Submit</span>
                <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                  </circle>
                </svg>
              </button>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col items-center justify-center">
              <h3 class="font-medium font-Rubik text-[16px] leading-[18.96px] text-black mb-[8px]">
                Created!
              </h3>
              <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">Your collection has been created</p>
              <button @click.prevent="closeModal" class="mt-3 border-b border-teal font-medium py-3 px-4 text-white text-[13px] leading-[15.41px] rounded-[4px] bg-black">Okay</button>
            </div>
          </template>
        </div>
      </Modal>
    </div>
  </main>
</template>

<script>
import { useRouter } from 'vue-router'
import Modal from '@/components/Modal.vue'
import { reactive, ref, computed } from 'vue'
import CollectionService from '@/services/collection.service'
import { useStore } from 'vuex'
import Helpers from '@/utils/helpers'
export default {
  components: {
    Modal
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const collections = computed(() => store.state.app.collection)
    const viewList = (collectionId) => {
      router.push({ name: 'Company Collection Page', params: { id: collectionId } })
    }
    const loading = ref(false)
    const data = reactive({
      modalOpen: false,
      collection: false,
      collection_name: '',
      collection_description: ''
    })
    const openModal = () => {
      data.modalOpen = !data.modalOpen
    }
    const dataloaded = ref(false)
    const closeModal = () => {
      data.modalOpen = false
      data.collection = false
    }
    const fetchAllCollection = async () => {
      try {
        const response = await CollectionService.fetchCollection()
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setCollection', response.data.data)
          dataloaded.value = true
        }
      } catch (e) {
        dataloaded.value = false
        Helpers.processError(e, store, router)
      }
    }
    fetchAllCollection()

    const getDate = (created) => {
      const date = new Date(created.split('T')[0])
      return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date)
    }

    const createCollection = async () => {
      try {
        loading.value = true
        const collectionData = {
          collection_name: data.collection_name,
          collection_description: data.collection_description
        }
        const response = await CollectionService.createCollection(collectionData)
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = false
          data.collection = true
          fetchAllCollection()
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }

    const truncateString = (string) => {
      if (string.length > 120) {
        return string.substring(0, 120) + '...'
      } else {
        return string
      }
    }
    return {
      viewList,
      openModal,
      closeModal,
      data,
      loading,
      createCollection,
      collections,
      dataloaded,
      getDate,
      truncateString
    }
  }
}
</script>

<style>

</style>
