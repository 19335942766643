<template>
  <div class="files pt-6">
    <div class="flex flex-col items-center">
      <div
        v-for="(file, index) in files"
        :key="index"
        class="relative flex justify-between items-center w-full rounded mb-3">
        <div class="flex items-center justify-between">
          <a v-if="Helpers.getFileExtension(file.file_name)"
            class="cursor-pointer"
            :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
            :download="`${file.file_name}`"
            target="_blank"
          >
            <img class="w-10 h-10" :src="require(`../../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/>
          </a>
          <a v-else
            class="cursor-pointer"
            :href="`${s3Bucket}${file.file_name}`"
            :download="`${file.file_name}`"
            target="_blank"
          >
            <img class="w-10 h-10" :src="require(`../../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/>

          </a>

          <div class="flex items-center w-full bg-white">
            <div class="ml-4">
              <h3 class="text-sm text-black font-Rubik">{{ file.file_name }}</h3>
              <p class="text-xs text-greyText font-Rubik">{{getDate(file.created_at)}}</p>
            </div>
          </div>
        </div>

        <button @click="selectFile(index)" class="font-bold flex items-center justify-center">
          ...
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
// import { useStore } from 'vuex'
import Helpers from '../../utils/helpers'
import {
  useRoute
  // useRouter
} from 'vue-router'
import CompanyService from '../../services/company.service'

export default {
  setup () {
    // const store = useStore()
    const files = ref(null)
    const s3Bucket = process.env.VUE_APP_ORIGINS_S3_BUCKET
    const route = useRoute()
    // const router = useRouter()

    const getDate = (created) => {
      const date = new Date(created.split('T')[0])
      return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date)
    }

    const getCompanyFiles = async () => {
      try {
        const id = route.params.id
        const response = await CompanyService.fetchAllCompanyfiles(id)
        if (response.status === 200 && response.data.status === 'success') {
          files.value = response.data.data
        }
      } catch (e) {
        // Helpers.processError(e, store, router)
      }
    }

    const selectFile = (index) => {
    }

    getCompanyFiles()

    // onMounted(() => {
    //   getCompanyFiles()
    // })

    return {
      files,
      s3Bucket,
      Helpers,
      getDate,
      getCompanyFiles,
      selectFile
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
