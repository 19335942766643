<template>
  <div class="min-w-[max-content] mx-2 my-1">
    <a-cascader
      v-model:value="value"
      :options="$props.list"
      placeholder="Please select country or city"
      change-on-select
      @change="handleChange"
    />
  </div>
</template>
<script>
import { ref } from 'vue'

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const value = ref([])
    const result = ref()
    const handleChange = (value) => {
      if (value.length === 0) {
        return null
      }
      if (value.length === 1) {
        emit('handleCountry', value[0])
      }
      if (value.length === 2) {
        emit('handleCity', value[1])
      }
    }
    return {
      handleChange,
      result,
      value
    }
  }
}
</script>

<style>
.ant-cascader {
  width:auto;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: 1.25rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: none;
  padding: 0.35rem !important;
  border-radius: 0.375rem;
}
.ant-select-selector {
  border: none!important;
  height: auto;
}
.ant-select-selector input {
  height: auto;
}
.ant-select-arrow {
  display: none!important;
}
.ant-select-selection-placeholder {
  color: black!important;
}
.ant-select-clear {
  display: none!important;
}
</style>
