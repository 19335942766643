import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class StageSevenService {
  static getStageSeven (id) {
    return axios.get(`${url}/admin/stage-seven/fetch/${id}`)
  }
}

export default StageSevenService
