<template>
  <main class="dealflow-tabs mt-8 flex flex-1 flex-col min-w-0 mr-10">
    <div class="main-title flex justify-between">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Company List</h1>
      <button
        class="flex items-center border border-greyText rounded text-xs leading-[15.41px] font-medium font-Rubik px-4 py-3"
      >
        <svg
          class="h-4 w-4 text-greyText"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2">
          <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
        </svg>
        <span class="ml-2 text-greyText">Export</span>
      </button>
    </div>
    <div class="mt-5 border rounded-sm border-gray-200 p-6">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-[15px] leading-[17.78px] font-Rubik font-medium text-black">Companies In Pipeline</h1>
        </div>
      </div>
      <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light font-Rubik text-greyText sm:pl-6 md:pl-0">Company Name</th>
                  <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Industry</th>
                  <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Business Model</th>
                  <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Target Raise</th>
                  <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Date</th>
                  <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Deal Leads</th>
                  <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Current Stage</th>
                  <th scope="col" class="py-3.5 pl-10 pr-3 text-left text-sm font-light font-Rubik text-greyText">Status</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr class="cursor-pointer" v-for="company in companyData" :key="company.id" @click="goToCompany(company.id)">
                  <td class="w-[180px] py-4 pl-4 pr-3 text-sm font-Rubik text-black sm:pl-6 md:pl-0">
                    <div class="flex items-center">
                      <div class="h-8 w-8 flex-shrink-0">
                        <img class="h-8 w-8 rounded-full" :src="`${s3Bucket}${company.logo}`" alt="" />
                      </div>
                      <div class="ml-4">
                        {{ company.company_name }}
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">{{ company.industry_type }}</td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">{{ company.business_model }}</td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">{{ Helpers.numberWithCommas(company.max_amount) }}</td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">{{getDate(company.created_at)}}</td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">Toni</td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">{{ company.current_stage }}</td>
                  <td class="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-Rubik text-black">
                    <span v-if="company.stage_status === 'Processing'" class="py-[6px] px-3 flex items-center justify-center bg-lightYellow text-originsYellow rounded text-[13px] leading-[15.41px] font-medium font-Rubik mr-12">
                      {{ company.stage_status }}
                    </span>
                    <span v-if="company.stage_status === 'Approved'" class="py-[6px] px-3 flex items-center justify-center bg-lightGreen text-originsGreen rounded text-[13px] leading-[15.41px] font-medium font-Rubik mr-12">
                      {{ company.stage_status }}
                    </span>
                    <span v-if="company.stage_status === 'Rejected'" class="py-[6px] px-3 flex items-center justify-center bg-lightRed text-originsRed rounded text-[13px] leading-[15.41px] font-medium font-Rubik mr-12">
                      {{ company.stage_status }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Helpers from '@/utils/helpers'

export default {
  components: {
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const companyData = computed(() => store.state.app.company)
    const viewList = (id) => {
      router.push({ name: 'Company Collection Page', params: { id: id } })
    }
    const s3Bucket = process.env.VUE_APP_ORIGINS_S3_BUCKET
    const getDate = (created) => {
      const date = new Date(created.split('T')[0])
      return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date)
    }
    const goToCompany = (id) => {
      router.push({ name: 'Company Dealflow', params: { id: id } })
    }
    return {
      viewList,
      companyData,
      getDate,
      s3Bucket,
      Helpers,
      goToCompany
    }
  }
}
</script>

<style>

</style>
