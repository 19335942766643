<template>
  <div
    class="
      modal
      bg-originsOverlay
      fixed
      h-screen
      w-screen
      left-0
      top-0
      z-[9999]
      flex
      items-center
      justify-center
    "
  >
    <div class="bg-white rounded-[6px] py-[10px] px-[10px] relative">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  setup () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
</style>
