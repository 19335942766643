<template>
  <main class="home mt-12 flex flex-1 flex-col min-w-0 mr-10">
    <div class="main-title flex justify-between">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Dashboard</h1>

    </div>
    <div class="grid lg:grid-cols-3 grid-cols-1 h-[212px] gap-5 mt-10">
      <div class="flex flex-col max-h-[212px] bg-black p-6">
        <div class="flex justify-between items-center">
          <h3 class="max-w-[120px] text-greyText">
            Total No. of Active Deals
          </h3>
          <button class="flex justify-center items-center rounded-full bg-white h-10 w-10">
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
          </button>
        </div>
        <div v-show="totalActiveDeals" class="text-white text-[36px] leading-[42.66px] font-Rubik">{{totalActiveDeals}}</div>
        <div class="flex justify-between mt-8">
          <div>
            <h4 class="text-greyText text-[13px] leading-[15.41px] font-Rubik">
              EQUITY VALUE
            </h4>
            <div v-show="totalEquityValue" class="text-white text-[18px] leading-[21.33px] font-Rubik">$ {{totalEquityValue}}</div>
          </div>
          <div>
            <h4 class="text-greyText text-[13px] leading-[15.41px] font-Rubik">
              MoM GROWTH
            </h4>
            <div class="text-white text-[18px] leading-[21.33px] font-Rubik">10%</div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap content-between h-[212px] bg-white">
        <div class="flex flex-row border rounded-sm justify-between px-4 items-center border-gray-200 h-[96px] w-full">
          <div class="flex flex-col">
            <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Total Capital<br> Requests In Pipeline</p>
            <span v-show="totalCapitalRequests" class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">$ {{totalCapitalRequests}}</span>
          </div>
          <svg
            class="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
          </svg>
        </div>
        <div class="flex flex-row border rounded-sm justify-between px-4 items-center border-gray-200 h-[96px] w-full">
          <div class="flex flex-col">
            <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Average div of <br> Days In Pipeline</p>
            <span class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">2 days</span>
          </div>
          <svg
            class="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
          </svg>
        </div>
      </div>
      <div class="flex flex-wrap content-between h-[212px] bg-white">
        <div class="flex flex-row border rounded-sm justify-between px-4 items-center border-gray-200 h-[96px] w-full">
          <div class="flex flex-col">
            <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Total No. of <br> Pending Reviews</p>
            <span v-show="totalPendingReviews" class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">{{totalPendingReviews}}</span>
          </div>
          <svg
            class="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
          </svg>
        </div>
        <div class="flex flex-row border rounded-sm justify-between px-4 items-center border-gray-200 h-[96px] w-full">
          <div class="flex flex-col">
            <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Average Deal Size  <br>In Pipeline</p>
            <span v-show="averageDealSize" class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">$ {{averageDealSize}}</span>
          </div>
          <svg
            class="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
          </svg>
        </div>
      </div>
    </div>
    <div id="chart" class="relative bottom-[calc(100vh-1380px)] sm:bottom-[calc(100vh-1365px)] lg:bottom-0 mt-4">
      <VueApexCharts v-if="treemapOptions.series[0].data" width="100%" type="treemap" :options="treemapOptions" :series="treemapOptions.series"></VueApexCharts>
    </div>
    <!-- <div v-if="totalActiveDeals && totalEquityValue && averageDealSize && totalPendingReviews" class="grid lg:grid-cols-3 grid-cols-1 h-[212px] gap-5 mt-10 animate-pulse">
      <div class="flex flex-col max-h-[212px] bg-greyText p-6">
        <div class="flex justify-between items-center">
          <div class="max-w-[120px] bg-faintGrey w-[100%] h-8"></div>
          <button class="flex justify-center items-center rounded-full bg-white h-10 w-10"></button>
        </div>
        <div class="bg-white text-[36px] leading-[42.66px] font-Rubik w-[100px] h-8">{{totalActiveDeals}}</div>
        <div class="flex justify-between mt-8">
          <div>
            <div class="bg-greyText text-[13px] leading-[15.41px] font-Rubik"></div>
            <div class="bg-white text-[18px] leading-[21.33px] font-Rubik w-[100px] h-8">{{totalEquityValue}}</div>
          </div>
          <div>
            <div class="bg-greyText text-[13px] leading-[15.41px] font-Rubik"></div>
            <div class="text-white text-[18px] leading-[21.33px] font-Rubik"></div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap content-between h-[212px] bg-white">
        <div class="flex flex-row border rounded-sm justify-between px-4 items-center border-gray-200 h-[96px] w-full">
          <div class="flex flex-col">
            <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Total Capital<br> Requests In Pipeline</p>
            <span class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">$ {{totalCapitalRequests}}</span>
          </div>
          <svg
            class="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
          </svg>
        </div>
        <div class="flex flex-row border rounded-sm justify-between px-4 items-center border-gray-200 h-[96px] w-full">
          <div class="flex flex-col">
            <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Average div of <br> Days In Pipeline</p>
            <span class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">2 days</span>
          </div>
          <svg
            class="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
          </svg>
        </div>
      </div>
      <div class="flex flex-wrap content-between h-[212px] bg-white">
        <div class="flex flex-row border rounded-sm justify-between px-4 items-center border-gray-200 h-[96px] w-full">
          <div class="flex flex-col">
            <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Total div of <br> Pending Reviews</p>
            <span class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">{{totalPendingReviews}}</span>
          </div>
          <svg
            class="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
          </svg>
        </div>
        <div class="flex flex-row border rounded-sm justify-between px-4 items-center border-gray-200 h-[96px] w-full">
          <div class="flex flex-col">
            <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Average Deal Size  <br>In Pipeline</p>
            <span class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">$ {{averageDealSize}}</span>
          </div>
          <svg
            class="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
          </svg>
        </div>
      </div>
    </div> -->
  </main>
</template>

<script>
import CompanyService from '@/services/company.service'
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import AnalyticsService from '@/services/analytics.service'
import Helpers from '../utils/helpers'
import { useRouter } from 'vue-router'
import VueApexCharts from 'vue3-apexcharts'

export default {
  name: 'Home',
  components: {
    VueApexCharts
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const totalActiveDeals = ref(null)
    const totalEquityValue = ref(null)
    const totalCapitalRequests = ref(null)
    const averageDealSize = ref(null)
    const totalPendingReviews = ref(null)
    const analyticsLoading = ref(false)
    const treemapOptions = reactive({
      chart: {
        type: 'treemap'
      },
      theme: {
        mode: 'light',
        palette: 'palette1',
        monochrome: {
          enabled: true,
          color: '#000000',
          shadeTo: 'dark',
          shadeIntensity: 0.65
        }
      },
      responsive: [{
        breakpoint: 1024,
        options: {

        }
      }],
      series: [
        {
          data: null
        }
      ]
    })
    const getChartData = async () => {
      try {
        const response = await AnalyticsService.fetchIndustryDistribution()
        if (response.status === 200 && response.data.status === 'success') {
          treemapOptions.series[0].data = response.data.data[0].data
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const getAllCompany = async () => {
      try {
        const response = await CompanyService.fetchAllCompany()
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setCompany', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }
    const getAllRegularCompanies = async () => {
      try {
        const response = await CompanyService.fetchOnlyRegularCompanies()
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setRegularCompany', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }
    const getAllTargetCompanies = async () => {
      try {
        const response = await CompanyService.fetchAllTargetCompanies()
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setTargetCompany', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }
    const getTotalActiveDeals = async () => {
      try {
        const response = await AnalyticsService.fetchTotalActiveDeals()
        if (response.status === 200 && response.data.status === 'success') {
          totalActiveDeals.value = Helpers.numberWithCommas(response.data.data.active_deals)
          // store.dispatch('setCompany', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
        analyticsLoading.value = false
      }
    }
    const getEquityValue = async () => {
      try {
        const response = await AnalyticsService.fetchEquityValue()
        if (response.status === 200 && response.data.status === 'success') {
          totalEquityValue.value = Helpers.numberWithCommas(response.data.data.equity_value)
          // store.dispatch('setCompany', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
        analyticsLoading.value = false
      }
    }
    const getCapitalRequestsAndAverageDealSize = async () => {
      try {
        const response = await AnalyticsService.fetchCapitalRequestsAndAverageDealSize()
        if (response.status === 200 && response.data.status === 'success') {
          totalCapitalRequests.value = Helpers.numberWithCommas(response.data.data.total_capital_requests)
          averageDealSize.value = Helpers.numberWithCommas(response.data.data.average_deal_size)
          // store.dispatch('setCompany', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
        analyticsLoading.value = false
      }
    }
    const getPendingReviews = async () => {
      try {
        const response = await AnalyticsService.fetchPendingReviews()
        if (response.status === 200 && response.data.status === 'success') {
          totalPendingReviews.value = Helpers.numberWithCommas(response.data.data.pending_reviews)
          // store.dispatch('setCompany', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
        analyticsLoading.value = false
      }
    }
    getAllCompany()
    getAllRegularCompanies()
    getAllTargetCompanies()
    getTotalActiveDeals()
    getEquityValue()
    getCapitalRequestsAndAverageDealSize()
    getPendingReviews()
    getChartData()
    return {
      company: computed(() => store.state.app.company),
      totalActiveDeals,
      totalEquityValue,
      totalCapitalRequests,
      averageDealSize,
      totalPendingReviews,
      treemapOptions
    }
  }
}
</script>
