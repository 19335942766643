<template>
  <main class="portfolio-tabs mt-8 flex flex-1 flex-col min-w-0 mr-10">
    <div class="main-title">
      <div class="flex justify-between">
        <h1 class="font-Playfair text-[32px] leading-[42.66px]">Portfolio</h1>
      </div>
      <Breadcrumb :page="companyID"/>
    </div>
    <div class="mt-[6px] sticky top-[150px] z-[500] bg-white border-b-[1px] border-faintGrey">
      <div class="flex justify-between">
        <nav class="mt-5 bg-white">
          <ul class="flex flex-row">
            <li v-for="(tab, index) in tabs" :key="index" class="pr-4">
              <router-link :to="`/portfolio/${routeID}/${tab.href}`" v-slot="{ href, navigate, isActive }">
                <a
                  :href="href"
                  @click="navigate"
                  class="border-black border-b-2 font-Rubik py-2 flex items-center text-sm leading-[18.96px]"
                  :class="[
                    isActive
                      ? 'border-black text-black'
                      : 'border-none text-greyText',
                  ]"
                >{{ tab.name }}</a>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="flex-auto">
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import {
  useRoute
  // useRouter
} from 'vue-router'
import Breadcrumb from '@/components/BreadCrumb.vue'
import { useStore } from 'vuex'

export default {
  components: {
    Breadcrumb
  },

  setup () {
    const route = useRoute()
    const store = useStore()
    const routeID = ref(null)
    const companyID = ref({})
    const tabs = [
      { name: 'General Info', href: 'generalinfo' },
      { name: 'Round History', href: 'roundhistory' },
      { name: 'Files', href: 'files' }
    ]
    const portfolioCompanies = computed(() => store.state.app.portfolioCompanies)
    const getPortfolioCompany = (id) => {
      return portfolioCompanies.value.filter(company => company.id === id)
    }
    const upDateList = () => {
      routeID.value = route.params.id
      const company = getPortfolioCompany(routeID.value)
      companyID.value.breadcrumbList = company[0].company_name
      companyID.value.route = route.path.split('/')[1]
    }
    onMounted(() => {
      upDateList()
    })
    return {
      routeID,
      companyID,
      tabs
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
