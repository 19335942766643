import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class FinancialService {
  static convertToPortfolioCompany (companyId) {
    return axios.put(`${url}/admin/company/${companyId}/portfolio`)
  }

  static fetchCompanyPortfolioLedger (companyId) {
    return axios.get(`${url}/admin/company/${companyId}/portfolio-ledgers`)
  }

  static fetchSinglePortfolioLedger (companyId) {
    return axios.get(`${url}/admin/portfolio-ledger/${companyId}`)
  }

  static createCompanyPortfolioLedger (data) {
    return axios.post(`${url}/admin/portfolio-ledger`, data)
  }

  static updateCompanyPortfolioLedger (recordId, data) {
    return axios.put(`${url}/admin/portfolio-ledger/${recordId}`, data)
  }
}

export default FinancialService
