<template>
  <div>
    <TransitionRoot :show="open" as="template" @after-leave="searchQuery = ''" appear>
      <Dialog as="div" class="relative z-[1000]" @close="toggleShow(false)">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <DialogPanel class="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox v-slot="{ activeOption }" @update:modelValue="onSelect">
                <div class="relative">
                  <span class="block pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                  </span>
                  <ComboboxInput class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Search for a company..." @change="performSearch($event.target.value)" />
                </div>

                <ComboboxOptions v-if="searchQuery === '' || searchResults.length > 0" class="flex divide-x divide-gray-100" as="div" static hold>
                  <div :class="['max-h-[400px] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4', activeOption && 'sm:h-[400px]']">
                    <h2 v-if="searchQuery === ''" class="mb-4 mt-2 text-xs font-semibold text-gray-500">Results</h2>
                    <div hold class="-mx-2 text-xs text-gray-700">
                      <ComboboxOption v-for="hit in searchQuery === '' ? recent : searchResults" :key="hit.id" :value="hit" as="template" v-slot="{ active }">
                        <div :class="['group flex cursor-default select-none items-center rounded-md p-2', active && 'bg-gray-100 text-gray-900']">
                          <img :src="`${s3Bucket}${hit.logo}`" alt="" class="h-6 w-6 flex-none rounded-full" />
                          <span class="ml-3 flex-auto truncate">{{ hit.company_name }}</span>
                          <span v-if="active" class="block ml-3 !h-5 !w-5 flex-none text-gray-400" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                          </span>
                        </div>
                      </ComboboxOption>
                    </div>
                  </div>

                  <div v-if="activeOption" class="hidden h-[400px] w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                    <div class="flex-none px-6 py-4 pt-2 text-center">
                      <img :src="`${s3Bucket}${activeOption.logo}`" alt="" class="mx-auto h-16 w-16 rounded-full" />
                      <h2 class="mt-3 font-semibold text-gray-900">
                        {{ activeOption.company_name }}
                      </h2>
                      <!-- {{ activeOption }} -->
                      <p v-show="activeOption.city" class="text-sm leading-6 text-gray-500">{{ activeOption.state }} {{ activeOption.city }}, {{ activeOption.country }}.</p>
                    </div>
                    <div class="flex flex-auto flex-col justify-between p-6 pt-2">
                      <dl class="grid grid-cols-1 gap-x-3 gap-y-3 text-xs text-gray-700">
                        <dt class="col-end-1 font-semibold text-gray-900">Company Summary:</dt>
                        <dd class="truncate">{{ activeOption.company_summary }}</dd>
                        <dt class="col-end-1 font-semibold text-gray-900">Business Model:</dt>
                        <dd>{{ activeOption.business_model }}</dd>
                        <dt class="col-end-1 font-semibold text-gray-900">Industry Type:</dt>
                        <dd>{{ activeOption.industry_type }}</dd>
                        <dt class="col-end-1 font-semibold text-gray-900">Funding Round:</dt>
                        <dd>{{ activeOption.funding_round }}</dd>
                        <dt class="col-end-1 font-semibold text-gray-900">Founded:</dt>
                        <dd>{{ activeOption.year_founded }}</dd>
                        <dt class="col-end-1 font-semibold text-gray-900">Website:</dt>
                        <dd class="truncate">
                          <a :href="activeOption.website" class="text-indigo-600 underline">
                            {{ activeOption.website }}
                          </a>
                        </dd>
                      </dl>
                      <router-link :to="`/dealflow/${activeOption.objectID}`" type="button" class="mt-6 w-full rounded-md bg-black px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-center">View more</router-link>
                    </div>
                  </div>
                </ComboboxOptions>

                <div v-if="(searchQuery !== '' || searchQuery.length > 4) && searchResults.length === 0" class="px-6 py-14 text-center text-sm sm:px-14">
                  <span class="block mx-auto h-6 w-6 text-gray-400" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                  </span>
                  <p class="mt-4 font-semibold text-gray-900">Hey there</p>
                  <p class="mt-2 text-gray-500">We couldn't find anything related to the search term. Search for something else.</p>
                </div>
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  computed,
  ref
} from 'vue'
import { useStore } from 'vuex'
import algoliasearch from 'algoliasearch/lite'

import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'

export default {
  components: {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot
  },
  emits: ['toggleShow'],
  props: ['open'],
  setup (props, context) {
    const store = useStore()
    const s3Bucket = process.env.VUE_APP_ORIGINS_S3_BUCKET
    const activeOption = ref(null)
    const companies = computed(() => store.state.app.company)
    const indexName = process.env.VUE_APP_ORIGINS_ALGOLIA_INDEX
    const searchQuery = ref('')
    const allHits = ref(null)
    const client = algoliasearch(
      process.env.VUE_APP_ORIGINS_ALGOLIA_APPID,
      process.env.VUE_APP_ORIGINS_ALGOLIA_APIKEY
    )
    const index = client.initIndex(indexName)
    const searchResults = ref('')
    const performSearch = async (query) => {
      searchQuery.value = query
      if (searchQuery.value.length > 1) {
        try {
          const results = await index.search(searchQuery.value)
          searchResults.value = results.hits
        } catch (error) {
          console.error(error)
        }
      }
    }

    const recent = ref([companies.value[5], companies.value[4], companies.value[2], companies.value[10], companies.value[16]])

    const toggleShow = (value) => {
      context.emit('toggleShow', value)
    }

    const onSelect = (company) => {
      // window.location = company.website
      // console.log(company)
    }

    const getActiveOption = (item) => {
      // eslint-disable-next-line no-unused-vars
      const addy = { id: '1a4d0ab1-7df0-4f74-a102-230812ed89d7', company_name: 'Funkasters', website: 'https://funkmasters.com', year_founded: '2022', formatted_address: 'Oworonshoki Nigeria', address: { city: 'Oworonshoki', state: 'Surulere', country: 'Nigeria', code: 'NG' }, coordinates: { type: 'Point', coordinates: ['6.5095442', '3.3710936'] }, geom: { type: 'Point', coordinates: [6.5095442, 3.3710936] }, pitch_deck: '1676490929390-pitchdeck.pdf', logo: '1676490929394-FdL5WRaXwAMuOdX.jpg', industry_type: 'Media', business_model: 'B2C', funding_round: 'Pre-Seed', funding_type: 'SAFE', min_amount: '0', max_amount: '0', current_stage: 'ONBOARDING', can_proceed: false, next_stage: 'ONBOARDING', last_interaction_at: null, stage_status: 'New', company_summary: 'We specialise in sound engineering', rejection_reason: null, is_target_company: true, is_portfolio_company: false, created_at: '2023-02-15T19:55:29.556Z', updated_at: '2023-02-15T19:55:29.556Z', deal_lead: null }
      activeOption.value = item
    }

    return {
      activeOption,
      recent,
      searchQuery,
      onSelect,
      companies,
      s3Bucket,
      toggleShow,
      getActiveOption,
      indexName,
      allHits,
      performSearch,
      searchResults
    }
  }
}

</script>

<style>
.alg-searchInput {
  height: 48px;
  width: 100%;
  border: none;
  background: transparent;
  padding-left: 44px;
  padding-right: 16px;
  color: rgba(24, 24, 27, var(--tw-text-opacity));
}
.alg-searchInput.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.alg-submitIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
}
.alg-resetIcon {
  display: none;
}
@media (min-width: 640px) {
  .alg-searchInput {
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
  }
}
</style>
