import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class MonoService {
  static getMonoTransactions (accountId) {
    return axios.get(`${url}/admin/financials/mono/${accountId}/transactions`)
  }

  static getNextBatchOfMonoTransactions (accountId, pageDirection, cursor, sort, order, limit) {
    return axios.get(`${url}/admin/financials/mono/${accountId}/transactions?pageDirection=${pageDirection}&cursor=${cursor}&sortBy=${sort}&order=${order}&limit=${limit}`)
  }

  static sortMonoTransactions (accountId, sortBy, order, limit) {
    return axios.get(`${url}/admin/financials/mono/${accountId}/transactions?sortBy=${sortBy}&order=${order}&limit=${limit}`)
  }

  static categorizeMonoTransactions (accountId, transactionId, data) {
    return axios.patch(`${url}/admin/financials/mono/${accountId}/transactions/${transactionId}`, data)
  }
}

export default MonoService
