<template>
  <main class="font-Rubik">
    <h2 class="text-[20px] leading-[23.7px] mb-[32px]">DATA REQUEST 2 REVIEW</h2>
    <div class="instructions text-greyText mb-[30px]">
      <p class="text-[15px] leading-[17.78px] mb-[8px]">Instructions:</p>
      <p class="text-[13px] leading-[20px]">Kindly read the diligence information provided
         by the individual company to the left in full.
          Afterwards, answer the below questions,
           referencing the diligence information when needed.
            Please leave helpful comments and feedback
             to supplement your scores.
             </p>
    </div>
    <div class="mt-20" v-if="!dataloaded">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(241, 242, 243); display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(26.666666666666668,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#000000">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.3s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#636363">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.2s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(26.666666666666668,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#e1e1e1">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="0s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#545454">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.1s"></animateTransform>
          </rect>
        </g>
      </svg>
    </div>
    <div v-else id="riskReview" class="activeArea">

      <div class="functioning-prototype grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Has the company communicated a clear, high quality solution?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Functioning prototype"
            id="no-score-functioning-prototype"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Functioning prototype') == '0'"
            @change="setRatings($event, 'functioningPrototype')"
            v-model="functioningPrototype"
          >
          <label for="no-score-functioning-prototype" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Functioning prototype"
            id="extremely-unclear-functioning-prototype"
            value="1" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Functioning prototype') == '1'"
            @change="setRatings($event, 'functioningPrototype')"
            v-model="functioningPrototype"
          >
          <label for="extremely-unclear-functioning-prototype" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The team does not have functioning prototype or product.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Functioning prototype"
            id="somewhat-unclear-functioning-prototype"
            value="2" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Functioning prototype') == '2'"
            @change="setRatings($event, 'functioningPrototype')"
            v-model="functioningPrototype"
          >
          <label for="somewhat-unclear-functioning-prototype" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">Somewhat Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Functioning prototype"
            id="clear-functioning-prototype"
            value="3" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Functioning prototype') == '3'"
            @change="setRatings($event, 'functioningPrototype')"
            v-model="functioningPrototype"
          >
          <label for="clear-functioning-prototype" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Functioning prototype"
            id="very-clear-functioning-prototype"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Functioning prototype') == '4'"
            @change="setRatings($event, 'functioningPrototype')"
            v-model="functioningPrototype"
          >
          <label for="very-clear-functioning-prototype" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">Somewhat Strong Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Functioning prototype"
            id="extremely-clear-functioning-prototype"
            value="5" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Functioning prototype') == '5'"
            @change="setRatings($event, 'functioningPrototype')"
            v-model="functioningPrototype"
          >
          <label for="extremely-clear-functioning-prototype" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The team has a functioning product with a great user experience</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-functioning-prototype" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-functioning-prototype"
            rows="3"
            cols="50"
            name="Functioning prototype"
            @blur="setComments($event, functioningPrototype)"
            :disabled="commentStatus.functioningPrototype &&  getRatingScore('Functioning prototype') == '0'"
            :class="[commentStatus.functioningPrototype &&  getRatingScore('Functioning prototype') == '0' ? 'hover:cursor-not-allowed' : '']"
            placeholder="Leave feedback here..."
            required
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
            :value="getRatingComment('Functioning prototype')"></textarea>
        </div>
      </div>

      <div class="existing-product-roadmap grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Does the team have a vision for the product beyond the initial MVP, prototype, or current product?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Existing Product Roadmap"
            id="no-score-existing-product"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Existing Product Roadmap') == '0'"
            @change="setRatings($event, 'existingProductRoadmap')"
            v-model="existingProductRoadmap"
          >
          <label for="no-score-existing-product" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Existing Product Roadmap"
            id="extremely-unclear-existing-product"
            value="1" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Existing Product Roadmap') == '1'"
            @change="setRatings($event, 'existingProductRoadmap')"
            v-model="existingProductRoadmap"
          >
          <label for="extremely-unclear-existing-product" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The team lacks a vision beyond the initial MVP or current product.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Existing Product Roadmap"
            id="somewhat-unclear-existing-product"
            value="2" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Existing Product Roadmap') == '2'"
            @change="setRatings($event, 'existingProductRoadmap')"
            v-model="existingProductRoadmap"
          >
          <label for="somewhat-unclear-existing-product" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The team has a limited vision beyond the MVP or current product.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Existing Product Roadmap"
            id="clear-existing-product"
            value="3" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Existing Product Roadmap') == '3'"
            @change="setRatings($event, 'existingProductRoadmap')"
            v-model="existingProductRoadmap"
          >
          <label for="clear-existing-product" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The team has a clear vision for the product beyond the MVP or current product.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Existing Product Roadmap"
            id="very-clear-existing-product"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Existing Product Roadmap') == '4'"
            @change="setRatings($event, 'existingProductRoadmap')"
            v-model="existingProductRoadmap"
          >
          <label for="very-clear-existing-product" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The team has a strong, well-defined vision extending well beyond the MVP or current product.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Existing Product Roadmap"
            id="extremely-clear-existing-product"
            value="5" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Existing Product Roadmap') == '5'"
            @change="setRatings($event, 'existingProductRoadmap')"
            v-model="existingProductRoadmap"
          >
          <label for="extremely-clear-existing-product" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The team has an exceptional and inspiring vision for long-term growth beyond the MVP or current product.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-existing-products" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-existing-product"
            rows="3"
            cols="50"
            name="Existing Product Roadmap"
            @blur="setComments($event, existingProductRoadmap)"
            :disabled="commentStatus.existingProductRoadmap &&  getRatingScore('Existing Product Roadmap') == '0'"
            :class="[commentStatus.existingProductRoadmap &&  getRatingScore('Existing Product Roadmap') == '0' ? 'hover:cursor-not-allowed' : '']"
            placeholder="Leave feedback here..."
            required
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
            :value="getRatingComment('Existing Product Roadmap')"></textarea>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
/* eslint-disable camelcase */
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import RiskscoreService from '@/services/riskscore.service'
import Helpers from '@/utils/helpers'
import { useStore } from 'vuex'

export default {
  components: {
  },
  setup () {
    const dataloaded = ref(false)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const ratings = ref([])
    const existingProductRoadmap = ref(null)
    const functioningPrototype = ref(null)
    const commentStatus = ref({
      productService: true,
      proprietaryOrProtected: true,
      functioningPrototype: true,
      existingProductRoadmap: true
    })

    const getRaterScore = async () => {
      try {
        const id = route.params.id
        const response = await RiskscoreService.fetchRiskscoreByRater(id)
        if (response.status === 200 && response.data.status === 'success') {
          ratings.value = response.data.data
          dataloaded.value = true
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const getRatingScore = (type) => {
      const rate = ratings.value.filter(rating => rating.type === type)
      return rate[0].score
    }

    const getRatingComment = (type) => {
      const rate = ratings.value.filter(rating => rating.type === type)
      return rate[0].comment
    }

    getRaterScore()

    const setRatings = (event, name) => {
      if (event.target.value > 0) {
        commentStatus.value[name] = false
      } else {
        commentStatus.value[name] = true
      }
    }

    const setComments = async (event, name) => {
      const score = Number(name) === 0 ? getRatingScore(event.target.name) : Number(name)
      const data = {
        category: Helpers.getRiskCategory(event.target.name),
        type: event.target.name,
        comment: event.target.value,
        score: score
      }
      const id = route.params.id
      try {
        const response = await RiskscoreService.createRiskscore(id, data)
        if (response.status === 200 && response.data.status === 'success') {
          Helpers.processRiskSuccess(response, store, response.data.message)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    return {
      dataloaded,
      ratings,
      getRatingScore,
      setRatings,
      getRatingComment,
      setComments,
      commentStatus,
      functioningPrototype,
      existingProductRoadmap
    }
  }
}
</script>

<style scoped>
textarea {
  max-width: 100%;
  border: solid 1px #E5E6E6;
  padding: 1rem;
  margin-top: 1rem;
  font-size: 14px;
  line-height: 16.59px;
  font-weight: 400;
  transition: all .15s ease;
}
</style>
