<template>
  <main class="target-companies mt-8 flex flex-1 flex-col min-w-0 mr-10">
    <div class="main-title flex justify-between">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Target Companies</h1>
      <button
        class="flex items-center border border-borderBlack rounded text-xs leading-[15.41px] font-medium font-Rubik px-4 py-3"
        @click="openNewTargetCompany"
      >
        <svg
          class="h-4 w-4 text-black"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 4v16m8-8H4"/>
        </svg>
        <span class="ml-2">New Target Company</span>
      </button>
    </div>
    <div
      class="flex items-center justify-center"
    >

      <div class="mt-5 border rounded-sm border-gray-200 p-6 w-full overflow-x-auto">
        <div class="sm:flex sm:items-center">
          <div class="sm:flex-auto">
            <h1 class="text-[15px] leading-[17.78px] font-Rubik font-medium text-black">Target companies</h1>
          </div>
        </div>
        <div class="mt-8 flex flex-col">
          <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light font-Rubik text-greyText sm:pl-6 md:pl-0">Company Name</th>
                    <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Industry</th>
                    <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Business Model</th>
                    <!-- <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Target Raise</th> -->
                    <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Date</th>
                    <!-- <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Deal Leads</th> -->
                    <!-- <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText">Current Stage</th> -->
                    <!-- <th scope="col" class="py-3.5 pl-10 pr-3 text-left text-sm font-light font-Rubik text-greyText">Status</th> -->
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr class="cursor-pointer" v-for="company in targetCompanyData" :key="company.id" @click="goToCompany(company.id)">
                    <td class="w-[180px] py-4 pl-4 pr-3 text-sm font-Rubik text-black sm:pl-6 md:pl-0">
                      <div class="flex items-center">
                        <div class="h-8 w-8 flex-shrink-0">
                          <img class="h-8 w-8 rounded-full" :src="`${s3Bucket}${company.logo}`" alt="" />
                        </div>
                        <div class="ml-4">
                          {{ company.company_name }}
                        </div>
                      </div>
                    </td>
                    <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">{{ company.industry_type }}</td>
                    <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">{{ company.business_model }}</td>
                    <!-- <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">{{ company.max_amount }}</td> -->
                    <!-- <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">{{ Helpers.numberWithCommas(company.max_amount) }}</td> -->
                    <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">{{getDate(company.created_at)}}</td>
                    <!-- <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">Toni</td> -->
                    <!-- <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">{{ company.current_stage }}</td> -->
                    <!-- <td class="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-Rubik text-black">
                      <span v-if="company.stage_status === 'Processing'" class="py-[6px] px-3 flex items-center justify-center bg-lightYellow text-originsYellow rounded text-[13px] leading-[15.41px] font-medium font-Rubik mr-12">
                        {{ company.stage_status }}
                      </span>
                      <span v-if="company.stage_status === 'Approved'" class="py-[6px] px-3 flex items-center justify-center bg-lightGreen text-originsGreen rounded text-[13px] leading-[15.41px] font-medium font-Rubik mr-12">
                        {{ company.stage_status }}
                      </span>
                      <span v-if="company.stage_status === 'Rejected'" class="py-[6px] px-3 flex items-center justify-center bg-lightRed text-originsRed rounded text-[13px] leading-[15.41px] font-medium font-Rubik mr-12">
                        {{ company.stage_status }}
                      </span>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <TransitionRoot as="template" :show="addTargetCompanyModal">
        <Dialog as="div" class="relative z-[9999]" @close="closeModal()">
          <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
              <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                  <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                    <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                      <div class="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button type="button" class="rounded-full text-black hover:text-white focus:outline-none hover:ring-2 hover:ring-black hover:bg-black bg-white p-1 transition-all" @click="closeModal()">
                          <span class="sr-only">Close panel</span>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </TransitionChild>
                    <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div class="px-4 sm:px-6">
                        <DialogTitle class="text-lg font-medium text-gray-900">New target Company</DialogTitle>
                      </div>
                      <div class="relative mt-6 flex-1 px-4 sm:px-6">
                        <!-- content -->
                        <template v-if="!newTargetCompany">
                          <div class="absolute inset-0 px-4 sm:px-6">
                            <div class="h-[max-content]">
                              <div class="max-w-[613px] pb-8">
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-sm font-normal"
                                    htmlFor="companyName"
                                  >
                                    <span class="text-red-400">*</span>
                                    Company Name
                                  </label>
                                  <input
                                    id="companyName"
                                    name="companyName"
                                    type="text"
                                    :class="[
                                      v$.company_name.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="Company XYZ"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.company_name.$model"
                                    @blur="v$.company_name.$touch()"
                                  />
                                  <div v-if="v$.company_name.$error">
                                    <p
                                      v-if="v$.company_name.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Company name is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                                    htmlFor="websiteLink"
                                  >
                                    <span class="text-red-400">*</span>
                                    Link to Website
                                  </label>
                                  <input
                                    id="websiteLink"
                                    name="websiteLink"
                                    type="text"
                                    :class="[
                                      v$.website.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="https://companyxyz.com"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.website.$model"
                                    @blur="v$.website.$touch()"
                                  />
                                  <div v-if="v$.website.$error">
                                    <p
                                      v-if="v$.website.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Company website is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                                    htmlFor="yearFounded"
                                  >
                                    <span class="text-red-400">*</span>
                                    Year Founded
                                  </label>
                                  <input
                                    id="yearFounded"
                                    name="yearFounded"
                                    type="number"
                                    :class="[
                                      v$.year_founded.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    min="1500"
                                    max="2099"
                                    step="1"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="2000"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.year_founded.$model"
                                    @blur="v$.year_founded.$touch()"
                                  />
                                  <div v-if="v$.year_founded.$error">
                                    <p
                                      v-if="v$.year_founded.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Year founded is required</p>
                                    <p
                                      v-if="v$.year_founded.betweenValue.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >{{v$.year_founded.betweenValue.$message}}</p>
                                    <div>
                                    </div>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                                    htmlFor="companyName"
                                  >
                                    <span class="text-red-400">*</span>
                                    Company Summary
                                  </label>
                                  <textarea
                                    id="companySummary"
                                    name="companySummary"
                                    type="text"
                                    rows="3"
                                    maxlength="450"
                                    :class="[
                                      v$.company_summary.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="Please summarize what your company does in a paragraph or less"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.company_summary.$model"
                                    @blur="v$.company_summary.$touch()"
                                  />
                                  <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.company_summary.length} of 450`}}</span>
                                  <div v-if="v$.company_summary.$error">
                                    <p
                                      v-if="v$.company_summary.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Company Summary is required</p>
                                    <p
                                      v-if="v$.company_summary.minLengthValue.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Company summary should be at least 3 characters long</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <div
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal mb-2"
                                    for="pitch-upload"
                                  >
                                    <span class="text-red-400">*</span>
                                    Pitch Deck
                                  </div>
                                  <div class="flex flex-row items-center">
                                    <label for="pitch-upload" class="flex items-center px-2 py-3 w-[max-content] relative cursor-pointer bg-white font-Rubik text-black rounded-sm border border-dashed focus:outline-none focus:border-black hover:border-black focus:ring-0 text-sm">
                                      <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                        </svg>
                                      </span>
                                      Upload a File
                                      <input
                                        id="pitch-upload"
                                        name="pitch-upload"
                                        type="file"
                                        accept="application/pdf"
                                        class="sr-only"
                                        @change="choosedata($event, 'pitch_deck', 'pitch_deck_type', 'PRESENTATION')"
                                      >
                                    </label>
                                    <div class="ml-5 text-blueLink">
                                      {{ files.pitch_deck }}
                                    </div>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <div
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal mb-2"
                                    for="logo-upload"
                                  >
                                    <span class="text-red-400">*</span>
                                    Company Logo
                                  </div>
                                  <div class="flex flex-row items-center">
                                    <label for="logo-upload" class="flex items-center px-2 py-3 w-[max-content] relative cursor-pointer bg-white font-Rubik text-black rounded-sm border border-dashed focus:outline-none focus:border-black hover:border-black focus:ring-0 text-sm">
                                      <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                        </svg>
                                      </span>
                                      Upload Logo
                                      <input
                                        id="logo-upload"
                                        name="logo-upload"
                                        type="file"
                                        accept="image/*"
                                        class="sr-only"
                                        @change="choosedata($event, 'logo', 'logo_type', 'PRESENTATION')"
                                      >
                                    </label>
                                    <div class="ml-5 text-blueLink">
                                      {{files.logo}}
                                    </div>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                                    htmlFor="location"
                                  >
                                    <span class="text-red-400">*</span>
                                    Location
                                  </label>
                                  <input
                                    id="location"
                                    name="location"
                                    type="text"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="Type location"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.location.$model"
                                    @blur="v$.location.$touch()"
                                  />
                                  <div v-if="v$.location.$error">
                                    <p
                                      v-if="v$.location.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Location is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6 z-30">
                                  <Listbox
                                    v-model="v$.industry_type.$model"
                                  >
                                    <div class="relative mt-1">
                                      <ListboxLabel class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                                        <span class="text-red-400">*</span>
                                        Industry Type
                                      </ListboxLabel>
                                      <ListboxButton
                                        @blur="v$.industry_type.$touch()"
                                        :class="[
                                          v$.industry_type.$error ? 'border-red-400' : 'border-grey-300',
                                        ]"
                                        class="relative px-4 py-4 mt-2 text-left placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                      >
                                        <span v-if="!v$.industry_type.$model" class="text-gray-400 block truncate">Select Industry Type</span>
                                        <span v-else class="block truncate">{{ v$.industry_type.$model }}</span>
                                        <span
                                          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                                        >

                                        </span>
                                      </ListboxButton>

                                      <transition
                                        leave-active-class="transition duration-100 ease-in"
                                        leave-from-class="opacity-100"
                                        leave-to-class="opacity-0"
                                      >
                                        <ListboxOptions
                                          class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                        >
                                          <ListboxOption
                                            v-slot="{ active, selected }"
                                            v-for="industry in industries"
                                            :key="industry"
                                            :value="industry"
                                            as="template"
                                          >
                                            <li
                                              :class="[
                                                active ? 'text-white bg-black' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-10 pr-4',
                                              ]"
                                            >
                                              <span
                                                :class="[
                                                  selected ? 'font-medium' : 'font-normal',
                                                  'block truncate',
                                                ]"
                                                >{{ industry }}</span
                                              >
                                              <span
                                                v-if="selected"
                                                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400"
                                              >

                                              </span>
                                            </li>
                                          </ListboxOption>
                                        </ListboxOptions>
                                      </transition>
                                    </div>
                                  </Listbox>
                                  <div v-if="v$.industry_type.$error">
                                    <p
                                      v-if="v$.industry_type.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Industry type is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6 z-20">
                                  <Listbox v-model="v$.business_model.$model">
                                    <div class="relative mt-1">
                                      <ListboxLabel class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                                        <span class="text-red-400">*</span>
                                        Business Model
                                      </ListboxLabel>
                                      <ListboxButton
                                        @blur="v$.business_model.$touch()"
                                        :class="[
                                          v$.business_model.$error ? 'border-red-400' : 'border-grey-300',
                                        ]"
                                        class="relative px-4 py-4 mt-2 text-left placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                      >
                                        <span v-if="!v$.business_model.$model" class="block truncate text-gray-400">Select Business model</span>
                                        <span v-else class="block truncate">{{ v$.business_model.$model }}</span>
                                        <span
                                          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                                        >

                                        </span>
                                      </ListboxButton>

                                      <transition
                                        leave-active-class="transition duration-100 ease-in"
                                        leave-from-class="opacity-100"
                                        leave-to-class="opacity-0"
                                      >
                                        <ListboxOptions
                                          class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                        >
                                          <ListboxOption
                                            v-slot="{ active, selected }"
                                            v-for="model in businessmodel"
                                            :key="model"
                                            :value="model"
                                            as="template"
                                          >
                                            <li
                                              :class="[
                                                active ? 'text-white bg-black' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-10 pr-4',
                                              ]"
                                            >
                                              <span
                                                :class="[
                                                  selected ? 'font-medium' : 'font-normal',
                                                  'block truncate',
                                                ]"
                                                >{{ model }}</span
                                              >
                                              <span
                                                v-if="selected"
                                                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400"
                                              >

                                              </span>
                                            </li>
                                          </ListboxOption>
                                        </ListboxOptions>
                                      </transition>
                                    </div>
                                  </Listbox>
                                  <div v-if="v$.business_model.$error">
                                    <p
                                      v-if="v$.business_model.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Business model is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-sm font-normal"
                                    htmlFor="firstname"
                                  >
                                    <span class="text-red-400">*</span>
                                    First Name
                                  </label>
                                  <input
                                    id="firstname"
                                    name="firstname"
                                    type="text"
                                    :class="[
                                      v$.first_name.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="John"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.first_name.$model"
                                    @blur="v$.first_name.$touch()"
                                  />
                                  <div v-if="v$.first_name.$error">
                                    <p
                                      v-if="v$.first_name.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >First name is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-sm font-normal"
                                    htmlFor="lastname"
                                  >
                                    <span class="text-red-400">*</span>
                                    Last Name
                                  </label>
                                  <input
                                    id="lastname"
                                    name="lastname"
                                    type="text"
                                    :class="[
                                      v$.last_name.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="Doe"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.last_name.$model"
                                    @blur="v$.last_name.$touch()"
                                  />
                                  <div v-if="v$.last_name.$error">
                                    <p
                                      v-if="v$.last_name.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Last name is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-sm font-normal"
                                    htmlFor="email"
                                  >
                                    <span class="text-red-400">*</span>
                                    Email
                                  </label>
                                  <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    :class="[
                                      v$.email.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="name@mail.com"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.email.$model"
                                    @blur="v$.email.$touch()"
                                  />
                                  <div v-if="v$.email.$error">
                                    <p
                                      v-if="v$.email.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Email is required</p>
                                  </div>
                                </div>
                                <div class="relative flex justify-end w-full mb-6">
                                  <button
                                    :disabled="v$.$invalid"
                                    class="flex bg-black text-white rounded-[6px] text-[16px] leading-[24px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none disabled:opacity-50"
                                    @click="onSubmit"
                                  >
                                    <span class="mr-2 text-white">Submit</span>
                                    <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                      <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                      </circle>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="flex flex-col items-center justify-center h-full">
                            <h3 class="font-medium font-Rubik text-[16px] leading-[18.96px] text-black mb-[8px]">
                              Success!
                            </h3>
                            <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">Target company created!</p>
                            <button @click.prevent="closeModal()" class="mt-3 border-b border-teal font-medium py-[12px] px-[35px] text-white text-[13px] leading-[15.41px] rounded-[4px] bg-black">Okay</button>
                          </div>
                        </template>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </main>
</template>

<script>
import { ref, reactive, computed, watch, onMounted } from 'vue'
import {
  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import Helpers from '../utils/helpers'
import useVuelidate from '@vuelidate/core'
import { required, url, between, minLength } from '@vuelidate/validators'
import industries from '@/utils/industries'
import businessmodel from '@/utils/businessmodels'
import fundinground from '@/utils/fundinground'
import fundingtype from '@/utils/fundingtype'
import { useStore } from 'vuex'
import CompanyService from '@/services/company.service.js'
import { useRouter } from 'vue-router'

export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption
  },
  setup () {
    const store = useStore()
    const targetCompanyData = computed(() => store.state.app.targetCompanies)
    const s3Bucket = process.env.VUE_APP_ORIGINS_S3_BUCKET
    const addTargetCompanyModal = ref(false)
    const router = useRouter()

    const files = ref({
      pitch_deck: null,
      logo: null
    })
    const loading = ref(false)
    const newTargetCompany = ref(false)
    const data = reactive({
      first_name: '',
      last_name: '',
      email: '',
      company_name: '',
      company_summary: '',
      website: '',
      year_founded: '',
      location: '',
      address: {},
      coordinates: '',
      pitch_deck: '',
      pitch_deck_type: '',
      logo: '',
      logo_type: '',
      industry_type: '',
      business_model: ''
      // funding_round: '',
      // funding_type: '',
      // min_amount: 0,
      // max_amount: 200000
    })
    const rules = computed(() => ({
      first_name: { required },
      last_name: { required },
      email: { required },
      company_name: {
        required,
        minLengthValue: minLength(3)
      },
      company_summary: {
        required,
        minLengthValue: minLength(3)
      },
      website: { required, url },
      year_founded: {
        required,
        betweenValue: between(1500, 2099)
      },
      location: { required },
      pitch_deck: { required },
      logo: { required },
      industry_type: { required },
      business_model: { required }
      // funding_round: { required },
      // funding_type: { required },
      // min_amount: { required },
      // max_amount: { required, betweenValue: between(200000, 1500000) }
    }))

    /**
     * @param {object} event - file event
     * @param {string} name - The name of the file ref
     * @param {string} type - The type of file eg(Legal)
     */
    const choosedata = (event, name, type, category) => {
      if (event.target.files[0]) {
        data[name] = event.target.files[0]
        data[type] = category
        files.value[name] = event.target.files[0].name
      }
    }

    const getDate = (created) => {
      const date = new Date(created.split('T')[0])
      return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date)
    }

    function getLocation () {
      const locationinput = document.getElementById('location')
      // eslint-disable-next-line no-undef
      const loc = new google.maps.places.Autocomplete(locationinput)
      loc.addListener('place_changed', () => {
        const place = loc.getPlace()
        const filteredPlace = Helpers.extractAddress(place)
        data.location = filteredPlace.formattedAddress
        data.address = filteredPlace.address
        data.coordinates = filteredPlace.coordinates
      })
    }

    const openNewTargetCompany = () => {
      addTargetCompanyModal.value = true
    }

    const closeModal = () => {
      addTargetCompanyModal.value = false
      newTargetCompany.value = false
    }

    const v$ = useVuelidate(rules, data)

    const updateCompanyList = async () => {
      try {
        const response = await CompanyService.fetchAllCompany()
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setCompany', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const onSubmit = async () => {
      loading.value = true
      const yearFounded = Number(data.year_founded)
      const formData = new FormData()
      formData.append('first_name', data.first_name)
      formData.append('last_name', data.last_name)
      formData.append('email', data.email)
      formData.append('company_name', data.company_name)
      formData.append('company_summary', data.company_summary)
      formData.append('website', data.website)
      formData.append('year_founded', yearFounded)
      formData.append('formatted_address', data.location)
      formData.append('address[city]', data.address.city)
      formData.append('address[state]', data.address.state)
      formData.append('address[country]', data.address.country)
      formData.append('address[code]', data.address.code)
      formData.append('pitch_deck', data.pitch_deck)
      formData.append('pitch_deck_type', data.pitch_deck_type)
      formData.append('logo', data.logo)
      formData.append('logo_type', data.logo_type)
      formData.append('coordinates[type]', 'Point')
      formData.append('coordinates[coordinates][]', data.coordinates.coordinates[0])
      formData.append('coordinates[coordinates][]', data.coordinates.coordinates[1])
      formData.append('industry_type', data.industry_type)
      formData.append('business_model', data.business_model)
      try {
        const response = await CompanyService.createTargetCompany(formData)
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = false
          newTargetCompany.value = true
          const success = {
            status: true,
            message: 'Target company created'
          }
          store.commit('setSuccess', success)
        }
        updateCompanyList()
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }

    const getAllTargetCompanies = async () => {
      try {
        const response = await CompanyService.fetchAllTargetCompanies()
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setTargetCompany', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const goToCompany = (id) => {
      router.push({ name: 'Company Dealflow', params: { id: id } })
    }

    onMounted(() => {
      getAllTargetCompanies()
      watch(() => addTargetCompanyModal.value, (modalOpen) => {
      // Watch the modal for adding company to collection and make a request for available companies only if the modal is open
        if (modalOpen) {
          setTimeout(() => {
            getLocation()
          }, 500)
        }
      }
      )
    })

    return {
      addTargetCompanyModal,
      data,
      rules,
      // effectSlider,
      choosedata,
      onSubmit,
      v$,
      files,
      loading,
      industries,
      Helpers,
      businessmodel,
      fundinground,
      fundingtype,
      openNewTargetCompany,
      targetCompanyData,
      getAllTargetCompanies,
      s3Bucket,
      getDate,
      closeModal,
      newTargetCompany,
      goToCompany
    }
  }
}
</script>

<style scoped>
input[type='range'] {
  width: 100%;
  height: 4px;
  -webkit-appearance: none;
  background-color: rgba(229, 230, 230, 1);
  border-radius: 5px;
  outline: none;
}

/* CHROME */
input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  color: transparent;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 3;
  position: relative;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  transition: .3s ease-in-out;
  z-index: 3;
  outline: none;
}
/* input[type='range']::-moz-range-track-slider-thumb {
  -moz-appearance: none;
  appearance: none;
  outline: none;
} */

/* MOZ */
input[type='range']::-moz-range-thumb {
  width: 30px;
  height: 30px;
  appearance: none;
  -moz-appearance: none;
  outline: none;
  background: transparent;
  border: none;
}
input[type='range']:active::-webkit-slider-thumb {
  outline: none;
}
input[type="range"]::-moz-range-progress {
  height: 100%;
  border-radius: 5;
}
input[type="range"]::-moz-range-track {
  background-color: rgba(229, 230, 230, 1);
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #000000;
}
input[type="range"]::-ms-fill-upper {
  background-color: rgba(229, 230, 230, 1);
}
/* #fundingSlider:hover + #selector>.currentValue {
  opacity: 1;
} */
/* #selectValue::after {
  content: '';
  border-top: 8px solid #000;
  border-left: 8px solid #fff;
  border-right: 8px solid #fff;
  position: absolute;
  bottom: -8px;
  left: 32px;
} */
</style>
