import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class CompanyService {
  static createTargetCompany (data) {
    return axios.post(`${url}/admin/target-company`, data)
  }

  static fetchAllCompany () {
    return axios.get(`${url}/admin/company/fetch`)
  }

  static fetchAllTargetCompaniesNotInAnyCollection (collectionId) {
    return axios.get(`${url}/admin/company/fetch?not-in-collection=${collectionId}`)
  }

  static createTargetCompanyAndAddToCollection (collectionId, data) {
    return axios.post(`${url}/admin/target-company?add-to-collection=${collectionId}`, data)
  }

  static fetchOnlyRegularCompanies () {
    return axios.get(`${url}/admin/company/fetch?only-regular-companies=true`)
  }

  static fetchAllTargetCompanies () {
    return axios.get(`${url}/admin/company/fetch?only-target-companies=true`)
  }

  static fetchAllPortfolioCompanies () {
    return axios.get(`${url}/admin/company/fetch?only-portfolio-companies=true`)
  }

  static fetchSingleCompany (id) {
    return axios.get(`${url}/admin/company/fetch/${id}`)
  }

  static fetchAllCompanyfiles (id) {
    return axios.get(`${url}/admin/company/files/${id}`)
  }

  static approveCompany (data) {
    return axios.post(`${url}/admin/company/approve`, data)
  }

  static rejectCompany (data) {
    return axios.post(`${url}/admin/company/reject`, data)
  }

  static updateCompanySummary (companyId, data) {
    return axios.patch(`${url}/admin/company/${companyId}/summary`, data)
  }
}

export default CompanyService
