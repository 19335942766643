export const memos = [
  {
    memo_title: 'The Netflix Memo 📌📌',
    company: {
      name: 'Netflix',
      type: 'B2C',
      logo: require('../assets/netflix-logo.png')
    },
    industry: 'Entertainment',
    date_created: '13 Apr, 2022',
    deal_lead: {
      name: 'Latade Dipe',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Founder Journey',
    status: 'Approved',
    slug: 'netflix-memo'
  },
  {
    memo_title: 'Airbnb Memo 📌💰',
    company: {
      name: 'Airbnb',
      type: 'B2BC',
      logo: require('../assets/memos-airbnb.jpeg')
    },
    industry: 'Hospitality',
    date_created: '12 Apr, 2022',
    deal_lead: {
      name: 'Mary Mary',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Product & Tech',
    status: 'Approved',
    slug: 'airbnb-memo'
  },
  {
    memo_title: 'Amazon Memo 📦📦',
    company: {
      name: 'Amazon Prime',
      type: 'B2BC',
      logo: require('../assets/memos-amazon-prime.jpeg')
    },
    industry: 'Retail',
    date_created: '13 Apr, 2022',
    deal_lead: {
      name: 'Latade Dipe',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Unit Economics',
    status: 'Approved',
    slug: 'amazon-memo'
  },
  {
    memo_title: 'The Piggy Memo 🐖🐖',
    company: {
      name: 'Piggyvest',
      type: 'B2C',
      logo: require('../assets/memos-piggyvest.jpeg')
    },
    industry: 'Investment',
    date_created: '12 Apr, 2022',
    deal_lead: {
      name: 'Faithful Biyi',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Founder Journey',
    status: 'Approved',
    slug: 'piggyvest-memo'
  },
  {
    memo_title: 'Risten Development Memo  📌',
    company: {
      name: 'Risten',
      type: 'B2C',
      logo: require('../assets/memos-dummy-logo.png')
    },
    industry: 'Entertainment',
    date_created: '11 Apr, 2022',
    deal_lead: {
      name: 'Chukwu James',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Fundraising',
    status: 'Approved',
    slug: 'risten-memo'
  },
  {
    memo_title: 'Soft Web Digital Memo 💻📌',
    company: {
      name: 'Soft web',
      type: 'B2C',
      logo: require('../assets/memos-dummy-logo.png')
    },
    industry: 'Technology',
    date_created: '9 Apr, 2022',
    deal_lead: {
      name: 'Toni Campbell',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Founder Journey',
    status: 'Approved',
    slug: 'softweb-memo'
  },
  {
    memo_title: 'Techpoint media 💻',
    company: {
      name: 'Techpoint',
      type: 'B2C',
      logo: require('../assets/memos-dummy-logo.png')
    },
    industry: 'Technology',
    date_created: '9 Apr, 2022',
    deal_lead: {
      name: 'Latade Dipe',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Data Request 4',
    status: 'Approved',
    slug: 'techpoint-memo'
  },
  {
    memo_title: 'Prime Pak Memo💰',
    company: {
      name: 'Prime Pak',
      type: 'B2C',
      logo: require('../assets/memos-dummy-logo.png')
    },
    industry: 'Retail',
    date_created: '4 Apr, 2022',
    deal_lead: {
      name: 'Latade Dipe',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Onboarding',
    status: 'Approved',
    slug: 'prime-pak-memo'
  },
  {
    memo_title: 'Clane Company Memo 👍🏽',
    company: {
      name: 'Clane',
      type: 'B2C',
      logo: require('../assets/memos-dummy-logo.png')
    },
    industry: 'Construction',
    date_created: '2 Apr, 2022',
    deal_lead: {
      name: 'Latade Dipe',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Founder Journey',
    status: 'Approved',
    slug: 'clane-memo'
  },
  {
    memo_title: 'The Netflix Memo 📌📌',
    company: {
      name: 'Netflix',
      type: 'B2C',
      logo: require('../assets/netflix-logo.png')
    },
    industry: 'Entertainment',
    date_created: '13 Apr, 2022',
    deal_lead: {
      name: 'Latade Dipe',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Founder Journey',
    status: 'Approved',
    slug: 'netflix-memo'
  },
  {
    memo_title: 'Airbnb Memo 📌💰',
    company: {
      name: 'Airbnb',
      type: 'B2BC',
      logo: require('../assets/memos-airbnb.jpeg')
    },
    industry: 'Hospitality',
    date_created: '12 Apr, 2022',
    deal_lead: {
      name: 'Mary Mary',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Product & Tech',
    status: 'Approved',
    slug: 'airbnb-memo'
  },
  {
    memo_title: 'Amazon Memo 📦📦',
    company: {
      name: 'Amazon Prime',
      type: 'B2BC',
      logo: require('../assets/memos-amazon-prime.jpeg')
    },
    industry: 'Retail',
    date_created: '13 Apr, 2022',
    deal_lead: {
      name: 'Latade Dipe',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Unit Economics',
    status: 'Approved',
    slug: 'amazon-memo'
  },
  {
    memo_title: 'The Piggy Memo 🐖🐖',
    company: {
      name: 'Piggyvest',
      type: 'B2C',
      logo: require('../assets/memos-piggyvest.jpeg')
    },
    industry: 'Investment',
    date_created: '12 Apr, 2022',
    deal_lead: {
      name: 'Faithful Biyi',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Founder Journey',
    status: 'Approved',
    slug: 'piggyvest-memo'
  },
  {
    memo_title: 'Risten Development Memo  📌',
    company: {
      name: 'Risten',
      type: 'B2C',
      logo: require('../assets/memos-dummy-logo.png')
    },
    industry: 'Entertainment',
    date_created: '11 Apr, 2022',
    deal_lead: {
      name: 'Chukwu James',
      position: 'Deal Lead',
      avatar: require('../assets/memos-dummy-logo.png')
    },
    current_stage: 'Fundraising',
    status: 'Approved',
    slug: 'risten-memo'
  },
  {
    memo_title: 'Soft Web Digital Memo 💻📌',
    company: {
      name: 'Soft web',
      type: 'B2C',
      logo: require('../assets/memos-dummy-logo.png')
    },
    industry: 'Technology',
    date_created: '9 Apr, 2022',
    deal_lead: {
      name: 'Toni Campbell',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Founder Journey',
    status: 'Approved',
    slug: 'softweb-memo'
  },
  {
    memo_title: 'Techpoint media 💻',
    company: {
      name: 'Techpoint',
      type: 'B2C',
      logo: require('../assets/memos-dummy-logo.png')
    },
    industry: 'Technology',
    date_created: '9 Apr, 2022',
    deal_lead: {
      name: 'Latade Dipe',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Data Request 4',
    status: 'Approved',
    slug: 'techpoint-memo'
  },
  {
    memo_title: 'Prime Pak Memo💰',
    company: {
      name: 'Prime Pak',
      type: 'B2C',
      logo: require('../assets/memos-dummy-logo.png')
    },
    industry: 'Retail',
    date_created: '4 Apr, 2022',
    deal_lead: {
      name: 'Latade Dipe',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Onboarding',
    status: 'Approved',
    slug: 'prime-pak-memo'
  },
  {
    memo_title: 'Clane Company Memo 👍🏽',
    company: {
      name: 'Clane',
      type: 'B2C',
      logo: require('../assets/memos-dummy-logo.png')
    },
    industry: 'Construction',
    date_created: '2 Apr, 2022',
    deal_lead: {
      name: 'Latade Dipe',
      position: 'Deal Lead',
      avatar: require('../assets/memo-deal-lead.png')
    },
    current_stage: 'Founder Journey',
    status: 'Approved',
    slug: 'clane-memo'
  }
]

export const allFiles = [
  {
    id: 'b9346dae-4692-48b0-89ca-d5e782689148',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655454786476-pitchdeck.pdf',
    mime_type: 'application/pdf',
    document_type: 'PRESENTATION',
    created_at: '2022-06-17T08:33:06.555Z',
    updated_at: '2022-06-17T08:33:06.555Z'
  },
  {
    id: '40b4ca4b-fdea-4e8f-8ac5-3e4c2bc0ae54',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655454786479-1f3a7@2x.png',
    mime_type: 'image/png',
    document_type: 'PRESENTATION',
    created_at: '2022-06-17T08:33:06.555Z',
    updated_at: '2022-06-17T08:33:06.555Z'
  },
  {
    id: 'dd26cdd4-3f3e-4686-a615-e3d3428db82d',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655457264830-current-capitalization.pdf',
    mime_type: 'application/pdf',
    document_type: 'FINANCIAL',
    created_at: '2022-06-17T09:14:24.898Z',
    updated_at: '2022-06-17T09:14:24.898Z'
  },
  {
    id: 'cdf85e0b-7457-469f-a8e8-ec69c90c2663',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655457297377-article-of-incorporation.pdf',
    mime_type: 'application/pdf',
    document_type: 'LEGAL',
    created_at: '2022-06-17T09:14:57.442Z',
    updated_at: '2022-06-17T09:14:57.442Z'
  },
  {
    id: '8af4b544-b7d4-45a4-b783-35d54bdbeb03',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655457500437-employee-resume.pdf',
    mime_type: 'application/pdf',
    document_type: 'PRESENTATION',
    created_at: '2022-06-17T09:18:20.517Z',
    updated_at: '2022-06-17T09:18:20.517Z'
  },
  {
    id: 'afdbc379-3b9c-4c3a-bae5-b223486c4342',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655457508189-employee-resume copy.pdf',
    mime_type: 'application/pdf',
    document_type: 'PRESENTATION',
    created_at: '2022-06-17T09:18:28.224Z',
    updated_at: '2022-06-17T09:18:28.224Z'
  },
  {
    id: 'fc6c6fd3-2872-45f9-a895-9e815f86fe53',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655457515575-employee-resume copy 2.pdf',
    mime_type: 'application/pdf',
    document_type: 'PRESENTATION',
    created_at: '2022-06-17T09:18:35.619Z',
    updated_at: '2022-06-17T09:18:35.619Z'
  },
  {
    id: 'f7c74e12-dc71-42a6-bfbc-e47927f10b7f',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655468788082-sample-customer-contract.pdf',
    mime_type: 'application/pdf',
    document_type: 'LEGAL',
    created_at: '2022-06-17T12:26:28.153Z',
    updated_at: '2022-06-17T12:26:28.153Z'
  },
  {
    id: 'a2e7ebfc-ef2d-4945-bb47-7960b8cec1ae',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655468796869-demo-customer-account.pdf',
    mime_type: 'application/pdf',
    document_type: 'LEGAL',
    created_at: '2022-06-17T12:26:36.904Z',
    updated_at: '2022-06-17T12:26:36.904Z'
  },
  {
    id: 'be80bb8f-b3b1-4065-9236-10c9e8a1d08a',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655468808871-technical-product-doc.pdf',
    mime_type: 'application/pdf',
    document_type: 'PRODUCT AND TECH',
    created_at: '2022-06-17T12:26:48.912Z',
    updated_at: '2022-06-17T12:26:48.912Z'
  },
  {
    id: '6bcb0f64-2b1a-4e78-b164-b84512903bbb',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655468835340-productandtech-roadmap.pdf',
    mime_type: 'application/pdf',
    document_type: 'PRODUCT AND TECH',
    created_at: '2022-06-17T12:27:15.382Z',
    updated_at: '2022-06-17T12:27:15.382Z'
  },
  {
    id: '0c1d7c98-846e-4d58-9722-93fd80bc8200',
    company_id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
    file_name: '1655470462945-financial-model.pdf',
    mime_type: 'application/pdf',
    document_type: 'FINANCIAL',
    created_at: '2022-06-17T12:54:23.023Z',
    updated_at: '2022-06-17T12:54:23.023Z'
  }
]

export default memos
