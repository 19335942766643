import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class MemoService {
  static createMemo (data) {
    return axios.post(`${url}/admin/memo/`, data)
  }

  static getMemos () {
    return axios.get(`${url}/admin/memo/`)
  }

  static getMemobyId (memoId) {
    return axios.get(`${url}/admin/memo/${memoId}`)
  }

  static updateMemo (memoId, data) {
    return axios.put(`${url}/admin/memo/${memoId}`, data)
  }
}

export default MemoService
