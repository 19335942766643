import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class FinancialService {
  static getConnectedAccounts (companyId) {
    return axios.get(`${url}/admin/financials/${companyId}/accounts`)
  }

  static getStakeholders () {
    return axios.get(`${url}/admin/stakeholder`)
  }

  static createStakeholder (data) {
    return axios.post(`${url}/admin/stakeholder`, data)
  }

  static getCategories () {
    return axios.get(`${url}/admin/category`)
  }

  static getFinancialStatements (accountId, statementType, period) {
    return axios.get(`${url}/admin/financials/${accountId}?statement_type=${statementType}&period=${period}`)
  }
}

export default FinancialService
