<template>
  <router-view :key="$route.path"/>
  <!-- **$%^**:key="$route.path"refresh components on params change**$%^*$ -->
</template>

<script>
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()

    const token = computed(() => store.state.auth.usertoken)
    const options = { method: 'GET', headers: { accept: 'application/json' } }

    fetch('https://api.withmono.com/v1/institutions', options)
      .then(response => response.json())
      .then(response => store.dispatch('setInstitutions', response))
      .catch(err => console.error(err))

    onMounted(() => {
      if (token.value) {
        const { exp } = jwtDecode(token.value)
        if (Date.now() >= exp * 1000) {
          store.dispatch('logoutUser')
        }
        axios.defaults.headers.common.Authorization = `Bearer ${token.value}`
      }
    })

    watch(
      () => token.value,
      async (val) => {
        if (val) {
          axios.defaults.headers.common.Authorization = `Bearer ${token.value}`
        } else {
          delete axios.defaults.headers.common.Authorization
        }
      },
      { immediate: true }
    )
  }
}
</script>

<style>
</style>
