import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class StageTwoService {
  static getStageTwo (id) {
    return axios.get(`${url}/admin/stage-two/fetch/${id}`)
  }
}

export default StageTwoService
