const state = {
  company: null,
  company_files: null,
  files: null,
  error: {
    status: false,
    message: null
  },
  alert: {
    status: false,
    type: null,
    color: null,
    message: null
  },
  current_company: null,
  collection: null,
  institutions: [],
  regularCompanies: null,
  targetCompanies: null,
  transactions: [],
  portfolioCompanies: null,
  balanceSheet: null,
  incomeStatement: null
}
const getters = {
}
const mutations = {
  setCompany (state, company) {
    state.company = company
  },
  setRegularCompany (state, regularCompanies) {
    state.regularCompanies = regularCompanies
  },
  setTargetCompany (state, targetCompanies) {
    state.targetCompanies = targetCompanies
  },
  setCurrentCompany (state, currentCompany) {
    state.current_company = currentCompany
  },
  setCompanyFiles (state, files) {
    state.files = files
  },
  setCollection (state, collection) {
    state.collection = collection
  },
  setError (state, error) {
    state.error = error
  },
  setAlert (state, data) {
    state.alert = data
  },
  setInstitutions (state, institutions) {
    state.institutions = institutions
  },
  setTransactions (state, transactions) {
    state.transactions = transactions
  },
  setPortfolioCompanies (state, portfolioCompanies) {
    state.portfolioCompanies = portfolioCompanies
  },
  setBalanceSheet (state, balanceSheet) {
    state.balanceSheet = balanceSheet
  },
  setIncomeStatement (state, incomeStatement) {
    state.incomeStatement = incomeStatement
  },
  resetApp (state) {
    state.company = null
    state.company_files = null
    state.files = null
    state.current_company = null
  }
}
const actions = {
  setCompany ({ commit }, company) {
    commit('setCompany', company)
  },
  setRegularCompany ({ commit }, regularCompanies) {
    commit('setRegularCompany', regularCompanies)
  },
  setTargetCompany ({ commit }, targetCompanies) {
    commit('setTargetCompany', targetCompanies)
  },
  setCurrentCompany ({ commit }, currentCompany) {
    commit('setCurrentCompany', currentCompany)
  },
  setCompanyFiles ({ commit }, files) {
    commit('setCompanyFiles', files)
  },
  setCollection ({ commit }, collection) {
    commit('setCollection', collection)
  },
  setError ({ commit }, error) {
    commit('setError', error)
  },
  setAlert ({ commit }, alert) {
    commit('setAlert', alert)
  },
  setInstitutions ({ commit }, institutions) {
    commit('setInstitutions', institutions)
  },
  setTransactions ({ commit }, transactions) {
    commit('setTransactions', transactions)
  },
  setPortfolioCompanies ({ commit }, portfolioCompanies) {
    commit('setPortfolioCompanies', portfolioCompanies)
  },
  setBalanceSheet ({ commit }, balanceSheet) {
    commit('setBalanceSheet', balanceSheet)
  },
  setIncomeStatement ({ commit }, incomeStatement) {
    commit('setIncomeStatement', incomeStatement)
  },
  resetApp ({ commit }) {
    commit('resetApp')
  }
}

export default { state, getters, mutations, actions }
