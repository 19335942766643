<template>
  <div class="map__main relative w-full">
    <div id="map"></div>
  </div>
</template>

<script>
import Mapboxgl from 'mapbox-gl'
import { onMounted } from 'vue'
// import Geojson from '../../assets/collection.json'

export default {
  props: ['mapData', 'markerColor'],
  setup (props) {
    Mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN

    const Geojson = {
      type: 'FeatureCollection',
      features: []
    }

    onMounted(() => {
      const getFeature = () => {
        // create a collection of features from company data
        if (props.mapData.companies.length > 0) {
          let data

          for (let index = 0; index < props.mapData?.companies.length; index++) {
            data = props.mapData?.companies[index]
            const payload = {
              type: 'feature',
              geometry: {
                type: 'Point',
                coordinates: [data?.geom.coordinates[1], data?.geom.coordinates[0]]
              },
              properties: {
                place_name: data?.company_name,
                industry_type: data?.industry_type,
                address: data?.formatted_address,
                business_model: data?.business_model,
                website: data?.website
              }
            }
            Geojson.features.push(payload)
          }
        }
      }
      getFeature()
      if (props.mapData.companies.length > 0) {
        var map = new Mapboxgl.Map({
          container: 'map',
          // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
          style: process.env.VUE_APP_MAPBOX_STYLE_URL,
          zoom: 1.9,
          // zoom: `${this.windowWidth > 1024 ? 1.5 : this.windowWidth < 600 ? 1.0 : 1.7}`,
          center: Geojson.features[0].geometry.coordinates,
          // center: [0, 0],
          projection: 'globe',
          attributionControl: true
        })

        map.on('load', () => {
          map.addSource('companies', {
            type: 'geojson',
            data: Geojson
          })
          map.addLayer({
            id: 'location',
            type: 'circle',
            source: 'companies',
            paint: {
              'circle-radius': 4,
              'circle-stroke-width': 1,
              'circle-color': props.markerColor,
              'circle-stroke-color': '#ffffff'
            },
            minzoom: 0.6
          })
          map.on('click', 'location', (e) => {
            new Mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(`
                <div class="tour-callout" style="background-color: ${props.markerColor}!important">
                  <div class="callout-container">
                    <h3 style="font-family: Rubik">${e.features[0].properties.place_name}</h3>
                    <div class="text-container">
                      <h4 style="font-family: Rubik">Business Model: </h4>
                      <h5 style="font-family: Rubik">${e.features[0].properties.business_model}</h5>
                    </div>
                    <div class="text-container">
                      <h4 style="font-family: Rubik">Industry type: </h4>
                      <h5 style="font-family: Rubik">${e.features[0].properties.industry_type}</h5>
                    </div>
                    <div class="text-container">
                      <h4 style="font-family: Rubik">Location: </h4>
                      <h5 style="font-family: Rubik">${e.features[0].properties.address.replace(' ', ', ')}</h5>
                    </div>
                    <div class="text-container">
                      <h4 style="font-family: Rubik">Website: </h4>
                      <a href="${e.features[0].properties.website}" target="_blank" style="font-family: Rubik">${e.features[0].properties.website}</a>
                    </div>
                  </div>
                </div>
              `) // replace with the content you want to display in the popup
              .addTo(map)
          })
        })
      }
    })

    return { }
  }
}
</script>

<style>
#map {
  position: absolute !important;
  top: 0;
  bottom: 0;
  width: 100%;
  height: fit-content;
}
#map canvas {
  /* height: calc(100vh - 300px) !important; */
  height: 80vh !important;
  width: -webkit-fill-available !important;
  margin: 0;
}
#map .mapboxgl-ctrl-attrib-inner {
  font-size: 13px !important;
  text-align: end;
}
#map .mapboxgl-canvas-container {
  border: solid 1px #dfdfdf;
  border-radius: 8px;
  overflow: hidden;
}
</style>
