<template>
  <main class="dealflow flex">
    <!-- <div class="mt-12 w-[157.5px] h-[157.5px]">
      <img class="rounded-full" src="../../assets/netflix-logo.png"/>
    </div> -->
    <div class="flex items-center mt-10 justify-center w-[157.5px] h-[157.5px] rounded-full bg-coolGray-100 p-1 ">
      <img class="rounded-full max-h-full max-w-full" :src="`${s3Bucket}${company.logo}`"/>
    </div>
    <div class="mt-9 ml-14 flex-1 flex-col min-w-0">
     <div class="w-full mx-auto bg-white rounded-2xl">
       <Disclosure v-slot="{ open, close }" v-for="(item, idx) in items" :key="item.id">
          <DisclosureButton
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-b border-gray-200"
          >
            <span>{{item.title}}</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500">
            <component :is="item.component"></component>
          </DisclosurePanel>

          <button
            :ref="(el) => (elements[idx] = el)"
            :data-id="item.id"
            v-show="false"
            @click="doClose(close)"
          ></button>
        <DisclosureStateEmitter :show="open" @show="hideOther(item.id)" />
        </Disclosure>
        <!-- <Disclosure v-slot="{ open }">
          <DisclosureButton
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-b border-gray-200"
          >
            <span>DATA REQUEST 1</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500">
            <Stage2></Stage2>
          </DisclosurePanel>
        </Disclosure>
        <Disclosure v-slot="{ open }">
          <DisclosureButton
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-b border-gray-200"
          >
            <span>PRODUCT & TECHNOLOGY DEEP DIVE</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500">
            <Stage3></Stage3>
          </DisclosurePanel>
        </Disclosure>
        <Disclosure v-slot="{ open }">
          <DisclosureButton
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-b border-gray-200"
          >
            <span>DATA REQUEST 2</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500">
            <Stage4></Stage4>
          </DisclosurePanel>
        </Disclosure>
        <Disclosure v-slot="{ open }">
          <DisclosureButton
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-b border-gray-200"
          >
            <span>UNIT ECONOMICS & FINANCIALS DEEP DIVE</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500">
            <Stage5></Stage5>
          </DisclosurePanel>
        </Disclosure>
        <Disclosure v-slot="{ open }">
          <DisclosureButton
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-b border-gray-200"
          >
            <span>DATA REQUEST 3</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500">
            <Stage6></Stage6>
          </DisclosurePanel>
        </Disclosure>
        <Disclosure v-slot="{ open }">
          <DisclosureButton
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-b border-gray-200"
          >
            <span>FUNDRAISING, HIRING & EXIT GOALS</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500">
            <Stage7></Stage7>
          </DisclosurePanel>
        </Disclosure>
        <Disclosure v-slot="{ open }">
          <DisclosureButton
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-b border-gray-200"
          >
            <span>DATA REQUEST 4</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500">
            <Stage8></Stage8>
          </DisclosurePanel>
        </Disclosure> -->
     </div>
    </div>
  </main>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { reactive, computed, ref } from 'vue'
import Stage1 from '@/views/stages/stage1.vue'
import Stage2 from '@/views/stages/stage2.vue'
import Stage3 from '@/views/stages/stage3.vue'
import Stage4 from '@/views/stages/stage4.vue'
import Stage5 from '@/views/stages/stage5.vue'
import Stage6 from '@/views/stages/stage6.vue'
import Stage7 from '@/views/stages/stage7.vue'
import Stage8 from '@/views/stages/stage8.vue'
import { useStore } from 'vuex'
import DisclosureStateEmitter from '../../components/DisclosureStateEmitter.vue'
export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
    DisclosureStateEmitter
  },
  setup () {
    const store = useStore()
    const company = computed(() => store.state.app.current_company)
    const s3Bucket = process.env.VUE_APP_ORIGINS_S3_BUCKET
    const progress = reactive({
      stage1: false,
      stage2: true,
      stage3: true
    })
    const disabled = reactive({
      stage1: false,
      stage2: true,
      stage3: true
    })
    const items = [
      {
        id: 1,
        title: 'FOUNDER JOURNEY & MARKET DEEP DIVE',
        component: Stage1
      },
      {
        id: 2,
        title: 'DATA REQUEST 1',
        component: Stage2
      },
      {
        id: 3,
        title: 'PRODUCT & TECHNOLOGY DEEP DIVE',
        component: Stage3
      },
      {
        id: 4,
        title: 'DATA REQUEST 2',
        component: Stage4
      },
      {
        id: 5,
        title: 'UNIT ECONOMICS & FINANCIALS DEEP DIVE',
        component: Stage5
      },
      {
        id: 6,
        title: 'DATA REQUEST 3',
        component: Stage6
      },
      {
        id: 7,
        title: 'FUNDRAISING, HIRING & EXIT GOALS',
        component: Stage7
      },
      {
        id: 8,
        title: 'DATA REQUEST 4',
        component: Stage8
      }
    ]
    const elements = ref([])

    const hideOther = (id) => {
      const items = elements.value.filter((elm) => {
        return elm.getAttribute('data-id') !== id.toString()
      })
      items.forEach((elm) => elm.click())
    }

    const doClose = (close) => {
      close()
    }
    return {
      progress,
      disabled,
      company,
      s3Bucket,
      elements,
      items,
      hideOther,
      doClose
    }
  }
}
</script>

<style>

</style>
