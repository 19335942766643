import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class AuthService {
  static signIn (data) {
    return axios.post(`${url}/admin/auth/signin`, data)
  }
}

export default AuthService
