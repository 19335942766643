<template>
  <main class="riskscorecard h-full mt-8 pb-2 flex flex-1 flex-col min-w-0 overflow-x-auto">
    <table class="table-auto font-Rubik text-[13px] z-[400]">
      <thead>
        <tr>
          <th class="eva" rowspan="2">RISK <br/> <span class="font-normal text-greyText">Ratings  1 [Low] to 5 [High]</span></th>
          <th :colspan="users.length" class="ratings">Ratings</th>
          <th rowspan="2" class="average min-w-[120px] bg-faintGrey">Avg Score</th>
        </tr>
        <tr class="users">
          <th v-for="(user, index) in users" :key="index">{{user}}</th>
        </tr>
      </thead>
      <tbody v-for="(risk, index) in allrisks" :key="risk" @click="toggleOpenState(index)">
        <tr class="total-entry">
          <td class="flex items-center font-medium text-[12px] leading-[14.22px] underline cursor-pointer">{{risk.toUpperCase()}}
            <svg
              :class="state[`activeState${index}`] ? 'transform rotate-180' : ''"
              class="w-5 h-5 "
              viewBox="0 0 24 24"
              width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"/>
              <path d="M12 8l6 6H6z"/>
            </svg>
          </td>
          <td v-for="(user, userindex) in users" :key="userindex">{{ getTotalPerceivedRiskByCategory(risk, user)}}</td>
          <td>{{totalScoreByCategory(risk)}}</td>
        </tr>
        <tr v-for="(score) in getRiskCategory(risk)" :key="score.id" class="numdata-entry text-greyText" :class="{closed: !state[`activeState${index}`]}">
          <td>{{score.type}}</td>
          <td v-for="(user, index) in users" :key="index">{{score[`${user}_score`]}}</td>
          <td>5</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="text-center">Total Perceived Risk Score</td>
          <td v-for="(user, index) in users" :key="index">{{getTotalPerceivedRisk(user)}}</td>
          <td>130</td>
        </tr>
      </tfoot>
    </table>

    <div id="floater" class="font-Rubik absolute bg-white rounded-[3px] transition-all duration-100 z-[500]" :class="[!state.floaterOn ? 'w-[340px] bottom-[35px] right-0' : 'min-w-full max-h-[calc(100vh-317px)] h-[100%] transform-none top-[150px] left-0 overflow-hidden']">
      <div class="horizontalscroll min-h-[calc(100vh-317px)]']">
        <div class="modalHead rounded-[3px] transition-all duration-[250ms]" :class="[!state.floaterOn ? 'border border-t-[10px]' : 'border-none bg-faintGrey']">
          <div class="grid w-full transition-all duration-[10ms] bg-white" :class="[!state.floaterOn ? '' : 'grid-cols-2']">
            <div class="left flex items-center justify-between border-r py-[14px] px-[22px] transition-all duration-[50ms] bg-faintGrey" :class="[!state.floaterOn ? 'hidden' : '']">
              <div class="title flex items-center font-medium text-[13px] leading-[15.41px]">
                <div id="submissionIcon" class="pr-[12px]">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.40298 0.395557C7.10223 0.411182 6.84273 0.699111 6.85836 0.999869C6.87398 1.30063 7.16191 1.55998 7.46281 1.54435H10.5261C10.8296 1.54863 11.1087 1.27352 11.1087 0.969955C11.1087 0.666533 10.8296 0.391283 10.5261 0.395557H7.40298ZM7.40298 1.92723C7.12266 1.95661 6.88694 2.21983 6.88841 2.50163V6.13926H5.54826C5.33432 6.13873 5.12518 6.26867 5.03103 6.46084C4.93687 6.65302 4.96212 6.8978 5.09353 7.06663L8.53971 11.4701C8.75339 11.7469 9.23537 11.7469 9.44919 11.4701L12.8954 7.06663C13.0268 6.89782 13.0522 6.65288 12.9579 6.46084C12.8637 6.26867 12.6546 6.13872 12.4406 6.13926H11.1005V2.50163C11.1005 2.20087 10.8268 1.92723 10.5261 1.92723H7.40292H7.40298ZM8.03721 3.07602H9.95175V6.71366C9.95175 7.01441 10.2254 7.28806 10.5261 7.28806H11.2561L8.99465 10.1778L6.73316 7.28806H7.46315C7.7639 7.28806 8.03755 7.01442 8.03755 6.71366L8.03721 3.07602ZM3.38247 4.0333C3.15703 4.0576 2.95432 4.22814 2.89179 4.44609L0.606238 12.4455C0.561632 12.563 0.575788 12.6988 0.570312 12.8044V17.0523C0.570312 17.3531 0.843952 17.6267 1.14471 17.6267H16.844C17.1447 17.6267 17.4184 17.3531 17.4184 17.0523V12.6256C17.4212 12.5667 17.406 12.529 17.3945 12.4814L15.2944 4.45809C15.2291 4.21584 14.9889 4.03248 14.7381 4.03327H12.0578C12.0379 4.0322 12.0179 4.0322 11.998 4.03327C11.6972 4.04889 11.4377 4.33682 11.4533 4.63758C11.469 4.93834 11.7569 5.19769 12.0578 5.18206H14.2954L16.1023 12.0744H12.0578C11.7571 12.0744 11.4834 12.3481 11.4834 12.6488V13.6061H6.5056V12.6488C6.5056 12.3481 6.23196 12.0744 5.9312 12.0744H1.90485L3.8732 5.18206H5.93131C6.23473 5.18634 6.51384 4.91123 6.51384 4.60767C6.51384 4.30424 6.23472 4.029 5.93131 4.03327H3.38253L3.38247 4.0333ZM1.71927 13.2231H5.35691V14.1804C5.35691 14.4811 5.63055 14.7548 5.93131 14.7548H12.0579C12.3586 14.7548 12.6323 14.4811 12.6323 14.1804V13.2231H16.2699V16.4778H1.71934L1.71927 13.2231Z" fill="black" fill-opacity="0.7"/>
                  </svg>
                </div>
                <div class="max-w-[max-content]">
                  <Listbox v-model="selectedStage">
                    <div class="relative mt-1">
                      <ListboxButton
                        class="relative w-full cursor-pointer py-2 pl-3 pr-10 text-left focus:outline-none "
                        v-slot="{ open }"
                      >
                        <span class="block truncate font-medium">{{ selectedStage.name }} Submission</span>
                        <span
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 w-[30px] h-30px]"
                        >
                          <svg v-show="open" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path fill="#000000" d="M17,13.41,12.71,9.17a1,1,0,0,0-1.42,0L7.05,13.41a1,1,0,0,0,0,1.42,1,1,0,0,0,1.41,0L12,11.29l3.54,3.54a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29A1,1,0,0,0,17,13.41Z"/>
                          </svg>
                          <svg v-show="!open" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path fill="#000000" d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/>
                          </svg>
                        </span>
                      </ListboxButton>

                      <transition
                        leave-active-class="transition duration-100 ease-in"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                      >
                        <ListboxOptions
                          class="absolute mt-1 max-h-[max-content] w-full overflow-auto rounded-md bg-white px-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-[1000]"
                        >
                          <ListboxOption
                            v-slot="{ active, selected }"
                            v-for="stage in dropdownStages"
                            :key="stage.name"
                            :value="stage"
                            as="template"
                          >
                            <li
                              class="border-b-[1px] border-b-faintGrey hover:font-bold"
                              :class="[
                                active ? 'bg-[transparent] text-gray-900' : 'text-gray-900',
                                'relative cursor-pointer select-none py-4 text-left',
                              ]"
                            >
                              <span
                                :class="[
                                  selected ? 'font-medium' : 'font-normal',
                                  'block truncate',
                                ]"

                                >{{ stage.name }}</span
                              >
                              <span
                                v-if="selected"
                                class="absolute inset-y-0 right-0 flex items-center pl-3 text-amber-600"
                              >
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="8" cy="8" r="8" fill="black"/>
                                    <path d="M4.83984 7.99996L6.95007 10.1093L11.1687 5.89062" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                              </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                </div>
              </div>
              <span class="flex items-center text-[13px] leading-[15.41px]">100% Complete
                <div id="blackTick" class="ml-[8px]">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="black"/>
                    <path d="M4.83984 7.99996L6.95007 10.1093L11.1687 5.89062" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </span>
            </div>
            <div class="right flex items-center justify-between border-l py-[14px] px-[22px] bg-faintGrey" :class="[!state.floaterOn ? '' : 'w-full']">
              <div class="title flex items-end font-medium text-[13px] leading-[15.41px]">
                <div id="reviewIcon" class="pr-[12px]">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.9231 0H2.07692C0.931688 0 0 0.931688 0 2.07692V15.9231C0 17.0683 0.931688 18 2.07692 18H15.9231C17.0683 18 18 17.0683 18 15.9231V2.07692C18 0.931688 17.0683 0 15.9231 0ZM1.38462 15.9231V2.07692C1.38462 1.69492 1.69523 1.38462 2.07692 1.38462H15.9231C16.3048 1.38462 16.6154 1.69492 16.6154 2.07692V15.6363L14.6541 13.6749C15.0169 13.1277 15.2308 12.4734 15.2308 11.7691C15.2308 9.8606 13.6777 8.30753 11.7692 8.30753C9.86076 8.30753 8.30769 9.8606 8.30769 11.7691C8.30769 13.6775 9.86076 15.2306 11.7692 15.2306C12.4736 15.2306 13.1279 15.0167 13.6751 14.6539L15.6364 16.6152H2.07708C1.69539 16.6152 1.38477 16.3049 1.38477 15.9229L1.38462 15.9231ZM11.7692 13.8462C10.624 13.8462 9.69231 12.9145 9.69231 11.7692C9.69231 10.624 10.624 9.69231 11.7692 9.69231C12.9145 9.69231 13.8462 10.624 13.8462 11.7692C13.8462 12.9145 12.9145 13.8462 11.7692 13.8462Z" fill="black" fill-opacity="0.7"/>
                    <path d="M3.11569 6.9227H14.8849C15.0761 6.9227 15.2311 6.7677 15.2311 6.57655V5.88424C15.2311 5.69308 15.0761 5.53809 14.8849 5.53809H3.11569C2.92453 5.53809 2.76953 5.69308 2.76953 5.88424V6.57655C2.76953 6.7677 2.92453 6.9227 3.11569 6.9227Z" fill="black" fill-opacity="0.7"/>
                    <path d="M3.11569 4.15415H14.8849C15.0761 4.15415 15.2311 3.99915 15.2311 3.80799V3.11569C15.2311 2.92453 15.0761 2.76953 14.8849 2.76953H3.11569C2.92453 2.76953 2.76953 2.92453 2.76953 3.11569V3.80799C2.76953 3.99915 2.92453 4.15415 3.11569 4.15415Z" fill="black" fill-opacity="0.7"/>
                    <path d="M6.57722 8.30762H3.11569C2.92453 8.30762 2.76953 8.46261 2.76953 8.65377V9.34607C2.76953 9.53722 2.92453 9.69222 3.11569 9.69222H6.57722C6.76838 9.69222 6.92338 9.53722 6.92338 9.34607V8.65377C6.92338 8.46261 6.76838 8.30762 6.57722 8.30762Z" fill="black" fill-opacity="0.7"/>
                    <path d="M6.57722 11.0762H3.11569C2.92453 11.0762 2.76953 11.2312 2.76953 11.4223V12.1146C2.76953 12.3058 2.92453 12.4608 3.11569 12.4608H6.57722C6.76838 12.4608 6.92338 12.3058 6.92338 12.1146V11.4223C6.92338 11.2312 6.76838 11.0762 6.57722 11.0762Z" fill="black" fill-opacity="0.7"/>
                    <path d="M6.57722 13.8457H3.11569C2.92453 13.8457 2.76953 14.0007 2.76953 14.1919V14.8842C2.76953 15.0753 2.92453 15.2303 3.11569 15.2303H6.57722C6.76838 15.2303 6.92338 15.0753 6.92338 14.8842V14.1919C6.92338 14.0007 6.76838 13.8457 6.57722 13.8457Z" fill="black" fill-opacity="0.7"/>
                  </svg>
                </div>
                Your Risk Review
              </div>
              <button class="expand p-[10px]" @click="toggleFloater">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path v-if="state.floaterOn" fill-rule="evenodd" clip-rule="evenodd" d="M10.957 4.54286L13.8534 1.64642C14.0487 1.45114 14.0487 1.13458 13.8534 0.939331L13.0605 0.146424C12.8653 -0.0488258 12.5487 -0.0488258 12.3534 0.146424L9.45701 3.04286L8.48582 2.01252C8.01335 1.54005 7.20701 1.87467 7.20701 2.54286L7.20701 6.04286C7.20701 6.45708 7.54129 6.79286 7.95551 6.79286L11.4555 6.79286C12.1237 6.79286 12.4583 5.98502 11.9858 5.51252L10.957 4.54286ZM3.04288 9.45702L0.146439 12.3535C-0.0488418 12.5487 -0.0488418 12.8653 0.146439 13.0605L0.939346 13.8535C1.1346 14.0487 1.45119 14.0487 1.64644 13.8535L4.54288 10.957L5.51406 11.9874C5.98653 12.4598 6.79288 12.1252 6.79288 11.457L6.79288 7.95702C6.79288 7.5428 6.4586 7.20702 6.04438 7.20702L2.54438 7.20702C1.87619 7.20702 1.54156 8.01486 2.01406 8.48736L3.04288 9.45702Z" fill="black" fill-opacity="0.6"/>
                  <path v-else d="M6.64644 8.85356L3.75 11.75L4.77881 12.7197C5.25131 13.1922 4.91669 14 4.2485 14H0.7485C0.334281 14 0 13.6642 0 13.25V9.75C0 9.08181 0.806344 8.74719 1.27881 9.21966L2.25 10.25L5.14644 7.35356C5.34169 7.15831 5.65828 7.15831 5.85353 7.35356L6.64644 8.14647C6.84172 8.34172 6.84172 8.65828 6.64644 8.85356ZM7.35356 5.14644L10.25 2.25L9.22119 1.28034C8.74869 0.807844 9.08331 0 9.7515 0H13.2515C13.6657 0 14 0.335781 14 0.75V4.25C14 4.91819 13.1937 5.25281 12.7212 4.78034L11.75 3.75L8.85356 6.64644C8.65831 6.84169 8.34172 6.84169 8.14647 6.64644L7.35356 5.85353C7.15828 5.65828 7.15828 5.34172 7.35356 5.14644Z" fill="black" fill-opacity="0.6"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <transition name="fade">
          <div v-if="showContent" id="modalBody" class="grid grid-cols-2 bg-white" :class="[!state.floaterOn ? 'w-full h-[0] min-h-0' : 'w-[unset] h-full min-h-[calc(100vh-317px)]']">
            <div class="leftt px-[40px] pl-0 py-[30px] border-r max-h-screen h-[calc(100vh-350px)] overflow-scroll">
              <div v-if="selectedStage.name === 'Founder Journey'">
                <Stage1></Stage1>
              </div>
              <div v-if="selectedStage.name === 'Data Request 1'">
                <Stage2></Stage2>
              </div>
              <div v-if="selectedStage.name === 'Product & Tech'">
                <Stage3></Stage3>
              </div>
              <div v-if="selectedStage.name === 'Data Request 2'">
                <Stage4></Stage4>
              </div>
              <div v-if="selectedStage.name === 'Unit Economics'">
                <Stage5></Stage5>
              </div>
              <div v-if="selectedStage.name === 'Data Request 3'">
                <Stage6></Stage6>
              </div>
            </div>
            <div class="rightt px-[40px] py-[30px] border-l max-h-screen h-[calc(100vh-350px)] overflow-scroll">
              <div v-if="selectedStage.name === 'Founder Journey'">
                <Stage1Review></Stage1Review>
              </div>
              <div v-if="selectedStage.name === 'Data Request 1'">
                <Stage2Review></Stage2Review>
              </div>
              <div v-if="selectedStage.name === 'Product & Tech'">
                <Stage3Review></Stage3Review>
              </div>
              <div v-if="selectedStage.name === 'Data Request 2'">
                <Stage4Review></Stage4Review>
              </div>
              <div v-if="selectedStage.name === 'Unit Economics'">
                <Stage5Review></Stage5Review>
              </div>
              <div v-if="selectedStage.name === 'Data Request 3'">
                <Stage6Review></Stage6Review>

              </div>
              <div v-if="selectedStage.name === 'Fundraising'">
                <!-- <Stage7Review></Stage7Review> -->
Nil
              </div>
              <div v-if="selectedStage.name === 'Data Request 4'">
                <!-- <Stage8Review></Stage8Review> -->
Nil
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </main>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
// import riskscore from '@/utils/riskscore.js'
import risks from '@/utils/risk.js'
import Stage1 from '@/views/stages/stage1.vue'
import Stage2 from '@/views/stages/stage2.vue'
import Stage3 from '@/views/stages/stage3.vue'
import Stage4 from '@/views/stages/stage4.vue'
import Stage5 from '@/views/stages/stage5.vue'
import Stage6 from '@/views/stages/stage6.vue'
// import Stage7 from '@/views/stages/stage7.vue'
// import Stage8 from '@/views/stages/stage8.vue'
import Stage1Review from '@/views/riskscorecard/stage1Review'
import Stage2Review from '@/views/riskscorecard/stage2Review'
import Stage3Review from '@/views/riskscorecard/stage3Review'
import Stage4Review from '@/views/riskscorecard/stage4Review'
import Stage5Review from '@/views/riskscorecard/stage5Review'
import Stage6Review from '@/views/riskscorecard/stage6Review'
// import Stage7Review from '@/views/riskscorecard/stage7Review'
// import Stage8Review from '@/views/riskscorecard/stage8Review'
import stages from '@/utils/stages.js'
import RiskscoreService from '@/services/riskscore.service'
import Helpers from '@/utils/helpers'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import {
  Listbox,
  // ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'

export default {
  components: {
    Stage1,
    Stage2,
    Stage3,
    Stage4,
    Stage5,
    Stage6,
    // Stage7,
    // Stage8,
    Stage1Review,
    Stage2Review,
    Stage3Review,
    Stage4Review,
    Stage5Review,
    Stage6Review,
    // Stage7Review,
    // Stage8Review,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const state = reactive({
      activeState0: false,
      activeState1: false,
      activeState2: false,
      activeState3: false,
      floaterOn: false
    })
    const allStages = ref(stages)
    const allrisks = ref(risks)
    // const risksCategory = ref(riskscore)
    const riskScore = ref([])
    const users = ref([])
    const getRiskScore = async () => {
      try {
        const id = route.params.id
        const response = await RiskscoreService.fetchRiskscoreById(id)
        if (response.status === 200 && response.data.status === 'success') {
          riskScore.value = response.data.data
          const data = response.data.data[0]
          const ausers = []
          for (const k in data) {
            if (k.endsWith('score')) {
              ausers.push(k.split('_')[0])
            }
          }
          users.value = ausers
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }
    getRiskScore()
    const toggleOpenState = (id) => {
      switch (id) {
        case 0:
          state.activeState0 = !state.activeState0
          break
        case 1:
          state.activeState1 = !state.activeState1
          break
        case 2:
          state.activeState2 = !state.activeState2
          break
        case 3:
          state.activeState3 = !state.activeState3
          break
        default:
          state.activeState0 = false
          state.activeState1 = false
          state.activeState2 = false
          state.activeState3 = false
          break
      }
    }
    const getRiskCategory = (category) => {
      return riskScore.value.filter(risk => risk.category === category)
    }

    const getTotalPerceivedRiskByCategory = (category, user) => {
      const risks = getRiskCategory(category)
      const score = risks.map(risk => {
        const userScore = risk[`${user}_score`]
        return userScore
      })
      const total = score.reduce((a, b) => a + b, 0)
      return total
    }

    const getTotalPerceivedRisk = (user) => {
      const score = riskScore.value.map(risk => {
        const userScore = risk[`${user}_score`]
        return userScore
      })
      const total = score.reduce((a, b) => a + b, 0)
      return total
    }

    const totalScoreByCategory = (risk) => {
      let score
      switch (risk) {
        case 'Product & Tech Risks':
          score = 30
          break
        case 'Market Risks':
          score = 30
          break
        case 'Management Risks':
          score = 50
          break
        case 'Other Risks':
          score = 20
          break
        default:
          score = 0
          break
      }
      return score
    }

    const toggleFloater = () => {
      if (state.floaterOn) {
        getRiskScore()
        state.floaterOn = false
      } else {
        state.floaterOn = true
      }

      // state.floaterOn = !state.floaterOn
      showContent.value = !showContent.value
    }
    const showContent = ref(false)

    const dropdownStages = [
      // { name: 'Onboarding' },
      { name: 'Founder Journey' },
      { name: 'Data Request 1' },
      { name: 'Product & Tech' },
      { name: 'Data Request 2' },
      { name: 'Unit Economics' },
      { name: 'Data Request 3' }
    ]
    const selectedStage = ref(dropdownStages[0])

    return {
      state,
      toggleOpenState,
      getRiskCategory,
      allrisks,
      toggleFloater,
      showContent,
      allStages,
      selectedStage,
      dropdownStages,
      users,
      getTotalPerceivedRisk,
      getTotalPerceivedRiskByCategory,
      totalScoreByCategory
    }
  }
}
</script>

<style scoped>
table,
td,
th {
  border-right: 1px solid rgba(244, 245, 245, 1);
  padding: 12px 24px;
}

table {
  border: 1px solid rgba(244, 245, 245, 1);
  border-collapse: collapse;
  width: 100%;
  min-width: 900px;
}
.average {
  border-left: solid 1px #E5E6E6;
}

th {
  height: 35px;
  width: 100px;
}
.eva {
  width: auto;
  border-right: solid 1px #E5E6E6;
}
td {
  height: 62px;
}
thead {
  border: solid 1px #E5E6E6;
}
tbody {
  font-size: 14px;
  border-bottom: solid 1.3px #E5E6E6;
}
tbody > tr td, tfoot > tr td:not(:first-child) {
  padding-right: 8px;
}
tbody > tr td {
  border-right: 1px solid #E5E6E6;
}
tbody tr:not(:first-child), tbody > tr td {
  border-bottom: solid 1px white;
}
tfoot > tr {
  background-color: rgba(244, 245, 245, 1);
  font-weight: 500;
}
tfoot > tr > td:first-child {
  border: 1px solid rgba(244, 245, 245, 1) !important;
}
tfoot > tr > td:not(:last-child) {
  border: 1px solid white;
}
tfoot > tr > td:not(:first-child) {
  text-align: right !important;
}
.users > th {
  font-weight: 400;
  font-size: 13px;
  line-height: 15.41px;
  border: none;
  background-color: rgba(244, 245, 245, 1);
}
.ratings {
  background-color: rgba(244, 245, 245, 1);
  border-bottom: solid 1px white;
  border-right: solid 1px #E5E6E6;
  border-left: solid 1px #E5E6E6;
}
.total-entry td {
  text-align: right;
}
.numdata-entry td:not(:first-child) {
  text-align: right;
  width: 100px !important;
  color: #828282
}
.numdata-entry > td:last-child {
  color: black
}
.closed {
  display: none;
}
</style>
