import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class StageSixService {
  static getStageSix (id) {
    return axios.get(`${url}/admin/stage-six/fetch/${id}`)
  }
}

export default StageSixService
