<template>
  <main class="font-Rubik">
    <h2 class="text-[20px] leading-[23.7px] mb-[32px]">PRODUCT AND TECH REVIEW</h2>
    <div class="instructions text-greyText mb-[30px]">
      <p class="text-[15px] leading-[17.78px] mb-[8px]">Instructions:</p>
      <p class="text-[13px] leading-[20px]">Kindly read the diligence information provided
         by the individual company to the left in full.
          Afterwards, answer the below questions,
           referencing the diligence information when needed.
            Please leave helpful comments and feedback
             to supplement your scores.
             </p>
    </div>
    <div class="mt-20" v-if="!dataloaded">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(241, 242, 243); display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(26.666666666666668,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#000000">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.3s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#636363">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.2s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(26.666666666666668,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#e1e1e1">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="0s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#545454">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.1s"></animateTransform>
          </rect>
        </g>
      </svg>
    </div>
    <div v-else id="riskReview" class="activeArea">

      <div class="product-service grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Has the company communicated a clear, high quality solution?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Product/Service"
            id="no-score-product-service"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Product/Service') == '0'"
            @change="setRatings($event, 'productService')"
            v-model="productService"
          >
          <label for="no-score-product-service" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Product/Service"
            id="extremely-unclear-product-service"
            value="1" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Product/Service') == '1'"
            @change="setRatings($event, 'productService')"
            v-model="productService"
          >
          <label for="extremely-unclear-product-service" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The product/service is unclear, and/or this is a low-quality solution.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Product/Service"
            id="somewhat-unclear-product-service"
            value="2" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Product/Service') == '2'"
            @change="setRatings($event, 'productService')"
            v-model="productService"
          >
          <label for="somewhat-unclear-product-service" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The product/service is somewhat clear, and from what I understand, this solution seems mediocre.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Product/Service"
            id="clear-product-service"
            value="3" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Product/Service') == '3'"
            @change="setRatings($event, 'productService')"
            v-model="productService"
          >
          <label for="clear-product-service" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]"> The product/service is clear and this is a pretty good solution.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Product/Service"
            id="very-clear-product-service"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Product/Service') == '4'"
            @change="setRatings($event, 'productService')"
            v-model="productService"
          >
          <label for="very-clear-product-service" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]"> The product/service is very clear and this good solution seems valuable.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Product/Service"
            id="extremely-clear-product-service"
            value="5" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Product/Service') == '5'"
            @change="setRatings($event, 'productService')"
            v-model="productService"
          >
          <label for="extremely-clear-product-service" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]"> The product/service is very compelling and this is great solution has significant, demonstrated value. </span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-product-service" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-product-service"
            rows="3"
            cols="50"
            name="Product/Service"
            @blur="setComments($event, productService)"
            :disabled="commentStatus.productService &&  getRatingScore('Product/Service') == '0'"
            :class="[commentStatus.productService &&  getRatingScore('Product/Service') == '0' ? 'hover:cursor-not-allowed' : '']"
            placeholder="Leave feedback here..."
            required
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
            :value="getRatingComment('Product/Service')"></textarea>
        </div>
      </div>

      <div class="proprietaryOrProtected grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Is the product or service, novel, unique, and useful? Can this potentially receive patent protection?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Proprietary or Protected"
            id="no-score-proprietary"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Proprietary or Protected') == '0'"
            @change="setRatings($event, 'proprietaryOrProtected')"
            v-model="proprietaryOrProtected"
          >
          <label for="no-score-proprietary" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Proprietary or Protected"
            id="extremely-unclear-proprietary"
            value="1" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Proprietary or Protected') == '1'"
            @change="setRatings($event, 'proprietaryOrProtected')"
            v-model="proprietaryOrProtected"
          >
          <label for="extremely-unclear-proprietary" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The product/service lacks novelty, uniqueness, or utility; unlikely to be patentable.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Proprietary or Protected"
            id="somewhat-unclear-proprietary"
            value="2" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Proprietary or Protected') == '2'"
            @change="setRatings($event, 'proprietaryOrProtected')"
            v-model="proprietaryOrProtected"
          >
          <label for="somewhat-unclear-proprietary" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The product/service has some elements of novelty and usefulness but may struggle with patent eligibility.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Proprietary or Protected"
            id="clear-proprietary"
            value="3" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Proprietary or Protected') == '3'"
            @change="setRatings($event, 'proprietaryOrProtected')"
            v-model="proprietaryOrProtected"
          >
          <label for="clear-proprietary" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The product/service is novel and useful, with a moderate chance of patent protection.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Proprietary or Protected"
            id="very-clear-proprietary"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Proprietary or Protected') == '4'"
            @change="setRatings($event, 'proprietaryOrProtected')"
            v-model="proprietaryOrProtected"
          >
          <label for="very-clear-proprietary" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The product/service is highly novel, unique, and useful, showing strong potential for patent protection in some aspects of the offering’s design and/or utility.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Proprietary or Protected"
            id="extremely-clear-proprietary"
            value="5" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Proprietary or Protected') == '5'"
            @change="setRatings($event, 'proprietaryOrProtected')"
            v-model="proprietaryOrProtected"
          >
          <label for="extremely-clear-proprietary" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The product/service is exceptionally innovative and useful with a high likelihood of successful patenting in all aspects of its offering.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-proprietary" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-proprietary"
            rows="3"
            cols="50"
            name="Proprietary or Protected"
            @blur="setComments($event, proprietaryOrProtected)"
            :disabled="commentStatus.proprietaryOrProtected &&  getRatingScore('Proprietary or Protected') == '0'"
            :class="[commentStatus.proprietaryOrProtected &&  getRatingScore('Proprietary or Protected') == '0' ? 'hover:cursor-not-allowed' : '']"
            placeholder="Leave feedback here..."
            required
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
            :value="getRatingComment('Proprietary or Protected')"></textarea>
        </div>
      </div>

      <div class="relevantTechnology grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Does the team have enough skills and resources to develop the product / service? Does the team’s current tech stack not only consider the current requirements of the current product, but also provides for the future maintainability and scalability?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant technology"
            id="no-score-relevant-technology"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant technology') == '0'"
            @change="setRatings($event, 'relevantTechnology')"
            v-model="relevantTechnology"
          >
          <label for="no-score-relevant-technology" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant technology"
            id="extremely-unclear-relevant-technology"
            value="1" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant technology') == '1'"
            @change="setRatings($event, 'relevantTechnology')"
            v-model="relevantTechnology"
          >
          <label for="extremely-unclear-relevant-technology" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The team lacks the necessary skills and resources; tech stack is inadequate</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant technology"
            id="somewhat-unclear-relevant-technology"
            value="2" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant technology') == '2'"
            @change="setRatings($event, 'relevantTechnology')"
            v-model="relevantTechnology"
          >
          <label for="somewhat-unclear-relevant-technology" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The team has some skills and resources, but the tech stack is limited for current and future needs.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant technology"
            id="clear-relevant-technology"
            value="3" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant technology') == '3'"
            @change="setRatings($event, 'relevantTechnology')"
            v-model="relevantTechnology"
          >
          <label for="clear-relevant-technology" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The team is adequately skilled and resourced, with a tech stack suitable for present needs and some future scalability.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant technology"
            id="very-clear-relevant-technology"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant technology') == '4'"
            @change="setRatings($event, 'relevantTechnology')"
            v-model="relevantTechnology"
          >
          <label for="very-clear-relevant-technology" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The team is well-equipped with skills and resources; the tech stack is robust for current and future scalability.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant technology"
            id="extremely-clear-relevant-technology"
            value="5" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant technology') == '5'"
            @change="setRatings($event, 'relevantTechnology')"
            v-model="relevantTechnology"
          >
          <label for="extremely-clear-relevant-technology" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The team possesses exceptional skills and abundant resources; the tech stack is cutting-edge, ensuring current excellence, future scalability, and future innovation at the frontier of technology.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-relevant-technology" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-relevant-technology"
            rows="3"
            cols="50"
            name="Relevant technology"
            @blur="setComments($event, relevantTechnology)"
            :disabled="commentStatus.relevantTechnology && getRatingScore('Relevant technology') == '0'"
            :class="[commentStatus.relevantTechnology && getRatingScore('Relevant technology') == '0' ? 'hover:cursor-not-allowed' : '']"
            placeholder="Leave feedback here..."
            required
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
            :value="getRatingComment('Relevant technology')"></textarea>
        </div>
      </div>

      <div class="dailyOperatingTasks grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Does the company’s product / service require resource-intensive operational functions? E.g. last mile delivery, freight, procurement, warehousing, facilities management. Does the company have High intensity (tech enabled) or low intensity (high tech) startup operations?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Daily operating tasks"
            id="no-score-operating-tasks"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Daily operating tasks') == '0'"
            @change="setRatings($event, 'dailyOperatingTasks')"
            v-model="dailyOperatingTasks"
          >
          <label for="no-score-operating-tasks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Daily operating tasks"
            id="extremely-unclear-operating-tasks"
            value="1" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Daily operating tasks') == '1'"
            @change="setRatings($event, 'dailyOperatingTasks')"
            v-model="dailyOperatingTasks"
          >
          <label for="extremely-unclear-operating-tasks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The operational functions are extremely resource-intensive and inefficient.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Daily operating tasks"
            id="somewhat-unclear-operating-tasks"
            value="2" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Daily operating tasks') == '2'"
            @change="setRatings($event, 'dailyOperatingTasks')"
            v-model="dailyOperatingTasks"
          >
          <label for="somewhat-unclear-operating-tasks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The operations are somewhat resource-intensive with mediocre efficiency.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Daily operating tasks"
            id="clear-operating-tasks"
            value="3" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Daily operating tasks') == '3'"
            @change="setRatings($event, 'dailyOperatingTasks')"
            v-model="dailyOperatingTasks"
          >
          <label for="clear-operating-tasks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">Operations are adequately managed with a reasonable level of resource intensity.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Daily operating tasks"
            id="very-clear-operating-tasks"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Daily operating tasks') == '4'"
            @change="setRatings($event, 'dailyOperatingTasks')"
            v-model="dailyOperatingTasks"
          >
          <label for="very-clear-operating-tasks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">Operations are well-managed, showing a good balance of resource use and efficiency.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Daily operating tasks"
            id="extremely-clear-operating-tasks"
            value="5" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Daily operating tasks') == '5'"
            @change="setRatings($event, 'dailyOperatingTasks')"
            v-model="dailyOperatingTasks"
          >
          <label for="extremely-clear-operating-tasks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The company excels in managing operations, with high efficiency and optimal resource use.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-operating-tasks" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-operating-tasks"
            rows="3"
            cols="50"
            name="Daily operating tasks"
            @blur="setComments($event, dailyOperatingTasks)"
            :disabled="commentStatus.dailyOperatingTasks && getRatingScore('Daily operating tasks') == '0'"
            :class="[commentStatus.dailyOperatingTasks && getRatingScore('Daily operating tasks') == '0' ? 'hover:cursor-not-allowed' : '']"
            placeholder="Leave feedback here..."
            required
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
            :value="getRatingComment('Daily operating tasks')"></textarea>
        </div>
      </div>

      <!-- <div class="flex justify-end pt-[28px]">
        <button class="font-medium text-greyText border border-greyText min-w-[99px] py-[12px] px-[16px] rounded-[4px] bg-[transparent] mr-[1rem]">Save Draft</button>
        <button class="font-medium text-white border border-originsGreen min-w-[143px] py-[12px] px-[16px] rounded-[4px] bg-originsGreen ml-[1rem]">Mark As Complete</button>
      </div> -->
    </div>
  </main>
</template>

<script>
/* eslint-disable camelcase */
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import RiskscoreService from '@/services/riskscore.service'
import Helpers from '@/utils/helpers'
import { useStore } from 'vuex'

export default {
  components: {
  },
  setup () {
    const dataloaded = ref(false)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const ratings = ref([])
    const productService = ref(null)
    const proprietaryOrProtected = ref(null)
    const relevantTechnology = ref(null)
    const dailyOperatingTasks = ref(null)
    const commentStatus = ref({
      productService: true,
      proprietaryOrProtected: true,
      dailyOperatingTasks: true,
      relevantTechnology: true
    })

    const getRaterScore = async () => {
      try {
        const id = route.params.id
        const response = await RiskscoreService.fetchRiskscoreByRater(id)
        if (response.status === 200 && response.data.status === 'success') {
          ratings.value = response.data.data
          dataloaded.value = true
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const getRatingScore = (type) => {
      const rate = ratings.value.filter(rating => rating.type === type)
      return rate[0].score
    }

    const getRatingComment = (type) => {
      const rate = ratings.value.filter(rating => rating.type === type)
      return rate[0].comment
    }

    getRaterScore()

    const setRatings = (event, name) => {
      if (event.target.value > 0) {
        commentStatus.value[name] = false
      } else {
        commentStatus.value[name] = true
      }
    }

    const setComments = async (event, name) => {
      const score = Number(name) === 0 ? getRatingScore(event.target.name) : Number(name)
      const data = {
        category: Helpers.getRiskCategory(event.target.name),
        type: event.target.name,
        comment: event.target.value,
        score: score
      }
      const id = route.params.id
      try {
        const response = await RiskscoreService.createRiskscore(id, data)
        if (response.status === 200 && response.data.status === 'success') {
          Helpers.processRiskSuccess(response, store, response.data.message)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    return {
      dataloaded,
      ratings,
      getRatingScore,
      setRatings,
      getRatingComment,
      setComments,
      productService,
      commentStatus,
      proprietaryOrProtected,
      relevantTechnology,
      dailyOperatingTasks
    }
  }
}
</script>

<style scoped>
textarea {
  max-width: 100%;
  border: solid 1px #E5E6E6;
  padding: 1rem;
  margin-top: 1rem;
  font-size: 14px;
  line-height: 16.59px;
  font-weight: 400;
  transition: all .15s ease;
}
</style>
