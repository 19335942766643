<template>
  <main class="font-Rubik">
    <h2 class="text-[20px] leading-[23.7px] mb-[32px]">DATA REQUEST 1 REVIEW</h2>
    <div class="instructions text-greyText mb-[30px]">
      <p class="text-[15px] leading-[17.78px] mb-[8px]">Instructions:</p>
      <p class="text-[13px] leading-[20px]">Kindly read the diligence information provided
         by the individual company to the left in full.
          Afterwards, answer the below questions,
           referencing the diligence information when needed.
            Please leave helpful comments and feedback
             to supplement your scores.
             </p>
    </div>
    <div class="mt-20" v-if="!dataloaded">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(241, 242, 243); display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(26.666666666666668,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#000000">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.3s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#636363">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.2s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(26.666666666666668,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#e1e1e1">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="0s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#545454">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.1s"></animateTransform>
          </rect>
        </g>
      </svg>
    </div>
    <div v-else id="riskReview" class="activeArea">

      <div class="market-knowledge grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Do the founders / senior leaders have a clear vision of their target customer and market, backed by a mix of intuition and data?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="no-score-market-knowledge"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '0'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
            v-model="knowledgeOfTargetMarket"
          >
          <label for="no-score-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="extremely-noncompelling-market-knowledge"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '1'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
            v-model="knowledgeOfTargetMarket"
          >
          <label for="extremely-noncompelling-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">Founders lack a clear vision and understanding of the target market.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="somewhat-noncompelling-market-knowledge"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '2'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
            v-model="knowledgeOfTargetMarket"
          >
          <label for="somewhat-noncompelling-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">Founders have a limited vision and understanding, with some reliance on intuition and data.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="compelling-market-knowledge"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '3'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
            v-model="knowledgeOfTargetMarket"
          >
          <label for="compelling-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">Founders have a clear vision and good market understanding, balanced with intuition and data.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="somewhat-compelling-market-knowledge"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '4'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
            v-model="knowledgeOfTargetMarket"
          >
          <label for="somewhat-compelling-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">Founders exhibit a strong, data-backed vision and deep market understanding.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="extremely-compelling-market-knowledge"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '5'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
            v-model="knowledgeOfTargetMarket"
          >
          <label for="extremely-compelling-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">Founders demonstrate an exceptional vision and profound market insight beyond industry peers, effectively using market-leading intuition and proprietary data.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-market-knowledge" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-market-knowledge"
            rows="3"
            cols="50"
            name="Knowledge of target market (size or characteristics)"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, knowledgeOfTargetMarket)"
            :disabled="commentStatus.knowledgeOfTargetMarket &&  getRatingScore('Knowledge of target market (size or characteristics)') == '0'"
            :class="[commentStatus.knowledgeOfTargetMarket &&  getRatingScore('Knowledge of target market (size or characteristics)') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Knowledge of target market (size or characteristics)')"
          ></textarea>
        </div>
      </div>

      <!-- <div class="demonstrated-leadership grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Have the founders / senior management demonstrated accountability through their past actions, either at this company or in prior roles? How are they are setting the pace for leadership and performance excellence?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated leadership in the past"
            id="no-score-demonstrated-leadership"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated leadership in the past') == '0'"
            @change="setRatings($event, 'demonstratedPastLeadership')"
            v-model="demonstratedPastLeadership"
          >
          <label for="no-score-demonstrated-leadership" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated leadership in the past"
            id="extremely-noncompelling-demonstrated-leadership"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated leadership in the past') == '1'"
            @change="setRatings($event, 'demonstratedPastLeadership')"
            v-model="demonstratedPastLeadership"
          >
          <label for="extremely-noncompelling-demonstrated-leadership" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">Low quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated leadership in the past"
            id="somewhat-noncompelling-demonstrated-leadership"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated leadership in the past') == '2'"
            @change="setRatings($event, 'demonstratedPastLeadership')"
            v-model=" demonstratedPastLeadership"
          >
          <label for="somewhat-noncompelling-demonstrated-leadership" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">Somewhat Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated leadership in the past"
            id="compelling-demonstrated-leadership"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated leadership in the past') == '3'"
            @change="setRatings($event, 'demonstratedPastLeadership')"
            v-model=" demonstratedPastLeadership"
          >
          <label for="compelling-demonstrated-leadership" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">Moderate Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated leadership in the past"
            id="somewhat-compelling-demonstrated-leadership"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated leadership in the past') == '4'"
            @change="setRatings($event, 'demonstratedPastLeadership')"
            v-model=" demonstratedPastLeadership"
          >
          <label for="somewhat-compelling-demonstrated-leadership" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">Somewhat Strong Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated leadership in the past"
            id="extremely-compelling-demonstrated-leadership"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated leadership in the past') == '5'"
            @change="setRatings($event, 'demonstratedPastLeadership')"
            v-model=" demonstratedPastLeadership"
          >
          <label for="extremely-compelling-demonstrated-leadership" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">High Quality / Very Compelling</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-demonstrated-leadership" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-demonstrated-leadership"
            rows="3"
            cols="50"
            name="Demonstrated leadership in the past"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, demonstratedPastLeadership)"
            :disabled="commentStatus.demonstratedPastLeadership && getRatingScore('Demonstrated leadership in the past') == '0'"
            :class="[commentStatus.demonstratedPastLeadership && getRatingScore('Demonstrated leadership in the past') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Demonstrated leadership in the past')"
          ></textarea>
        </div>
      </div> -->

      <div class="track-record grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Do the founders / senior management understand the characteristics of a highly scalable business? Have the founders / management demonstrated the ability in the past to withstand pressure as a result of growth, without being hindered by its resources or structure?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant track record to venture"
            id="no-score-track-record"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant track record to venture') == '0'"
            @change="setRatings($event, 'trackRecordToVenture')"
             v-model="trackRecordToVenture"
          >
          <label for="no-score-track-record" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant track record to venture"
            id="extremely-noncompelling-track-record"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant track record to venture') == '1'"
            @change="setRatings($event, 'trackRecordToVenture')"
            v-model="trackRecordToVenture"
          >
          <label for="extremely-noncompelling-track-record" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">Founders lack understanding of scalability and show poor pressure management.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant track record to venture"
            id="somewhat-noncompelling-track-record"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant track record to venture') == '2'"
            @change="setRatings($event, 'trackRecordToVenture')"
            v-model=" trackRecordToVenture"
          >
          <label for="somewhat-noncompelling-track-record" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">Founders have limited scalability understanding and moderate pressure management skills.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant track record to venture"
            id="compelling-track-record"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant track record to venture') == '3'"
            @change="setRatings($event, 'trackRecordToVenture')"
            v-model=" trackRecordToVenture"
          >
          <label for="compelling-track-record" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">Founders understand scalability well and can handle growth-related pressure adequately.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant track record to venture"
            id="somewhat-compelling-track-record"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant track record to venture') == '4'"
            @change="setRatings($event, 'trackRecordToVenture')"
            v-model=" trackRecordToVenture"
          >
          <label for="somewhat-compelling-track-record" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">Founders have a strong grasp of scalability and demonstrate good pressure management.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Relevant track record to venture"
            id="extremely-compelling-track-record"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Relevant track record to venture') == '5'"
            @change="setRatings($event, 'trackRecordToVenture')"
            v-model=" trackRecordToVenture"
          >
          <label for="extremely-compelling-track-record" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">Founders excel in understanding scalability and are outstanding in managing growth pressures.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-track-record" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-track-record"
            rows="3"
            cols="50"
            name="Relevant track record to venture"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, trackRecordToVenture)"
            :disabled="commentStatus.trackRecordToVenture && getRatingScore('Relevant track record to venture') == '0'"
            :class="[commentStatus.trackRecordToVenture && getRatingScore('Relevant track record to venture') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Relevant track record to venture')"
          ></textarea>
        </div>
      </div>

      <div class="founder-risks grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Is the founder a first-time CEO? Can the founder successfully navigate from a first-time founder to a seasoned CEO?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="First time founders risk"
            id="no-score-founder-risks"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('First time founders risk') == '0'"
            @change="setRatings($event, 'firstTimeFoundersRisk')"
             v-model="firstTimeFoundersRisk"
          >
          <label for="no-score-founder-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="First time founders risk"
            id="extremely-noncompelling-founder-risks"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('First time founders risk') == '1'"
            @change="setRatings($event, 'firstTimeFoundersRisk')"
            v-model="firstTimeFoundersRisk"
          >
          <label for="extremely-noncompelling-founder-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The founder lacks the experience and skills to transition from a first-time founder to a seasoned CEO.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="First time founders risk"
            id="somewhat-noncompelling-founder-risks"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('First time founders risk') == '2'"
            @change="setRatings($event, 'firstTimeFoundersRisk')"
            v-model=" firstTimeFoundersRisk"
          >
          <label for="somewhat-noncompelling-founder-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The founder has some potential but shows only moderate ability to transition from a first-time founder to a seasoned CEO.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="First time founders risk"
            id="compelling-founder-risks"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('First time founders risk') == '3'"
            @change="setRatings($event, 'firstTimeFoundersRisk')"
            v-model=" firstTimeFoundersRisk"
          >
          <label for="compelling-founder-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The founder appears capable and shows good potential to transition from a first-time founder to a seasoned CEO.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="First time founders risk"
            id="somewhat-compelling-founder-risks"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('First time founders risk') == '4'"
            @change="setRatings($event, 'firstTimeFoundersRisk')"
            v-model=" firstTimeFoundersRisk"
          >
          <label for="somewhat-compelling-founder-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The founder demonstrates strong ability and clear readiness to transition from a first-time founder to a seasoned CEO.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="First time founders risk"
            id="extremely-compelling-founder-risks"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('First time founders risk') == '5'"
            @change="setRatings($event, 'firstTimeFoundersRisk')"
            v-model=" firstTimeFoundersRisk"
          >
          <label for="extremely-compelling-founder-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The founder exhibits exceptional skills and undeniable aptitude in transitioning from a first-time founder to a seasoned CEO, with proven results.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-founder-risks" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-founder-risks"
            rows="3"
            cols="50"
            name="First time founders risk"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, firstTimeFoundersRisk)"
            :disabled="commentStatus.firstTimeFoundersRisk && getRatingScore('First time founders risk') == '0'"
            :class="[commentStatus.firstTimeFoundersRisk && getRatingScore('First time founders risk') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('First time founders risk')"
          ></textarea>
        </div>
      </div>

      <div class="reputation grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Does the founder, CEO, and core management team shine in their reference checks?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Familiar with their reputation"
            id="no-score-reputation"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Familiar with their reputation') == '0'"
            @change="setRatings($event, 'familiarWithReputation')"
             v-model="familiarWithReputation"
          >
          <label for="no-score-reputation" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Familiar with their reputation"
            id="extremely-noncompelling-reputation"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Familiar with their reputation') == '01'"
            @change="setRatings($event, 'familiarWithReputation')"
            v-model="familiarWithReputation">
          <label for="extremely-noncompelling-reputation" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">Low quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Familiar with their reputation"
            id="somewhat-noncompelling-reputation"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Familiar with their reputation') == '2'"
            @change="setRatings($event, 'familiarWithReputation')"
            v-model="familiarWithReputation">
          <label for="somewhat-noncompelling-reputation" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">Somewhat Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Familiar with their reputation"
            id="compelling-reputation"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Familiar with their reputation') == '3'"
            @change="setRatings($event, 'familiarWithReputation')"
            v-model="familiarWithReputation">
          <label for="compelling-reputation" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">Moderate Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Familiar with their reputation"
            id="somewhat-compelling-reputation"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Familiar with their reputation') == '4'"
            @change="setRatings($event, 'familiarWithReputation')"
            v-model="familiarWithReputation">
          <label for="somewhat-compelling-reputation" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">Somewhat Strong Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Familiar with their reputation"
            id="extremely-compelling-reputation"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Familiar with their reputation') == '5'"
            @change="setRatings($event, 'familiarWithReputation')"
            v-model="familiarWithReputation">
          <label for="extremely-compelling-reputation" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">High Quality / Very Compelling</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-reputation" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-reputation"
            rows="3"
            cols="50"
            name="Familiar with their reputation"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, familiarWithReputation)"
            :disabled="commentStatus.familiarWithReputation &&  getRatingScore('Familiar with their reputation') == '0'"
            :class="[commentStatus.familiarWithReputation &&  getRatingScore('Familiar with their reputation') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Familiar with their reputation')"
          ></textarea>
        </div>
      </div>

      <div class="complementary-skills grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Does the core management team have complementary skills are dissimilar skills that, when combined, become more useful than individual skills in accomplishing a goal due to coordinated efforts of individual team members? Does each team member play to his unique strengths, such as strategy, leadership, or operations, while rallying around the team’s shared purpose?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Complementary skills"
            id="no-score-complementary-skills"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Complementary skills') == '0'"
            @change="setRatings($event, 'complementarySkills')"
             v-model="complementarySkills"
          >
          <label for="no-score-complementary-skills" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Complementary skills"
            id="extremely-noncompelling-complementary-skills"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Complementary skills') == '1'"
            @change="setRatings($event, 'complementarySkills')"
            v-model="complementarySkills"
          >
          <label for="extremely-noncompelling-complementary-skills" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The core management team lacks complementary skills and shows little evidence of coordinated efforts.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Complementary skills"
            id="somewhat-noncompelling-complementary-skills"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Complementary skills') == '2'"
            @change="setRatings($event, 'complementarySkills')"
            v-model=" complementarySkills"
          >
          <label for="somewhat-noncompelling-complementary-skills" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The core management team has some complementary skills but limited evidence of effective coordination.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Complementary skills"
            id="compelling-complementary-skills"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Complementary skills') == '3'"
            @change="setRatings($event, 'complementarySkills')"
            v-model=" complementarySkills"
          >
          <label for="compelling-complementary-skills" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The core management team has clear complementary skills and shows good coordination with early evidence in support..</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Complementary skills"
            id="somewhat-compelling-complementary-skills"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Complementary skills') == '4'"
            @change="setRatings($event, 'complementarySkills')"
            v-model=" complementarySkills"
          >
          <label for="somewhat-compelling-complementary-skills" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The core management team's skills are highly complementary, with strong evidence of effective coordination and teamwork.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Complementary skills"
            id="extremely-compelling-complementary-skills"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Complementary skills') == '5'"
            @change="setRatings($event, 'complementarySkills')"
            v-model=" complementarySkills"
          >
          <label for="extremely-compelling-complementary-skills" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The core management team exhibits exceptionally complementary skills, with outstanding coordination and synergy, significantly enhancing team performance.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-complementary-skills" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-complementary-skills"
            rows="3"
            cols="50"
            name="Complementary skills"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, complementarySkills)"
            :disabled="commentStatus.complementarySkills && getRatingScore('Complementary skills') == '0'"
            :class="[commentStatus.complementarySkills && getRatingScore('Complementary skills') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Complementary skills')"
          ></textarea>
        </div>
      </div>

    </div>
  </main>
</template>

<script>
/* eslint-disable camelcase */
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import RiskscoreService from '@/services/riskscore.service'
import Helpers from '@/utils/helpers'
import { useStore } from 'vuex'

export default {
  components: {
  },
  setup () {
    const dataloaded = ref(false)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const ratings = ref([])
    const knowledgeOfTargetMarket = ref(null)
    const demonstratedPastLeadership = ref(null)
    const trackRecordToVenture = ref(null)
    const firstTimeFoundersRisk = ref(null)
    const complementarySkills = ref(null)
    // Demonstrated leadership in the past
    const distributionChannels = ref(null)
    const supplierSourcingRisks = ref(null)
    const familiarWithReputation = ref(null)
    const commentStatus = ref({
      knowledgeOfTargetMarket: true,
      distributionChannels: true,
      supplierSourcingRisks: true,
      demonstratedPastLeadership: true,
      firstTimeFoundersRisk: true,
      trackRecordToVenture: true,
      complementarySkills: true,
      familiarWithReputation: true
    })

    const getRaterScore = async () => {
      try {
        const id = route.params.id
        const response = await RiskscoreService.fetchRiskscoreByRater(id)
        if (response.status === 200 && response.data.status === 'success') {
          ratings.value = response.data.data
          dataloaded.value = true
          // const element = []
          // for (let i = 0; i < ratings.value.length; i++) {
          //   const score = ratings.value[i]
          //   const node = document.getElementsByName(score.type)
          //   element.push(node)
          //   for (let j = 0; j < element[i].length; j++) {
          //     if (element[j].value === score.value) {
          //       element[j].checked = true
          //     }
          //   }
          // }
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const getRatingScore = (type) => {
      const rate = ratings.value.filter(rating => rating.type === type)
      // productService.value = rate[0].score
      return rate[0].score
    }

    const getRatingComment = (type) => {
      const rate = ratings.value.filter(rating => rating.type === type)
      return rate[0].comment
    }

    getRaterScore()

    const setRatings = (event, name) => {
      if (event.target.value > 0) {
        commentStatus.value[name] = false
      } else {
        commentStatus.value[name] = true
      }
    }

    const setComments = async (event, name) => {
      const score = Number(name) === 0 ? getRatingScore(event.target.name) : Number(name)
      const data = {
        category: Helpers.getRiskCategory(event.target.name),
        type: event.target.name,
        comment: event.target.value,
        score: score
      }
      const id = route.params.id
      try {
        const response = await RiskscoreService.createRiskscore(id, data)
        if (response.status === 200 && response.data.status === 'success') {
          Helpers.processRiskSuccess(response, store, response.data.message)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    return {
      dataloaded,
      ratings,
      getRatingScore,
      setRatings,
      getRatingComment,
      setComments,
      commentStatus,
      knowledgeOfTargetMarket,
      distributionChannels,
      supplierSourcingRisks,
      demonstratedPastLeadership,
      firstTimeFoundersRisk,
      trackRecordToVenture,
      complementarySkills,
      familiarWithReputation
    }
  }
}
</script>

<style scoped>
textarea {
  max-width: 100%;
  border: solid 1px #E5E6E6;
  padding: 1rem;
  margin-top: 1rem;
  font-size: 14px;
  line-height: 16.59px;
  font-weight: 400;
  transition: all .15s ease;
}
</style>
