import { onMounted } from 'vue';

<template>
  <iframe id="filePreviewIframe" class="filePreviewIframe w-[calc(100%+6px)] h-full bg-transparent scale-105" allowtransparency="true" scrolling="no" style="overflow:hidden; pointer-events:none;" :src="filePath" frameborder="0"></iframe>
</template>

<script>
import { onMounted } from 'vue'

export default {
  props: ['filePath'],
  setup (props) {
    console.log(props)

    onMounted(() => {
      var iframe = document.querySelector('.filePreviewIframe')
      var iframeDocument = iframe.contentWindow
      console.log(iframeDocument, 'framee')

      // var iframeContentElement = iframeDocument.querySelector('body') // Replace 'body' with the actual element
      // console.log(iframeContentElement)
      // if (iframeContentElement) {
      //   iframeContentElement.style.color = 'red'
      //   iframeContentElement.style.display = 'flex'
      //   // Add more styles as needed
      // }
    })

    return { }
  }
}
</script>

<style>

.scrollable-div::-webkit-scrollbar {
  display: none;
}

iframe #document body {
  background-color: transparent!important;
}

</style>
