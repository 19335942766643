<template>
  <div class="tooltip" id="tooltip-container">
    <slot></slot>
    <div class="tooltip-text text-xs left-[70%]">
      <span class="text truncate max-w-[250px]">{{text}}</span>
    </div>
  </div>
</template>

<script>
// import { ref } from 'vue'

export default {
  props: ['text'],
  setup () {
    // const hover = ref(false)
    return {}
  }
}
</script>

<style scoped>
  #tooltip-container {
    display: inline-block;
    position: relative;
  }
  #tooltip-container:hover .tooltip-text {
    visibility: visible;
  }
  .tooltip-text {
    color: #ffffff;
    text-align: center;
    padding: 5px;
    border-radius: 4px;

    min-width: 120px;
    bottom: 100%;
    /* left: 20%; */
    margin-left: -60px;

    visibility: hidden;
    transition: opacity 1s;

    position: absolute;
    z-index: 1;

    background: #000000;
  }
  .text::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000000 transparent transparent transparent;

  }
  /* .tooltip-text {
    position: absolute;
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    white-space: nowrap;
    padding: 10px 15px;
    border-radius: 6px;
  } */
  /* .tooltip-text::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    border: solid 6px;
    border-color: #000 #0000 #0000 #0000
  } */
</style>
