<template>
  <div class="tooltip" id="tooltip_container">
    <slot></slot>
    <div class="tooltip-text text-xs">
      <span class="text truncate max-w-[250px]">{{text}}</span>
    </div>
  </div>
</template>

<script>

export default {
  props: ['text'],
  setup () {
    return {}
  }
}
</script>

<style scoped>
  #tooltip_container {
    display: inline-block;
  }
  #tooltip_container:hover .tooltip-text {
    opacity: 1;
    transition: opacity .5s;
    visibility: visible;
  }
  .tooltip-text {
    color: #ffffff;
    text-align: center;
    padding: 16px;
    padding-left: 20px;
    border-radius: 4px;

    min-width: 100px;
    left: 100%;
    top: 0;

    opacity: 0;
    transition: opacity .5s;
    visibility: hidden;

    position: absolute;
    z-index: 1;

    background: #000000;
  }
</style>
