<template>
  <main class="report font-Rubik">

    <div class="">
        <TabGroup>
          <TabList as="div" class="flex items-center">
            <Tab as="div" v-slot="{ selected }">
              <button class="px-5 py-2 text-sm" :class="{ 'bg-black text-white rounded-tl-[5px] rounded-tr-[5px]': selected, 'bg-white text-black': !selected }">INCOME STATEMENT</button>
            </Tab>
            <Tab as="div" v-slot="{ selected }">
              <button class="px-5 py-2 text-sm" :class="{ 'bg-black text-white rounded-tl-[5px] rounded-tr-[5px]': selected, 'bg-white text-black': !selected }">BALANCE SHEET</button>
            </Tab>
            <!-- <Tab as="div" v-slot="{ selected }">
              <button class="px-5 py-2 text-sm" :class="{ 'bg-black text-white rounded-tl-[5px] rounded-tr-[5px]': selected, 'bg-white text-black': !selected }">CASH ACTIVITY</button>
            </Tab> -->
          </TabList>
          <TabPanels as="div">
            <TabPanel as="div" unmount>
              <IncomeStatement />
            </TabPanel>
            <TabPanel as="div" unmount>
              <BalanceSheet />
            </TabPanel>
            <!-- <TabPanel>
              <IncomeStatement />
              <BalanceSheet />
            </TabPanel> -->
          </TabPanels>
        </TabGroup>
    </div>
  </main>
</template>

<script>
import { watchEffect } from 'vue'
import {
  TabGroup, TabList, Tab, TabPanels, TabPanel
} from '@headlessui/vue'
// import { useStore } from 'vuex'
// import Helpers from '../utils/helpers'
import BalanceSheet from './BalanceSheetTable'
import IncomeStatement from './IncomeStatement'

export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    BalanceSheet,
    IncomeStatement
  },
  setup () {
    watchEffect(() => {
      // watch for new data after pagination and sort the available transactions by year
    })

    return {
    }
  }
}
</script>

<style scoped>
</style>
