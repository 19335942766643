<template>
  <main class="dealflow-tabss mt-8 pb-20 flex flex-1 flex-col min-w-0 mr-10 relative">
    <div class="main-title">
      <div class="flex justify-between">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Dealflow</h1>
      <div class="w-[200px] text-right relative">
        <Menu>
          <MenuButton>
            <div class="flex items-center bg-black text-white py-[10px] px-[14px] font-medium rounded-[4px]">Actions
              <span class="ml-[10px]">
                <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.9998 6.33398C3.82961 6.33313 3.66174 6.29439 3.50838 6.22059C3.35501 6.14679 3.22 6.03978 3.11314 5.90732L0.306469 2.50732C0.142465 2.30263 0.0392593 2.05599 0.00861339 1.7955C-0.0220325 1.53501 0.0211136 1.27115 0.133135 1.03398C0.223988 0.827869 0.372251 0.65226 0.560215 0.528133C0.748179 0.404006 0.9679 0.336606 1.19314 0.333984H6.80647C7.0317 0.336606 7.25143 0.404006 7.43939 0.528133C7.62735 0.65226 7.77561 0.827869 7.86647 1.03398C7.97849 1.27115 8.02164 1.53501 7.99099 1.7955C7.96035 2.05599 7.85714 2.30263 7.69314 2.50732L4.88647 5.90732C4.7796 6.03978 4.64459 6.14679 4.49123 6.22059C4.33786 6.29439 4.17 6.33313 3.9998 6.33398Z" fill="white"/>
                </svg>
              </span>
            </div>
          </MenuButton>
          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems class="flex absolute right-0 top-[50px] flex-col py-[4px] bg-white rounded-[4px] shadow-md z-[1000] whitespace-nowrap">
              <MenuItem v-slot="{ active }" @click="openModal">
                <span :class='{ "bg-black text-white": active }' class="text-sm text-left py-2 px-4 cursor-pointer">Add to Collection</span>
              </MenuItem>
              <MenuItem v-slot="{ active }" @click="data.portfolioConfirmation = true">
                <span :class='{ "bg-black text-white": active }' class="text-sm text-left py-2 px-4 cursor-pointer whitespace-nowrap">Promote company to portfolio</span>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
      </div>
      <Breadcrumb :page="companyID"/>
      <div class="mt-[6px] sticky top-0 z-[500] bg-white">
        <div class="flex justify-between">
          <nav class="mt-5 bg-white">
            <ul class="flex flex-row">
              <li v-for="(tab, index) in tabs" :key="index" class="pr-4">
                <router-link :to="`/dealflow/${routeID}/${tab.href}`" v-slot="{ href, navigate, isActive }">
                  <a
                    :href="href"
                    @click="navigate"
                    class="border-black border-b-2 font-Rubik py-2 flex items-center text-[16px] leading-[18.96px]"
                    :class="[
                      isActive
                        ? 'border-black text-black'
                        : 'border-none text-greyText',
                    ]"
                  >{{ tab.name }}</a>
                </router-link>
              </li>
            </ul>
          </nav>
          <!-- <div class="flex flex-row items-center">
            <div class="flex flex-col font-Rubik text-[11px] leading-[13.04px] mr-2">
              <span class="flex justify-end">Last interaction by Latade</span>
              <span class="flex justify-end">11 Oct, 2021</span>
            </div>
            <div>
              <img class="rounded-full" src="../../assets/avatar.png"/>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="flex-auto">
      <router-view></router-view>
    </div>
    <Modal v-show="data.modalOpen">
      <div class="bg-white rounded-[2px] py-[40px] px-[48px] relative w-[480px]">
        <template v-if="!data.collection">
          <button @click.prevent="closeModal" class="close absolute right-[20px] top-[20px]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0D0D0D"/>
          </svg>
          </button>
          <h3 class="font-medium font-Rubik leading-[18.96px] text-black mb-[8px]">
            Add this company to a collection
          </h3>
          <hr />
          <div class="mt-4 w-full">
            <label for="search" class="sr-only">Search Collection</label>
            <div class="relative text-black">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  class="h-4 w-4 text-black"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                </svg>
              </div>
              <input
                id="search"
                name="search"
                class="block w-full pl-10 pr-3 py-3 rounded-md text-[14px] leading-[17.78px] font-Rubik bg-faintGrey placeholder-black text-black text-sm border-none focus:outline-none focus:ring-0 "
                placeholder="Search collection"
                type="search"
              />
            </div>
          </div>
          <div class="mt-4 mb-4 h-[350px] overflow-auto">
            <div v-for="(collection, collectionIdx) in collection" :key="collectionIdx" class="mt-3 mb-3 relative flex items-start p-6 border rounded">
              <div class="mr-5">
                <img src="../../assets/collection.svg"/>
              </div>
              <div class="min-w-0 flex-1 text-sm">
                <div class="flex flex-col">
                  <label :for="`collection-${collection.id}`" class="text-[15px] leading-[17.78px] font-Rubik">{{ collection.collection_name }}</label>
                  <label :for="`collection-${collection.id}`" class="text-[11px] leading-[13.04px] font-Rubik text-greyText">{{ collection.companies_count }} Companies</label>
                </div>
              </div>
              <div class="ml-3 flex items-center my-auto h-5">
                <input @input="addSelected(collection.id)" :id="`collection-${collection.id}`" :name="`collection-${collection.id}`" type="checkbox" class="focus:ring-black h-4 w-4 text-black border-gray-300 rounded" />
              </div>
            </div>
          </div>
          <div class="w-full flex items-center justify-end">
            <button :disabled="!collection_ids.length > 0" @click="addCompanytoCollection" class="mt-3 flex bg-black text-white rounded-[2px] text-[16px] font-Rubik px-8 py-4 outline-none focus:outline-none disabled:opacity-50">
              <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white">Submit</span>
              <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
              </svg>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col items-center justify-center">
            <h3 class="font-medium font-Rubik text-[16px] leading-[18.96px] text-black mb-[8px]">
              Created!
            </h3>
            <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">Company has been added to collection!</p>
            <button @click.prevent="closeModal" class="mt-3 border-b border-teal font-medium py-[12px] px-[35px] text-white text-[13px] leading-[15.41px] rounded-[4px] bg-black">Okay</button>
          </div>
        </template>
      </div>
    </Modal>
    <Modal v-show="data.portfolioConfirmation">
      <template v-if="!loading">
        <div class="bg-white w-[399px] rounded-[6px] p-4 relative">
          <span class="block w-[48px] h-[48px] mb-[12px] mx-auto">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28Z" fill="#FEE4E2"/>
              <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28 48C16.9543 48 8 39.0457 8 28H0C0 43.464 12.536 56 28 56V48ZM48 28C48 39.0457 39.0457 48 28 48V56C43.464 56 56 43.464 56 28H48ZM28 8C39.0457 8 48 16.9543 48 28H56C56 12.536 43.464 0 28 0V8ZM28 0C12.536 0 0 12.536 0 28H8C8 16.9543 16.9543 8 28 8V0Z" fill="#FEF3F2"/>
            </svg>
          </span>
          <div class="flex flex-col items-start justify-center">
            <h3 class="font-medium font-Rubik text-[18px] leading-[24px] text-black mb-[12px] text-center w-full">
              Confirm company upgrade
            </h3>
            <p class="font-Rubik text-[13px] leading-[20px] text-greyText max-w-[95%] text-center w-full">Are you sure you want to convert this company to a portfolio company? <br /><span class="block mt-2">This action cannot be undone.</span></p>
            <div class="flex items-center justify-between w-full">
              <button @click.prevent="closeModal()" class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] px-[45px] text-[13px] text-black leading-[15.41px] rounded-[6px] mr-[24px] border-[0.6px] border-lineGray max-w-[170px] w-[50%]">Cancel</button>
              <button @click.prevent="promoteToPortfolio" class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] px-[45px] text-white text-[13px] leading-[15.41px] rounded-[6px] bg-black max-w-[170px] w-[50%]">Promote</button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col items-center justify-center py-6 px-14">
          <h3 class="font-medium font-Rubik text-[14px] leading-[18.96px] text-greyText mb-[8px]">
            Company upgraded successfully!
          </h3>
          <button @click.prevent="closeModal()" class="mt-3 border-b border-teal font-medium py-[12px] px-[35px] text-white text-[13px] leading-[15.41px] rounded-[4px] bg-black">Okay</button>
        </div>
      </template>

        <!-- <template v-if="!data.collection">
          <button @click.prevent="closeModal" class="close absolute right-[10px] top-[10px]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0D0D0D"/>
          </svg>
          </button>
          <h3 class="font-medium font-Rubik leading-[18.96px] text-black mb-[8px]">
            Attention!
          </h3>
          <hr />
          <div class="mt-4 w-full text-center text-sms">
            <p>Are you sure you want to convert this company to a portfolio company? <br /><span class="block mt-2">This action cannot be undone.</span></p>
          </div>
          <div class="w-full flex items-center justify-between mt-4">
            <button @click.prevent="promoteToPortfolio" class="mt-3 mr-2 max-w-1/2 w-full flex items-center justify-center bg-[transparent] border border-black text-black rounded-[4px] text-sm font-Rubik px-8 py-2 outline-none focus:outline-none disabled:opacity-50">
              <span>Yes</span>
            </button>
            <button @click.prevent="closeModal()" class="mt-3 max-w-1/2 w-full flex items-center justify-center bg-originsRed text-white rounded-[4px] text-sm font-Rubik px-8 py-2 outline-none focus:outline-none disabled:opacity-50">
              <span>Cancel</span>
            </button>
          </div>
        </template> -->
        <!-- <template v-else>
          <div class="flex flex-col items-center justify-center">
            <h3 class="font-medium font-Rubik text-[16px] leading-[18.96px] text-black mb-[8px]">
              Created!
            </h3>
            <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">Company has been added to collection!</p>
            <button @click.prevent="closeModal" class="mt-3 border-b border-teal font-medium py-[12px] px-[35px] text-white text-[13px] leading-[15.41px] rounded-[4px] bg-black">Okay</button>
          </div>
        </template> -->
    </Modal>
  </main>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted, computed, reactive } from 'vue'
import Breadcrumb from '@/components/BreadCrumb.vue'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'
import { useStore } from 'vuex'
import Modal from '@/components/Modal.vue'
import CollectionService from '@/services/collection.service'
import PortfolioService from '@/services/portfolio.service'
import Helpers from '@/utils/helpers'
const tabs = [
  { name: 'General Info', href: 'generalinfo' },
  { name: 'Diligence Responses', href: 'diligenceresponses' },
  { name: 'Metrics & Financials', href: 'metricsandfinancials' },
  { name: 'Risk Scorecard', href: 'riskscorecard' },
  { name: 'Company Documents', href: 'companydocuments' }
]
const pages = [
  { name: 'Projects', href: 'fd', current: false },
  { name: 'Project Nero', href: '/dealflow/1/generalinfo' }
]
export default {
  components: {
    Breadcrumb,
    Modal,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const companyID = ref({})
    const routeID = ref(null)
    const store = useStore()
    // const companyData = ref(dealflow)
    const companyData = computed(() => store.state.app.company)
    const collection = computed(() => store.state.app.collection)
    const getCompany = (id) => {
      return companyData.value.filter(company => company.id === id)
    }
    const upDateList = () => {
      routeID.value = route.params.id
      const company = getCompany(route.params.id)
      companyID.value.breadcrumbList = company[0].company_name
      companyID.value.route = route.path.split('/')[1]
    }
    onMounted(() => {
      upDateList()
    })
    // eslint-disable-next-line camelcase
    const collection_ids = ref([])
    const addSelected = (id) => {
      if (collection_ids.value.includes(id)) {
        collection_ids.value = collection_ids.value.filter(item => item !== id)
      } else {
        collection_ids.value.push(id)
      }
    }
    const loading = ref(false)
    const data = reactive({
      modalOpen: false,
      collection: false,
      collection_name: '',
      collection_description: '',
      portfolioConfirmation: false
    })
    const openModal = () => {
      data.modalOpen = !data.modalOpen
    }
    const closeModal = () => {
      data.modalOpen = false
      data.collection = false
      data.portfolioConfirmation = false
      loading.value = false
    }
    const addCompanytoCollection = async () => {
      try {
        loading.value = true
        const payload = {
          collection_ids: collection_ids.value.map((str) => ({ id: str })),
          company_ids: [{ id: route.params.id }]
        }
        const response = await CollectionService.addCompanytoCollection(payload)
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = false
          data.collection = true
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }

    const promoteToPortfolio = async () => {
      // closeModal()
      loading.value = false
      try {
        const response = await PortfolioService.convertToPortfolioCompany(routeID.value)
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = true
        }
      } catch (error) {
        loading.value = false
        Helpers.processError(error, store, router)
      }
    }

    return {
      tabs,
      pages,
      companyID,
      routeID,
      openModal,
      closeModal,
      data,
      loading,
      collection,
      addSelected,
      collection_ids,
      addCompanytoCollection,
      promoteToPortfolio
    }
  }
}
</script>

<style>

</style>
