<template>
  <div class="px-4 sm:px-6 lg:px-8 relative">
    <div class="mt-2 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle">
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr class="divide-x divide-gray-200 relative">
                  <th
                    v-for="(item, idx) in config"
                    :key="idx"
                    scope="col"
                    class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    :class="[item.sortable ? 'cursor-pointer' : '']"
                  >
                    <div class="flex items-center justify-start">
                      {{item.title}}
                      <SelectorIcon
                        v-if="
                          item.sortable === true
                        "
                        class="w-4 h-4 text-gray-400 ml-5"
                        aria-hidden="true"
                        @click="sortBy(item.type)"
                      />
                    </div>
                  </th>
                <th class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">ACTIONS</th>
                </tr>
              </thead>
              <tbody
                v-if="isLoading"
                class="animate-pulse divide-y divide-gray-200 bg-white"
              >
                <tr
                  v-for="(row, index) in theData"
                  :key="index"
                  class="divide-x divide-gray-200"
                >
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    <div class="h-2 bg-gray-300"></div>
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="h-2 bg-gray-300"></div>
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="h-2 bg-gray-300"></div>
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="h-2 bg-gray-300"></div>
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="h-2 bg-gray-300"></div>
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6"
                  >
                    <div class="h-2 bg-gray-300"></div>
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="h-2 bg-gray-300">
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="(row, index) in theData"
                  :key="index"
                  class="divide-x divide-gray-200"
                >
                  <td
                    v-for="(obj, idx) in config"
                    :key="idx"
                    class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    <div class="h-full">
                      <template v-if="obj.type === 'type'">
                        <span class="">{{ row[obj.key].toUpperCase() }}</span>
                      </template>
                      <template v-if="obj.type === 'amount'">
                        <span class="block">
                          {{ row[obj.key] }}
                          <span v-if="plaid" class="text-xs text-greyText">{{ row.iso_currency_code }}</span>
                          <span v-else class="text-xs text-greyText">{{ row.currency }}</span>
                        </span>
                      </template>
                      <template v-if="obj.type === 'description'">
                        <span v-if="row[obj.key]" class="w-full">
                          <TruncateTooltip :text="row[obj.key]">{{ row[obj.key] }}</TruncateTooltip>
                        </span>
                        <span v-else class="text-greyText block text-center w-full">-</span>
                      </template>
                      <template v-if="obj.type === 'category'">
                        <span v-if="row[obj.key].category" class="block">{{ row[obj.key].category }}</span>
                        <span v-else class="block w-[max-content] py-2 px-3 rounded-full bg-[#FFFAEB] text-xs font-medium text-[#DC6803]">Uncategorized</span>
                      </template>
                      <template v-if="obj.type === 'date'">
                        <span class="block w-full">{{ getDate(row[obj.key]) }}</span>
                      </template>
                    </div>
                  </td>
                  <td class="categorize whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="h-2 flex items-center justify-center">
                      <Menu as="div">
                        <MenuButton @click="getSelectedTransaction(row.id)" class="p-4">
                          <span class="flex items-center justify-center px-4">
                            <svg
                              width="18"
                              height="4"
                              viewBox="0 0 18 4"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="16" cy="2" r="2" fill="#828282" fill-opacity="0.6" />
                              <circle cx="9" cy="2" r="2" fill="#828282" fill-opacity="0.6" />
                              <circle cx="2" cy="2" r="2" fill="#828282" fill-opacity="0.6" />
                            </svg>
                          </span>
                        </MenuButton>
                        <MenuItems
                          class="flex flex-col absolute right-[40px] bg-white z-[150] min-w-[200px] border-[1px] border-faintGrey rounded-[4px]"
                        >
                          <MenuItem as="template" v-slot="{ active }">
                            <a
                              :class="{
                                'bg-black text-white': active,
                                'bg-white text-black': !active,
                              }"
                              class="p-2 text-sm"
                              @click="categorizeTransaction = true"
                            >
                              Categorize transaction
                            </a>
                          </MenuItem>
                        </MenuItems>
                      </Menu>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <nav
              v-if="isBottomNav"
              class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div class="hidden sm:block">
                <p class="text-sm text-gray-700">
                  Showing
                  {{ data.pageStart }}
                  -
                  {{ data.pageEnd }}
                  of
                  {{ ' ' }}
                  <span class="font-medium">{{ metadata.transactions_count }}</span>
                  {{ ' ' }}
                  results
                </p>
              </div>
              <div class="flex flex-1 justify-between sm:justify-end">
                <!-- <button
                  @click="changePage('first')"
                  class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-faintGrey disabled:text-gray-400"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                  </svg>
                </button> -->
                <button
                  @click="changePage('previous')"
                  :disabled="previousCursor === null"
                  class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-faintGrey disabled:text-gray-400"
                >
                  Previous
                </button>
                <button
                  @click="changePage('next')"
                  :disabled="nextCursor === null"
                  class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-faintGrey disabled:text-gray-400"
                >
                  Next
                </button>
                <!-- <button
                  @click="changePage('last')"
                  class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-faintGrey disabled:text-gray-400"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                  </svg>
                </button> -->
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- Sidenav -->
    <TransitionRoot as="template" :show="categorizeTransaction">
      <Dialog as="div" class="relative z-[1000]" @close="closeModal()">
          <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
              <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                  <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                    <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                      <div class="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button type="button" class="rounded-full text-black hover:text-white focus:outline-none hover:ring-2 hover:ring-black hover:bg-black bg-white p-1 transition-all" @click="closeModal()">
                          <span class="sr-only">Close panel</span>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </TransitionChild>
                    <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div class="px-4 sm:px-6">
                        <DialogTitle class="text-lg font-medium text-gray-900">Categorize transaction</DialogTitle>
                      </div>
                      <div class="relative mt-6 flex-1 px-4 sm:px-6">
                        <!-- content -->
                        <template v-if="!financials">
                          <div class="absolute inset-0 px-4 sm:px-6">
                            <div class="h-[max-content]">
                              <div class="max-w-[613px] pb-8">
                                <div class="relative z-[30] mb-3">
                                  <Listbox v-model="selectedCategory">
                                    <div class="relative mt-1">
                                      <ListboxLabel class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                                        <span class="text-red-400">*</span>
                                        Select category
                                      </ListboxLabel>
                                      <ListboxButton
                                        class="relative px-4 py-4 mt-2 text-left placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0 rounded-[4px]"
                                      >
                                        <span v-if="!selectedCategory" class="text-gray-400 block truncate">Select category</span>
                                        <span v-else class="block truncate">{{ selectedCategory.category }}</span>
                                        <span
                                          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                                        >
                                          <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                      </ListboxButton>

                                      <transition
                                        leave-active-class="transition duration-100 ease-in"
                                        leave-from-class="opacity-100"
                                        leave-to-class="opacity-0"
                                      >
                                        <ListboxOptions
                                          class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-sm max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                        >
                                          <ListboxOption
                                            v-slot="{ active, selected }"
                                            v-for="category in categories" :key="category.id" :value="category"
                                            as="ul"
                                          >
                                            <li
                                              :class="[
                                                active ? 'text-white bg-black' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-10 pr-4',
                                              ]"
                                            >
                                              <span
                                                :class="[
                                                  selected ? 'font-medium' : 'font-normal',
                                                  'block truncate',
                                                ]"
                                                >{{ category.category }}</span
                                              >
                                              <span
                                                v-if="selected"
                                                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400"
                                              >
                                                <CheckIcon class="w-5 h-5" aria-hidden="true" />
                                              </span>
                                            </li>
                                          </ListboxOption>
                                        </ListboxOptions>
                                      </transition>
                                    </div>
                                  </Listbox>
                                </div>
                                <div class="z-[50] py-4 mb-6">
                                  <div class="wrap w-full relative min-h-[70px]">
                                    <label class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal mb-3">
                                      <span class="text-red-400">*</span>
                                      Select stakeholder
                                    </label>
                                    <div class="popover absolute bg-white rounded-[4px] w-full">
                                      <div @click="searchDropData.isVisible = !searchDropData.isVisible" class="result min-h-[54px] p-4 flex items-center justify-between w-full border-[1px] border-gray-200 rounded-[4px]">
                                        <div class="text-sm">
                                          {{ searchDropData.selectedItem ? searchDropData.selectedItem.name : 'Select stakeholder' }}
                                        </div>
                                        <span
                                            class="absolute inset-y-0 right-0 top-0 flex items-center pointer-events-none h-[max-content] p-4"
                                          >
                                          <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                      </div>

                                      <div v-if="searchDropData.isVisible" class="options relative z-[20] bg-white overflow-y-auto text-base shadow-sm w-full max-h-[300px] ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <div class="w-full my-2 mx-auto px-2 flex justify-center items-center">
                                          <input type="text" name="stakeholder" id="stakeholder" v-model="searchDropData.searchQuery"
                                           placeholder="Search stakeholders" class="w-[95%] mx-auto text-sm p-4 focus:ring-1 focus:ring-faintGrey border-[1px] border-faintGrey rounded-[4px] text-black placeholder-gray-400" />
                                        </div>

                                        <ul class="text-sm max-h-[300px]">
                                          <li @click="selectItem(item)" v-for="item in filteredStakeholders" :key="item" class="py-3 px-4 cursor-pointer border-b-[1px] border-faintGrey hover:bg-black hover:text-white">{{ item.name }}</li>
                                          <li v-show="filteredStakeholders.length === 0" @click="createStakeholder()" class="py-3 px-4 cursor-pointer">+ Add new stakeholder</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6 z-[10]">
                                  <label
                                    class="font-Rubik text-greyText text-sm font-normal"
                                    htmlFor="comment"
                                  >
                                    <span class="text-red-400">*</span>
                                    Comments
                                  </label>
                                  <textarea
                                    id="comment"
                                    name="comment"
                                    type="text"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[4px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    style="transition: 'all .15s ease'"
                                    placeholder="Leave a comment"
                                    v-model="data.comments"
                                  />
                                </div>
                                <template v-if="!plaid">
                                  <div class="relative">
                                    <button @click="categorizeMonoTransactions()" class="bg-black text-white text-sm p-3 rounded-[6px]">Submit</button>
                                  </div>
                                </template>
                                <template v-else>
                                  <div class="relative">
                                    <button @click="categorizePlaidTransactions()" class="bg-black text-white text-sm p-3 rounded-[6px]">Submit</button>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="flex flex-col items-center justify-center h-full">
                            <h3 class="font-medium font-Rubik text-[16px] leading-[18.96px] text-black mb-[8px]">
                              Success!
                            </h3>
                            <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">Transaction categorized!</p>
                            <button @click.prevent="closeModal()" class="mt-3 border-b border-teal font-medium py-[12px] px-[35px] text-white text-[13px] leading-[15.41px] rounded-[4px] bg-black">Okay</button>
                          </div>
                        </template>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
// Temporarily disabled table refresh due to pointers resetting on page refresh.
import {
  CheckIcon,
  SelectorIcon
} from '@heroicons/vue/solid'
import {
  Menu, MenuButton, MenuItems, MenuItem,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import Helpers from '@/utils/helpers'
import FinancialService from '@/services/financials.service'
import MonoService from '@/services/mono.service'
import PlaidService from '@/services/plaid.service'
import {
  // useRoute,
  useRouter
} from 'vue-router'
import { useStore } from 'vuex'
import TruncateTooltip from './TruncateTooltip.vue'

import { reactive, computed, ref, onBeforeMount } from 'vue'
export default {
  props: [
    'plaid',
    'config',
    'theData',
    'isLoading',
    'isBottomNav',
    'pagination',
    'totalRecords',
    'accountId',
    'selectedAccount',
    'categories',
    'stakeholders',
    'metadata',
    'nextCursor',
    'previousCursor'
  ],
  emits: [
    'nextPage',
    'previousPage',
    'reset',
    'updateTable',
    'sortBy'
  ],
  components: {
    CheckIcon,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    SelectorIcon,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    ListboxLabel,
    TruncateTooltip
  },
  setup (props, context) {
    const store = useStore()
    // const route = useRoute()
    const router = useRouter()
    const selectedTransaction = ref(null)
    const categorizeTransaction = ref(false)
    const financials = ref(false)
    const selectedCategory = ref(null)
    const loading = ref(false)
    const isTransactionReporting = ref(false)
    const data = reactive({
      page: 1,
      perPage: 20,
      pageStart: 1,
      pageEnd: 20,
      order: 'DESC',
      sortBy: 'date',
      comments: ''
    })

    const searchDropData = reactive({
      searchQuery: '',
      selectedItem: null,
      isVisible: false,
      stakeholdersArray: []
    })

    // const pages = computed(() => {
    //   const rem = props.totalRecords % data.perPage

    //   if (rem > 0) {
    //     return Math.floor(props.totalRecords / data.perPage) + 1
    //   } else {
    //     return props.totalRecords / data.perPage
    //   }
    // })
    const changePage = (value) => {
      data.pageStart = data.page
      switch (value) {
        case 'next':
          context.emit('nextPage', { pageDirection: value, cursor: props.metadata.next_cursor, order: data.order, sortBy: data.sortBy, limit: data.perPage })
          data.page = data.page + 1
          data.pageEnd = data.page * data.perPage
          data.pageStart = ((props.theData.length * data.page) - props.theData.length) + 1
          if (props.metadata.transactions_count < data.pageEnd) {
            data.pageEnd = props.metadata.transactions_count
          }
          break
        case 'previous':
          context.emit('previousPage', { pageDirection: value, cursor: props.metadata.previous_cursor, order: data.order, sortBy: data.sortBy, limit: data.perPage })
          data.page = data.page - 1
          data.pageEnd = data.page * data.perPage
          data.pageStart = ((props.theData.length * data.page) - props.theData.length) + 1
          if (props.metadata.transactions_count < data.pageEnd) {
            data.pageEnd = props.metadata.transactions_count
          }
          break
      }
      // context.emit('nextPage', { pageDirection: value, cursor: props.theData.nextCursor, limit: data.perPage })
    }
    const getDate = (created) => {
      const date = new Date(created)
      return new Intl.DateTimeFormat('en-GB', {
        timeStyle: 'short',
        dateStyle: 'long'
      }).format(date)
    }

    const getSelectedTransaction = (transactionId) => {
      selectedTransaction.value = transactionId
    }

    const closeModal = () => {
      categorizeTransaction.value = false
      searchDropData.isVisible = false
      searchDropData.searchQuery = ''
      financials.value = false
    }

    const filteredStakeholders = computed(() => {
      const query = searchDropData.searchQuery.toLowerCase()
      if (searchDropData.searchQuery === '') {
        return searchDropData.stakeholdersArray
      }
      return searchDropData.stakeholdersArray.filter((stakeholder) => {
        return Object.values(stakeholder).some((word) => String(word).toLowerCase().includes(query))
      })
    })

    const fetchStakeholders = async () => {
      if (props.metadata.transactions_count < data.pageEnd) {
        data.pageEnd = props.metadata.transactions_count
      } else {
        data.pageEnd = 20
      }
      try {
        const response = await FinancialService.getStakeholders()
        if (response.status === 200 && response.data.status === 'success') {
          searchDropData.stakeholdersArray = response.data.data
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const createStakeholder = async () => {
      const payload = {
        name: searchDropData.searchQuery
      }
      try {
        const response = await FinancialService.createStakeholder(payload)
        if (response.status === 200 && response.data.status === 'success') {
          fetchStakeholders()
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const selectItem = (item) => {
      searchDropData.selectedItem = item
      searchDropData.isVisible = false
    }

    const categorizeMonoTransactions = async () => {
      loading.value = true
      const stakeholder = searchDropData.stakeholdersArray.find((stakeholder) => {
        return (searchDropData.selectedItem.name).toLowerCase() === (stakeholder.name).toLowerCase()
      })
      const payload = {
        category: selectedCategory.value.id,
        stakeholder: stakeholder.id,
        comment: data.comments
      }
      try {
        const response = await MonoService.categorizeMonoTransactions(props.accountId, selectedTransaction.value, payload)
        if (response.status === 200 && response.data.status === 'success') {
          financials.value = true
          context.emit('updateTable')
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const categorizePlaidTransactions = async () => {
      loading.value = true
      const stakeholder = searchDropData.stakeholdersArray.find((stakeholder) => {
        return (searchDropData.selectedItem.name).toLowerCase() === (stakeholder.name).toLowerCase()
      })

      const payload = {
        category: selectedCategory.value.id,
        stakeholder: stakeholder.id,
        comment: data.comments
      }

      try {
        loading.value = false
        const response = await PlaidService.categorizePlaidTransactions(props.accountId, selectedTransaction.value, payload)
        if (response.status === 200 && response.data.status === 'success') {
          financials.value = true
          context.emit('updateTable')
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const sortBy = (column) => {
      data.sortBy = column
      if (data.order === 'ASC') {
        data.order = 'DESC'
      } else {
        data.order = 'ASC'
      }
      context.emit('sortBy', { sortBy: data.sortBy, order: data.order, limit: data.perPage })
    }

    onBeforeMount(() => {
      fetchStakeholders()
    })

    return {
      data,
      getDate,
      // pages,
      changePage,
      getSelectedTransaction,
      categorizeTransaction,
      closeModal,
      financials,
      searchDropData,
      selectedCategory,
      filteredStakeholders,
      createStakeholder,
      selectItem,
      categorizeMonoTransactions,
      categorizePlaidTransactions,
      loading,
      sortBy,
      isTransactionReporting
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
