<template>
  <div
    class="h-screen min-h-screen overflow-y-auto lg:overflow-hidden overflow-x-hidden bg-black relative"
  >
    <div class="max-w-[1200px] w-full mx-auto h-full">
      <!-- Sprites -->
      <div
        class="circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] border-opacity-25 rounded-full top-[-530px] right-[-110px] lg:right-[100px] md:top-[-450px] opacity-1 z-10"
      ></div>
      <div
        class="square-sprite lg:max-w-[470px] max-w-[350px] w-full lg:max-h-[470px] max-h-[350px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] border-opacity-25 transform rotate-[55deg] left-[300px] lg:bottom-[-350px] bottom-[-120px] opacity-1 z-10"
      ></div>
      <!-- Sprites -->
      <div
        class="flex lg:flex-row flex-col items-center justify-center lg:h-full h-[max-content] px-[16px] lg:px-0"
      >
        <div class="lg:w-[50%] w-full flex flex-col justify-center lg:pl-[25px] pl-auto">
          <div
            class="flex flex-col lg:items-start items-center justify-center lg:max-w-[310px] max-w-[350px] mx-auto lg:ml-0 lg:my-auto my-[80px]"
          >
            <div class="mb-[28px]">
              <img
                src="../../assets/KFLogo.svg"
                class="lg:h-[108px] h-[80px] lg:w-[123px] w-[80px]"
              />
            </div>
            <h1 class="text-sm text-white text-[28px] font-Playfair font-normal mt-3">
              Kinfolk Venture Capital
            </h1>
            <p class="text-greyText text-[16px] leading-[18.96px] font-normal mt-3">
              The Fundraising Process for Africa’s Next-Gen Startups—Fully Digitized
            </p>
          </div>
        </div>
        <!-- right -->
        <div
          class="lg:w-[50%] w-full flex flex-col justify-center items-center min-w-0 h-full lg:py-0 py-[34px] px-0 z-50"
        >
          <div
            class="signin-card max-w-[458px] w-full bg-white lg:px-[48px] px-[30px] py-[40px] rounded-[6px]"
          >
            <h1 class="text-center font-Playfair text-[32px] leading-[42.66px]">
              Sign In
            </h1>
            <p
              class="text-center text-greyText font-Rubik text-[15px] leading-[17.78px] font-light mt-3 mb-6"
            >
              Welcome to Kinfolk Venture Capital
            </p>
            <div v-if="error" class="rounded-md bg-red-50 p-4 mb-3">
              <div class="flex">
                <div class="flex-shrink-0">
                  <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-red-800">
                    {{ error }}
                  </p>
                </div>
                <div class="ml-auto pl-3 my-auto">
                  <div class="-mx-1.5 -my-1.5">
                    <button
                      type="button"
                      class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                    >
                      <span class="sr-only">Dismiss</span>
                      <XIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="username"
                  :class="[v$.email.$error ? 'border-red-400' : 'border-grey-300']"
                  class="px-4 py-3 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                  placeholder=""
                  style="transition: 'all .15s ease'"
                  v-model="v$.email.$model"
                  @blur="v$.email.$touch()"
                />
                <div v-if="v$.email.$error">
                  <p
                    v-if="v$.email.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Email is required
                  </p>
                  <p
                    v-if="v$.email.email.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Email is invalid
                  </p>
                </div>
              </div>
              <div class="relative w-full mb-6">
                <label
                  class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                  htmlFor="password"
                >
                  Password
                </label>
                <div class="flex relative w-full my-auto">
                  <span class="absolute inset-y-0 right-0 flex items-center pr-1 mt-2">
                    <div class="">
                      <img
                        :src="eye"
                        alt=""
                        height="100%"
                        width="100%"
                        class="w-[20px] h-[20px] mx-[10px] hover:cursor-pointer"
                        @click="toggleShow"
                      />
                    </div>
                  </span>
                  <input
                    id="password"
                    name="password"
                    autocomplete="new-password"
                    :type="fieldVisibility"
                    :class="[v$.password.$error ? 'border-red-400' : 'border-grey-300']"
                    class="px-4 py-3 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                    placeholder=""
                    style="transition: 'all .15s ease'"
                    v-model="v$.password.$model"
                    @blur="v$.password.$touch()"
                  />
                </div>
                <div v-if="v$.password.$error">
                  <p
                    v-if="v$.password.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Password is required
                  </p>
                  <p
                    v-if="v$.password.minLength.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Password should be alteast 8 characters
                  </p>
                </div>
                <a
                  href="#"
                  class="text-black float-right font-normal font-Rubik leading-[17px] text-[14px] mt-3"
                  >Forgot Password?</a
                >
              </div>

              <button
                :disabled="v$.$invalid"
                type="button"
                class="submit w-full py-4 text-white font-medium text-[16px] text-center leading-[19px] bg-black my-[30px] disabled:opacity-50"
                @click="signIn"
              >
                Sign In
              </button>
              <p class="text-center font-Rubik text-[15px] font-light">
                Dont have an account?&nbsp;
                <router-link to="/sign-up"
                  ><b class="text-black font-medium">Sign Up</b></router-link
                >
              </p>
            </form>
          </div>
          <p
            class="text-center text-white text-[15px] leading-[18px] font-light mt-[32px]"
          >
            By registering you agree with our website's
            <a href="https://kinfolk.vc/terms-of-use" target="_blank" class="underline"
              >Terms of Use</a
            >
            &
            <a href="https://kinfolk.vc/privacy-policy" target="_blank" class="underline"
              >Privacy Policy</a
            >
          </p>
        </div>
      </div>
      <!-- left -->
    </div>
  </div>
</template>

<script>
import eyeOpen from '../../assets/visible-eye.svg'
import eyeClosed from '../../assets/not-visible-eye.svg'
import { ref, reactive, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { XCircleIcon, XIcon } from '@heroicons/vue/solid'
import AuthService from '@/services/auth.service'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  name: 'SignIn',
  components: {
    XCircleIcon,
    XIcon
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const fieldVisibility = ref('password')
    const error = ref(null)
    const toggleShow = () => {
      fieldVisibility.value === 'password'
        ? (fieldVisibility.value = 'text')
        : (fieldVisibility.value = 'password')
    }
    const eye = computed(() => {
      return fieldVisibility.value === 'password' ? eyeClosed : eyeOpen
    })

    const signIn = async () => {
      try {
        const response = await AuthService.signIn({
          email: data.email,
          password: data.password
        })
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setUser', response.data.data)
          router.push('/')
        }
      } catch (e) {
        if (e.response) {
          error.value = e.response.data.message
          setTimeout(() => {
            error.value = null
          }, 2000)
        } else if (e.message === 'Network Error') {
          error.value = 'Network Error check your internet connection'
          setTimeout(() => {
            error.value = null
          }, 3000)
        }
      }
    }

    const data = reactive({
      email: '',
      password: ''
    })

    const rules = computed(() => ({
      email: { required, email },
      password: {
        minLength: minLength(8),
        required
      }
    }))
    const v$ = useVuelidate(rules, data)

    return {
      toggleShow,
      eye,
      signIn,
      fieldVisibility,
      v$,
      error
    }
  }
}
</script>

<style lang="scss" scoped></style>
