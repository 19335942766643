export default function useDealflow (resData) {
  const countryList = resData.map((item) => {
    return {
      city: item.address.city,
      country: item.address.country
    }
  })
  const filteredCountryList = new Map()
  countryList.forEach((item) => {
    if (!filteredCountryList.has(item.country)) {
      filteredCountryList.set(item.country, [item.city])
    } else if (!filteredCountryList.get(item.country).includes(item.city)) {
      filteredCountryList.get(item.country).push(item.city)
    }
  })
  const countryListCascader = Array.from(filteredCountryList).map(([country, cities]) => ({
    label: country,
    value: country,
    country: country,
    children: cities.map((city) => ({
      label: city,
      value: city,
      city: city
    }))
  }))
  const stateList = new Set(
    resData
      .map((item) => {
        if (item.address.state) return item.address.state
      })
      .filter((item) => item && item)
  )
  const fundingTypeList = new Set(
    resData
      .map((item) => {
        if (item.funding_type) return item.funding_type
      })
      .filter((item) => item && item)
  )
  const fundingRoundList = new Set(
    resData
      .map((item) => {
        if (item.funding_round) return item.funding_round
      })
      .filter((item) => item && item)
  )
  const industryTypeList = new Set(
    resData
      .map((item) => {
        if (item.industry_type) return item.industry_type
      })
      .filter((item) => item && item)
  )
  const yearFoundedList = new Set(
    resData
      .map((item) => {
        if (item.year_founded) return item.year_founded
      })
      .filter((item) => item && item)
  )
  return {
    countryList,
    stateList,
    fundingTypeList,
    fundingRoundList,
    industryTypeList,
    yearFoundedList,
    countryListCascader
  }
}
