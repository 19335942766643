<template>
  <div class="general-info mt-5">
    <div class="flex w-full">
      <div class="w-[50%]">
        <div class="relative w-full mb-4 mt-2">
          <label
            class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
            for="company-name"
          >
            Company name
          </label>
          <input
            id="company-name"
            name="company-name"
            type="text"
            readonly
            :value="currentPortfolioCompany.company_name"
            class="px-4 py-2 mt-2 font-Rubik text-black text-sm w-full border-[#DDE2E4] focus:outline-none focus:ring-0 placeholder-gray-400 rounded-[6px]"
            :class="[!isEditingRound ? 'focus:border-[#DDE2E4] border cursor-not-allowed !text-greyText' : 'border-[1px] focus:border-[#DDE2E4]']"
            placeholder="Enter a round title"
            style="transition: 'all .15s ease'"
          />
        </div>
        <div class="relative w-full mb-4 mt-2">
          <label
            class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal mb-2"
            for="company-summary"
          >
            Company Summary
          </label>
          <!-- <input
            id="company-summary"
            name="company-summary"
            type="text"
            readonly
            :value="currentPortfolioCompany.company_summary"
            class="px-4 py-2 mt-2 font-Rubik text-black text-sm w-full border-[#DDE2E4] focus:outline-none focus:ring-0 placeholder-gray-400 rounded-[6px]"
            :class="[!isEditingRound ? 'focus:border-[#DDE2E4] border cursor-not-allowed !text-greyText' : 'border-[1px] focus:border-[#DDE2E4]']"
            placeholder=""
            style="transition: 'all .15s ease'"
          /> -->
          <WYSIWYGField v-model="currentPortfolioCompany.company_summary" :isEditable="false" />
        </div>
        <div class="relative w-full mb-4 mt-2">
          <label
            class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
            for="funding-round"
          >
            Funding round
          </label>
          <input
            id="funding-round"
            name="funding-round"
            type="text"
            readonly
            :value="currentPortfolioCompany.funding_round"
            class="px-4 py-2 mt-2 font-Rubik text-black text-sm w-full border-[#DDE2E4] focus:outline-none focus:ring-0 placeholder-gray-400 rounded-[6px]"
            :class="[!isEditingRound ? 'focus:border-[#DDE2E4] border cursor-not-allowed !text-greyText' : 'border-[1px] focus:border-[#DDE2E4]']"
            placeholder=""
            style="transition: 'all .15s ease'"
          />
        </div>

        <div class="relative w-full mb-4">
          <label
            class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
            for="industry-type"
          >
            Industry type
          </label>
          <input
            id="industry-type"
            name="industry-type"
            type="text"
            placeholder=""
            readonly
            :value="currentPortfolioCompany.industry_type"
            class="px-4 py-2 mt-2 font-Rubik text-black text-sm w-full border-[#DDE2E4] focus:outline-none focus:ring-0 placeholder-gray-400 rounded-[6px]"
            :class="[!isEditingRound ? 'focus:border-[#DDE2E4] border cursor-not-allowed !text-greyText' : 'border-[1px] focus:border-[#DDE2E4]']"
            style="transition: 'all .15s ease'"
          />
        </div>
        <div class="relative w-full mb-4">
          <label
            class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
            for="business-model"
          >
            Business model
          </label>
          <input
            id="business-model"
            name="business-model"
            type="text"
            readonly
            placeholder=""
            :value="currentPortfolioCompany.business_model"
            class="px-4 py-2 mt-2 font-Rubik text-black text-sm w-full border-[#DDE2E4] focus:outline-none focus:ring-0 placeholder-gray-400 rounded-[6px]"
            :class="[!isEditingRound ? 'focus:border-[#DDE2E4] border cursor-not-allowed !text-greyText' : 'border-[1px] focus:border-[#DDE2E4]']"
            style="transition: 'all .15s ease'"
          />
        </div>

        <div class="relative w-full mb-4">
          <label
            class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
            for="city"
          >
            City
          </label>
          <input
            id="city"
            name="city"
            type="text"
            readonly
            placeholder=""
            :value="currentPortfolioCompany.address.city"
            class="px-4 py-2 mt-2 font-Rubik text-black text-sm w-full border-[#DDE2E4] focus:outline-none focus:ring-0 placeholder-gray-400 rounded-[6px]"
            :class="[!isEditingRound ? 'focus:border-[#DDE2E4] border cursor-not-allowed !text-greyText' : 'border-[1px] focus:border-[#DDE2E4]']"
            style="transition: 'all .15s ease'"
          />
        </div>
        <!-- <div class="relative flex items-center justify-end w-full">
          <button @click="isEditingRound = !isEditingRound" class="flex items-center text-greyText border-[1px] border-greyText rounded text-xs font-medium font-Rubik px-3 py-2 disabled:opacity-50 mx-2">{{ isEditingRound ? 'Cancel' : 'Edit' }}</button>
          <button class="flex items-center text-white bg-black rounded text-xs font-medium font-Rubik px-3 py-2 disabled:opacity-50 mx-2">Save</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import {
//   Listbox,
//   ListboxLabel,
//   ListboxButton,
//   ListboxOptions,
//   ListboxOption
// } from '@headlessui/vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import fundingRounds from '../../utils/fundinground'
import { useRoute } from 'vue-router'
import WYSIWYGField from '@/components/WYSIWYGField.vue'
export default {
  components: {
    WYSIWYGField
    // Listbox,
    // ListboxLabel,
    // ListboxButton,
    // ListboxOptions,
    // ListboxOption
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const routeID = ref(route.params.id)
    const isEditingRound = ref(false)
    const selectedFundingRound = ref(null)
    const selectedStatus = ref(null)
    const roundStatus = ref(['Open', 'Closed'])
    const portfolioCompanies = computed(() => store.state.app.portfolioCompanies)
    const currentPortfolioCompany = ref(null)
    const getPortfolioCompany = () => {
      currentPortfolioCompany.value = portfolioCompanies.value.find(company => company.id === routeID.value)
    }
    getPortfolioCompany()

    return {
      isEditingRound,
      fundingRounds,
      selectedFundingRound,
      roundStatus,
      selectedStatus,
      getPortfolioCompany,
      currentPortfolioCompany
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
