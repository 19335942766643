import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class RiskscoreService {
  static createRiskscore (companyid, data) {
    return axios.post(`${url}/admin/risk-scorecard/${companyid}`, data)
  }

  static fetchRiskscoreByRater (companyid) {
    return axios.get(`${url}/admin/risk-scorecard/ratings/${companyid}`)
  }

  static fetchRiskscoreById (companyid) {
    return axios.get(`${url}/admin/risk-scorecard/${companyid}`)
  }
}

export default RiskscoreService
