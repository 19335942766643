<template>
  <main class="dealflow-tabs mt-8 flex flex-1 flex-col min-w-0 mr-10">
    <div class="main-title flex justify-between pt-2">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Collections</h1>
      <!-- {{ industries }} -->
      <div class="w-[200px] text-right relative">
        <Menu>
          <MenuButton>
            <div class="flex items-center bg-black text-white py-[10px] px-[14px] font-medium rounded-[4px]">Actions
              <span class="ml-[10px]">
                <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.9998 6.33398C3.82961 6.33313 3.66174 6.29439 3.50838 6.22059C3.35501 6.14679 3.22 6.03978 3.11314 5.90732L0.306469 2.50732C0.142465 2.30263 0.0392593 2.05599 0.00861339 1.7955C-0.0220325 1.53501 0.0211136 1.27115 0.133135 1.03398C0.223988 0.827869 0.372251 0.65226 0.560215 0.528133C0.748179 0.404006 0.9679 0.336606 1.19314 0.333984H6.80647C7.0317 0.336606 7.25143 0.404006 7.43939 0.528133C7.62735 0.65226 7.77561 0.827869 7.86647 1.03398C7.97849 1.27115 8.02164 1.53501 7.99099 1.7955C7.96035 2.05599 7.85714 2.30263 7.69314 2.50732L4.88647 5.90732C4.7796 6.03978 4.64459 6.14679 4.49123 6.22059C4.33786 6.29439 4.17 6.33313 3.9998 6.33398Z" fill="white"/>
                </svg>
              </span>
            </div>
          </MenuButton>
          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems class="flex absolute right-0 top-[50px] flex-col py-[4px] bg-white rounded-[4px] shadow-md z-[5000]">
              <MenuItem v-slot="{ active }" @click="mapView = !mapView">
                <span :class='{ "bg-black text-white": active }' class="text-sm text-left py-2 px-4 cursor-pointer">{{ !mapView ? 'View Map' : 'View List' }}</span>
              </MenuItem>
              <MenuItem v-slot="{ active }" @click="data.addCompanyModal = true">
                <span :class='{ "bg-black text-white": active }' class="text-sm text-left py-2 px-4 cursor-pointer">Add company</span>
              </MenuItem>
              <MenuItem v-slot="{ active }" @click="openAddTargetCompanyToCollection">
                <span :class='{ "bg-black text-white": active }' class="text-sm text-left py-2 px-4 cursor-pointer">Add target company</span>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <span :class='{ "bg-black text-white": active }' @click="data.editCollectionModal = true" class="w-full text-sm text-left py-2 px-4 cursor-pointer">
                  Edit collection
                </span>
              </MenuItem>
              <MenuItem v-slot="{ active }" @click="data.createSubModal = true">
                <span :class='{ "bg-black text-white": active }' class="w-full text-sm text-left py-2 px-4 cursor-pointer">
                  Add new subcollection
                </span>
              </MenuItem>
              <MenuItem v-slot="{ active }" @click="data.deleteCollectionModal = true">
                <span :class='{ "bg-originsRed text-white": active }' class="disabled:opacity-75 text-sm text-left py-2 px-4 cursor-pointer">Delete collection</span>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
    <!-- **$*&loading...&*$$** -->
    <div class="mt-20" v-if="!dataloaded">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(241, 242, 243); display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(26.666666666666668,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#000000">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.3s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#636363">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.2s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(26.666666666666668,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#e1e1e1">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="0s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#545454">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.1s"></animateTransform>
          </rect>
        </g>
      </svg>
    </div>
    <div v-else>
      <Breadcrumb :page="companyID"/>
      <div class="mt-5">
        <div class="flex justify-between md:flex-row flex-col">
          <div class="flex flex-col md:flex-row md:items-center items-start">
            <h3 class="text-[20px] leading-[23.7px] font-Rubik mr-3">{{ collection.collection_name }}</h3>
            <span class="mr-2 md:block hidden">&#8226;</span>
            <div class="md:mb-0 mt-2">
              <span class="inline-flex items-center px-3 py-2 rounded-full text-[12px] leading-[14.22px] font-Rubik bg-faintGrey text-gray-800 font-medium ">
                {{ collection.companies.length }} {{ collection.companies.length == 0 ? "Companies" : collection.companies.length > 1 ? "Companies" : "Company" }}
              </span>
            </div>
          </div>
          <div class="flex flex-row items-center md:mt-0 mt-2">
            <div class="flex flex-col font-Rubik text-[11px] leading-[13.04px] mr-2">
              <span class="flex justify-end">Created by {{ collection.created_by.first_name }}</span>
              <span class="flex justify-end">{{getDate(collection.created_at)}}</span>
            </div>
            <div class="mr-2 h-9 w-9 bg-coolGray-100 rounded-full p-[2px]">
              <img class="rounded-full" :src="`https://origins-files-production.s3.us-east-2.amazonaws.com/admin/${collection.created_by.profile_image}`"/>
            </div>
          </div>
        </div>
        <p class="mt-4 text-sm font-Rubik text-greyText pb-[20px]">{{ collection.collection_description }}</p>
      </div>
      <div class="flex items-center justify-end">
        <SwitchGroup>
          <div class="flex items-center">
            <SwitchLabel class="mr-2 text-xs" :class="[!mapView ? 'text-black' : 'text-greyText']">List view</SwitchLabel>
            <Switch
              v-model="mapView"
              :class="mapView ? 'bg-black' : 'bg-greyText'"
              class="relative inline-flex h-6 w-11 items-center rounded-full"
              >
              <span class="sr-only">Map view</span>
              <span
              :class="mapView ? 'translate-x-6' : 'translate-x-1'"
              class="inline-block h-4 w-4 transform rounded-full bg-white transition"
              />
            </Switch>
            <SwitchLabel class="ml-2 text-xs" :class="[mapView ? 'text-black' : 'text-greyText']">Map view</SwitchLabel>
          </div>
        </SwitchGroup>
      </div>
      <div v-show="!mapView">
        <div v-if="subCollections.length != 0">
          <div class="flex items-center justify-between">
            <h3 class="text-[20px] leading-[23.7px] font-Rubik my-[20px]">Subcollections</h3>
            <button @click="data.viewAllSubCollections = !data.viewAllSubCollections" class="flex items-center text-sm text-black">
              {{ !data.viewAllSubCollections ? 'View all' : 'Show few'}}
              <span class="ml-1">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.6668 8.00047C10.6671 8.15624 10.6128 8.30719 10.5134 8.42713L7.1801 12.4271C7.06694 12.5633 6.90434 12.6489 6.72805 12.6651C6.55177 12.6814 6.37625 12.627 6.2401 12.5138C6.10396 12.4006 6.01834 12.238 6.00209 12.0617C5.98584 11.8855 6.04028 11.7099 6.15344 11.5738L9.1401 8.00047L6.2601 4.42713C6.20473 4.35894 6.16337 4.28048 6.13842 4.19625C6.11346 4.11203 6.1054 4.0237 6.11469 3.93635C6.12399 3.84899 6.15045 3.76434 6.19257 3.68725C6.23468 3.61016 6.29162 3.54215 6.3601 3.48713C6.42865 3.42608 6.50907 3.37984 6.59632 3.35131C6.68357 3.32278 6.77577 3.31258 6.86714 3.32134C6.95852 3.33011 7.0471 3.35764 7.12734 3.40223C7.20757 3.44682 7.27774 3.5075 7.33344 3.58047L10.5534 7.58047C10.6371 7.70386 10.677 7.85173 10.6668 8.00047Z" fill="#4A4F4A"/>
                </svg>
              </span>
            </button>
          </div>
          <div class="relative">
            <template v-if="!data.viewAllSubCollections">
              <div class="flex items-center flex-wrap">
                <div v-for="(sub, i) in subCollections.slice(0, 4)" :key="sub.id" @click="viewSubCollection(sub.id)" class="w-[259px] h-[80px] border-faintGrey border-[2px] rounded-[6px] p-4 flex items-start justify-between cursor-pointer mb-2" :class="[i < 3 ? 'mr-2' : '']">
                  <span class="mr-3 w-[20%]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3465_24199)">
                        <path d="M0.896136 8.33001H23.1039C23.3582 8.33001 23.5926 8.43327 23.7644 8.62053C23.9361 8.8078 24.0186 9.05057 23.9965 9.30376L22.9095 21.7614C22.8688 22.2277 22.4852 22.5794 22.017 22.5794H2.27567C1.81831 22.5794 1.43559 22.2365 1.38519 21.7821L0.00565234 9.3245C-0.0227402 9.0679 0.0564824 8.82077 0.228646 8.6284C0.400915 8.43593 0.637945 8.33001 0.896136 8.33001ZM22.8111 6.73493V4.76393C22.8111 4.32337 22.5427 3.97011 22.1022 3.97011H13.2406V3.72117C13.2406 2.42181 12.3712 1.41797 11.1936 1.41797H3.23592C2.05832 1.41797 1.01162 2.42181 1.01162 3.72117V6.37444C1.01162 6.50534 1.08393 6.62742 1.14635 6.73493H22.8111Z" fill="#666666"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_3465_24199">
                          <rect width="24" height="24" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <div class="w-[82%]">
                    <h4 class="text-sm font-medium overflow-hidden overflow-ellipsis whitespace-nowrap">{{ sub.collection_name }}</h4>
                    <span class="text-xs py-[3px] px-[10px] bg-faintGrey rounded-[64px]">{{ sub.companies_count }} {{ sub.companies_count == 0 ? "Companies" : sub.companies_count > 1 ? "Companies" : "Company" }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="flex items-center flex-wrap w-full">
                <div v-for="sub in subCollections" :key="sub.id" @click="viewSubCollection(sub.id)" class="w-[259px] h-[80px] border-faintGrey border-[2px] rounded-[4px] p-4 flex items-start justify-between cursor-pointer mb-2 mr-2">
                  <span class="mr-3 w-[20%]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3465_24199)">
                        <path d="M0.896136 8.33001H23.1039C23.3582 8.33001 23.5926 8.43327 23.7644 8.62053C23.9361 8.8078 24.0186 9.05057 23.9965 9.30376L22.9095 21.7614C22.8688 22.2277 22.4852 22.5794 22.017 22.5794H2.27567C1.81831 22.5794 1.43559 22.2365 1.38519 21.7821L0.00565234 9.3245C-0.0227402 9.0679 0.0564824 8.82077 0.228646 8.6284C0.400915 8.43593 0.637945 8.33001 0.896136 8.33001ZM22.8111 6.73493V4.76393C22.8111 4.32337 22.5427 3.97011 22.1022 3.97011H13.2406V3.72117C13.2406 2.42181 12.3712 1.41797 11.1936 1.41797H3.23592C2.05832 1.41797 1.01162 2.42181 1.01162 3.72117V6.37444C1.01162 6.50534 1.08393 6.62742 1.14635 6.73493H22.8111Z" fill="#666666"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_3465_24199">
                          <rect width="24" height="24" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <div class="w-[80%]">
                    <h4 class="text-sm font-medium overflow-hidden overflow-ellipsis whitespace-nowrap">{{ sub.collection_name }}</h4>
                    <span class="text-xs py-[3px] px-[10px] bg-faintGrey rounded-[64px]">{{ sub.companies_count }} {{ sub.companies_count == 0 ? "Companies" : sub.companies_count > 1 ? "Companies" : "Company" }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div
          :class="!collection ? 'flex items-center justify-center' : ''"
          class=""
        >
          <h3 class="text-[20px] leading-[23.7px] font-Rubik mb-[20px] mt-[40px]">Companies</h3>

          <div v-if="!collection.companies.length > 0" class="flex flex-col mt-20">
            <div class="flex justify-center">
              <img src="../../assets/collectionpage.svg"/>
            </div>
            <p class="my-4 font-Rubik text-[13px] text-center leading-[20px] text-greyText">You are yet to add a company to this list.</p>
            <button @click="data.addCompanyModal = true" class="flex items-center w-[121px] justify-start text-[15px] font-medium mx-auto">
              <span class="mr-2">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 0C7.48325 0 7.875 0.391751 7.875 0.875V6.125H13.125C13.6082 6.125 14 6.51675 14 7C14 7.48325 13.6082 7.875 13.125 7.875H7.875V13.125C7.875 13.6082 7.48325 14 7 14C6.51675 14 6.125 13.6082 6.125 13.125V7.875H0.875C0.391751 7.875 0 7.48325 0 7C0 6.51675 0.391751 6.125 0.875 6.125H6.125V0.875C6.125 0.391751 6.51675 0 7 0Z" fill="black"/>
                </svg>
              </span>
              Add company
            </button>
          </div>
          <div v-else class="flex flex-col mt-8 pb-20 overflow-auto min-h-[500px] z-[100] relative">
            <div
              v-for="company in collection.companies"
              :key="company.id"
              class="flex flex-row justify-between items-center h-[96px] min-w-[900px] py-6 px-5 border rounded-[6px] border-gray-200 mb-2">
              <div class="flex flex-row cursor-pointer w-[170px]" @click="goToCompany(company.id)">
                <div class="mr-2 h-9 bg-coolGray-100 rounded-full p-[2px] w-[20%]">
                  <img class="rounded-full" :src="`${s3Bucket}${company.logo}`"/>
                </div>
                <div class="flex flex-col items-start justify-center w-[70%]">
                  <span class="w-full">
                    <h4 class="text-[15px] leading-[17.78px] font-Rubik overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {{ company.company_name }}
                    </h4>
                  </span>
                  <div class="flex justify-start">
                    <span class="flex justify-start items-center px-2 py-1 bg-faintGrey text-greyText text-[12px] leading-[16px] font-Rubik mt-[6px]">{{company.business_model}}</span>
                  </div>
                </div>
              </div>
              <div class="w-[100px]">
                <h5 class="flex justify-start text-[15px] leading-[17.78px] font-Rubik">{{company.industry_type}}</h5>
              </div>
              <div class="w-[97px]">
                <h5 class="flex justify-start text-[15px] leading-[17.78px] font-Rubik">${{company.min_amount}} to ${{company.max_amount}}</h5>
              </div>
              <div class="w-[120px]">
                <h5 class="flex justify-start text-[15px] leading-[17.78px] font-Rubik">{{company.formatted_address.split(" ")[0]}}</h5>
              </div>
              <div class="w-[170px]">
                <div class="flex flex-row items-center w-full">
                  <div class="mr-2 h-9 w-[20%] bg-coolGray-100 rounded-full p-[2px]">
                    <img class="rounded-full" :src="`https://origins-files-production.s3.us-east-2.amazonaws.com/admin/${collection.created_by.profile_image}`"/>
                  </div>
                  <div class="flex flex-col font-Rubik w-[70%]">
                    <span class="w-full">
                      <h4 class="text-[15px] leading-[17.78px] font-Rubik overflow-hidden overflow-ellipsis whitespace-nowrap">
                       {{collection.created_by.first_name}} {{collection.created_by.last_name}}
                      </h4>
                    </span>
                    <span class="text-[12px] leading-[14.22px] text-greyText">Deal Lead</span>
                  </div>
                </div>
              </div>
              <div class="w-[100px]">
                <h5 class="flex justify-start text-[15px] leading-[17.78px] font-Rubik">{{company.current_stage}}</h5>
              </div>
              <div clas>
                <span v-if="company.stage_status === 'Processing'" class="py-[6px] px-3 flex items-center justify-center bg-lightYellow text-originsYellow rounded-[100px] text-[13px] leading-[15.41px] font-medium font-Rubik">
                  {{ company.stage_status }}
                </span>
                <span v-if="company.stage_status === 'Approved'" class="py-[6px] px-3 flex items-center justify-center bg-lightGreen text-originsGreen rounded-[100px] text-[13px] leading-[15.41px] font-medium font-Rubik">
                  {{ company.stage_status }}
                </span>
                <span v-if="company.stage_status === 'Rejected'" class="py-[6px] px-3 flex items-center justify-center bg-lightRed text-originsRed rounded-[100px] text-[13px] leading-[15.41px] font-medium font-Rubik">
                  {{ company.stage_status }}
                </span>
              </div>
              <div class="relative">
                <!-- {{ data.modalOpen }} -->
                <Menu>
                  <MenuButton>
                    <div class="flex items-center justify-center text-white py-[10px] px-[14px] font-medium rounded-[4px]">
                      <span>
                        <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="16" cy="2" r="2" fill="#666666"/>
                          <circle cx="9" cy="2" r="2" fill="#666666"/>
                          <circle cx="2" cy="2" r="2" fill="#666666"/>
                        </svg>
                      </span>
                    </div>
                  </MenuButton>
                  <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-out"
                    leave-from-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0"
                  >
                    <MenuItems class="flex absolute right-0 top-[50px] flex-col py-2 bg-white rounded-[4px] shadow-md z-[200] w-[180px]">
                      <MenuItem v-slot="{ active }">
                        <span @click="selectCompany(company.id)" :class='{ "bg-black text-white": active }' class="w-full text-sm text-left py-2 px-4 cursor-pointer">
                          Add to a subcollection
                        </span>
                      </MenuItem>
                      <MenuItem v-slot="{ active }" @click="data.deleteCompanyModal = !data.deleteCompanyModal">
                        <span @click="deleteCompany(company.id)" :class='{ "bg-originsRed text-white" : active }' class="disabled:opacity-75 text-sm text-left text-black py-2 px-4 cursor-pointer">Remove company</span>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="mapView" class="min-h-[calc(100vh-320px)]">
        <div class="">
          <h3 class="text-[20px] leading-[23.7px] font-Rubik my-[20px]">Map view</h3>
        </div>
        <div class="flex items-start w-full max-h-[calc(100vh-400px)]">
          <div class="left w-[25%] max-h-[584px] overflow-y-auto">
            <div class="w-full">
              <div class="mx-auto w-full max-w-md rounded-2xl bg-white p-2 border border-faintGrey">
                <h3 class="font-bold mb-4 mt-2 px-4">{{collection.collection_name}}</h3>
                <Disclosure v-slot="{ open }">
                  <DisclosureButton class="flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium hover:bg-faintGrey focus:outline-none focus-visible:ring focus-visible:ring-faintGrey focus-visible:ring-opacity-75">
                    <p class="flex items-center">
                      <span class="mr-2">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.3335 5.33398C2.22683 5.33398 1.3335 6.22732 1.3335 7.33398V12.6673C1.3335 13.774 2.22683 14.6673 3.3335 14.6673H4.3335C5.06683 14.6673 5.66683 14.0673 5.66683 13.334V6.66732C5.66683 5.93398 5.06683 5.33398 4.3335 5.33398H3.3335ZM4.00016 11.334C4.00016 11.6073 3.7735 11.834 3.50016 11.834C3.22683 11.834 3.00016 11.6073 3.00016 11.334V8.66732C3.00016 8.39398 3.22683 8.16732 3.50016 8.16732C3.7735 8.16732 4.00016 8.39398 4.00016 8.66732V11.334Z" fill="#666666"/>
                          <path d="M12.6665 1.33398H8.6665C7.55984 1.33398 6.6665 2.22732 6.6665 3.33398V12.6673C6.6665 13.774 7.55984 14.6673 8.6665 14.6673H8.99984C9.1865 14.6673 9.33317 14.5206 9.33317 14.334V12.0007C9.33317 11.634 9.63317 11.334 9.99984 11.334H11.3332C11.6998 11.334 11.9998 11.634 11.9998 12.0007V14.334C11.9998 14.5206 12.1465 14.6673 12.3332 14.6673H12.6665C13.7732 14.6673 14.6665 13.774 14.6665 12.6673V3.33398C14.6665 2.22732 13.7732 1.33398 12.6665 1.33398ZM9.83317 8.66732C9.83317 8.94065 9.6065 9.16732 9.33317 9.16732C9.05984 9.16732 8.83317 8.94065 8.83317 8.66732V5.33398C8.83317 5.06065 9.05984 4.83398 9.33317 4.83398C9.6065 4.83398 9.83317 5.06065 9.83317 5.33398V8.66732ZM12.4998 8.66732C12.4998 8.94065 12.2732 9.16732 11.9998 9.16732C11.7265 9.16732 11.4998 8.94065 11.4998 8.66732V5.33398C11.4998 5.06065 11.7265 4.83398 11.9998 4.83398C12.2732 4.83398 12.4998 5.06065 12.4998 5.33398V8.66732Z" fill="#666666"/>
                        </svg>
                      </span>
                      <!-- {{collection.companies.length}} {{ collection.companies.length > 1 ? 'COMPANIES' : 'COMPANY' }} -->
                      ALL COMPANIES
                    </p>
                    <span>
                      <svg :class="open ? 'rotate-180 transform' : ''" class="h-5 w-5" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.00002 10.6675C7.84425 10.6678 7.6933 10.6136 7.57336 10.5142L3.57335 7.18084C3.43721 7.06768 3.35159 6.90507 3.33534 6.72878C3.31909 6.5525 3.37353 6.37698 3.48669 6.24083C3.59985 6.10469 3.76245 6.01907 3.93874 6.00282C4.11502 5.98657 4.29054 6.04101 4.42669 6.15417L8.00002 9.14083L11.5734 6.26083C11.6415 6.20546 11.72 6.1641 11.8042 6.13915C11.8885 6.11419 11.9768 6.10613 12.0641 6.11542C12.1515 6.12472 12.2361 6.15118 12.3132 6.1933C12.3903 6.23541 12.4583 6.29235 12.5134 6.36083C12.5744 6.42938 12.6206 6.5098 12.6492 6.59705C12.6777 6.6843 12.6879 6.7765 12.6791 6.86787C12.6704 6.95925 12.6428 7.04783 12.5983 7.12807C12.5537 7.20831 12.493 7.27847 12.42 7.33417L8.42002 10.5542C8.29663 10.6378 8.14876 10.6777 8.00002 10.6675Z" fill="#4A4F4A"/>
                      </svg>
                    </span>
                  </DisclosureButton>
                  <DisclosurePanel class="px-4 pt-4 pb-2 text-sm text-gray-500">
                    <ul>
                      <li v-for="company in collection.companies" :key="company.id" @click="goToCompany(company.id)" class="mb-4 text-sm cursor-pointer">{{ company.company_name }}</li>
                    </ul>
                  </DisclosurePanel>
                </Disclosure>
                <Disclosure v-if="collection.sub_collections.length > 0" v-slot="{ open }">
                  <DisclosureButton class="flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium hover:bg-faintGrey focus:outline-none focus-visible:ring focus-visible:ring-faintGrey focus-visible:ring-opacity-75">
                    <p class="flex items-center">
                      <span class="mr-2">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.3335 5.33398C2.22683 5.33398 1.3335 6.22732 1.3335 7.33398V12.6673C1.3335 13.774 2.22683 14.6673 3.3335 14.6673H4.3335C5.06683 14.6673 5.66683 14.0673 5.66683 13.334V6.66732C5.66683 5.93398 5.06683 5.33398 4.3335 5.33398H3.3335ZM4.00016 11.334C4.00016 11.6073 3.7735 11.834 3.50016 11.834C3.22683 11.834 3.00016 11.6073 3.00016 11.334V8.66732C3.00016 8.39398 3.22683 8.16732 3.50016 8.16732C3.7735 8.16732 4.00016 8.39398 4.00016 8.66732V11.334Z" fill="#666666"/>
                          <path d="M12.6665 1.33398H8.6665C7.55984 1.33398 6.6665 2.22732 6.6665 3.33398V12.6673C6.6665 13.774 7.55984 14.6673 8.6665 14.6673H8.99984C9.1865 14.6673 9.33317 14.5206 9.33317 14.334V12.0007C9.33317 11.634 9.63317 11.334 9.99984 11.334H11.3332C11.6998 11.334 11.9998 11.634 11.9998 12.0007V14.334C11.9998 14.5206 12.1465 14.6673 12.3332 14.6673H12.6665C13.7732 14.6673 14.6665 13.774 14.6665 12.6673V3.33398C14.6665 2.22732 13.7732 1.33398 12.6665 1.33398ZM9.83317 8.66732C9.83317 8.94065 9.6065 9.16732 9.33317 9.16732C9.05984 9.16732 8.83317 8.94065 8.83317 8.66732V5.33398C8.83317 5.06065 9.05984 4.83398 9.33317 4.83398C9.6065 4.83398 9.83317 5.06065 9.83317 5.33398V8.66732ZM12.4998 8.66732C12.4998 8.94065 12.2732 9.16732 11.9998 9.16732C11.7265 9.16732 11.4998 8.94065 11.4998 8.66732V5.33398C11.4998 5.06065 11.7265 4.83398 11.9998 4.83398C12.2732 4.83398 12.4998 5.06065 12.4998 5.33398V8.66732Z" fill="#666666"/>
                        </svg>
                      </span>
                      {{collection.sub_collections.length}} {{ collection.sub_collections.length > 1 ? 'SUBCATEGORIES' : 'SUBCATEGORY' }}
                    </p>
                    <span>
                      <svg :class="open ? 'rotate-180 transform' : ''" class="h-5 w-5" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.00002 10.6675C7.84425 10.6678 7.6933 10.6136 7.57336 10.5142L3.57335 7.18084C3.43721 7.06768 3.35159 6.90507 3.33534 6.72878C3.31909 6.5525 3.37353 6.37698 3.48669 6.24083C3.59985 6.10469 3.76245 6.01907 3.93874 6.00282C4.11502 5.98657 4.29054 6.04101 4.42669 6.15417L8.00002 9.14083L11.5734 6.26083C11.6415 6.20546 11.72 6.1641 11.8042 6.13915C11.8885 6.11419 11.9768 6.10613 12.0641 6.11542C12.1515 6.12472 12.2361 6.15118 12.3132 6.1933C12.3903 6.23541 12.4583 6.29235 12.5134 6.36083C12.5744 6.42938 12.6206 6.5098 12.6492 6.59705C12.6777 6.6843 12.6879 6.7765 12.6791 6.86787C12.6704 6.95925 12.6428 7.04783 12.5983 7.12807C12.5537 7.20831 12.493 7.27847 12.42 7.33417L8.42002 10.5542C8.29663 10.6378 8.14876 10.6777 8.00002 10.6675Z" fill="#4A4F4A"/>
                      </svg>
                    </span>
                  </DisclosureButton>
                  <DisclosurePanel class="px-4 pt-4 pb-2 text-sm text-gray-500">
                    <ul>
                      <li v-for="collection in collection.sub_collections" :key="collection.id" @click="goToSubCollection(collection.id)" class="mb-4 text-sm truncate cursor-pointer">{{ collection.collection_name }}</li>
                    </ul>
                  </DisclosurePanel>
                </Disclosure>
              </div>
            </div>
          </div>
          <div class="right w-[75%] pl-4">
            <Map :mapData="collection" :markerColor="collection.color" />
          </div>
        </div>
      </div>
      <!-- <div v-else>
        <p class="text-center text-greyText text-xs">You currently don't have a sub collection</p>
      </div> -->
    </div>
    <Modal v-show="data.modalOpen">
      <div class="bg-white rounded-[2px] p-4 relative w-[480px]">
        <template v-if="!data.collection">
          <button @click.prevent="closeModal" class="close absolute right-[20px] top-[20px]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0D0D0D"/>
          </svg>
          </button>
          <h3 class="font-medium font-Rubik leading-[18.96px] text-black mb-[8px]">
            Add this company to a collection
          </h3>
          <hr />
          <div class="mt-4 w-full">
            <label for="search" class="sr-only">Search Collection</label>
            <div class="relative text-black">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  class="h-4 w-4 text-black"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                </svg>
              </div>
              <input
                id="search"
                name="search"
                class="block w-full pl-10 pr-3 py-3 rounded-md text-[14px] leading-[17.78px] font-Rubik bg-faintGrey placeholder-black text-black text-sm border-none focus:outline-none focus:ring-0 "
                placeholder="Search collection"
                type="search"
              />
            </div>
          </div>
          <div class="mt-4 mb-4 h-[350px] overflow-auto">
            <div v-for="(collection, collectionIdx) in collection.sub_collections" :key="collectionIdx" class="mt-3 mb-3 relative flex items-start p-6 border rounded">
              <div class="mr-5">
                <img src="../../assets/collection.svg"/>
              </div>
              <div class="min-w-0 flex-1 text-sm">
                <div class="flex flex-col">
                  <label :for="`collection-${collection.id}`" class="text-[15px] leading-[17.78px] font-Rubik">{{ collection.collection_name }}</label>
                  <label :for="`collection-${collection.id}`" class="text-[11px] leading-[13.04px] font-Rubik text-greyText">{{ collection.companies_count }} Companies</label>
                </div>
              </div>
              <div class="ml-3 flex items-center my-auto h-5">
                <input @input="addSelected(collection.id)" :id="`collection-${collection.id}`" :name="`collection-${collection.id}`" type="checkbox" class="focus:ring-black h-4 w-4 text-black border-gray-300 rounded" />
              </div>
            </div>
          </div>
          <div class="w-full flex items-center justify-end">
            <button :disabled="!selectedCollectionIds.length > 0" @click="addCompanyToSubCollection" class="mt-3 flex bg-black text-white rounded-[4px] text-[13px] font-Rubik px-4 py-3 outline-none focus:outline-none disabled:opacity-50">
              <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white">Submit</span>
              <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
              </svg>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col items-center justify-center">
            <h3 class="font-medium font-Rubik text-[16px] leading-[18.96px] text-black mb-[8px]">
              Created!
            </h3>
            <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">Company has been added to collection!</p>
            <button @click.prevent="closeModal" class="mt-3 border-b border-teal font-medium py-[12px] px-[35px] text-white text-[13px] leading-[15.41px] rounded-[4px] bg-black">Okay</button>
          </div>
        </template>
      </div>
    </Modal>
    <TransitionRoot as="template" :show="addTargetCompanyModal">
      <Dialog as="div" class="relative z-[9999]" @close="closeModal()">
          <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
              <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                  <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                    <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                      <div class="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button type="button" class="rounded-full text-black hover:text-white focus:outline-none hover:ring-2 hover:ring-black hover:bg-black bg-white p-1 transition-all" @click="closeModal()">
                          <span class="sr-only">Close panel</span>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </TransitionChild>
                    <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div class="px-4 sm:px-6">
                        <DialogTitle class="text-lg font-medium text-gray-900">New target Company</DialogTitle>
                      </div>
                      <div class="relative mt-6 flex-1 px-4 sm:px-6">
                        <!-- content -->
                        <template v-if="!newTargetCompany">
                          <div class="absolute inset-0 px-4 sm:px-6">
                            <div class="h-[max-content]">
                              <div class="max-w-[613px] pb-8">
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-sm font-normal"
                                    htmlFor="companyName"
                                  >
                                    <span class="text-red-400">*</span>
                                    Company Name
                                  </label>
                                  <input
                                    id="companyName"
                                    name="companyName"
                                    type="text"
                                    :class="[
                                      v$.company_name.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="Company XYZ"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.company_name.$model"
                                    @blur="v$.company_name.$touch()"
                                  />
                                  <div v-if="v$.company_name.$error">
                                    <p
                                      v-if="v$.company_name.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Company name is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                                    htmlFor="websiteLink"
                                  >
                                    <span class="text-red-400">*</span>
                                    Link to Website
                                  </label>
                                  <input
                                    id="websiteLink"
                                    name="websiteLink"
                                    type="text"
                                    :class="[
                                      v$.website.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="https://companyxyz.com"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.website.$model"
                                    @blur="v$.website.$touch()"
                                  />
                                  <div v-if="v$.website.$error">
                                    <p
                                      v-if="v$.website.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Company website is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                                    htmlFor="yearFounded"
                                  >
                                    <span class="text-red-400">*</span>
                                    Year Founded
                                  </label>
                                  <input
                                    id="yearFounded"
                                    name="yearFounded"
                                    type="number"
                                    :class="[
                                      v$.year_founded.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    min="1500"
                                    max="2099"
                                    step="1"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="2000"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.year_founded.$model"
                                    @blur="v$.year_founded.$touch()"
                                  />
                                  <div v-if="v$.year_founded.$error">
                                    <p
                                      v-if="v$.year_founded.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Year founded is required</p>
                                    <p
                                      v-if="v$.year_founded.betweenValue.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >{{v$.year_founded.betweenValue.$message}}</p>
                                    <div>
                                    </div>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                                    htmlFor="companyName"
                                  >
                                    <span class="text-red-400">*</span>
                                    Company Summary
                                  </label>
                                  <textarea
                                    id="companySummary"
                                    name="companySummary"
                                    type="text"
                                    rows="3"
                                    maxlength="450"
                                    :class="[
                                      v$.company_summary.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="Please summarize what your company does in a paragraph or less"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.company_summary.$model"
                                    @blur="v$.company_summary.$touch()"
                                  />
                                  <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${targetCompanyData.company_summary.length} of 450`}}</span>
                                  <div v-if="v$.company_summary.$error">
                                    <p
                                      v-if="v$.company_summary.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Company Summary is required</p>
                                    <p
                                      v-if="v$.company_summary.minLengthValue.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Company summary should be at least 3 characters long</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <div
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal mb-2"
                                    for="pitch-upload"
                                  >
                                    <span class="text-red-400">*</span>
                                    Pitch Deck
                                  </div>
                                  <div class="flex flex-row items-center">
                                    <label for="pitch-upload" class="flex items-center px-2 py-3 w-[max-content] relative cursor-pointer bg-white font-Rubik text-black rounded-sm border border-dashed focus:outline-none focus:border-black hover:border-black focus:ring-0 text-sm">
                                      <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                        </svg>
                                      </span>
                                      Upload a File
                                      <input
                                        id="pitch-upload"
                                        name="pitch-upload"
                                        type="file"
                                        accept="application/pdf"
                                        class="sr-only"
                                        @change="choosedata($event, 'pitch_deck', 'pitch_deck_type', 'PRESENTATION')"
                                      >
                                    </label>
                                    <div class="ml-5 text-blueLink">
                                      {{ files.pitch_deck }}
                                    </div>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <div
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal mb-2"
                                    for="logo-upload"
                                  >
                                    <span class="text-red-400">*</span>
                                    Company Logo
                                  </div>
                                  <div class="flex flex-row items-center">
                                    <label for="logo-upload" class="flex items-center px-2 py-3 w-[max-content] relative cursor-pointer bg-white font-Rubik text-black rounded-sm border border-dashed focus:outline-none focus:border-black hover:border-black focus:ring-0 text-sm">
                                      <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                        </svg>
                                      </span>
                                      Upload Logo
                                      <input
                                        id="logo-upload"
                                        name="logo-upload"
                                        type="file"
                                        accept="image/*"
                                        class="sr-only"
                                        @change="choosedata($event, 'logo', 'logo_type', 'PRESENTATION')"
                                      >
                                    </label>
                                    <div class="ml-5 text-blueLink">
                                      {{files.logo}}
                                    </div>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                                    htmlFor="location"
                                  >
                                    <span class="text-red-400">*</span>
                                    Location
                                  </label>
                                  <input
                                    id="location"
                                    name="location"
                                    type="text"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="Type location"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.location.$model"
                                    @blur="v$.location.$touch()"
                                  />
                                  <div v-if="v$.location.$error">
                                    <p
                                      v-if="v$.location.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Location is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6 z-30">
                                  <Listbox
                                    v-model="v$.industry_type.$model"
                                  >
                                    <div class="relative mt-1">
                                      <ListboxLabel class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                                        <span class="text-red-400">*</span>
                                        Industry Type
                                      </ListboxLabel>
                                      <ListboxButton
                                        @blur="v$.industry_type.$touch()"
                                        :class="[
                                          v$.industry_type.$error ? 'border-red-400' : 'border-grey-300',
                                        ]"
                                        class="relative px-4 py-4 mt-2 text-left placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                      >
                                        <span v-if="!v$.industry_type.$model" class="text-gray-400 block truncate">Select Industry Type</span>
                                        <span v-else class="block truncate">{{ v$.industry_type.$model }}</span>
                                        <span
                                          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                                        >

                                        </span>
                                      </ListboxButton>

                                      <transition
                                        leave-active-class="transition duration-100 ease-in"
                                        leave-from-class="opacity-100"
                                        leave-to-class="opacity-0"
                                      >
                                        <ListboxOptions
                                          class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                        >
                                          <ListboxOption
                                            v-slot="{ active, selected }"
                                            v-for="industry in industries"
                                            :key="industry"
                                            :value="industry"
                                            as="template"
                                          >
                                            <li
                                              :class="[
                                                active ? 'text-white bg-black' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-10 pr-4',
                                              ]"
                                            >
                                              <span
                                                :class="[
                                                  selected ? 'font-medium' : 'font-normal',
                                                  'block truncate',
                                                ]"
                                                >{{ industry }}</span
                                              >
                                              <span
                                                v-if="selected"
                                                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400"
                                              >

                                              </span>
                                            </li>
                                          </ListboxOption>
                                        </ListboxOptions>
                                      </transition>
                                    </div>
                                  </Listbox>
                                  <div v-if="v$.industry_type.$error">
                                    <p
                                      v-if="v$.industry_type.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Industry type is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6 z-20">
                                  <Listbox v-model="v$.business_model.$model">
                                    <div class="relative mt-1">
                                      <ListboxLabel class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                                        <span class="text-red-400">*</span>
                                        Business Model
                                      </ListboxLabel>
                                      <ListboxButton
                                        @blur="v$.business_model.$touch()"
                                        :class="[
                                          v$.business_model.$error ? 'border-red-400' : 'border-grey-300',
                                        ]"
                                        class="relative px-4 py-4 mt-2 text-left placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                      >
                                        <span v-if="!v$.business_model.$model" class="block truncate text-gray-400">Select Business model</span>
                                        <span v-else class="block truncate">{{ v$.business_model.$model }}</span>
                                        <span
                                          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                                        >

                                        </span>
                                      </ListboxButton>

                                      <transition
                                        leave-active-class="transition duration-100 ease-in"
                                        leave-from-class="opacity-100"
                                        leave-to-class="opacity-0"
                                      >
                                        <ListboxOptions
                                          class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                        >
                                          <ListboxOption
                                            v-slot="{ active, selected }"
                                            v-for="model in businessmodel"
                                            :key="model"
                                            :value="model"
                                            as="template"
                                          >
                                            <li
                                              :class="[
                                                active ? 'text-white bg-black' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-10 pr-4',
                                              ]"
                                            >
                                              <span
                                                :class="[
                                                  selected ? 'font-medium' : 'font-normal',
                                                  'block truncate',
                                                ]"
                                                >{{ model }}</span
                                              >
                                              <span
                                                v-if="selected"
                                                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400"
                                              >

                                              </span>
                                            </li>
                                          </ListboxOption>
                                        </ListboxOptions>
                                      </transition>
                                    </div>
                                  </Listbox>
                                  <div v-if="v$.business_model.$error">
                                    <p
                                      v-if="v$.business_model.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Business model is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-sm font-normal"
                                    htmlFor="firstname"
                                  >
                                    <span class="text-red-400">*</span>
                                    First Name
                                  </label>
                                  <input
                                    id="firstname"
                                    name="firstname"
                                    type="text"
                                    :class="[
                                      v$.first_name.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="John"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.first_name.$model"
                                    @blur="v$.first_name.$touch()"
                                  />
                                  <div v-if="v$.first_name.$error">
                                    <p
                                      v-if="v$.first_name.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >First name is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-sm font-normal"
                                    htmlFor="lastname"
                                  >
                                    <span class="text-red-400">*</span>
                                    Last Name
                                  </label>
                                  <input
                                    id="lastname"
                                    name="lastname"
                                    type="text"
                                    :class="[
                                      v$.last_name.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="Doe"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.last_name.$model"
                                    @blur="v$.last_name.$touch()"
                                  />
                                  <div v-if="v$.last_name.$error">
                                    <p
                                      v-if="v$.last_name.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Last name is required</p>
                                  </div>
                                </div>
                                <div class="relative w-full mb-6">
                                  <label
                                    class="font-Rubik text-greyText text-sm font-normal"
                                    htmlFor="email"
                                  >
                                    <span class="text-red-400">*</span>
                                    Email
                                  </label>
                                  <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    :class="[
                                      v$.email.$error ? 'border-red-400' : 'border-grey-300',
                                    ]"
                                    class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                    placeholder="name@mail.com"
                                    style="transition: 'all .15s ease'"
                                    v-model="v$.email.$model"
                                    @blur="v$.email.$touch()"
                                  />
                                  <div v-if="v$.email.$error">
                                    <p
                                      v-if="v$.email.required.$invalid"
                                      class="text-red-400 text-[10px] font-light"
                                    >Email is required</p>
                                  </div>
                                </div>
                                <div class="relative flex justify-end w-full mb-6">
                                  <button
                                    :disabled="v$.$invalid"
                                    class="flex bg-black text-white rounded-[6px] text-[16px] leading-[24px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none disabled:opacity-50"
                                    @click="onSubmitTargetCompany"
                                  >
                                    <span class="mr-2 text-white">Submit</span>
                                    <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                      <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                      </circle>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="flex flex-col items-center justify-center h-full">
                            <h3 class="font-medium font-Rubik text-[16px] leading-[18.96px] text-black mb-[8px]">
                              Success!
                            </h3>
                            <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">Target company created!</p>
                            <button @click.prevent="closeModal()" class="mt-3 border-b border-teal font-medium py-[12px] px-[35px] text-white text-[13px] leading-[15.41px] rounded-[4px] bg-black">Okay</button>
                          </div>
                        </template>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
      </Dialog>
    </TransitionRoot>
    <Modal v-show="data.editCollectionModal">
      <div class="bg-white rounded-[2px] p-4 relative w-[480px]">
        <template v-if="!data.collection">
          <button @click.prevent="closeModal" class="close absolute right-[10px] top-[10px]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0D0D0D"/>
          </svg>
          </button>
          <h3 class="font-medium font-Rubik leading-[18.96px] text-black mb-[8px]">
            Edit collection
          </h3>
          <hr />
          <div class="role mt-6 mb-6">
            <label
              class="
                font-Rubik
                text-greyText text-[14px]
                leading-[16.59px]
                font-normal
              "
              for="collection_description"
            >
              Description
            </label>

             <div class="relative">
              <textarea
                id="collection_description"
                name="collection_description"
                type="text"
                rows="2"
                maxlength="400"
                class="px-3 py-2 mt-2 font-Rubik text-black text-sm w-full border-[#DDE2E4] focus:outline-none focus:ring-0 placeholder-gray-400 rounded-[6px]"
                style="transition: 'all .15s ease'"
                :placeholder="collection.collection_description"
              v-model="data.collection_description"
              />
              <span class="block w-max absolute right-0 font-Rubik font-light text-[10px] leading-[14.22px]">{{`${data.collection_description.length} of 400`}}</span>
            </div>
          </div>
          <div class="w-full flex items-center justify-end">
            <button :disabled="!data.collection_description" @click="modifyCollection" class="mt-3 flex bg-black text-white rounded-[4px] text-[13px] font-Rubik px-4 py-3 outline-none focus:outline-none disabled:opacity-50">
              <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white">Save</span>
              <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
              </svg>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col items-center justify-center">
            <h3 class="font-medium font-Rubik text-[16px] leading-[18.96px] text-black mb-[8px]">
              Saved!
            </h3>
            <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">Your collection has been saved</p>
            <button @click.prevent="closeModal" class="mt-3 flex bg-black text-white rounded-[4px] text-[13px] font-Rubik px-4 py-3 outline-none focus:outline-none disabled:opacity-50">Okay</button>
          </div>
        </template>
      </div>
    </Modal>
    <Modal v-show="data.createSubModal">
      <div class="bg-white rounded-[2px] p-4 relative w-[480px]">
        <template v-if="!data.collection">
          <button @click.prevent="closeModal" class="close absolute right-[10px] top-[10px]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0D0D0D"/>
          </svg>
          </button>
          <h3 class="font-medium font-Rubik leading-[18.96px] text-black mb-[8px]">
            Create new Subcollection
          </h3>
          <hr />
          <div class="name mt-6 mb-3">
            <label
              class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
              for="collection_name"
            >
              Name
            </label>
            <div class="relative">
              <input
                id="collection_name"
                name="collection_name"
                type="text"
                maxlength="400"
                class="px-4 py-2 mt-2 font-Rubik text-black text-sm w-full border-[#DDE2E4] focus:outline-none focus:ring-0 placeholder-gray-400 rounded-[6px] mb-2"
                placeholder=""
                style="transition: 'all .15s ease'"
                v-model="data.collection_name"
              />
              <span class="block w-max absolute right-0 font-Rubik font-light text-[10px] leading-[14.22px]">{{`${data.collection_name.length}`}}</span>
            </div>
          </div>
          <div class="description mb-6">
            <label
              class="
                font-Rubik
                text-greyText text-[14px]
                leading-[16.59px]
                font-normal
              "
              for="collection_description"
            >
              Description
            </label>

             <div class="relative">
              <textarea
                id="collection_description"
                name="collection_description"
                type="text"
                rows="2"
                maxlength="400"
                class="px-4 py-2 mt-2 font-Rubik text-black text-sm w-full border-[#DDE2E4] focus:outline-none focus:ring-0 placeholder-gray-400 rounded-[6px]"
                placeholder=""
                style="transition: 'all .15s ease'"
                v-model="data.collection_description"
              />
              <span class="block w-max absolute right-0 font-Rubik font-light text-[10px] leading-[14.22px]">{{`${data.collection_description.length} of 400`}}</span>
            </div>
          </div>
          <div class="w-full flex items-center justify-end">
            <button @click="closeModal" class="mt-3 flex bg-faintGrey text-black text-[13px] font-Rubik px-4 py-3 outline-none focus:outline-none mr-6 rounded-[4px]">
              Cancel
            </button>
            <button :disabled="!data.collection_name || !data.collection_description" @click="createCollection" class="mt-3 flex bg-black text-white rounded-[4px] text-xs font-Rubik px-4 py-3 outline-none focus:outline-none disabled:opacity-50">
              <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white">Submit</span>
              <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
              </svg>
            </button>

          </div>
        </template>
        <template v-else>
          <div class="flex flex-col items-center justify-center">
            <h3 class="font-medium font-Rubik text-[16px] leading-[18.96px] text-black mb-[8px]">
              Created!
            </h3>
            <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">Your collection has been created</p>
            <button @click.prevent="closeModal" class="mt-3 border-b border-teal font-medium py-3 px-4 text-white text-xs leading-[15.41px] rounded-[4px] bg-black">Okay</button>
          </div>
        </template>
      </div>
    </Modal>
    <Modal v-show="data.deleteCompanyModal">
      <template v-if="!data.collection">
        <div class="flex flex-col items-center justify-center py-6 px-14">
          <h3 class="font-medium font-Rubik text-[14px] leading-[18.96px] text-greyText mb-[8px]">
            Company removed successfully!
          </h3>
          <!-- <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">You are about to remove this company from this collection.<br/>This action cannot be undone</p> -->
          <button @click.prevent="closeModal" class="mt-3 border-b border-teal font-medium py-3 px-[16px] text-white text-xs leading-[15.41px] rounded-[6px] bg-black">Okay</button>
        </div>
      </template>
    </Modal>
    <TransitionRoot as="template" :show="data.addCompanyModal">
        <Dialog as="div" class="relative z-[9999]" @close="closeModal()">
          <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
              <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                  <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                    <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                      <div class="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button type="button" class="rounded-full text-black hover:text-white focus:outline-none hover:ring-2 hover:ring-black hover:bg-black bg-white p-1 transition-all" @click="closeModal()">
                          <span class="sr-only">Close panel</span>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </TransitionChild>
                    <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div class="px-4 sm:px-6">
                        <DialogTitle class="text-lg font-medium text-gray-900">Add company to this collection</DialogTitle>
                      </div>
                      <div class="relative mt-6 flex-1 px-4 sm:px-6">
                        <!-- Replace with your content -->
                        <div class="absolute inset-0 px-4 sm:px-6">
                          <div v-if="!data.collection" class="h-full" aria-hidden="true">
                            <div class="dropdown-container w-full h-full relative mb-6 flex flex-col items-start justify-between">
                              <div class="head w-full">
                                <label for="select-company" class="text-greyText text-sm">Company</label>
                                  <div @click="companyDropdown = !companyDropdown" class="input relative flex items-center space-between border-[1px] rounded-[4px] border-faintGrey px-4 py-2 my-3 hover:cursor-pointer max-h-[70px] overflow-y-auto">
                                    <div class="left w-[95%] text-greyText text-sm h-[60px] flex items-center">
                                      <template v-if="data.addedCompanies.length < 1">
                                        Select company
                                      </template>
                                      <template v-else>
                                        <div class="w-full flex flex-row flex-wrap justify-start">
                                          <span v-for="(company, i) in data.addedCompanies" :key="i" class="block mr-1 mb-1 py-[5px] px-[10px] text-xs bg-faintGrey rounded-[4px]">
                                            {{ company.company_name }}
                                          </span>
                                        </div>
                                      </template>
                                    </div>
                                    <div class="right w-[5%] h-[max-content] mx-2 static right-0 icon flex items-center justify-content">
                                      <svg :class="companyDropdown ? '' : 'rotate-[180deg]'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 15L12 9L18 15" stroke="#1C1C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                    </div>
                                  </div>
                              </div>
                              <div class="items-container h-[calc(100vh-300px)] overflow-y-auto mb-2 w-full">
                                <transition
                                  enter-active-class="transition duration-100 ease-out"
                                  enter-from-class="transform scale-95 opacity-0"
                                  enter-to-class="transform scale-100 opacity-100"
                                  leave-active-class="transition duration-75 ease-out"
                                  leave-from-class="transform scale-100 opacity-100"
                                  leave-to-class="transform scale-95 opacity-0"
                                >
                                <ul v-show="companyDropdown" class="items py-2">
                                  <li v-for="(company, companyIdx) in data.filteredCompanies" :key="companyIdx" class="relative flex items-start py-3 px-[22px] border hover:cursor-pointer">
                                    <div class="mr-5 w-[36px] h-[36px] rounded-full overflow-hidden">
                                      <img :src="`${s3Bucket}${company.logo}`" />
                                    </div>
                                    <div class="min-w-0 flex-1 text-sm">
                                      <div class="flex flex-col">
                                        <label :for="`company-${company.id}`" class="text-sm leading-[17.78px] font-Rubik">{{ company.company_name }}</label>
                                        <label :for="`company-${company.id}`" class="text-xs leading-[17.78px] font-Rubik bg-faintGrey text-greyText w-[max-content] px-[6px] py-[2px] rounded-[2px]">{{ company.business_model }}</label>
                                      </div>
                                    </div>
                                    <div class="ml-3 flex items-center my-auto h-5">
                                      <input @input="addSelectedCompanies(company)" :id="`company-${company.id}`" :v-model="`company-${company.id}`" :name="`company-${company.id}`" type="checkbox" class="focus:ring-black h-4 w-4 text-black border-gray-300 rounded-[2px]" />
                                    </div>
                                  </li>
                                </ul>
                              </transition>
                              </div>
                              <div class="w-full flex flex-row-reverse items-center justify-between">
                                <button :disabled="!data.addedCompanies.length > 0" @click="addMultipleCompaniesToOneCollection" class="mt-3 flex bg-black text-white rounded-[4px] text-sm font-Rubik px-4 py-3 outline-none focus:outline-none disabled:opacity-50">
                                  <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white">Add</span>
                                  <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                    </circle>
                                  </svg>
                                </button>
                                <!-- <button @click="openAddTargetCompanyToCollection()" class="mt-3 flex bg-white border-[1px] border-black text-white rounded-[4px] text-sm font-Rubik px-4 py-3 outline-none focus:outline-none disabled:opacity-50">
                                  <span class="text-black">Add Target Company</span>
                                </button> -->
                              </div>
                            </div>
                          </div>
                          <div v-else class="h-full">
                            <div class="flex flex-col items-center justify-center h-full">
                              <h3 class="font-medium font-Rubik text-[16px] leading-[18.96px] text-black mb-[8px]">
                                Added!
                              </h3>
                              <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">Companies have been added to your collection!</p>
                              <button @click.prevent="closeModal()" class="mt-3 border-b border-teal font-medium py-[12px] px-[35px] text-white text-[13px] leading-[15.41px] rounded-[4px] bg-black">Okay</button>
                            </div>
                          </div>
                        </div>
                        <!-- /End replace -->
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
    </TransitionRoot>
    <Modal v-show="data.deleteCollectionModal">
      <template v-if="!data.collection">
        <div class="bg-white w-[399px] rounded-[6px] p-4 relative">
          <span class="block w-[48px] h-[48px] mb-[12px] mx-auto">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28Z" fill="#FEE4E2"/>
              <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28 48C16.9543 48 8 39.0457 8 28H0C0 43.464 12.536 56 28 56V48ZM48 28C48 39.0457 39.0457 48 28 48V56C43.464 56 56 43.464 56 28H48ZM28 8C39.0457 8 48 16.9543 48 28H56C56 12.536 43.464 0 28 0V8ZM28 0C12.536 0 0 12.536 0 28H8C8 16.9543 16.9543 8 28 8V0Z" fill="#FEF3F2"/>
            </svg>
          </span>
          <div class="flex flex-col items-start justify-center">
            <h3 class="font-medium font-Rubik text-[18px] leading-[24px] text-black mb-[12px] text-center w-full">
              Confirm Deletion
            </h3>
            <p class="font-Rubik text-[13px] leading-[20px] text-greyText max-w-[95%] text-center w-full"> Are you sure you want to delete this collection? </p>
            <div class="flex items-center justify-between w-full">
              <button @click="data.deleteCollectionModal = false" class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] px-[45px] text-[13px] text-black leading-[15.41px] rounded-[6px] mr-[24px] border-[0.6px] border-lineGray max-w-[170px] w-[50%]">Cancel</button>
              <button @click="deleteCollection" class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] px-[45px] text-white text-[13px] leading-[15.41px] rounded-[6px] bg-originsRed max-w-[170px] w-[50%]">Delete</button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col items-center justify-center py-6 px-14">
          <h3 class="font-medium font-Rubik text-[14px] leading-[18.96px] text-greyText mb-[8px]">
            Collection deleted successfully!
          </h3>
          <button @click.prevent="viewAllParentCollections" class="mt-3 border-b border-teal font-medium py-[12px] px-[35px] text-white text-[13px] leading-[15.41px] rounded-[4px] bg-black">Okay</button>
        </div>
      </template>
    </Modal>
  </main>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { ref, reactive, onMounted, computed, watch } from 'vue'
import Breadcrumb from '@/components/BreadCrumb.vue'
import { useStore } from 'vuex'
import Helpers from '../../utils/helpers'
import CollectionService from '../../services/collection.service'
import {
  Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Switch,
  SwitchLabel,
  SwitchGroup,
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/vue'
import Modal from '../../components/Modal.vue'
import useVuelidate from '@vuelidate/core'
import { required, url, between, minLength } from '@vuelidate/validators'
import CompanyService from '../../services/company.service'
import industries from '@/utils/industries'
import businessmodel from '@/utils/businessmodels'
import fundinground from '@/utils/fundinground'
import fundingtype from '@/utils/fundingtype'
import Map from '@/components/map/Map'

export default {
  components: {
    Breadcrumb,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    Modal,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    Switch,
    SwitchLabel,
    SwitchGroup,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Map
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const companyID = ref({})
    const collection = ref({})
    const subCollections = ref(collection.value.sub_collections)
    const dataloaded = ref(false)
    // eslint-disable-next-line camelcase
    const selectedCollectionIds = ref([])
    const selectedCompanyIds = ref([])
    const loading = ref(false)
    const s3Bucket = process.env.VUE_APP_ORIGINS_S3_BUCKET
    const collectionId = ref(route.params.id)
    const companyData = computed(() => store.state.app.company)
    const selectedIds = ref([])
    const companyDropdown = ref(true)
    const addTargetCompanyModal = ref(false)
    const newTargetCompany = ref(false)
    const mapView = ref(false)

    const files = ref({
      pitch_deck: null,
      logo: null
    })

    const data = reactive({
      modalOpen: false,
      createSubModal: false,
      collection: false,
      collection_name: '',
      collection_description: '',
      editCollectionModal: false,
      viewAllSubCollections: false,
      deleteCompanyModal: false,
      selectedCompanyId: '',
      addCompanyModal: false,
      addedCompanies: [],
      deleteCollectionModal: false,
      filteredCompanies: []
    })

    const targetCompanyData = reactive({
      first_name: '',
      last_name: '',
      email: '',
      company_name: '',
      company_summary: '',
      website: '',
      year_founded: '',
      location: '',
      address: {},
      coordinates: '',
      pitch_deck: '',
      pitch_deck_type: '',
      logo: '',
      logo_type: '',
      industry_type: '',
      business_model: ''
    })

    const rules = computed(() => ({
      first_name: { required },
      last_name: { required },
      email: { required },
      company_name: {
        required,
        minLengthValue: minLength(3)
      },
      company_summary: {
        required,
        minLengthValue: minLength(3)
      },
      website: { required, url },
      year_founded: {
        required,
        betweenValue: between(1500, 2099)
      },
      location: { required },
      pitch_deck: { required },
      logo: { required },
      industry_type: { required },
      business_model: { required }
    }))

    const v$ = useVuelidate(rules, targetCompanyData)

    /**
     * @param {object} event - file event
     * @param {string} name - The name of the file ref
     * @param {string} type - The type of file eg(Legal)
     */

    const clearInputs = () => {
      data.collection_name = ''
      data.collection_description = ''
    }

    const choosedata = (event, name, type, category) => {
      if (event.target.files[0]) {
        targetCompanyData[name] = event.target.files[0]
        targetCompanyData[type] = category
        files.value[name] = event.target.files[0].name
      }
    }

    function getLocation () {
      const locationinput = document.getElementById('location')
      // eslint-disable-next-line no-undef
      const loc = new google.maps.places.Autocomplete(locationinput)
      loc.addListener('place_changed', () => {
        const place = loc.getPlace()
        const filteredPlace = Helpers.extractAddress(place)
        targetCompanyData.location = filteredPlace.formattedAddress
        targetCompanyData.address = filteredPlace.address
        targetCompanyData.coordinates = filteredPlace.coordinates
      })
    }

    const updateCollectionDataWithCustomColor = (obj) => {
      // Generate random RGB values
      var r = Math.floor(Math.random() * 256)
      var g = Math.floor(Math.random() * 256)
      var b = Math.floor(Math.random() * 256)
      // Assign the color to the object
      obj.color = 'rgb(' + r + ',' + g + ',' + b + ')'
      return obj
    }

    const getCollection = async (collectionId) => {
      let col
      // fetch companies in the collection with the collection id
      try {
        const response = await CollectionService.fetchCollectionById(collectionId)
        if (response.status === 200 && response.data.status === 'success') {
          col = response.data.data
          const modifiedCol = updateCollectionDataWithCustomColor(col)
          collection.value = modifiedCol
          subCollections.value = collection.value.sub_collections
          companyID.value.breadcrumbList = col.collection_name
          companyID.value.route = route.path.split('/')[1]
          dataloaded.value = true
        }
      } catch (e) {
        dataloaded.value = false
        Helpers.processError(e, store, router)
      }
    }

    const getDate = (created) => {
      const date = new Date(created.split('T')[0])
      return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date)
    }

    const goToCompany = (id) => {
      router.push({ name: 'Company Dealflow', params: { id: id } })
    }
    const goToSubCollection = (id) => {
      router.push({ name: 'Sub Collection Page', params: { subId: id } })
    }

    const createCollection = async () => {
      try {
        loading.value = true
        const collectionData = {
          collection_name: data.collection_name,
          collection_description: data.collection_description,
          parent_id: route.params.id
        }
        const response = await CollectionService.createCollection(collectionData)
        if (response.status === 200 && response.data.status === 'success') {
          clearInputs()
          loading.value = false
          closeModal()
          getCollection(route.params.id)
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }

    const openModal = () => {
      data.modalOpen = !data.modalOpen
    }
    const closeModal = () => {
      data.modalOpen = false
      data.createSubModal = false
      data.editCollectionModal = false
      data.deleteCompanyModal = false
      data.addCompanyModal = false
      data.collection = false
      data.addedCompanies = []
      data.filteredCompanies = []
      addTargetCompanyModal.value = false
      newTargetCompany.value = false
    }

    const getCompaniesNotInCollection = async (collectionId) => {
      try {
        const response = await CollectionService.fetchCompaniesNotInCollection(collectionId)
        if (response.status === 200 && response.data.status === 'success') {
          // loading.value = false
          // data.collection = true
          data.filteredCompanies = response.data.data
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }

    const selectCompany = (companyId) => {
      openModal()
      if (selectedCompanyIds.value.includes(companyId)) {
        selectedCompanyIds.value = selectedCompanyIds.value.filter(item => item !== companyId)
      } else {
        selectedCompanyIds.value.push(companyId)
      }
      data.selectedCompanyId = selectedCompanyIds.value[0]
    }

    const addSelected = (id) => {
      if (selectedCollectionIds.value.includes(id)) {
        selectedCollectionIds.value = selectedCollectionIds.value.filter(item => item !== id)
      } else {
        selectedCollectionIds.value.push(id)
      }
    }

    const addSelectedCompanies = (company) => {
      const id = company.id
      // remove and add ids to selectedIds array
      if (selectedIds.value.includes(id)) {
        selectedIds.value = selectedIds.value.filter(item => item !== company.id)
        data.addedCompanies = data.addedCompanies.filter(item => item !== company)
      } else {
        selectedIds.value.push(company.id)
        data.addedCompanies.push(company)
      }
      // addedCompanies
    }

    const openAddTargetCompanyToCollection = () => {
      closeModal()
      addTargetCompanyModal.value = true
    }

    const addCompanyToSubCollection = async () => {
      try {
        loading.value = true
        const payload = {
          collection_ids: selectedCollectionIds.value.map((str) => ({ id: str })),
          company_ids: selectedCompanyIds.value.map((str) => ({ id: str }))
        }
        const response = await CollectionService.addCompanytoCollection(payload)
        if (response.status === 200 && response.data.status === 'success') {
          clearInputs()
          loading.value = false
          data.collection = true
          getCollection(route.params.id)
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }

    const addMultipleCompaniesToOneCollection = async () => {
      try {
        loading.value = true
        const payload = {
          collection_ids: [{ id: route.params.id }],
          company_ids: data.addedCompanies.map((company) => ({ id: company.id }))
        }
        const response = await CollectionService.addCompanytoCollection(payload)
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = false
          data.collection = true
          getCollection(route.params.id)
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }

    const modifyCollection = async () => {
      try {
        loading.value = true
        const payload = {
          collection_description: data.collection_description
        }
        const response = await CollectionService.updateCollection(collectionId.value, payload)
        if (response.status === 200 && response.data.status === 'success') {
          clearInputs()
          closeModal()
          loading.value = false
          // data.collection = true
          getCollection(route.params.id)
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }
    const deleteCompany = async (companyId) => {
      try {
        loading.value = true
        const response = await CollectionService.deleteCompanyFromCollection(collectionId.value, companyId)
        if (response.status === 200 && response.data.status === 'success') {
          clearInputs()
          loading.value = false
          getCollection(route.params.id)
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }

    const deleteCollection = async () => {
      try {
        loading.value = true
        const response = await CollectionService.deleteCollection(collectionId.value)
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = false
          data.collection = true
        }
      } catch (e) {
        loading.value = false
        data.deleteCollectionModal = false
        Helpers.processError(e, store, router)
      }
    }

    const viewSubCollection = (collectionId) => {
      router.push({ name: 'Sub Collection Page', params: { subId: collectionId } })
    }

    const viewAllParentCollections = () => {
      router.push('/collection')
    }

    const goBack = () => {
      router.go(-1)
    }

    const onSubmitTargetCompany = async () => {
      loading.value = true
      const collectionId = route.params.id
      const yearFounded = Number(targetCompanyData.year_founded)
      const formData = new FormData()
      formData.append('first_name', targetCompanyData.first_name)
      formData.append('last_name', targetCompanyData.last_name)
      formData.append('email', targetCompanyData.email)
      formData.append('company_name', targetCompanyData.company_name)
      formData.append('company_summary', targetCompanyData.company_summary)
      formData.append('website', targetCompanyData.website)
      formData.append('year_founded', yearFounded)
      formData.append('formatted_address', targetCompanyData.location)
      formData.append('address[city]', targetCompanyData.address.city)
      formData.append('address[state]', targetCompanyData.address.state)
      formData.append('address[country]', targetCompanyData.address.country)
      formData.append('address[code]', targetCompanyData.address.code)
      formData.append('pitch_deck', targetCompanyData.pitch_deck)
      formData.append('pitch_deck_type', targetCompanyData.pitch_deck_type)
      formData.append('logo', targetCompanyData.logo)
      formData.append('logo_type', targetCompanyData.logo_type)
      formData.append('coordinates[type]', 'Point')
      formData.append('coordinates[coordinates][]', targetCompanyData.coordinates.coordinates[0])
      formData.append('coordinates[coordinates][]', targetCompanyData.coordinates.coordinates[1])
      formData.append('industry_type', targetCompanyData.industry_type)
      formData.append('business_model', targetCompanyData.business_model)

      // for (var pair of formData.entries()) {
      // }

      try {
        const response = await CompanyService.createTargetCompanyAndAddToCollection(collectionId, formData)
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = false
          newTargetCompany.value = true
          const success = {
            status: true,
            message: 'Target company created'
          }
          store.commit('setSuccess', success)
          getCollection(collectionId)
        }
        // updateCompanyList()
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }

    watch(() => data.addCompanyModal, (modalOpen) => {
      // Watch the modal for adding company to collection and make a request for available companies if the modal is clicked
      if (modalOpen) {
        getCompaniesNotInCollection(collectionId.value)
      }
    }
    )

    onMounted(() => {
      getCollection(collectionId.value)
      watch(() => addTargetCompanyModal.value, (modalOpen) => {
      // Watch the modal for adding company to collection and make a request for available companies only if the modal is open
        if (modalOpen) {
          setTimeout(() => {
            getLocation()
          }, 500)
        }
      }
      )
    })

    return {
      companyID,
      collection,
      s3Bucket,
      getDate,
      goToCompany,
      dataloaded,
      createCollection,
      addCompanyToSubCollection,
      addSelected,
      openModal,
      closeModal,
      data,
      // collections,
      selectedCollectionIds,
      loading,
      selectCompany,
      selectedCompanyIds,
      subCollections,
      viewSubCollection,
      modifyCollection,
      deleteCompany,
      goBack,
      companyData,
      companyDropdown,
      selectedIds,
      addSelectedCompanies,
      addMultipleCompaniesToOneCollection,
      deleteCollection,
      viewAllParentCollections,
      getCompaniesNotInCollection,
      addTargetCompanyModal,
      openAddTargetCompanyToCollection,
      v$,
      targetCompanyData,
      rules,
      files,
      onSubmitTargetCompany,
      choosedata,
      Helpers,
      industries,
      businessmodel,
      fundinground,
      fundingtype,
      newTargetCompany,
      mapView,
      goToSubCollection
    }
  }
}
</script>

<style>

</style>
