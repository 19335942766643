<template>
  <main class="font-Rubik">
    <h2 class="text-[20px] leading-[23.7px] mb-[32px]">FOUNDER JOURNEY REVIEW</h2>
    <div class="instructions text-greyText mb-[30px]">
      <p class="text-[15px] leading-[17.78px] mb-[8px]">Instructions:</p>
      <p class="text-[13px] leading-[20px]">Kindly read the diligence information provided
         by the individual company to the left in full.
          Afterwards, answer the below questions,
           referencing the diligence information when needed.
            Please leave helpful comments and feedback
             to supplement your scores.
             </p>
    </div>
    <div class="mt-20" v-if="!dataloaded">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(241, 242, 243); display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(26.666666666666668,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#000000">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.3s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#636363">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.2s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(26.666666666666668,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#e1e1e1">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="0s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#545454">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.1s"></animateTransform>
          </rect>
        </g>
      </svg>
    </div>
    <form v-else id="riskReview" class="activeArea">
      <div class="feasibility grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">How feasible is the proposed business model? Is the model structured in a way in which the company can successfully operate and make money?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Feasibility of Business Model"
            id="no-score-business-model"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Feasibility of Business Model') == '0'"
            @change="setRatings($event, 'feasibilityOfBusiness')"
             v-model="feasibilityOfBusiness"
          >
          <label for="no-score-business-model" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Feasibility of Business Model"
            id="extremely-nonfeasible-business-model"
            value="1"
            :checked="getRatingScore('Feasibility of Business Model') == '1'"
            @change="setRatings($event, 'feasibilityOfBusiness')"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            v-model="feasibilityOfBusiness"
          >
          <label for="extremely-nonfeasible-business-model" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">There is no way the outlined model is feasible. There is a high probability that operations will be unsuccessful as is, and a path to revenue seems far out.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Feasibility of Business Model"
            id="somewhat-nonfeasible-business-model"
            value="2"
            :checked="getRatingScore('Feasibility of Business Model') == '2'"
            @change="setRatings($event, 'feasibilityOfBusiness')"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            v-model="feasibilityOfBusiness"
          >
          <label for="somewhat-nonfeasible-business-model" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The business model is probably not feasible. There are some main concerns that the company will need to address through major pivorts. The path to revenue does not seem as clear as the teams sees it.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Feasibility of Business Model"
            id="feasible-business-model"
            value="3"
            :checked="getRatingScore('Feasibility of Business Model') == '3'"
            @change="setRatings($event, 'feasibilityOfBusiness')"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            v-model="feasibilityOfBusiness"
          >
          <label for="feasible-business-model" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">More evidence is needed, but I see a potential feasible model. A few minor tweaks will ensure successful operations. Proceeding with the current model is the best way to advance towards revenue growth.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
            <input
            type="radio"
            name="Feasibility of Business Model"
            id="somewhat-feasible-business-model"
            value="4"
            :checked="getRatingScore('Feasibility of Business Model') == '4'"
            @change="setRatings($event, 'feasibilityOfBusiness')"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
              v-model="feasibilityOfBusiness"
          >
          <label for="somewhat-feasible-business-model" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The company has built a business model that is clearly feasible. The team has actual revenue and cost metrics that support further growth.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Feasibility of Business Model"
            id="extremely-feasible-business-model"
            value="5"
            :checked="getRatingScore('Feasibility of Business Model') == '5'"
            @change="setRatings($event, 'feasibilityOfBusiness')"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
              v-model="feasibilityOfBusiness"
          >
          <label for="extremely-feasible-business-model" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The company has already validated their business model through steady revenue growth. The business model can withstand the proposed plans to scale.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-business-model" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-business-model"
            rows="3"
            cols="50"
            name="Feasibility of Business Model"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, feasibilityOfBusiness)"
            :disabled="commentStatus.feasibilityOfBusiness &&  getRatingScore('Feasibility of Business Model') == '0'"
            :class="[commentStatus.feasibilityOfBusiness &&  getRatingScore('Feasibility of Business Model') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Feasibility of Business Model')"
          ></textarea>
        </div>
      </div>

      <div class="compelling-market grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Is there a compelling market (or projected market) for the proposed product/service and is it large enough for the company to succeed? Does the proposed market seem attainable to capture?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Characteristics"
            id="no-score-compelling-market"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Characteristics') == '0'"
            @change="setRatings($event, 'marketCharacteristics')"
            v-model="marketCharacteristics"
          >
          <label for="no-score-compelling-market" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Characteristics"
            id="extremely-noncompelling-compelling-market"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Characteristics') == '1'"
            @change="setRatings($event, 'marketCharacteristics')"
            v-model="marketCharacteristics"
            >
          <label for="extremely-noncompelling-compelling-market" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The proposed market is not compelling. The market is not big enough for a high-growth strategy and/or the company has underestimated its attainability to capture.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Characteristics"
            id="somewhat-noncompelling-compelling-market"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Characteristics') == '2'"
            @change="setRatings($event, 'marketCharacteristics')"
            v-model="marketCharacteristics"
            >
          <label for="somewhat-noncompelling-compelling-market" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The proposed market is interesting. The market might be big enough for a scalable startup, but it is unclear if it will be attainable to capture</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Characteristics"
            id="compelling-compelling-market"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Characteristics') == '3'"
            @change="setRatings($event, 'marketCharacteristics')"
            v-model="marketCharacteristics"
            >
          <label for="compelling-compelling-market" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The proposed market is somewhat compelling. The market is big enough to meet the goals and the team’s vision seems attainable.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Characteristics"
            id="somewhat-compelling-compelling-market"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Characteristics') == '4'"
            @change="setRatings($event, 'marketCharacteristics')"
            v-model="marketCharacteristics"
            >
          <label for="somewhat-compelling-compelling-market" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The proposed market is compelling. The market is big enough for the company to meet its goals, and based on the market dynamics, capturing will be attainable.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Characteristics"
            id="extremely-compelling-compelling-market"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Characteristics') == '5'"
            @change="setRatings($event, 'marketCharacteristics')"
            v-model="marketCharacteristics"
            >
          <label for="extremely-compelling-compelling-market" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The proposed market is very compelling. THe market is extremely big, enough so that the company can achieve their goals, and initial traction provides evidence that market capture is possible.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-compelling-market" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-compelling-market"
            rows="3"
            cols="50"
            name="Market Characteristics"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, marketCharacteristics)"
            :disabled="commentStatus.marketCharacteristics &&  getRatingScore('Market Characteristics') == '0'"
            :class="[commentStatus.marketCharacteristics &&  getRatingScore('Market Characteristics') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Market Characteristics')"
          ></textarea>
        </div>
      </div>

      <div class="market-size grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Is this market sufficiently large enough to generate a venture-like return? Are there ancillary markets that when combined present an opportunity large enough to sustainably expand the potential of the company?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Size"
            id="no-score-market-size"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Size') == '0'"
            @change="setRatings($event, 'marketSize')"
             v-model="marketSize"
          >
          <label for="no-score-market-size" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Size"
            id="extremely-noncompelling-market-size"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Size') == '1'"
            @change="setRatings($event, 'marketSize')"
            v-model="marketSize"
          >
          <label for="extremely-noncompelling-market-size" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The market is too small for venture-like returns; no significant ancillary markets.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Size"
            id="somewhat-noncompelling-market-size"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Size') == '2'"
            @change="setRatings($event, 'marketSize')"
            v-model="marketSize"
          >
          <label for="somewhat-noncompelling-market-size" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The market is somewhat limited, with few ancillary markets for expansion.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Size"
            id="compelling-market-size"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Size') == '3'"
            @change="setRatings($event, 'marketSize')"
            v-model="marketSize"
          >
          <label for="compelling-market-size" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The market is sufficiently large for good returns; some ancillary markets present.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Size"
            id="somewhat-compelling-market-size"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Size') == '4'"
            @change="setRatings($event, 'marketSize')"
            v-model="marketSize"
          >
          <label for="somewhat-compelling-market-size" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The market is very large, offering strong returns; multiple ancillary markets for expansion.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Market Size"
            id="extremely-compelling-market-size"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Market Size') == '5'"
            @change="setRatings($event, 'marketSize')"
            v-model="marketSize"
          >
          <label for="extremely-compelling-market-size" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The market is exceptionally large and diverse, with extensive ancillary markets, ensuring sustainable expansion.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-market-size" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-market-size"
            rows="3"
            cols="50"
            name="Market Size"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, marketSize)"
            :disabled="commentStatus.marketSize &&  getRatingScore('Market Size') == '0'"
            :class="[commentStatus.marketSize &&  getRatingScore('Market Size') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Market Size')"
          ></textarea>
        </div>
      </div>

      <div class="sectoral-downturn grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Is the market subject to cyclicality? What will be the impact for the product, service, and company in the case of a market downturn?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of sectoral downturn"
            id="no-score-sectoral-downturn"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of sectoral downturn') == '0'"
            @change="setRatings($event, 'threatOfSectoralDownturn')"
             v-model="threatOfSectoralDownturn"
          >
          <label for="no-score-sectoral-downturn" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of sectoral downturn"
            id="extremely-noncompelling-sectoral-downturn"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of sectoral downturn') == '1'"
            @change="setRatings($event, 'threatOfSectoralDownturn')"
            v-model="threatOfSectoralDownturn"
            >
          <label for="extremely-noncompelling-sectoral-downturn" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The market is highly cyclical; a downturn would severely impact the product/service and company.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of sectoral downturn"
            id="somewhat-noncompelling-sectoral-downturn"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of sectoral downturn') == '2'"
            @change="setRatings($event, 'threatOfSectoralDownturn')"
            v-model="threatOfSectoralDownturn"
            >
          <label for="somewhat-noncompelling-sectoral-downturn" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The market has some cyclicality; a downturn could have a notable negative impact.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of sectoral downturn"
            id="compelling-sectoral-downturn"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of sectoral downturn') == '3'"
            @change="setRatings($event, 'threatOfSectoralDownturn')"
            v-model="threatOfSectoralDownturn"
            >
          <label for="compelling-sectoral-downturn" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The market is moderately cyclical; the company is somewhat prepared for a downturn.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of sectoral downturn"
            id="somewhat-compelling-sectoral-downturn"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of sectoral downturn') == '4'"
            @change="setRatings($event, 'threatOfSectoralDownturn')"
            v-model="threatOfSectoralDownturn"
            >
          <label for="somewhat-compelling-sectoral-downturn" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The market has low cyclicality; the company is well-prepared for potential downturns.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of sectoral downturn"
            id="extremely-compelling-sectoral-downturn"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of sectoral downturn') == '5'"
            @change="setRatings($event, 'threatOfSectoralDownturn')"
            v-model="threatOfSectoralDownturn"
            >
          <label for="extremely-compelling-sectoral-downturn" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The market is minimally affected by cyclicality; the company is excellently positioned to withstand downturns.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-sectoral-downturn" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-sectoral-downturn"
            rows="3"
            cols="50"
            name="Threat of sectoral downturn"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, threatOfSectoralDownturn)"
            :disabled="commentStatus.threatOfSectoralDownturn &&  getRatingScore('Threat of sectoral downturn') == '0'"
            :class="[commentStatus.threatOfSectoralDownturn &&  getRatingScore('Threat of sectoral downturn') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Threat of sectoral downturn')"
          ></textarea>
        </div>
      </div>

      <div class="competitive-landscape grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Does the team understand the competitive landscape? Have they keyed in on their competitive advantage?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of competition"
            id="no-score-competitive-landscape"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of competition') == '0'"
            @change="setRatings($event, 'threatOfCompetition')"
             v-model="threatOfCompetition"
          >
          <label for="no-score-competitive-landscape" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of competition"
            id="extremely-nonadvantageous-competitive-landscape"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of competition') == '1'"
            @change="setRatings($event, 'threatOfCompetition')"
            v-model="threatOfCompetition"
          >
          <label for="extremely-nonadvantageous-competitive-landscape" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The company does not understand its market nor its potential competition. Their product or service doesn’t have any competitive advantage.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of competition"
            id="somewhat-nonadvantageous-competitive-landscape"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of competition') == '2'"
            @change="setRatings($event, 'threatOfCompetition')"
            v-model="threatOfCompetition"
          >
          <label for="somewhat-nonadvantageous-competitive-landscape" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The product or service is interesting and attractive, but I’m concerned with their ability to compete for the identified target market. Their position amongst potential competitors does not seem attractive enough to justify pursuit of this business.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of competition"
            id="advantageous-competitive-landscape"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of competition') == '3'"
            @change="setRatings($event, 'threatOfCompetition')"
            v-model="threatOfCompetition"
          >
          <label for="advantageous-competitive-landscape" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The company understands its unique competitive advantage and has correctly assessed its competitors. I think their approach is different enough to pursue.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of competition"
            id="somewhat-advantageous-competitive-landscape"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of competition') == '4'"
            @change="setRatings($event, 'threatOfCompetition')"
            v-model="threatOfCompetition"
          >
          <label for="somewhat-advantageous-competitive-landscape" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The product or solution is innovative and clearly brings something new and different in the company’s identified market. They have proven through expressed interest or sales that they can compete.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of competition"
            id="extremely-advantageous-competitive-landscape"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of competition') == '5'"
            @change="setRatings($event, 'threatOfCompetition')"
            v-model="threatOfCompetition"
          >
          <label for="extremely-advantageous-competitive-landscape" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">Not only is the competitive advantage evident, but the company’s approach is so unique that it can really transform this market. Existing competitors will be forced to pay attention and adjust to this new offering.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-competitive-landscape" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-competitive-landscape"
            rows="3"
            cols="50"
            name="Threat of competition"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, threatOfCompetition)"
            :disabled="commentStatus.threatOfCompetition &&  getRatingScore('Threat of competition') == '0'"
            :class="[commentStatus.threatOfCompetition &&  getRatingScore('Threat of competition') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Threat of competition')"
          ></textarea>
        </div>
      </div>

      <div class="alternatives grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Is this a product or service that can be easily replaced with another by its users?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Substitution alternatives"
            id="no-score-alternatives"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Substitution alternatives') == '0'"
            @change="setRatings($event, 'substitutionAlternatives')"
             v-model="substitutionAlternatives"
          >
          <label for="no-score-alternatives" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Substitution alternatives"
            id="extremely-noncompelling-alternatives"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Substitution alternatives') == '01'"
            @change="setRatings($event, 'substitutionAlternatives')"
            v-model="substitutionAlternatives">
          <label for="extremely-noncompelling-alternatives" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The product/service can be very easily replaced by users.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Substitution alternatives"
            id="somewhat-noncompelling-alternatives"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Substitution alternatives') == '2'"
            @change="setRatings($event, 'substitutionAlternatives')"
            v-model="substitutionAlternatives">
          <label for="somewhat-noncompelling-alternatives" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The product/service has some unique features but can be somewhat easily replaced.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Substitution alternatives"
            id="compelling-alternatives"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Substitution alternatives') == '3'"
            @change="setRatings($event, 'substitutionAlternatives')"
            v-model="substitutionAlternatives">
          <label for="compelling-alternatives" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The product/service is moderately unique, making replacement somewhat challenging.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Substitution alternatives"
            id="somewhat-compelling-alternatives"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Substitution alternatives') == '4'"
            @change="setRatings($event, 'substitutionAlternatives')"
            v-model="substitutionAlternatives">
          <label for="somewhat-compelling-alternatives" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The product/service is quite unique and not easily replaceable.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Substitution alternatives"
            id="extremely-compelling-alternatives"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Substitution alternatives') == '5'"
            @change="setRatings($event, 'substitutionAlternatives')"
            v-model="substitutionAlternatives">
          <label for="extremely-compelling-alternatives" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The product/service is extremely unique and almost irreplaceable by users.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-alternatives" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-alternatives"
            rows="3"
            cols="50"
            name="Substitution alternatives"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, substitutionAlternatives)"
            :disabled="commentStatus.substitutionAlternatives &&  getRatingScore('Substitution alternatives') == '0'"
            :class="[commentStatus.substitutionAlternatives &&  getRatingScore('Substitution alternatives') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Substitution alternatives')"
          ></textarea>
        </div>
      </div>

      <div class="entry-threats grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">What is the threat of new entrants, or potential for a new business to appear in the industry? How high are the barriers to entry for new market participants?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of entrants"
            id="no-score-entry-threats"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of entrants') == '0'"
            @change="setRatings($event, 'threatOfEntrants')"
             v-model="threatOfEntrants"
          >
          <label for="no-score-entry-threats" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of entrants"
            id="extremely-noncompelling-entry-threats"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of entrants') == '1'"
            @change="setRatings($event, 'threatOfEntrants')"
            v-model="threatOfEntrants"
          >
          <label for="extremely-noncompelling-entry-threats" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The industry has low barriers to entry and high threat of new entrants.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of entrants"
            id="somewhat-noncompelling-entry-threats"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of entrants') == '2'"
            @change="setRatings($event, 'threatOfEntrants')"
            v-model="threatOfEntrants"
          >
          <label for="somewhat-noncompelling-entry-threats" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The industry has moderate barriers to entry and some threat of new entrants.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of entrants"
            id="compelling-entry-threats"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of entrants') == '3'"
            @change="setRatings($event, 'threatOfEntrants')"
            v-model="threatOfEntrants"
          >
          <label for="compelling-entry-threats" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The industry presents reasonable barriers to entry, with a moderate threat of new businesses.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of entrants"
            id="somewhat-compelling-entry-threats"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of entrants') == '4'"
            @change="setRatings($event, 'threatOfEntrants')"
            v-model="threatOfEntrants"
          >
          <label for="somewhat-compelling-entry-threats" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The industry has high barriers to entry, limiting the threat of new entrants.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Threat of entrants"
            id="extremely-compelling-entry-threats"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Threat of entrants') == '5'"
            @change="setRatings($event, 'threatOfEntrants')"
            v-model="threatOfEntrants"
          >
          <label for="extremely-compelling-entry-threats" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The industry has very high barriers to entry, making the threat of new entrants minimal.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-entry-threats" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-entry-threats"
            rows="3"
            cols="50"
            name="Threat of entrants"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, threatOfEntrants)"
            :disabled="commentStatus.threatOfEntrants &&  getRatingScore('Threat of entrants') == '0'"
            :class="[commentStatus.threatOfEntrants &&  getRatingScore('Threat of entrants') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Threat of entrants')"
          ></textarea>
        </div>
      </div>

      <!-- <div class="market-knowledge grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Do the founders / senior leaders have a clear vision of their target customer and market, backed by a mix of intuition and data?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="no-score-market-knowledge"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '0'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
             v-model="knowledgeOfTargetMarket"
          >
          <label for="no-score-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="extremely-noncompelling-market-knowledge"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '1'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
            v-model="knowledgeOfTargetMarket"
          >
          <label for="extremely-noncompelling-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">Founders lack a clear vision and understanding of the target market.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="somewhat-noncompelling-market-knowledge"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '2'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
            v-model="knowledgeOfTargetMarket"
          >
          <label for="somewhat-noncompelling-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">Founders have a limited vision and understanding, with some reliance on intuition and data.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="compelling-market-knowledge"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '3'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
            v-model="knowledgeOfTargetMarket"
          >
          <label for="compelling-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">Founders have a clear vision and good market understanding, balanced with intuition and data.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="somewhat-compelling-market-knowledge"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '4'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
            v-model="knowledgeOfTargetMarket"
          >
          <label for="somewhat-compelling-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">Founders exhibit a strong, data-backed vision and deep market understanding.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Knowledge of target market (size or characteristics)"
            id="extremely-compelling-market-knowledge"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Knowledge of target market (size or characteristics)') == '5'"
            @change="setRatings($event, 'knowledgeOfTargetMarket')"
            v-model="knowledgeOfTargetMarket"
          >
          <label for="extremely-compelling-market-knowledge" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">Founders demonstrate an exceptional vision and profound market insight beyond industry peers, effectively using market-leading intuition and proprietary data.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-market-knowledge" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-market-knowledge"
            rows="3"
            cols="50"
            name="Knowledge of target market (size or characteristics)"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, knowledgeOfTargetMarket)"
            :disabled="commentStatus.knowledgeOfTargetMarket &&  getRatingScore('Knowledge of target market (size or characteristics)') == '0'"
            :class="[commentStatus.knowledgeOfTargetMarket &&  getRatingScore('Knowledge of target market (size or characteristics)') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Knowledge of target market (size or characteristics)')"
          ></textarea>
        </div>
      </div> -->

      <div class="distribution-channels grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Does the company employ more than one distribution channel to reach its ultimate customers and users? Can indirect distribution channels scale efficiently?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Distribution channels"
            id="no-score-distribution-channels"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Distribution channels') == '0'"
            @change="setRatings($event, 'distributionChannels')"
             v-model="distributionChannels"
          >
          <label for="no-score-distribution-channels" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Distribution channels"
            id="extremely-noncompelling-distribution-channels"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Distribution channels') == '1'"
            @change="setRatings($event, 'distributionChannels')"
            v-model="distributionChannels"
          >
          <label for="extremely-noncompelling-distribution-channels" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The company relies on a single, unclear distribution channel, and/or scaling is highly inefficient.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Distribution channels"
            id="somewhat-noncompelling-distribution-channels"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Distribution channels') == '2'"
            @change="setRatings($event, 'distributionChannels')"
            v-model="distributionChannels"
          >
          <label for="somewhat-noncompelling-distribution-channels" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The company employs more than one distribution channel, but they are somewhat unclear or inefficient in scaling.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Distribution channels"
            id="compelling-distribution-channels"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Distribution channels') == '3'"
            @change="setRatings($event, 'distributionChannels')"
            v-model="distributionChannels"
          >
          <label for="compelling-distribution-channels" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The company uses multiple distribution channels effectively, and scaling seems reasonably efficient.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Distribution channels"
            id="somewhat-compelling-distribution-channels"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Distribution channels') == '4'"
            @change="setRatings($event, 'distributionChannels')"
            v-model="distributionChannels"
          >
          <label for="somewhat-compelling-distribution-channels" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The company clearly employs multiple strong distribution channels, and scaling them appears to be very efficient.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input type="radio"
            name="Distribution channels"
            id="extremely-compelling-distribution-channels"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Distribution channels') == '5'"
            @change="setRatings($event, 'distributionChannels')"
            v-model="distributionChannels"
          >
          <label for="extremely-compelling-distribution-channels" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The company’s multi-channel distribution strategy is compelling and demonstrates highly efficient scaling capabilities.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-distribution-channels" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-distribution-channels"
            rows="3"
            cols="50"
            name="Distribution channels"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, distributionChannels)"
            :disabled="commentStatus.distributionChannels &&  getRatingScore('Distribution channels') == '0'"
            :class="[commentStatus.distributionChannels &&  getRatingScore('Distribution channels') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Distribution channels')"
          ></textarea>
        </div>
      </div>

      <div class="sourcing-risks grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Is the company’s product / service dependent on one key supplier or one platform? Would a unilateral action of this key supplier / platform fully jeopardize the company’s performance and/or functionality of its product / service?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Supplier/Sourcing risks"
            id="no-score-sourcing-risks"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Supplier/Sourcing risks') == '0'"
            @change="setRatings($event, 'supplierSourcingRisks')"
             v-model="supplierSourcingRisks"
          >
          <label for="no-score-sourcing-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Supplier/Sourcing risks"
            id="extremely-noncompelling-sourcing-risks"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Supplier/Sourcing risks') == '1'"
            @change="setRatings($event, 'supplierSourcingRisks')"
            v-model="supplierSourcingRisks"
          >
          <label for="extremely-noncompelling-sourcing-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The company is highly dependent on a single supplier/platform, posing a significant risk to its operations.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Supplier/Sourcing risks"
            id="somewhat-noncompelling-sourcing-risks"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Supplier/Sourcing risks') == '2'"
            @change="setRatings($event, 'supplierSourcingRisks')"
            v-model="supplierSourcingRisks"
          >
          <label for="somewhat-noncompelling-sourcing-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">There is some dependence on one key supplier/platform, which could moderately affect the company's performance.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Supplier/Sourcing risks"
            id="compelling-sourcing-risks"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Supplier/Sourcing risks') == '3'"
            @change="setRatings($event, 'supplierSourcingRisks')"
            v-model="supplierSourcingRisks"
          >
          <label for="compelling-sourcing-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The company has a balanced dependence on a key supplier/platform, with manageable risk to its performance.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Supplier/Sourcing risks"
            id="somewhat-compelling-sourcing-risks"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Supplier/Sourcing risks') == '4'"
            @change="setRatings($event, 'supplierSourcingRisks')"
            v-model="supplierSourcingRisks"
          >
          <label for="somewhat-compelling-sourcing-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The company exhibits minimal dependence on a key supplier/platform, ensuring strong operational stability.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input type="radio"
            name="Supplier/Sourcing risks"
            id="extremely-compelling-sourcing-risks"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Supplier/Sourcing risks') == '5'"
            @change="setRatings($event, 'supplierSourcingRisks')"
            v-model="supplierSourcingRisks"
          >
          <label for="extremely-compelling-sourcing-risks" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The company’s operations are robust against unilateral actions of any single supplier/platform, showcasing exceptional strategic resilience.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-sourcing-risks" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-sourcing-risks"
            rows="3"
            cols="50"
            name="Supplier/Sourcing risks"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, supplierSourcingRisks)"
            :disabled="commentStatus.supplierSourcingRisks &&  getRatingScore('Supplier/Sourcing risks') == '0'"
            :class="[commentStatus.supplierSourcingRisks &&  getRatingScore('Supplier/Sourcing risks') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Supplier/Sourcing risks')"
          ></textarea>
        </div>
      </div>

      <div class="trustworthy-source grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Did this investment opportunity come from a reliable and trustworthy source to the firm?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Referred from trustworthy source"
            id="no-score-trustworthy-source"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Referred from trustworthy source') == '0'"
            @change="setRatings($event, 'trustworthySource')"
             v-model="trustworthySource"
          >
          <label for="no-score-trustworthy-source" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Referred from trustworthy source"
            id="extremely-noncompelling-trustworthy-source"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Referred from trustworthy source') == '1'"
            @change="setRatings($event, 'trustworthySource')"
            v-model="trustworthySource"
          >
          <label for="extremely-noncompelling-trustworthy-source" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The investment opportunity comes from an unreliable and untrustworthy source.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Referred from trustworthy source"
            id="somewhat-noncompelling-trustworthy-source"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Referred from trustworthy source') == '2'"
            @change="setRatings($event, 'trustworthySource')"
            v-model=" trustworthySource"
          >
          <label for="somewhat-noncompelling-trustworthy-source" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The source of the investment opportunity is somewhat reliable and trustworthy.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Referred from trustworthy source"
            id="compelling-trustworthy-source"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Referred from trustworthy source') == '3'"
            @change="setRatings($event, 'trustworthySource')"
            v-model=" trustworthySource"
          >
          <label for="compelling-trustworthy-source" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The investment opportunity comes from a moderately reliable and trustworthy source.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Referred from trustworthy source"
            id="somewhat-compelling-trustworthy-source"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Referred from trustworthy source') == '4'"
            @change="setRatings($event, 'trustworthySource')"
            v-model=" trustworthySource"
          >
          <label for="somewhat-compelling-trustworthy-source" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The source of the investment opportunity is very reliable and trustworthy.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Referred from trustworthy source"
            id="extremely-compelling-trustworthy-source"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Referred from trustworthy source') == '5'"
            @change="setRatings($event, 'trustworthySource')"
            v-model=" trustworthySource"
          >
          <label for="extremely-compelling-trustworthy-source" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The investment opportunity originates from an exceptionally reliable and highly trustworthy source. The source alone provides a strong and compelling reason to back this team.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-trustworthy-source" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-trustworthy-source"
            rows="3"
            cols="50"
            name="Referred from trustworthy source"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, trustworthySource)"
            :disabled="commentStatus.trustworthySource && getRatingScore('Referred from trustworthy source') == '0'"
            :class="[commentStatus.trustworthySource && getRatingScore('Referred from trustworthy source') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Referred from trustworthy source')"
          ></textarea>
        </div>
      </div>

      <div class="demonstrated-integrity grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Have the founders / senior management demonstrated accountability through their past actions, either at this company or in prior roles? How are they are setting the pace for leadership and performance excellence?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated Intellectual Integrity"
            id="no-score-demonstrated-integrity"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated Intellectual Integrity') == '0'"
            @change="setRatings($event, 'demonstratedIntegrity')"
            v-model="demonstratedIntegrity"
          >
          <label for="no-score-demonstrated-integrity" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated Intellectual Integrity"
            id="extremely-noncompelling-demonstrated-integrity"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated Intellectual Integrity') == '1'"
            @change="setRatings($event, 'demonstratedIntegrity')"
            v-model="demonstratedIntegrity"
          >
          <label for="extremely-noncompelling-demonstrated-integrity" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">Founders lack demonstrated accountability and leadership qualities.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated Intellectual Integrity"
            id="somewhat-noncompelling-demonstrated-integrity"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated Intellectual Integrity') == '2'"
            @change="setRatings($event, 'demonstratedIntegrity')"
            v-model=" demonstratedIntegrity"
          >
          <label for="somewhat-noncompelling-demonstrated-integrity" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">Founders show some accountability and leadership, but with limitations.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated Intellectual Integrity"
            id="compelling-demonstrated-integrity"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated Intellectual Integrity') == '3'"
            @change="setRatings($event, 'demonstratedIntegrity')"
            v-model=" demonstratedIntegrity"
          >
          <label for="compelling-demonstrated-integrity" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">Founders have demonstrated moderate accountability and good leadership skills.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated Intellectual Integrity"
            id="somewhat-compelling-demonstrated-integrity"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated Intellectual Integrity') == '4'"
            @change="setRatings($event, 'demonstratedIntegrity')"
            v-model=" demonstratedIntegrity"
          >
          <label for="somewhat-compelling-demonstrated-integrity" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">Founders have a strong record of accountability and excellent leadership abilities.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Demonstrated Intellectual Integrity"
            id="extremely-compelling-demonstrated-integrity"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Demonstrated Intellectual Integrity') == '5'"
            @change="setRatings($event, 'demonstratedIntegrity')"
            v-model=" demonstratedIntegrity"
          >
          <label for="extremely-compelling-demonstrated-integrity" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">Founders excel in accountability and exhibit exceptional leadership and performance.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-demonstrated-integrity" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-demonstrated-integrity"
            rows="3"
            cols="50"
            name="Demonstrated Intellectual Integrity"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, demonstratedIntegrity)"
            :disabled="commentStatus.demonstratedIntegrity && getRatingScore('Demonstrated Intellectual Integrity') == '0'"
            :class="[commentStatus.demonstratedIntegrity && getRatingScore('Demonstrated Intellectual Integrity') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Demonstrated Intellectual Integrity')"
          ></textarea>
        </div>
      </div>

      <div class="sustained-effort grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Does the founder or core management team have grit? Do they exhibit unrelenting perseverance and a strong passion for a goal (mostly long-term), even in the face of significant challenges, setbacks, and distractions? </h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Capable of sustained effort"
            id="no-score-sustained-effort"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Capable of sustained effort') == '0'"
            @change="setRatings($event, 'capableOfSustainedEffort')"
             v-model="capableOfSustainedEffort"
          >
          <label for="no-score-sustained-effort" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Capable of sustained effort"
            id="extremely-noncompelling-sustained-effort"
            value="1"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Capable of sustained effort') == '1'"
            @change="setRatings($event, 'capableOfSustainedEffort')"
            v-model="capableOfSustainedEffort"
          >
          <label for="extremely-noncompelling-sustained-effort" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">There is a distinct lack of perseverance and passion in the team.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Capable of sustained effort"
            id="somewhat-noncompelling-sustained-effort"
            value="2"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Capable of sustained effort') == '2'"
            @change="setRatings($event, 'capableOfSustainedEffort')"
            v-model=" capableOfSustainedEffort"
          >
          <label for="somewhat-noncompelling-sustained-effort" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">The team shows some grit but it's not consistent or particularly impactful.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Capable of sustained effort"
            id="compelling-sustained-effort"
            value="3"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Capable of sustained effort') == '3'"
            @change="setRatings($event, 'capableOfSustainedEffort')"
            v-model=" capableOfSustainedEffort"
          >
          <label for="compelling-sustained-effort" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">The team generally exhibits good levels of perseverance and passion.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Capable of sustained effort"
            id="somewhat-compelling-sustained-effort"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Capable of sustained effort') == '4'"
            @change="setRatings($event, 'capableOfSustainedEffort')"
            v-model=" capableOfSustainedEffort"
          >
          <label for="somewhat-compelling-sustained-effort" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">The team consistently shows strong perseverance and passion.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Capable of sustained effort"
            id="extremely-compelling-sustained-effort"
            value="5"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Capable of sustained effort') == '5'"
            @change="setRatings($event, 'capableOfSustainedEffort')"
            v-model=" capableOfSustainedEffort"
          >
          <label for="extremely-compelling-sustained-effort" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The team displays exceptional levels of grit, demonstrating unrelenting perseverance and passion.</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-sustained-effort" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-sustained-effort"
            rows="3"
            cols="50"
            name="Capable of sustained effort"
            form="riskReview"
            placeholder="Leave feedback here..."
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-black text-black text-sm w-full border-none focus:outline-none focus:ring-0"
            @blur="setComments($event, capableOfSustainedEffort)"
            :disabled="commentStatus.capableOfSustainedEffort && getRatingScore('Capable of sustained effort') == '0'"
            :class="[commentStatus.capableOfSustainedEffort && getRatingScore('Capable of sustained effort') == '0' ? 'hover:cursor-not-allowed' : '']"
            :value="getRatingComment('Capable of sustained effort')"
          ></textarea>
        </div>
      </div>

    </form>
  </main>
</template>

<script>
/* eslint-disable camelcase */
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import RiskscoreService from '@/services/riskscore.service'
import Helpers from '@/utils/helpers'
import { useStore } from 'vuex'

export default {
  components: {
  },
  setup () {
    const dataloaded = ref(false)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const ratings = ref([])
    const feasibilityOfBusiness = ref(null)
    const proprietaryOrProtected = ref(null)
    const marketCharacteristics = ref(null)
    const marketSize = ref(null)
    const threatOfSectoralDownturn = ref(null)
    const threatOfCompetition = ref(null)
    const substitutionAlternatives = ref(null)
    const threatOfEntrants = ref(null)
    const knowledgeOfTargetMarket = ref(null)
    const distributionChannels = ref(null)
    const supplierSourcingRisks = ref(null)
    const demonstratedIntegrity = ref(null)
    const capableOfSustainedEffort = ref(null)
    const trustworthySource = ref(null)
    const commentStatus = ref({
      proprietaryOrProtected: true,
      feasibilityOfBusiness: true,
      functioningPrototype: true,
      relevantTechnology: true,
      existingProduct: true,
      marketCharacteristics: true,
      marketSize: true,
      threatOfSectoralDownturn: true,
      threatOfCompetition: true,
      substitutionAlternatives: true,
      threatOfEntrants: true,
      knowledgeOfTargetMarket: true,
      distributionChannels: true,
      supplierSourcingRisks: true,
      demonstratedIntegrity: true,
      capableOfSustainedEffort: true,
      trustworthySource: true
    })

    const getRaterScore = async () => {
      try {
        const id = route.params.id
        const response = await RiskscoreService.fetchRiskscoreByRater(id)
        if (response.status === 200 && response.data.status === 'success') {
          ratings.value = response.data.data
          dataloaded.value = true
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const getRatingScore = (type) => {
      const rate = ratings.value.filter(rating => rating.type === type)
      return rate[0].score
    }

    const getRatingComment = (type) => {
      const rate = ratings.value.filter(rating => rating.type === type)
      return rate[0].comment
    }

    getRaterScore()

    const setRatings = (event, name) => {
      if (event.target.value > 0) {
        commentStatus.value[name] = false
      } else {
        commentStatus.value[name] = true
      }
    }

    const setComments = async (event, name) => {
      const score = Number(name) === 0 ? getRatingScore(event.target.name) : Number(name)
      const data = {
        category: Helpers.getRiskCategory(event.target.name),
        type: event.target.name,
        comment: event.target.value,
        score: score
      }
      const id = route.params.id
      try {
        const response = await RiskscoreService.createRiskscore(id, data)
        if (response.status === 200 && response.data.status === 'success') {
          Helpers.processRiskSuccess(response, store, response.data.message)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    return {
      dataloaded,
      ratings,
      getRatingScore,
      setRatings,
      getRatingComment,
      setComments,
      commentStatus,
      proprietaryOrProtected,
      feasibilityOfBusiness,
      marketCharacteristics,
      marketSize,
      threatOfSectoralDownturn,
      threatOfCompetition,
      substitutionAlternatives,
      threatOfEntrants,
      knowledgeOfTargetMarket,
      distributionChannels,
      supplierSourcingRisks,
      demonstratedIntegrity,
      capableOfSustainedEffort,
      trustworthySource
    }
  }
}
</script>

<style scoped>
textarea {
  max-width: 100%;
  border: solid 1px #E5E6E6;
  padding: 1rem;
  margin-top: 1rem;
  font-size: 14px;
  line-height: 16.59px;
  font-weight: 400;
  transition: all .15s ease;
}
</style>
