import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class PlaidService {
  static getPlaidInstitutions (institutionId) {
    return axios.get(`${url}/plaid/institution/${institutionId}`)
  }

  static getPlaidTransactions (accountId) {
    return axios.get(`${url}/admin/financials/plaid/${accountId}/transactions`)
  }

  static getNextBatchOfPlaidTransactions (accountId, pageDirection, cursor, sort, order, limit) {
    return axios.get(`${url}/admin/financials/plaid/${accountId}/transactions?pageDirection=${pageDirection}&cursor=${cursor}&sortBy=${sort}&order=${order}&limit=${limit}`)
  }

  static sortPlaidTransactions (accountId, sortBy, order, limit) {
    return axios.get(`${url}/admin/financials/plaid/${accountId}/transactions?sortBy=${sortBy}&order=${order}&limit=${limit}`)
  }

  static categorizePlaidTransactions (accountId, transactionId, data) {
    return axios.patch(`${url}/admin/financials/plaid/${accountId}/transactions/${transactionId}`, data)
  }
}

export default PlaidService
