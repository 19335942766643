import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import DashboardLayout from '../components/layouts/DashboardLayout.vue'
import DealflowLayout from '../components/layouts/DealflowLayout.vue'
import DealflowTabsLayout from '../components/layouts/DealflowTabsLayout.vue'
import Dealflow from '../views/Dealflow.vue'

import CompanyDocuments from '../views/dealflow/CompanyDocuments.vue'
import DeligenceResponses from '../views/dealflow/DeligenceResponses.vue'
import GeneralInfo from '../views/dealflow/GeneralInfo.vue'
import MetricsandFinancials from '../views/dealflow/MetricsandFinancials.vue'
import RiskscoreCard from '../views/dealflow/RiskscoreCard.vue'

import CompanyCollectionLayout from '../components/layouts/CompanyCollectionLayout.vue'
import CompanyMemoLayout from '../components/layouts/CompanyMemoLayout.vue'
import CompanyCollection from '../views/CompanyCollection.vue'
import CompanyMemo from '../views/CompanyMemo.vue'
import CompanyCollectionPage from '../views/company/CompanyCollectionPage.vue'
import SubCollectionPage from '../views/company/SubCollectionPage.vue'
import CompanyMemoPage from '../views/company/CompanyMemoPage.vue'
import CompanyList from '../views/CompanyList.vue'
import CompanySettings from '../views/CompanySettings.vue'
import TargetCompanies from '../views/TargetCompanies.vue'
import SignIn from '../views/auth/SignIn.vue'
import store from '@/store'
import PortfolioLayout from '../components/layouts/PortfolioLayout.vue'
import PortfolioTabsLayout from '../components/layouts/PortfolioTabsLayout.vue'
import Portfolio from '../views/portfolio/Portfolio.vue'
import PortfolioGeneralInfo from '../views/portfolio/GeneralInfo.vue'
import RoundHistory from '../views/portfolio/RoundHistory.vue'
import Files from '../views/portfolio/Files.vue'

const routes = [
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '',
    redirect: '/dashboard',
    name: 'Home',
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Home',
        component: Home
      },
      {
        path: '/dealflow',
        name: 'Dealflow Layout',
        component: DealflowLayout,
        children: [
          {
            path: '',
            name: 'Dealflow',
            component: Dealflow
          },
          {
            path: ':id',
            name: 'Company Dealflow',
            redirect: { name: 'General Info' },
            component: DealflowTabsLayout,
            children: [
              {
                path: 'generalinfo',
                name: 'General Info',
                component: GeneralInfo
              },
              {
                path: 'diligenceresponses',
                name: 'Deligence Responses',
                component: DeligenceResponses
              },
              {
                path: 'metricsandfinancials',
                name: 'Metrics and Financials',
                component: MetricsandFinancials
              },
              {
                path: 'riskscorecard',
                name: 'Risk Score Card',
                component: RiskscoreCard
              },
              {
                path: 'companydocuments',
                name: 'Company Documents',
                component: CompanyDocuments
              }
            ]
          }
        ]
      },
      {
        path: '/collection',
        name: 'Company Collection',
        component: CompanyCollectionLayout,
        children: [
          {
            path: '',
            name: 'Company Collection',
            component: CompanyCollection
          },
          {
            path: ':id',
            name: 'Company Collection Page',
            component: CompanyCollectionPage
          },
          {
            path: ':id/:subId',
            name: 'Sub Collection Page',
            component: SubCollectionPage
          }
        ]
      },
      {
        path: '/memo',
        name: 'Company Memo',
        component: CompanyMemoLayout,
        children: [
          {
            path: '',
            name: 'Company Memo',
            component: CompanyMemo
          },
          {
            path: ':id',
            name: 'Company Memo Page',
            component: CompanyMemoPage
          }
        ]
      },
      {
        path: '/companylist',
        name: 'Company List',
        component: CompanyList
      },
      {
        path: '/portfolio',
        name: 'Portfolio Layout',
        component: PortfolioLayout,
        children: [
          {
            path: '',
            name: 'Portfolio',
            component: Portfolio
          },
          {
            path: ':id',
            name: 'Portfolio Details',
            redirect: { name: 'Portfolio Info' },
            component: PortfolioTabsLayout,
            children: [
              {
                path: 'generalinfo',
                name: 'Portfolio Info',
                component: PortfolioGeneralInfo
              },
              {
                path: 'roundhistory',
                name: 'Round History',
                component: RoundHistory
              },
              {
                path: 'files',
                name: 'Files',
                component: Files
              }
            ]
          }
        ]
      },
      {
        path: '/target-companies',
        name: 'Target Companies',
        component: TargetCompanies
      },
      {
        path: '/settings',
        name: 'Company Settings',
        component: CompanySettings
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if ((to.name === 'SignIn') && store.state.auth.isUserLoggedIn) {
    next({ path: '/dashboard' })
  } else if (!to.meta.allowAnonymous && !store.state.auth.isUserLoggedIn) {
    next({
      path: '/sign-in',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})

export default router
