<template>
  <div id="income-statement-table" class="w-full border-[1px] border-faintGrey rounded-[4px] p-4">
                <table id="income_statement" class="w-full border-[red] overflow-hidden">
                  <thead class="border-b-[1px] border-faintGrey">
                    <tr>
                      <th class="w-[200px]" colspan="3"></th>
                      <th class="w-[150px] font-medium text-sm py-2 text-right px-2" v-for="(year, index) in incomeStatementYears" :key="index" colspan="1">{{ year }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-sm mt-3">
                    <tr>
                      <td class="font-medium pl-2" colspan="6">
                        <button class="flex items-center py-3 font-medium" @click="toggleRevenue = !toggleRevenue">
                          REVENUE
                          <span class="ml-3" :class="[toggleRevenue ? '' : 'transform -rotate-90']">
                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                            </svg>
                          </span>
                        </button>

                        <table class="c1 ml-4 w-[-webkit-fill-available] hidden" :class="toggleRevenue ? 'show' : ''">
                          <thead>
                            <tr>
                              <th class="w-[200px]" colspan="3"></th>
                              <th class="w-[150px] font-normal" colspan="1"></th>
                              <th class="w-[150px] font-normal" colspan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="c1">
                              <td class="font-medium pl-2" colspan="6">
                                <button class="parent-btn flex items-center">
                                  Revenue
                                  <span class="icon ml-3 transform -rotate-90">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                    </svg>
                                  </span>
                                </button>
                                <table class="revenue parent ml-4 w-[-webkit-fill-available] hidden">
                                  <thead>
                                    <tr>
                                      <th class="w-[200px]" colspan="3"></th>
                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                    </tr>
                                  </thead>
                                  <tbody class="ml-4">
                                    <tr class="c2" v-show="revenueTransactions.filter((rt) => rt.c2 === 'Recurring Revenue').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button :class="`child-btn flex items-center`">
                                          <!-- Recurring Revenue -->
                                          Recurring Revenue
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table :class="`revenue child`" class="ml-4 w-[-webkit-fill-available] hidden">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="_ in incomeStatementYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr class="category" v-show="revenueTransactions.filter((rt) => rt.category === 'Product Name').length > 0">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Product Name
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="revenue nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in incomeStatementYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(revenue, revenueIndx) in revenueTransactions" :key="revenueIndx">
                                                    <tr v-if="revenue.c2 === 'Recurring Revenue'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{ revenue.stakeholder }}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${revenue.length > 1 ? 1 : (revenue.length + 1)}`">{{ revenue.year === year && revenue.amount ? Helpers.numberWithCommas(revenue.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="revenueTransactions.filter((rt) => rt.c2 === 'Non Recurring Revenue').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- c2 -->
                                          Non Recurring Revenue
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="revenue child ml-4 w-[-webkit-fill-available] hidden">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="revenueTransactions.filter((rt) => rt.category === 'Product Name').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Product Name
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="revenue nested-child hidden category ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(revenue, revenueIndx) in revenueTransactions" :key="revenueIndx" >
                                                    <tr v-if="revenue.c2 === 'Non Recurring Revenue'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{ revenue.stakeholder }}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${revenue.length > 1 ? 1 : (revenue.length + 1)}`">{{ revenue.year === year && revenue.amount ? Helpers.numberWithCommas(revenue.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="revenueTransactions.filter((rt) => rt.c2 === 'Sales Returns').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- Recurring Revenue -->
                                          Sales Returns
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="revenue child ml-4 w-[-webkit-fill-available] hidden">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="revenueTransactions.filter((rt) => rt.category === 'Product Name').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Product Name
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="revenue nested-child hidden category ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(revenue, revenueIndx) in revenueTransactions" :key="revenueIndx" >
                                                    <tr v-if="revenue.c2 === 'Sales Returns'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{ revenue.stakeholder }}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${revenue.length > 1 ? 1 : (revenue.length + 1)}`">{{ revenue.year === year && revenue.amount ? Helpers.numberWithCommas(revenue.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="uncategorizedRevenueTransactions.filter((rt) => rt.c2 === 'Uncategorized Revenue').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- Recurring Revenue -->
                                          Uncategorized Revenue
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="child ml-4 w-[-webkit-fill-available] hidden">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="uncategorizedRevenueTransactions.filter((rt) => rt.category === 'Uncategorized Revenue').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Uncategorized Revenue
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">Unknown</td>
                                                      <!-- <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${costOfGoods.length > 1 ? 1 : (costOfGoods.length + 1)}`">{{ costOfGoods.year === year && costOfGoods.amount ? costOfGoods.amount : '0.00' }}</td> -->
                                                      <td v-for="(revenue, revenueIndx) in uncategorizedRevenueTransactions" :key="revenueIndx" class="font-normal pr-2 text-right w-[150px]" colspan="1">{{ revenue.year === 2021 && revenue.amount ? Helpers.numberWithCommas(revenue.amount) : revenue.year === 2022 && revenue.amount ? Helpers.numberWithCommas(revenue.amount) : revenue.year === 2023 && revenue.amount ? Helpers.numberWithCommas(revenue.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <tr class="total border-b-[1px] border-white bg-faintGrey rounded-[6px]">
                              <td class="font-medium pl-2" colspan="3">
                                Total Net Revenue
                              </td>
                              <td v-for="(revenue, revenueIndx) in totalNetRevenue" :key="revenueIndx" class="font-medium pr-2 text-right w-[150px]" :colspan="`${incomeStatementYears.length > 1 ? 1 : (incomeStatementYears.length + 1)}`">{{ (revenue.year === 2021 &&  incomeStatementYears.includes(2021)) ? Helpers.numberWithCommas(revenue.amount) : (revenue.year === 2022 && incomeStatementYears.includes(2022)) ? Helpers.numberWithCommas(revenue.amount) : (revenue.year === 2023 && incomeStatementYears.includes(2023)) ? Helpers.numberWithCommas(revenue.amount) : '0.00' }}</td>
                            </tr>

                            <tr v-show="costOfGoodsSoldTransactions.filter((rt) => rt.c1 === 'Cost of Goods Sold').length > 0" class="">
                              <td class="font-medium pl-2" colspan="6">
                                <button class="parent-btn flex items-center">
                                  Cost of Goods Sold
                                  <span class="icon ml-3 transform -rotate-90">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                    </svg>
                                  </span>
                                </button>

                                <table class="cogs parent c2 ml-4 w-[-webkit-fill-available] hidden">
                                  <thead>
                                    <tr>
                                      <th class="w-[200px]" colspan="3"></th>
                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                    </tr>
                                  </thead>
                                  <tbody class="ml-4">
                                    <tr v-show="costOfGoodsSoldTransactions.filter((rt) => rt.c2 === 'Direct Materials').length > 0" class="">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- Recurring Revenue -->
                                          Direct Materials
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="costOfGoodsSoldTransactions.filter((rt) => rt.category === 'Software').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Software
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(costOfGoods, costOfGoodsIndx) in costOfGoodsSoldTransactions" :key="costOfGoodsIndx">
                                                    <tr v-if="costOfGoods.c2 === 'Direct Materials' && costOfGoods.category === 'Software'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{ costOfGoods.stakeholder }}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${costOfGoods.length > 1 ? 1 : (costOfGoods.length + 1)}`">{{ costOfGoods.year === year && costOfGoods.amount ? Helpers.numberWithCommas(costOfGoods.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="costOfGoodsSoldTransactions.filter((rt) => rt.category === 'Perpetual Software License (Amortize)').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Perpetual Software License (Amortize)
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(costOfGoods, costOfGoodsIndx) in costOfGoodsSoldTransactions" :key="costOfGoodsIndx">
                                                    <tr v-if="costOfGoods.c2 === 'Direct Materials' && costOfGoods.category === 'Perpetual Software License (Amortize)'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{ costOfGoods.stakeholder }}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${costOfGoods.length > 1 ? 1 : (costOfGoods.length + 1)}`">{{ costOfGoods.year === year && costOfGoods.amount ? Helpers.numberWithCommas(costOfGoods.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="costOfGoodsSoldTransactions.filter((rt) => rt.category === 'SaaS & Subscriptions').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  SaaS & Subscriptions
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(costOfGoods, costOfGoodsIndx) in costOfGoodsSoldTransactions" :key="costOfGoodsIndx">
                                                    <tr v-if="costOfGoods.c2 === 'Direct Materials' && costOfGoods.category === 'SaaS & Subscriptions'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{ costOfGoods.stakeholder }}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${costOfGoods.length > 1 ? 1 : (costOfGoods.length + 1)}`">{{ costOfGoods.year === year && costOfGoods.amount ? Helpers.numberWithCommas(costOfGoods.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="costOfGoodsSoldTransactions.filter((rt) => rt.category === 'Hosting').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Hosting
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(costOfGoods, costOfGoodsIndx) in costOfGoodsSoldTransactions" :key="costOfGoodsIndx">
                                                    <tr v-if="costOfGoods.c2 === 'Direct Materials' && costOfGoods.category === 'Hosting'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{ costOfGoods.stakeholder }}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${costOfGoods.length > 1 ? 1 : (costOfGoods.length + 1)}`">{{ costOfGoods.year === year && costOfGoods.amount ? Helpers.numberWithCommas(costOfGoods.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="costOfGoodsSoldTransactions.filter((rt) => rt.c2 === 'Direct Labor').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- Recurring Revenue -->
                                          Direct Labor
                                          <span class="icon ml-3 transform -rotate-90" :class="[toggleIncomeRow ? '' : 'transform -rotate-90']">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="costOfGoodsSoldTransactions.filter((rt) => rt.category === 'Customer Service').length > 0">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Customer Service
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(costOfGoods, costOfGoodsIndx) in costOfGoodsSoldTransactions" :key="costOfGoodsIndx">
                                                    <tr v-if="costOfGoods.c2 === 'Direct Labor'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{ costOfGoods.stakeholder }}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${costOfGoods.length > 1 ? 1 : (costOfGoods.length + 1)}`">{{ costOfGoods.year === year && costOfGoods.amount ? Helpers.numberWithCommas(costOfGoods.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>

                                </table>
                              </td>
                            </tr>

                            <tr class="total border-b-[1px] border-faintGrey bg-faintGrey rounded-[6px]">
                              <td class="font-medium pl-2" colspan="3">
                                Total Cost of Goods Sold
                              </td>
                              <td v-for="(cogs, cogsIndx) in totalNetCostOfGoodsSold" :key="cogsIndx" class="font-medium pr-2 text-right w-[150px]" :colspan="`${incomeStatementYears.length > 1 ? 1 : (incomeStatementYears.length + 1)}`">{{ cogs.year === 2021 && cogs.amount ? cogs.amount : cogs.year === 2022 && cogs.amount ? cogs.amount : cogs.year === 2023 && cogs.amount ? cogs.amount : '0.00' }}</td>
                            </tr>

                          </tbody>
                        </table>
                      </td>
                    </tr>

                    <tr class="total border-b-[1px] border-faintGrey bg-faintGrey">
                      <td class="font-medium pl-2" colspan="3">
                        Gross Profit
                      </td>
                      <td v-for="(grossProfit, gpIndx) in grossProfit" :key="gpIndx" class="font-medium pr-2 text-right w-[150px]" :colspan="`${incomeStatementYears.length > 1 ? 1 : (incomeStatementYears.length + 1)}`">{{ grossProfit.year === 2021 && grossProfit.amount ? Helpers.numberWithCommas(grossProfit.amount) : grossProfit.year === 2022 && grossProfit.amount ? Helpers.numberWithCommas(grossProfit.amount) : grossProfit.year === 2023 && grossProfit.amount ? Helpers.numberWithCommas(grossProfit.amount) : '0.00' }}</td>
                    </tr>

                    <tr>
                      <td class="font-medium pl-2" colspan="6">
                        <button class="flex items-center py-3 font-medium" @click="toggleOperatingExpenses = !toggleOperatingExpenses">
                          OPERATING EXPENSES
                          <span class="ml-3" :class="[toggleOperatingExpenses ? '' : 'transform -rotate-90']">
                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                            </svg>
                          </span>
                        </button>

                        <table class="c1 ml-4 w-[-webkit-fill-available] hidden" :class="toggleOperatingExpenses ? 'show' : ''">
                          <thead>
                            <tr>
                              <th class="w-[200px]" colspan="3"></th>
                              <th v-for="(_, yearId) in incomeStatementYears" :key="yearId" class="w-[150px] font-normal" colspan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="font-medium pl-2" colspan="6">
                                <button class="parent-btn flex items-center">
                                  <!-- operatingExpensesTransactions -->
                                  Operating Expenses
                                  <span class="icon ml-3 transform -rotate-90">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                    </svg>
                                  </span>
                                </button>

                                <table class="c2 parent hidden ml-4 w-[-webkit-fill-available] ">
                                  <tbody class="ml-4">
                                    <tr v-show="operatingExpensesTransactions.filter((oet) => oet.c2 === 'Research & Development').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- Research & Development -->
                                          Research & Development
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Salaries').length > 0">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Salaries
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Research & Development' && operatingExpenses.category === 'Salaries'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Contractors').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Contractors
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Research & Development' && operatingExpenses.category === 'Contractors'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="operatingExpensesTransactions.filter((oet) => oet.c2 === 'Sales & Marketing').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- Sales & Marketing -->
                                          Sales & Marketing
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Salaries').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Salaries
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Sales & Marketing' && operatingExpenses.category === 'Salaries'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.category}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Sales Commissions').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Sales Commissions
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Research & Development' && operatingExpenses.category === 'Sales Commissions'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="operatingExpensesTransactions.filter((oet) => oet.c2 === 'General & Administrative').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- General & Administrative -->
                                          General & Administrative
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Salaries').length" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Salaries
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Research & Development' && operatingExpenses.category === 'Salaries'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Employer Taxes').length" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Employer Taxes
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Research & Development' && operatingExpenses.category === 'Employer Taxes'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Workers\' Comp').length" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Workers' Comp
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Research & Development' && operatingExpenses.category === 'Workers\' Comp'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Health Insurance').length" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Health Insurance
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Research & Development' && operatingExpenses.category === 'Health Insurance'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Pension/Retirement Contribution').length" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Pension/Retirement Contribution
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Research & Development' && operatingExpenses.category === 'Pension/Retirement Contribution'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="operatingExpensesTransactions.filter((oet) => oet.c2 === 'Professional Services').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- Professional Services -->
                                          Professional Services
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Legal Fees').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Legal Fees
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Professional Services' && operatingExpenses.category === 'Legal Fees'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Consultant Fees').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Consultant Fees
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Professional Services' && operatingExpenses.category === 'Consultant Fees'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Accounting Fees').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Accounting Fees
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Professional Services' && operatingExpenses.category === 'Accounting Fees'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="operatingExpensesTransactions.filter((oet) => oet.c2 === 'Travel & Entertainment').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- Travel & Entertainment -->
                                          Travel & Entertainment
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Food & Drinks').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Food & Drinks
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Travel & Entertainment' && operatingExpenses.category === 'Food & Drinks'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Ground Transportation').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Ground Transportation
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Travel & Entertainment' && operatingExpenses.category === 'Ground Transportation'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Air & Lodging').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Air & Lodging
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Travel & Entertainment' && operatingExpenses.category === 'Air & Lodging'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Other T&E').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Other T&E
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Travel & Entertainment' && operatingExpenses.category === 'Other T&E'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="operatingExpensesTransactions.filter((oet) => oet.c2 === 'Other Expenses').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- Other Expenses -->
                                          Other Expenses
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Coworking & Rent').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Coworking & Rent
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Other Expenses' && operatingExpenses.category === 'Coworking & Rent'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Bank Charges').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Bank Charges
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Other Expenses' && operatingExpenses.category === 'Bank Charges'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Business Filings').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Business Filings
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Other Expenses' && operatingExpenses.category === 'Business Filings'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Business Insurance').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Business Insurance
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Other Expenses' && operatingExpenses.category === 'Business Insurance'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Internet, Phone & Utility').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Internet, Phone & Utility
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Other Expenses' && operatingExpenses.category === 'Internet, Phone & Utility'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Memberships & Training').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Memberships & Training
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Other Expenses' && operatingExpenses.category === 'Memberships & Training'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Donations').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Donations
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Other Expenses' && operatingExpenses.category === 'Donations'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Clearing (Forex for example)').length > 0">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Clearing (Forex for example)
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Other Expenses' && operatingExpenses.category === 'Clearing (Forex for example)'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="operatingExpensesTransactions.filter((oet) => oet.c2 === 'Unfamiliar Expenses').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          <!-- Unfamiliar Expenses -->
                                          Unfamiliar Expenses
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="operatingExpensesTransactions.filter((oet) => oet.category === 'Other').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Other
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(operatingExpenses, operatingExpensesIndx) in operatingExpensesTransactions" :key="operatingExpensesIndx">
                                                    <tr v-if="operatingExpenses.c2 === 'Unfamiliar Expenses' && operatingExpenses.category === 'Other'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{operatingExpenses.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in incomeStatementYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${operatingExpenses.length > 1 ? 1 : (operatingExpenses.length + 1)}`">{{ operatingExpenses.year === year && operatingExpenses.amount ? Helpers.numberWithCommas(operatingExpenses.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>

                                    <tr v-show="uncategorizedExpensesTransactions.filter((rt) => rt.c2 === 'Uncategorized Expenses').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="child-btn flex items-center">
                                          Uncategorized Expenses
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="uncategorizedExpensesTransactions.filter((rt) => rt.category === 'Uncategorized Expenses').length > 0" class="">
                                              <td class="font-normal" colspan="6">
                                                <button class="nested-child-btn flex items-center">
                                                  Uncategorized Expenses
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="nested-child hidden ml-4 w-[-webkit-fill-available]">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="(year, index) in incomeStatementYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">Unknown</td>
                                                      <td v-for="(amount, amountIndx) in uncategorizedExpensesTransactions" :key="amountIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${amount.length > 1 ? 1 : (amount.length + 1)}`">{{ amount.year === 2021 && amount.amount ? Helpers.numberWithCommas(amount.amount) : amount.year === 2022 && amount.amount ? Helpers.numberWithCommas(amount.amount) : amount.year === 2023 && amount.amount ? Helpers.numberWithCommas(amount.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>

                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <tr class="total border-b-[1px] border-white bg-faintGrey">
                              <td class="font-medium pl-2" colspan="3">
                                Total Operating Expenses
                              </td>
                              <td v-for="(total, totalIndx) in calculateTotalOperatingExpenses" :key="totalIndx" class="font-medium pr-2 text-right w-[150px]" colspan="1">{{ total.year === 2021 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2022 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2023 && total.amount ? Helpers.numberWithCommas(total.amount) : '0.00' }}</td>

                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>

                    <tr class="total border-b-[1px] border-faintGrey bg-faintGrey">
                      <td class="font-medium pl-2" colspan="3">
                        EBIT
                      </td>
                      <td v-for="(ebit, ebitIndx) in calculateEBIT" :key="ebitIndx" class="font-medium pr-2 text-right w-[150px]" colspan="1">{{ (ebit.year === 2021 && ebit.amount) ? Helpers.numberWithCommas(ebit.amount) : (ebit.year === 2022 && ebit.amount) ? Helpers.numberWithCommas(ebit.amount) : (ebit.year === 2023 && ebit.amount) ? Helpers.numberWithCommas(ebit.amount) : '0.00' }}</td>
                    </tr>
                    <tr>
                      <td class="font-normal pl-2" colspan="3">
                        Depreciation
                      </td>
                      <td v-for="_ in incomeStatementYears" :key="_" class="font-normal pr-2 text-right w-[150px]" :colspan="`${incomeStatementYears.length > 1 ? 1 : (incomeStatementYears.length + 1)}`">0.00</td>
                    </tr>
                    <tr class="total border-b-[1px] border-faintGrey bg-faintGrey">
                      <td class="font-medium pl-2" colspan="3">
                        EBITDA
                      </td>
                      <td v-for="_ in incomeStatementYears" :key="_" class="font-medium pr-2 text-right w-[150px]" colspan="1">0.00</td>
                    </tr>
                    <tr>
                      <td class="font-normal pl-2" colspan="3">
                        Interest Expense
                      </td>
                      <td v-for="_ in incomeStatementYears" :key="_" class="font-normal pr-2 text-right w-[150px]" :colspan="`${incomeStatementYears.length > 1 ? 1 : (incomeStatementYears.length + 1)}`">0.00</td>
                    </tr>
                    <tr>
                      <td class="font-normal pl-2" colspan="3">
                        Taxes
                      </td>
                      <td v-for="_ in incomeStatementYears" :key="_" class="font-normal pr-2 text-right w-[150px]" :colspan="`${incomeStatementYears.length > 1 ? 1 : (incomeStatementYears.length + 1)}`">0.00</td>
                    </tr>
                    <tr class="total border-b-[1px] border-faintGrey bg-faintGrey">
                      <td class="font-medium pl-2" colspan="3">
                        Net Income
                      </td>
                      <td v-for="_ in incomeStatementYears" :key="_" class="font-medium pr-2 text-right w-[150px]" colspan="1">0.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
</template>

<script>
import { ref, computed, onBeforeUnmount, onMounted } from 'vue'
import FinancialService from '@/services/financials.service'
import { useRoute, useRouter } from 'vue-router'
import Helpers from '../utils/helpers'
import { useStore } from 'vuex'

export default {
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const incomeStatementTransactions = ref(null)
    const incomeStatementYears = ref([])
    const consolidatedIncomeStatementTransactions = ref([])
    const toggleIncomeRow = ref(false)
    const toggleRevenue = ref(false)
    const toggleOperatingExpenses = ref(false)
    const revenueTransactions = ref([])
    const costOfGoodsSoldTransactions = ref([])
    const operatingExpensesTransactions = ref([])
    const incomeTransactions = ref([])
    const uncategorizedRevenueTransactions = ref([])
    const uncategorizedExpensesTransactions = ref([])
    const incomeStatement = computed(() => store.state.app.incomeStatement)
    const balanceSheet = computed(() => store.state.app.balanceSheet)

    const fetchIncomeStatementByYear = async () => {
      const companyId = route.params.id
      const statementType = 'income_statement'
      const period = 'annual'
      try {
        const response = await FinancialService.getFinancialStatements(companyId, statementType, period)
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setIncomeStatement', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const selectIncomeStatementYear = () => {
      incomeStatement.value.map((obj) => {
        incomeStatementYears.value.push(obj.year)
      })
    }
    fetchIncomeStatementByYear()
    selectIncomeStatementYear()

    const consolidateIncomeStatement = () => {
      // consolidate transactions and add their years to the individual objects
      const consolidatedData = []
      for (const yearData of incomeStatement.value) {
        const year = yearData.year

        for (const entry of yearData.financials) {
          const consolidated = {
            year,
            financial_statement: entry.financial_statement,
            c1: entry.c1,
            c2: entry.c2,
            category: entry.category,
            stakeholder: entry.stakeholder,
            amount: entry.amount.toFixed(2)
          }

          consolidatedData.push(consolidated)
        }
      }
      consolidatedIncomeStatementTransactions.value = consolidatedData

      revenueTransactions.value = consolidatedIncomeStatementTransactions.value.filter((transaction) => transaction.c1 === 'Revenue')
      costOfGoodsSoldTransactions.value = consolidatedIncomeStatementTransactions.value.filter((transaction) => transaction.c1 === 'Cost of Goods Sold')
      operatingExpensesTransactions.value = consolidatedIncomeStatementTransactions.value.filter((transaction) => transaction.c1 === 'Operating Expenses')
      incomeTransactions.value = consolidatedIncomeStatementTransactions.value.filter((transaction) => transaction.c1 === 'Income')
      uncategorizedRevenueTransactions.value = consolidatedIncomeStatementTransactions.value.filter((transaction) => transaction.c1 === 'Uncategorized Revenue')
      uncategorizedExpensesTransactions.value = consolidatedIncomeStatementTransactions.value.filter((transaction) => transaction.c1 === 'Uncategorized Expenses')
    }
    consolidateIncomeStatement()

    const totalNetRevenue = computed(() => {
      const result = incomeStatementYears.value.map((year, i) => {
        const transactions = revenueTransactions.value.filter(transaction => transaction.year === year)
        const total = transactions.reduce((acc, transaction) => acc + Number(transaction.amount), 0.00)
        const uncategorizedTotalForYear = uncategorizedRevenueTransactions.value[i]?.amount !== undefined ? Number(uncategorizedRevenueTransactions.value[i]?.amount) : 0.00
        const totalAmount = uncategorizedTotalForYear !== 0 ? total + uncategorizedTotalForYear : total
        return {
          year,
          amount: totalAmount.toFixed(2) // Convert total to a string with 2 decimal places
        }
      })
      return [...result]
    })

    const totalNetCostOfGoodsSold = computed(() => {
      const result = incomeStatementYears.value.map((year, i) => {
        const transactions = costOfGoodsSoldTransactions.value.filter(transaction => transaction.year === year)
        const total = transactions.reduce((tot, transaction) => tot + Number(transaction.amount), 0.00)
        return {
          year,
          amount: total.toFixed(2)
        }
      })
      return [...result]
    })

    const grossProfit = computed(() => {
      const result = totalNetRevenue.value.map(revItem => {
        const cogsItem = totalNetCostOfGoodsSold.value.find(cogs => cogs.year === revItem.year)
        const cogsAmount = cogsItem ? Number(cogsItem.amount) : 0.00
        const revenueAmount = Number(revItem.amount)
        const difference = (revenueAmount - cogsAmount)

        return {
          year: revItem.year,
          amount: difference.toFixed(2)
        }
      })
      return [...result]
    })

    const calculateTotalOperatingExpenses = computed(() => {
      const result = incomeStatementYears.value.map((year, i) => {
        const transactions = operatingExpensesTransactions.value.filter(transaction => transaction.year === year)
        const total = transactions.reduce((oe, transaction) => oe + Number(transaction.amount), 0.00)
        const uncategorizedTotalForYear = uncategorizedExpensesTransactions.value[i]?.amount !== undefined ? Number(uncategorizedExpensesTransactions.value[i]?.amount) : 0.00
        const totalAmount = uncategorizedTotalForYear !== undefined ? total + uncategorizedTotalForYear : total
        return {
          year,
          amount: totalAmount.toFixed(2) // Convert total to a string with 2 decimal places
        }
      })
      return [...result]
    })

    const calculateEBIT = computed(() => {
      const result = incomeStatementYears.value.map((year, i) => {
        const revenueTotal = totalNetRevenue.value.filter(tot => tot.year === year)
        const cogsTotal = totalNetCostOfGoodsSold.value.filter(cogs => cogs.year === year)
        const opExpensesTotal = calculateTotalOperatingExpenses.value.filter(opExp => opExp.year === year)
        const total = (Number(revenueTotal[0].amount) - Number(cogsTotal[0].amount))

        return {
          year,
          amount: (total - opExpensesTotal[0].amount).toFixed(2)
        }
      })
      return [...result]
    })

    onMounted(() => {
      const wrapper = document.querySelector('#income-statement-table')
      const parentTriggers = wrapper.querySelectorAll('.parent-btn')
      const parentRows = wrapper.querySelectorAll('.parent')
      const childrenRows = wrapper.querySelectorAll('.child')
      const childrenTriggers = wrapper.querySelectorAll('.child-btn')
      const nestedChildrenRows = wrapper.querySelectorAll('.nested-child')
      const nestedChildrenTriggers = wrapper.querySelectorAll('.nested-child-btn')

      parentTriggers.forEach((row, parentIndex) => {
        row.addEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')

          if (parentRows[parentIndex]) {
            parentRows[parentIndex].classList.toggle('show')
          }
        })
      })
      childrenTriggers.forEach((row, childIndex) => {
        row.addEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')

          if (childrenRows[childIndex]) {
            childrenRows[childIndex].classList.toggle('show')
          }
        })
      })
      nestedChildrenTriggers.forEach((row, nestedChildIndex) => {
        row.addEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')

          if (nestedChildrenRows[nestedChildIndex]) {
            nestedChildrenRows[nestedChildIndex].classList.toggle('show')
          }
        })
      })
    })
    onBeforeUnmount(() => {
      const wrapper = document.querySelector('#income-statement-table')
      const parentTriggers = wrapper.querySelectorAll('.parent-btn')
      const parentRows = wrapper.querySelectorAll('.parent')
      const childrenRows = wrapper.querySelectorAll('.child')
      const childrenTriggers = wrapper.querySelectorAll('.child-btn')
      const nestedChildrenRows = wrapper.querySelectorAll('.nested-child')
      const nestedChildrenTriggers = wrapper.querySelectorAll('.nested-child-btn')

      parentTriggers.forEach((row, parentIndex) => {
        row.removeEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')
          if (parentRows[parentIndex]) {
            parentRows[parentIndex].classList.toggle('show')
          }
        })
      })
      childrenTriggers.forEach((row, childIndex) => {
        row.removeEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')
          if (childrenRows[childIndex]) {
            childrenRows[childIndex].classList.toggle('show')
          }
        })
      })
      nestedChildrenTriggers.forEach((row, nestedChildIndex) => {
        row.removeEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')
          if (nestedChildrenRows[nestedChildIndex]) {
            nestedChildrenRows[nestedChildIndex].classList.toggle('show')
          }
        })
      })
    })

    return {
      fetchIncomeStatementByYear,
      incomeStatementTransactions,
      toggleIncomeRow,
      incomeStatement,
      balanceSheet,
      totalNetRevenue,
      incomeStatementYears,
      consolidatedIncomeStatementTransactions,
      revenueTransactions,
      costOfGoodsSoldTransactions,
      operatingExpensesTransactions,
      incomeTransactions,
      uncategorizedRevenueTransactions,
      uncategorizedExpensesTransactions,
      totalNetCostOfGoodsSold,
      grossProfit,
      calculateTotalOperatingExpenses,
      calculateEBIT,
      Helpers,
      toggleRevenue,
      toggleOperatingExpenses
    }
  }
}
</script>

<style scoped>
  table {
    border-color: none;
  }
  table.show {
    display: table !important
  }
  table.rotate {
    transform: rotate(0) !important
  }
</style>
