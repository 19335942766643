<template>
  <main class="dealflow flex">
    <div class="flex flex-col">
      <div class="flex items-center mt-10 justify-center w-[157.5px] h-[157.5px] bg-coolGray-100 rounded-full p-1">
        <img class="rounded-full max-h-full max-w-full" :src="`${s3Bucket}${company.logo}`"/>
      </div>
      <button
        class="flex items-center justify-center border border-borderBlack rounded text-xs leading-[15.41px] font-medium font-Rubik px-4 py-3 mt-8"
        @click="openModal"
      >
        <svg
          class="h-4 w-4 text-black"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
        </svg>
        <span class="ml-2">Upload File</span>
      </button>

    </div>
    <div class="mt-9 ml-14 flex-1 flex-col min-w-0">
     <div class="w-full mx-auto bg-white rounded-2xl">
       <Disclosure v-slot="{ open }">
          <DisclosureButton
            :class="[open ? 'border-b' : 'border-b-0']"
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-gray-200"
          >
            <span>INVESTOR PRESENTATION</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500 mb-12">
            <Presentation></Presentation>
          </DisclosurePanel>
        </Disclosure>
        <Disclosure v-slot="{ open }">
          <DisclosureButton
            :class="[open ? 'border-b' : 'border-b-0']"
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-t border-gray-200"
          >
            <span>FINANCIAL DOCUMENTS</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500 mb-12">
            <Financial></Financial>
          </DisclosurePanel>
        </Disclosure>
        <Disclosure v-slot="{ open }">
          <DisclosureButton
            :class="[open ? 'border-b' : 'border-b-0']"
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-t border-gray-200"
          >
            <span>LEGAL DOCUMENTS</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500 mb-12">
            <Legal></Legal>
          </DisclosurePanel>
        </Disclosure>
        <Disclosure v-slot="{ open }">
          <DisclosureButton
            :class="[open ? 'border-b' : 'border-b-0']"
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-t border-gray-200"
          >
            <span>PRODUCT AND TECH DOCUMENTS</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500 mb-12">
            <Product></Product>
          </DisclosurePanel>
        </Disclosure>
        <Disclosure v-slot="{ open }">
          <DisclosureButton
            :class="[open ? 'border-b' : 'border-b-0']"
            class="flex justify-between w-full py-3 font-Rubik text-[20px] leading-[23.7px] font-normal text-left text-greyText border-t border-gray-200"
          >
            <span>RESEARCH DOCUMENTS</span>
            <ChevronDownIcon
              :class="open ? 'transform rotate-180' : ''"
              class="w-5 h-5 text-greyText"
            />
          </DisclosureButton>
          <DisclosurePanel class="flex flex-col justify-between pt-4 pb-2 text-sm text-gray-500 mb-12">
            <Research></Research>
          </DisclosurePanel>
        </Disclosure>
     </div>
    </div>
    <Modal v-show="data.modalOpen">
      <div class="bg-white rounded-[2px] py-[40px] px-[48px] relative w-[480px]">
        <template v-if="!data.file_upload">
          <button @click.prevent="closeModal" class="close absolute right-[20px] top-[20px]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0D0D0D"/>
          </svg>
          </button>
          <h3 class="font-medium font-Rubik leading-[18.96px] text-black mb-[8px]">
            Upload a new File
          </h3>
          <hr />
          <div class="role mt-6 mb-6">
            <label
              class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
              for="file-upload"
            >
              Choose a file to upload
            </label>
            <div class="relative">
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                maxlength="30"
                class="px-4 py-4 mt-2
                  text-[15px]
                  leading-[17.78px]
                  font-Rubik
                  placeholder-gray-400
                  text-black text-sm
                  border border-gray-200
                  w-full
                  focus:outline-none focus:border-black
                  hover:border-black
                  focus:ring-0
                  rounded-[2px]
                "
                placeholder=""
                style="transition: 'all .15s ease'"
                @change="handleFile"
              />
            </div>
          </div>
          <div class="role mt-6 mb-6">
            <div class="relative z-[5]">
              <Listbox v-model="data.document_type">
                <div class="relative mt-1">
                  <ListboxLabel class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                    <span class="text-red-400">*</span>
                    Document Type
                  </ListboxLabel>
                  <ListboxButton
                    class="relative px-4 py-4 mt-2 text-left placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                  >
                    <span v-if="!data.document_type" class="text-gray-400 block truncate">Select Document Type</span>
                    <span v-else class="block truncate">{{ data.document_type }}</span>
                    <span
                      class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                      <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="doctype in documentTypes"
                        :key="doctype"
                        :value="doctype"
                        as="template"
                      >
                        <li
                          :class="[
                            active ? 'text-white bg-black' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-10 pr-4',
                          ]"
                        >
                          <span
                            :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ doctype }}</span
                          >
                          <span
                            v-if="selected"
                            class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400"
                          >
                            <CheckIcon class="w-5 h-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>
          <div class="w-full flex items-center justify-end">
            <button :disabled="!data.file || !data.document_type" @click="uploadCompanyFile" class="mt-3 flex bg-black text-white rounded-[2px] text-[16px] font-Rubik px-8 py-4 outline-none focus:outline-none disabled:opacity-50">
              <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white">Submit</span>
              <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
              </svg>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col items-center justify-center">
            <h3 class="font-medium font-Rubik text-[16px] leading-[18.96px] text-black mb-[8px]">
              Created!
            </h3>
            <p class="font-Rubik text-[15px] leading-[24px] text-center text-greyText max-w-[90%] mx-auto">Your file has been uploaded</p>
            <button @click.prevent="closeModal" class="mt-3 border-b border-teal font-medium py-[12px] px-[35px] text-white text-[13px] leading-[15.41px] rounded-[4px] bg-black">Okay</button>
          </div>
        </template>
      </div>
    </Modal>
  </main>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { ChevronDownIcon, CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { reactive, computed, ref } from 'vue'
import documentTypes from '@/utils/documentType'
// import Document from '@/views/files/document.vue'
import Presentation from '@/views/files/presentation.vue'
import Legal from '@/views/files/legal.vue'
import Financial from '@/views/files/financial.vue'
import Product from '@/views/files/product.vue'
import Research from '@/views/files/research.vue'
import { useStore } from 'vuex'
import Modal from '@/components/Modal.vue'
import FileService from '@/services/file.service'
import CompanyService from '@/services/company.service'
import { useRoute, useRouter } from 'vue-router'
import Helpers from '@/utils/helpers'
export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    ChevronDownIcon,
    CheckIcon,
    SelectorIcon,
    Presentation,
    Financial,
    Legal,
    Product,
    Research,
    Modal
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const company = computed(() => store.state.app.current_company)
    const s3Bucket = process.env.VUE_APP_ORIGINS_S3_BUCKET
    const progress = reactive({
      stage1: false,
      stage2: true,
      stage3: true
    })
    const data = reactive({
      modalOpen: false,
      file_upload: false,
      document_type: null,
      file: ''
    })
    const disabled = reactive({
      stage1: false,
      stage2: true,
      stage3: true
    })
    const openModal = () => {
      data.modalOpen = !data.modalOpen
    }
    const handleFile = (e) => {
      data.file = e.target.files[0]
    }
    const closeModal = () => {
      data.modalOpen = false
      data.file_upload = false
    }
    const getCompanyFiles = async () => {
      try {
        const id = route.params.id
        const response = await CompanyService.fetchAllCompanyfiles(id)
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setCompanyFiles', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const uploadCompanyFile = async () => {
      loading.value = true
      const id = route.params.id
      const formData = new FormData()
      formData.append('company_id', id)
      formData.append('document_type', data.document_type)
      formData.append('file', data.file)
      try {
        const response = await FileService.upload(formData)
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = false
          data.file_upload = response.data.data.file_name
          getCompanyFiles()
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }
    const loading = ref(false)
    return {
      progress,
      disabled,
      company,
      s3Bucket,
      data,
      openModal,
      closeModal,
      loading,
      handleFile,
      documentTypes,
      uploadCompanyFile
    }
  }
}
</script>

<style>

</style>
