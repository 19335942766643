<template>
  <main class="portfolio mt-8 flex flex-1 flex-col min-w-0 mr-10">
    <div class="main-title flex justify-between">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Portfolio</h1>
      <!-- <div class="utils max-w-[max-content] flex items-center">
        <button
          class="flex items-center border border-borderBlack rounded text-xs leading-[15.41px] font-medium font-Rubik px-4 py-3 mr-3"
          @click="openModal"
        >
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1.2C0 0.813401 0.313401 0.5 0.7 0.5H6.3C6.48565 0.5 6.6637 0.57375 6.79497 0.705025L13.795 7.70503C14.0683 7.97839 14.0683 8.42161 13.795 8.69497L8.19497 14.295C7.92161 14.5683 7.47839 14.5683 7.20503 14.295L0.205025 7.29497C0.0737498 7.1637 0 6.98565 0 6.8V1.2ZM1.4 1.9V6.51005L7.7 12.8101L12.3101 8.2L6.01005 1.9H1.4Z" fill="currentColor"/>
            <path d="M4.9 4.35C4.9 4.9299 4.4299 5.4 3.85 5.4C3.2701 5.4 2.8 4.9299 2.8 4.35C2.8 3.7701 3.2701 3.3 3.85 3.3C4.4299 3.3 4.9 3.7701 4.9 4.35Z" fill="currentColor"/>
          </svg>
          <span class="ml-2">Create tag</span>
        </button>
        <button
          class="flex items-center border border-borderBlack rounded text-xs leading-[15.41px] font-medium font-Rubik px-4 py-3"
          @click="openModal"
        >
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 0.5C7.48325 0.5 7.875 0.891751 7.875 1.375V6.625H13.125C13.6082 6.625 14 7.01675 14 7.5C14 7.98325 13.6082 8.375 13.125 8.375H7.875V13.625C7.875 14.1082 7.48325 14.5 7 14.5C6.51675 14.5 6.125 14.1082 6.125 13.625V8.375H0.875C0.391751 8.375 0 7.98325 0 7.5C0 7.01675 0.391751 6.625 0.875 6.625H6.125V1.375C6.125 0.891751 6.51675 0.5 7 0.5Z" fill="currentColor"/>
          </svg>
          <span class="ml-2">Add to list</span>
        </button>
      </div> -->
    </div>
    <div class="mt-5 rounded-sm p-6">
      <!-- <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-sm font-Rubik font-medium text-black">General Info</h1>
        </div>
      </div> -->
      <div class="mt-4 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 min-h-[80vh]">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <!-- <th scope="col" class="py-4 px-8 text-left text-sm font-light font-Rubik text-greyText">
                    <input type="checkbox" name="selectAll" id="selectAll" class="cursor-pointer" :checked="false">
                  </th> -->
                  <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText sm:pl-6 md:pl-0 whitespace-nowrap">Company</th>
                  <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText whitespace-nowrap">Funding round</th>
                  <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText whitespace-nowrap">Industry type</th>
                  <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText whitespace-nowrap">Business model</th>
                  <th scope="col" class="py-3.5 px-3 text-left text-sm font-light font-Rubik text-greyText whitespace-nowrap">City</th>
                  <th scope="col" class="py-3.5 pl-10 pr-3 text-left text-sm font-light font-Rubik text-greyText whitespace-nowrap"></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr class="cursor-default" v-for="(company, i) in allCompanies" :key="company.id">
                  <!-- <td class="whitespace-nowrap py-4 px-8 text-sm font-Rubik text-black">
                    <input type="checkbox" name="select" id="select" class="cursor-pointer">
                  </td> -->
                  <td class="w-[180px] py-4 pl-4 pr-3 text-sm font-Rubik text-black sm:pl-6 md:pl-0 text-left">
                    {{ company.company_name }}
                  </td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black text-left">{{ company.funding_round }}</td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black text-left">
                    {{ company.industry_type }}
                  </td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black">
                    <span class="py-[6px] px-2 flex items-center justify-center bg-[transparent] border border-greyText text-black rounded text-[13px] leading-[15.41px] font-medium font-Rubik max-w-[max-content]">
                      {{ company.business_model }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black text-left">
                    {{ company.address.city }}
                  </td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-Rubik text-black relative">
                    <Menu>
                      <MenuButton @click="editPortfolio(i)">
                        <div class="flex items-center justify-center text-white py-[10px] px-[14px] font-medium rounded-[4px]">
                          <span>
                            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="16" cy="2" r="2" fill="#666666"/>
                              <circle cx="9" cy="2" r="2" fill="#666666"/>
                              <circle cx="2" cy="2" r="2" fill="#666666"/>
                            </svg>
                          </span>
                        </div>
                      </MenuButton>
                      <transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-from-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-out"
                        leave-from-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0"
                      >
                        <MenuItems class="flex absolute right-0 top-[50px] flex-col py-2 bg-white rounded-[8px] shadow-md z-[200] w-[max-content] p-3">
                          <MenuItem v-slot="{ active }" @click="viewCompanyPortfolio(company.id)">
                            <span :class='{ "bg-[#F6F8F9]": active }' class="w-full text-sm text-left py-2 px-3 cursor-pointer rounded-[4px] flex items-center justify-start">
                              <span class="mr-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142Z" fill="#5B6871"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5C7.52201 5 3.73201 7.943 2.45801 12C3.73201 16.057 7.52201 19 12 19C16.478 19 20.268 16.057 21.542 12C20.268 7.943 16.478 5 12 5ZM14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8C10.9391 8 9.92173 8.42143 9.17158 9.17157C8.42143 9.92172 8.00001 10.9391 8.00001 12C8.00001 13.0609 8.42143 14.0783 9.17158 14.8284C9.92173 15.5786 10.9391 16 12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284Z" fill="#5B6871"/>
                                </svg>
                              </span>
                              View
                            </span>
                          </MenuItem>
                          <!-- <MenuItem v-slot="{ active }">
                            <span :class='{ "bg-[#F6F8F9]" : active }' class="disabled:opacity-75 text-sm text-left text-black py-2 px-3 cursor-pointer rounded-[4px] flex items-center justify-start">
                              <span class="mr-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11 4C10.8143 4.0001 10.6324 4.05188 10.4745 4.14955C10.3166 4.24722 10.189 4.38692 10.106 4.553L9.382 6H6C5.73478 6 5.48043 6.10536 5.29289 6.29289C5.10536 6.48043 5 6.73478 5 7C5 7.26522 5.10536 7.51957 5.29289 7.70711C5.48043 7.89464 5.73478 8 6 8V18C6 18.5304 6.21071 19.0391 6.58579 19.4142C6.96086 19.7893 7.46957 20 8 20H16C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18V8C18.2652 8 18.5196 7.89464 18.7071 7.70711C18.8946 7.51957 19 7.26522 19 7C19 6.73478 18.8946 6.48043 18.7071 6.29289C18.5196 6.10536 18.2652 6 18 6H14.618L13.894 4.553C13.811 4.38692 13.6834 4.24722 13.5255 4.14955C13.3676 4.05188 13.1857 4.0001 13 4H11ZM9 10C9 9.73478 9.10536 9.48043 9.29289 9.29289C9.48043 9.10536 9.73478 9 10 9C10.2652 9 10.5196 9.10536 10.7071 9.29289C10.8946 9.48043 11 9.73478 11 10V16C11 16.2652 10.8946 16.5196 10.7071 16.7071C10.5196 16.8946 10.2652 17 10 17C9.73478 17 9.48043 16.8946 9.29289 16.7071C9.10536 16.5196 9 16.2652 9 16V10ZM14 9C13.7348 9 13.4804 9.10536 13.2929 9.29289C13.1054 9.48043 13 9.73478 13 10V16C13 16.2652 13.1054 16.5196 13.2929 16.7071C13.4804 16.8946 13.7348 17 14 17C14.2652 17 14.5196 16.8946 14.7071 16.7071C14.8946 16.5196 15 16.2652 15 16V10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z" fill="#F76659"/>
                                </svg>
                              </span>
                              Delete
                            </span>
                          </MenuItem> -->
                        </MenuItems>
                      </transition>
                    </Menu>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ref } from 'vue'
import CompanyService from '../../services/company.service'
import Helpers from '@/utils/helpers'
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem
} from '@headlessui/vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem
  },

  setup () {
    const router = useRouter()
    const store = useStore()
    const companies = ref(['Norebase', 'Netflix', 'BlueTrain', 'FlyMd', 'ManusCrypt', 'XBaze'])
    const allCompanies = ref(null)

    const portfolioData = ref([
      {
        id: '2e61d56a-3d25-4e73-a6bb-084535c0eac1',
        company_name: 'EDIFY INC',
        website: 'https://edifyapp.co',
        year_founded: '2022',
        formatted_address: 'Lagos Nigeria',
        address: { city: 'Lagos', state: '', country: 'Nigeria', code: 'NG' },
        coordinates: { type: 'Point', coordinates: ['6.5243793', '3.3792057'] },
        geom: { type: 'Point', coordinates: [6.5243793, 3.3792057] },
        pitch_deck: '1674042525796-Investment Memo.pdf',
        logo: '1674042525799-edify_logo.png',
        industry_type: 'Media',
        business_model: 'B2C',
        funding_round: 'Pre-Seed',
        funding_type: 'SAFE',
        min_amount: '0',
        max_amount: '150000',
        valuation: '1500000',
        funding_status: 'Open',
        current_stage: 'ONBOARDING',
        can_proceed: true,
        next_stage: 'STAGE 1',
        last_interaction_at: '2023-01-18T11:49:04.042Z',
        stage_status: 'Approved',
        company_summary: 'On demand sermon streaming app',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2023-01-18T11:48:45.963Z',
        updated_at: '2023-01-18T11:48:45.963Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      },
      {
        id: 'fb73e219-f86d-4dc0-a82c-8ecc33698f01',
        company_name: 'PlugExpresso',
        website: 'https://plugexpresso.com',
        year_founded: '2021',
        formatted_address: 'Lagos Nigeria',
        address: { city: 'Lagos', state: '', country: 'Nigeria', code: 'NG' },
        coordinates: { type: 'Point', coordinates: ['6.5243793', '3.3792057'] },
        geom: { type: 'Point', coordinates: [6.5243793, 3.3792057] },
        pitch_deck: '1673985074116-PlugExpresso for Individuals (1).pdf',
        logo: '1673985074120-Untitled design (3).png',
        industry_type: 'Retail',
        business_model: 'B2B',
        funding_round: 'Pre-Seed',
        funding_type: 'SAFE',
        min_amount: '0',
        max_amount: '100000',
        valuation: '1500000',
        funding_status: 'Open',
        current_stage: 'ONBOARDING',
        can_proceed: true,
        next_stage: 'STAGE 1',
        last_interaction_at: '2023-01-17T19:51:21.231Z',
        stage_status: 'Approved',
        company_summary: 'Digital Gifting. ',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2023-01-17T19:51:14.344Z',
        updated_at: '2023-01-17T19:51:14.344Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      },
      {
        id: '92067eca-1a07-4fcf-9e6c-a69f255fad24',
        company_name: 'Norebase',
        website: 'http://norebase.com',
        year_founded: '2020',
        formatted_address: 'Lagos Nigeria',
        address: { city: 'Lagos', state: '', country: 'Nigeria', code: 'NG' },
        coordinates: { type: 'Point', coordinates: ['6.5243793', '3.3792057'] },
        geom: { type: 'Point', coordinates: [6.5243793, 3.3792057] },
        pitch_deck: '1673458993485-65204.pdf',
        logo: '1673458993489-W5bRzGiw_400x400.jpg',
        industry_type: 'B2B SaaS',
        business_model: 'B2B',
        funding_round: 'Seed',
        funding_type: 'SAFE',
        min_amount: '0',
        max_amount: '500000',
        valuation: '1500000',
        funding_status: 'Closed',
        current_stage: 'ONBOARDING',
        can_proceed: true,
        next_stage: 'STAGE 1',
        last_interaction_at: '2023-01-11T17:43:39.528Z',
        stage_status: 'Approved',
        company_summary: "The easiest way to start, scale and operate your business\r\nWhether you're registering a new business in Nigeria, trying to protect your company's intellectual property in Kenya, or expanding to the U.S, we've got you covered.",
        rejection_reason: null,
        is_target_company: false,
        created_at: '2023-01-11T17:43:13.634Z',
        updated_at: '2023-01-11T17:43:13.634Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      },
      {
        id: '9ffbc3ed-ba2e-4ac8-86fe-9c537b82b356',
        company_name: 'Moneda Investments',
        website: 'https://monedainvest.com',
        year_founded: '2018',
        formatted_address: 'Lagos Nigeria',
        address: { city: 'Lagos', state: '', country: 'Nigeria', code: 'NG' },
        coordinates: { type: 'Point', coordinates: ['6.5243793', '3.3792057'] },
        geom: { type: 'Point', coordinates: [6.5243793, 3.3792057] },
        pitch_deck: '1672826801099-Moneda Pitch Deck - BII.pdf',
        logo: '1672826801102-cropped-Image_20220402_082703-removebg-1-1536x320-1.png',
        industry_type: 'Finance',
        business_model: 'B2B',
        funding_round: 'Seed',
        funding_type: 'Credit',
        min_amount: '0',
        max_amount: '200000',
        valuation: '1500000',
        funding_status: 'Open',
        current_stage: 'ONBOARDING',
        can_proceed: true,
        next_stage: 'STAGE 1',
        last_interaction_at: '2023-01-04T10:07:25.529Z',
        stage_status: 'Approved',
        company_summary: 'Alternative Credit and Execution Support for African Energy',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2023-01-04T10:06:41.366Z',
        updated_at: '2023-01-04T10:06:41.366Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      },
      {
        id: 'd1b31e69-2c58-48cf-a5ff-cd1c21802a1f',
        company_name: 'ToniCo',
        website: 'https://kinfolk.vc',
        year_founded: '2021',
        formatted_address: 'Lagos Nigeria',
        address: { city: 'Lagos', state: '', country: 'Nigeria', code: 'NG' },
        coordinates: { type: 'Point', coordinates: ['6.5243793', '3.3792057'] },
        geom: { type: 'Point', coordinates: [6.5243793, 3.3792057] },
        pitch_deck: '1669144946346-Kinfolk Venture Capital Snapshot Prepared for Scott Lamond—Aqueduct.pdf',
        logo: '1669144946350-New Icon.png',
        industry_type: 'Fitness',
        business_model: 'B2B2C',
        funding_round: 'Seed',
        funding_type: 'SAFE',
        min_amount: '0',
        max_amount: '1500000',
        valuation: '1500000',
        funding_status: 'Open',
        current_stage: 'ONBOARDING',
        can_proceed: true,
        next_stage: 'STAGE 1',
        last_interaction_at: '2023-05-22T12:44:52.913Z',
        stage_status: 'Approved',
        company_summary: 'Testing for Chris Skinner',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2022-11-22T19:22:26.712Z',
        updated_at: '2022-11-22T19:22:26.712Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      },
      {
        id: '4661becc-0fcb-44c8-a6ba-d14369841cad',
        company_name: 'LeatherBack',
        website: 'https://leatherback.co/',
        year_founded: '2021',
        formatted_address: 'London United Kingdom',
        address: { city: 'London', state: 'Greater London', country: 'United Kingdom', code: 'GB' },
        coordinates: { type: 'Point', coordinates: ['51.5072178', '-0.1275862'] },
        geom: { type: 'Point', coordinates: [51.5072178, -0.1275862] },
        pitch_deck: '1664913757243-Profile.pdf',
        logo: '1664913757243-udacity.png',
        industry_type: 'Finance',
        business_model: 'B2B2C',
        funding_round: 'Series A',
        funding_type: 'Priced Round',
        min_amount: '0',
        max_amount: '1230000',
        valuation: '1500000',
        funding_status: 'Open',
        current_stage: 'ONBOARDING',
        can_proceed: true,
        next_stage: 'STAGE 1',
        last_interaction_at: '2022-10-18T19:50:02.447Z',
        stage_status: 'Approved',
        company_summary: 'Simplifying Global Trade for individuals and businesses across the globe. Leatherback moves your money from one continent to another with ease.',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2022-10-04T20:02:37.320Z',
        updated_at: '2022-10-04T20:02:37.320Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      },
      {
        id: 'f57e2e70-22f5-4740-8187-d35ebf7aed5c',
        company_name: 'Chukwu.Co',
        website: 'https://chukwu.co',
        year_founded: '2022',
        formatted_address: 'Lagos Nigeria',
        address: { city: 'Lagos', state: '', country: 'Nigeria', code: 'NG' },
        coordinates: { type: 'Point', coordinates: ['6.5243793', '3.3792057'] },
        geom: { type: 'Point', coordinates: [6.5243793, 3.3792057] },
        pitch_deck: '1664913316602-pitchdeck.pdf',
        logo: '1664913316605-logo.png',
        industry_type: 'B2B SaaS',
        business_model: 'B2B',
        funding_round: 'Series A',
        funding_type: 'SAFE',
        min_amount: '0',
        max_amount: '660000',
        valuation: '1500000',
        funding_status: 'Open',
        current_stage: 'STAGE 6',
        can_proceed: true,
        next_stage: 'STAGE 7',
        last_interaction_at: '2023-01-26T09:40:30.415Z',
        stage_status: 'Approved',
        company_summary: 'We basically test softwares',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2022-10-04T19:55:16.682Z',
        updated_at: '2023-01-26T09:40:01.680Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      },
      {
        id: '6c0e440c-53fc-48ab-900d-efc956f74229',
        company_name: 'ToniCo',
        website: 'https://kinfolk.vc',
        year_founded: '2021',
        formatted_address: 'Lagos Nigeria',
        address: { city: 'Lagos', state: '', country: 'Nigeria', code: 'NG' },
        coordinates: { type: 'Point', coordinates: ['6.5243793', '3.3792057'] },
        geom: { type: 'Point', coordinates: [6.5243793, 3.3792057] },
        pitch_deck: '1661633239823-Kinfolk Venture Capital Snapshot.pdf',
        logo: '1661633239827-KinfolkLogoArtboard-w.jpg',
        industry_type: 'Finance',
        business_model: 'B2B',
        funding_round: 'Pre-Seed',
        funding_type: 'SAFE',
        min_amount: '0',
        max_amount: '1500000',
        valuation: '35000000',
        funding_status: 'Open',
        current_stage: 'ONBOARDING',
        can_proceed: true,
        next_stage: 'STAGE 1',
        last_interaction_at: '2022-08-27T20:50:01.137Z',
        stage_status: 'Approved',
        company_summary: 'This is Kinfolk',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2022-08-27T20:47:20.040Z',
        updated_at: '2022-08-27T20:47:20.040Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      },
      {
        id: 'c1df0f69-923b-4670-a778-de2f7808495f',
        company_name: 'Mecrank',
        website: 'https://mecrank.com',
        year_founded: '2022',
        formatted_address: 'Warri Nigeria',
        address: { city: 'Warri', state: '', country: 'Nigeria', code: 'NG' },
        coordinates: { type: 'Point', coordinates: ['5.549430399999999', '5.7668515'] },
        geom: { type: 'Point', coordinates: [5.5494304, 5.7668515] },
        pitch_deck: '1661411705228-pitchdeck.pdf',
        logo: '1661411705231-1f3a7@2x.png',
        industry_type: 'Developer Tools',
        business_model: 'B2C',
        funding_round: 'Seed',
        funding_type: 'SAFE',
        min_amount: '0',
        max_amount: '700000',
        valuation: '1500000',
        funding_status: 'Open',
        current_stage: 'STAGE 7',
        can_proceed: true,
        next_stage: 'STAGE 8',
        last_interaction_at: '2022-08-26T00:51:46.896Z',
        stage_status: 'Approved',
        company_summary: 'We provide a sandbox environment for developers test and sharpen their skills',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2022-08-25T07:15:05.330Z',
        updated_at: '2022-08-26T00:34:25.317Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      },
      {
        id: '6c4635d3-f846-43df-a33d-65b354bc74a0',
        company_name: 'Oppzo',
        website: 'https://www.oppzo.com/',
        year_founded: '2021',
        formatted_address: 'Miami United States',
        address: { city: 'Miami', state: 'Miami-Dade County', country: 'United States', code: 'US' },
        coordinates: { type: 'Point', coordinates: ['25.7616798', '-80.1917902'] },
        geom: { type: 'Point', coordinates: [25.7616798, -80.1917902] },
        pitch_deck: '1658330648978-OppZo Investor Deck Seed+ - March 2022.pdf',
        logo: '1658330648982-OppZo.png',
        industry_type: 'Finance',
        business_model: 'B2B',
        funding_round: 'Seed',
        funding_type: 'SAFE',
        min_amount: '0',
        max_amount: '1500000',
        valuation: '10000000',
        funding_status: 'Open',
        current_stage: 'ONBOARDING',
        can_proceed: true,
        next_stage: 'STAGE 1',
        last_interaction_at: '2022-07-20T15:24:58.337Z',
        stage_status: 'Approved',
        company_summary: 'Provider of business loans to companies focused on federal, state and local governments.',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2022-07-20T15:24:09.151Z',
        updated_at: '2022-07-20T15:24:09.151Z',
        deal_lead: { id: '7984634c-c182-4d26-8a99-121e9102347b', first_name: 'Andrew', last_name: 'Hallowell', email: 'ahallowell@arcadiafunds.com', profile_image: null, nickname: 'andrew', is_super_admin: false, created_at: '2022-07-19T08:28:09.088Z', updated_at: '2022-07-19T08:28:09.088Z' }
      },
      {
        id: '1d968a4d-485e-4dcf-8f14-bcadeb1d8438',
        company_name: 'Iloyamar capital',
        website: 'https://iloyamar@capital.com',
        year_founded: '2018',
        formatted_address: 'Lagos Nigeria',
        address: { city: 'Lagos', state: '', country: 'Nigeria', code: 'NG' },
        coordinates: { type: 'Point', coordinates: ['6.5243793', '3.3792057'] },
        geom: { type: 'Point', coordinates: [6.5243793, 3.3792057] },
        pitch_deck: '1655455299870-Week 3_Mary Ayemere _User flow.pdf',
        logo: '1655455299870-untitled - Home@2x.png',
        industry_type: 'Agriculture',
        business_model: 'B2B',
        funding_round: 'Pre-Seed',
        funding_type: 'SAFE',
        min_amount: '0',
        max_amount: '300000',
        valuation: '1500000',
        funding_status: 'Open',
        current_stage: 'STAGE 7',
        can_proceed: true,
        next_stage: 'STAGE 8',
        last_interaction_at: '2022-06-17T13:51:34.800Z',
        stage_status: 'Approved',
        company_summary: 'Chop life',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2022-06-17T08:41:39.949Z',
        updated_at: '2022-06-17T13:50:46.265Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      },
      {
        id: 'ddb2bc91-91c7-4688-aa8f-7fb1ebeea826',
        company_name: 'Chukwu Co.',
        website: 'https://www.chukwu.co',
        year_founded: '2022',
        formatted_address: 'Manchester United States',
        address: { city: 'Manchester', state: 'Coffee County', country: 'United States', code: 'US' },
        coordinates: { type: 'Point', coordinates: ['35.4817431', '-86.0885993'] },
        geom: { type: 'Point', coordinates: [35.4817431, -86.0885993] },
        pitch_deck: '1655454786476-pitchdeck.pdf',
        logo: '1655454786479-1f3a7@2x.png',
        industry_type: 'Adtech',
        business_model: 'B2C',
        funding_round: 'Series A',
        funding_type: 'Priced Round',
        min_amount: '0',
        max_amount: '700000',
        valuation: '1500000',
        funding_status: 'Open',
        current_stage: 'STAGE 7',
        can_proceed: true,
        next_stage: 'STAGE 8',
        last_interaction_at: '2022-06-17T13:35:29.461Z',
        stage_status: 'Approved',
        company_summary: 'We deal in all things tech',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2022-06-17T08:33:06.555Z',
        updated_at: '2022-06-17T13:34:52.605Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      },
      {
        id: 'd761255e-6768-4d08-aa2b-4486512734e1',
        company_name: 'Kenney Inc',
        website: 'https://kenney.com',
        year_founded: '2020',
        formatted_address: 'Lagos Nigeria',
        address: { city: 'Lagos', state: '', country: 'Nigeria', code: 'NG' },
        coordinates: { type: 'Point', coordinates: ['6.5243793', '3.3792057'] },
        geom: { type: 'Point', coordinates: [6.5243793, 3.3792057] },
        pitch_deck: '1653989141200-Kinfolk newpitchdeck.pdf',
        logo: '1653989141204-aplelogo2.png',
        industry_type: 'Construction',
        business_model: 'B2B',
        funding_round: 'Pre-Seed',
        funding_type: 'SAFE',
        min_amount: '0',
        max_amount: '200000',
        valuation: '1500000',
        funding_status: 'Closed',
        current_stage: 'STAGE 7',
        can_proceed: true,
        next_stage: 'STAGE 8',
        last_interaction_at: '2022-06-11T16:46:42.478Z',
        stage_status: 'Approved',
        company_summary: 'The Fundraising Process for Africa’s Next-Gen Startups—Fully Digitized',
        rejection_reason: null,
        is_target_company: false,
        created_at: '2022-05-31T09:25:41.291Z',
        updated_at: '2022-06-11T16:42:15.980Z',
        deal_lead: { id: 'dcab72c7-d582-42c5-8843-19e8aee5ca9c', first_name: 'Toni', last_name: 'Campbell', email: 'toni@kinfolk.vc', profile_image: 'toni-1647522540394.png', nickname: 'toni', is_super_admin: false, created_at: '2022-05-31T08:59:31.469Z', updated_at: '2022-05-31T12:32:02.456Z' }
      }
    ])

    const fetchPortfolioCompanies = async () => {
      try {
        const response = await CompanyService.fetchAllPortfolioCompanies()
        if (response.status === 200 && response.data.status === 'success') {
          allCompanies.value = response.data.data
          store.dispatch('setPortfolioCompanies', response.data.data)
        }
      } catch (error) {
        Helpers.processError(error, store, router)
      }
    }

    const getDate = (created) => {
      const date = new Date(created.split('T')[0])
      return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date)
    }

    const editPortfolio = (index) => {
    }

    const getChecked = () => {
      if (document.querySelector('#selectAll').checked === true) {
        // document.querySelector('#select').checked = false
      }
    }

    const viewCompanyPortfolio = (id) => {
      router.push({ name: 'Portfolio Info', params: { id: id } })
    }

    fetchPortfolioCompanies()
    return {
      companies,
      fetchPortfolioCompanies,
      allCompanies,
      Helpers,
      getDate,
      editPortfolio,
      getChecked,
      portfolioData,
      viewCompanyPortfolio
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
