<template>
  <main class="font-Rubik">
    <h2 class="text-[20px] leading-[23.7px] mb-[32px]">MARKET AND UNIT ECONONICS</h2>
    <div class="instructions text-greyText mb-[30px]">
      <p class="text-[15px] leading-[17.78px] mb-[8px]">Instructions:</p>
      <p class="text-[13px] leading-[20px]">Kindly read the diligence information provided
         by the individual company to the left in full.
          Afterwards, answer the below questions,
           referencing the diligence information when needed.
            Please leave helpful comments and feedback
             to supplement your scores.
             </p>
    </div>
    <div class="mt-20" v-if="!dataloaded">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(241, 242, 243); display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(26.666666666666668,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#000000">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.3s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,26.666666666666668)">
          <rect x="-20" y="-20" width="40" height="40" fill="#636363">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.2s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(26.666666666666668,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#e1e1e1">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="0s"></animateTransform>
          </rect>
        </g>
        <g transform="translate(73.33333333333333,73.33333333333333)">
          <rect x="-20" y="-20" width="40" height="40" fill="#545454">
            <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.1s"></animateTransform>
          </rect>
        </g>
      </svg>
    </div>
    <div v-else id="riskReview" class="activeArea">

      <div class="qualityOfFinancials grid grid-rows-7 mb-[calc(40px-12px)]">
        <h4 class="font-medium text-[14px] leading-[20px] mb-[16px]">Has the company communicated a clear, high quality solution?</h4>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Quality of Financials/KPI Reporting & Forecasting"
            id="no-score"
            value="0"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Quality of Financials/KPI Reporting & Forecasting') == '0'"
            @change="setRatings($event, 'qualityOfFinancials_KPIReporting_Forecasting')"
            v-model="qualityOfFinancials_KPIReporting_Forecasting"
          >
          <label for="no-score" class="text-[13px] leading-[20px] flex flex-nowrap">
            <!-- <span class="mx-[15px]">0 -</span> -->
            <span class="ml-[45px] max-w-[80%]">No score Given</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Quality of Financials/KPI Reporting & Forecasting"
            id="extremely-unclear"
            value="1" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Quality of Financials/KPI Reporting & Forecasting') == '1'"
            @change="setRatings($event, 'qualityOfFinancials_KPIReporting_Forecasting')"
            v-model="qualityOfFinancials_KPIReporting_Forecasting"
          >
          <label for="extremely-unclear" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">1 -</span>
            <span class="max-w-[80%]">The team does not have functioning prototype or product.</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Quality of Financials/KPI Reporting & Forecasting"
            id="somewhat-unclear"
            value="2" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Quality of Financials/KPI Reporting & Forecasting') == '2'"
            @change="setRatings($event, 'qualityOfFinancials_KPIReporting_Forecasting')"
            v-model="qualityOfFinancials_KPIReporting_Forecasting"
          >
          <label for="somewhat-unclear" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">2 -</span>
            <span class="max-w-[80%]">Somewhat Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Quality of Financials/KPI Reporting & Forecasting"
            id="clear"
            value="3" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Quality of Financials/KPI Reporting & Forecasting') == '3'"
            @change="setRatings($event, 'qualityOfFinancials_KPIReporting_Forecasting')"
            v-model="qualityOfFinancials_KPIReporting_Forecasting"
          >
          <label for="clear" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">3 -</span>
            <span class="max-w-[80%]">Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Quality of Financials/KPI Reporting & Forecasting"
            id="very-clear"
            value="4"
            class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Quality of Financials/KPI Reporting & Forecasting') == '4'"
            @change="setRatings($event, 'qualityOfFinancials_KPIReporting_Forecasting')"
            v-model="qualityOfFinancials_KPIReporting_Forecasting"
          >
          <label for="very-clear" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">4 -</span>
            <span class="max-w-[80%]">Somewhat Strong Quality</span>
          </label>
        </div>
        <div class="flex items-start mb-[12px]">
          <input
            type="radio"
            name="Quality of Financials/KPI Reporting & Forecasting"
            id="extremely-clear"
            value="5" class="mt-[3px] focus:ring-1 focus:ring-black h-4 text-black border-gray-600"
            :checked="getRatingScore('Quality of Financials/KPI Reporting & Forecasting') == '5'"
            @change="setRatings($event, 'qualityOfFinancials_KPIReporting_Forecasting')"
            v-model="qualityOfFinancials_KPIReporting_Forecasting"
          >
          <label for="extremely-clear" class="text-[13px] leading-[20px] flex flex-nowrap">
            <span class="mx-[15px]">5 -</span>
            <span class="max-w-[80%]">The team has a functioning product with a great user experience</span>
          </label>
        </div>
        <div class="flex flex-col mb-[12px] pt-[24px]">
          <label for="comment-quality-financials" class="text-[13px] text-greyText leading-[15.41px] flex flex-col">
            <span class="flex items-center">
              <span class="text-[red]">*</span> &nbsp;
              Comments
            </span> <br/>
            <span class="font-light italic mt-[8px]">You must leave a comment to save your rating. Feedback must be at least 10 words and no longer than 85 words.</span>
          </label>
          <textarea
            id="comment-quality-financials"
            rows="3"
            cols="50"
            name="Quality of Financials/KPI Reporting & Forecasting"
            @blur="setComments($event, qualityOfFinancials_KPIReporting_Forecasting)"
            :disabled="commentStatus.qualityOfFinancials_KPIReporting_Forecasting &&  getRatingScore('Quality of Financials/KPI Reporting & Forecasting') == '0'"
            :class="[commentStatus.qualityOfFinancials_KPIReporting_Forecasting &&  getRatingScore('Quality of Financials/KPI Reporting & Forecasting') == '0' ? 'hover:cursor-not-allowed' : '']"
            placeholder="Leave feedback here..."
            required
            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
            :value="getRatingComment('Quality of Financials/KPI Reporting & Forecasting')"></textarea>
        </div>
      </div>

      <!-- <div class="flex justify-end pt-[28px]">
        <button class="font-medium text-greyText border border-greyText min-w-[99px] py-[12px] px-[16px] rounded-[4px] bg-[transparent] mr-[1rem]">Save Draft</button>
        <button class="font-medium text-white border border-originsGreen min-w-[143px] py-[12px] px-[16px] rounded-[4px] bg-originsGreen ml-[1rem]">Mark As Complete</button>
      </div> -->
    </div>
  </main>
</template>

<script>
/* eslint-disable camelcase */
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import RiskscoreService from '@/services/riskscore.service'
import Helpers from '@/utils/helpers'
import { useStore } from 'vuex'

export default {
  components: {
  },
  setup () {
    const dataloaded = ref(false)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const ratings = ref([])
    const qualityOfFinancials_KPIReporting_Forecasting = ref(null)
    const commentStatus = ref({
      qualityOfFinancials_KPIReporting_Forecasting: true
    })

    const getRaterScore = async () => {
      try {
        const id = route.params.id
        const response = await RiskscoreService.fetchRiskscoreByRater(id)
        if (response.status === 200 && response.data.status === 'success') {
          ratings.value = response.data.data
          dataloaded.value = true
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const getRatingScore = (type) => {
      const rate = ratings.value.filter(rating => rating.type === type)
      return rate[0].score
    }

    const getRatingComment = (type) => {
      const rate = ratings.value.filter(rating => rating.type === type)
      return rate[0].comment
    }

    getRaterScore()

    const setRatings = (event, name) => {
      if (event.target.value > 0) {
        commentStatus.value[name] = false
      } else {
        commentStatus.value[name] = true
      }
    }

    const setComments = async (event, name) => {
      const score = Number(name) === 0 ? getRatingScore(event.target.name) : Number(name)
      const data = {
        category: Helpers.getRiskCategory(event.target.name),
        type: event.target.name,
        comment: event.target.value,
        score: score
      }
      const id = route.params.id
      try {
        const response = await RiskscoreService.createRiskscore(id, data)
        if (response.status === 200 && response.data.status === 'success') {
          Helpers.processRiskSuccess(response, store, response.data.message)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    return {
      dataloaded,
      ratings,
      getRatingScore,
      setRatings,
      getRatingComment,
      setComments,
      commentStatus,
      qualityOfFinancials_KPIReporting_Forecasting
    }
  }
}
</script>

<style scoped>
textarea {
  max-width: 100%;
  border: solid 1px #E5E6E6;
  padding: 1rem;
  margin-top: 1rem;
  font-size: 14px;
  line-height: 16.59px;
  font-weight: 400;
  transition: all .15s ease;
}
</style>
