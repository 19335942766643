<template>
  <div class="flex h-screen overflow-hidden">
    <div :class="sidebarOpen ? 'block' : 'hidden'" class="fixed inset-0 bg-black opacity-50 md:hidden z-[2000]" @click="sidebarOpen = false"></div>

    <div class="flex flex-col justify-between w-[84px] h-full bg-black relative z-[3000]">
          <div class="flex flex-col items-center justify-center h-[max-content]">
            <div class="flex items-center justify-center w-full h-[136px] border-b-[0.4px] border-faintGrey">
              <img src="../../assets/KFLogo.svg" class=" h-8 w-8" />
            </div>
            <nav class="mt-12">
              <ul>
                <li class="relative">
                  <DashboardTooltip :text="`Dashboard`">
                    <router-link to="/dashboard" v-slot="{ href, navigate, isActive }">
                        <a
                          :href="href"
                          @click="navigate, sidebarOpen = false"
                          class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto rounded-[4px]"
                          :class="[
                            isActive
                              ? 'text-black bg-white'
                              : 'text-faintGrey bg-black',
                          ]"
                        >
                          <svg
                          class="h-6 w-6"
                          :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                          ]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
                        </svg>
                          <!-- <span class="pl-[10px]">Dashboard</span> -->
                        </a>
                    </router-link>
                  </DashboardTooltip>
                </li>
                <li class="relative">
                  <DashboardTooltip :text="`Dealflow`">
                    <router-link to="/dealflow" v-slot="{ href, navigate, isActive }">
                      <a
                        :href="href"
                        @click="navigate, sidebarOpen = false"
                        class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto rounded-[4px]"
                        :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                        ]"
                      >
                        <svg
                          class="h-6 w-6"
                          :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                          ]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2z"/>
                        </svg>
                        <!-- <span class="pl-[10px]">Deal Flow</span> -->
                      </a>
                    </router-link>
                  </DashboardTooltip>
                </li>
                <li class="relative">
                  <DashboardTooltip :text="`Collections`">
                    <router-link to="/collection" v-slot="{ href, navigate, isActive }">
                      <a
                        :href="href"
                        @click="navigate, sidebarOpen = false"
                        class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto rounded-[4px]"
                        :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                        ]"
                      >
                        <svg
                          class="h-6 w-6"
                          :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                          ]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"/>
                        </svg>
                        <!-- <span class="pl-[10px]">Collections</span> -->
                      </a>
                    </router-link>
                  </DashboardTooltip>
                </li>
                <li class="relative">
                  <DashboardTooltip :text="`Company Memos`">
                    <router-link to="/memo" v-slot="{ href, navigate, isActive }">
                      <a
                        :href="href"
                        @click="navigate, sidebarOpen = false"
                        class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto rounded-[4px]"
                        :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                        ]"
                      >
                        <svg
                          :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                          ]" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.0232 1.80889C11.6418 1.39643 11.9512 1.1902 12.2882 1.22358C12.6253 1.25695 12.8882 1.51984 13.4139 2.04562L15.9541 4.58575C16.4799 5.11153 16.7427 5.37442 16.7761 5.71147C16.8095 6.04851 16.6033 6.35786 16.1908 6.97654L14.6289 9.31937C14.0967 10.1178 13.8305 10.517 13.6181 10.943C13.4606 11.2589 13.3253 11.5854 13.2133 11.9202C13.0623 12.3717 12.9682 12.8421 12.78 13.783L12.5991 14.6877C12.5984 14.6909 12.5981 14.6925 12.5979 14.6937C12.463 15.3595 11.6917 15.6711 11.1321 15.2858C11.1311 15.2851 11.1298 15.2842 11.1271 15.2824V15.2824C11.1145 15.2736 11.1082 15.2693 11.1019 15.2649C7.83375 13.0001 4.99957 10.1659 2.73476 6.89776C2.73042 6.89151 2.72606 6.8852 2.71733 6.87259V6.87259C2.71548 6.86992 2.71455 6.86858 2.71387 6.8676C2.32856 6.30802 2.64014 5.53674 3.30601 5.40181C3.30718 5.40158 3.30878 5.40126 3.31197 5.40062L4.21666 5.21968C5.15758 5.0315 5.62803 4.93741 6.07953 4.78635C6.41427 4.67435 6.74078 4.53911 7.05666 4.38161C7.48274 4.16916 7.88193 3.90303 8.68032 3.37077L11.0232 1.80889Z" stroke="currentColor" stroke-width="1.89474"/>
                          <path d="M1.89505 16.105L6.1582 11.8418" stroke="currentColor" stroke-width="1.89474" stroke-linecap="round"/>
                        </svg>
                        <!-- <span class="pl-[10px]">Memos</span> -->
                      </a>
                    </router-link>
                  </DashboardTooltip>
                </li>
                <li class="relative">
                  <DashboardTooltip :text="`Company List`">
                    <router-link to="/companylist" v-slot="{ href, navigate, isActive }">
                      <a
                        :href="href"
                        @click="navigate, sidebarOpen = false"
                        class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto rounded-[4px]"
                        :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                        ]"
                      >
                        <svg
                          class="h-6 w-6"
                          :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                          ]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>
                        <!-- <span class="pl-[10px]">Company List</span> -->
                      </a>
                    </router-link>
                  </DashboardTooltip>
                </li>
                <li class="relative">
                  <DashboardTooltip :text="`Target Companies`">
                    <router-link to="/target-companies" v-slot="{ href, navigate, isActive }">
                      <a
                        :href="href"
                        @click="navigate, sidebarOpen = false"
                        class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto rounded-[4px]"
                        :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                        ]"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                          ]">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5" />
                        </svg>

                        <!-- <span class="pl-[10px]">Target Companies</span> -->
                      </a>
                    </router-link>
                  </DashboardTooltip>
                </li>
                <li class="relative">
                  <DashboardTooltip :text="`Portfolio`">
                    <router-link to="/portfolio" v-slot="{ href, navigate, isActive }">
                      <a
                        :href="href"
                        @click="navigate, sidebarOpen = false"
                        class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto rounded-[4px]"
                        :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                        ]"
                      >
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="stroke-current" :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                          ]">
                          <path d="M0 2.60078C0 1.60667 0.805887 0.800781 1.8 0.800781H6.3C6.53869 0.800781 6.76761 0.895602 6.9364 1.06439L8.47279 2.60078H16.2C17.1941 2.60078 18 3.40667 18 4.40078V13.4008C18 14.3949 17.1941 15.2008 16.2 15.2008H1.8C0.805888 15.2008 0 14.3949 0 13.4008V2.60078ZM5.92721 2.60078L1.8 2.60078V13.4008H16.2V4.40078H8.1C7.86131 4.40078 7.63239 4.30596 7.4636 4.13718L5.92721 2.60078Z" fill="black"/>
                        </svg>

                        <!-- <span class="pl-[10px]">Portfolio</span> -->
                      </a>
                    </router-link>
                  </DashboardTooltip>
                </li>
                <li class="relative">
                  <DashboardTooltip :text="`Settings`">
                    <router-link to="/settings" v-slot="{ href, navigate, isActive }">
                      <a
                        :href="href"
                        @click="navigate, sidebarOpen = false"
                        class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto rounded-[4px]"
                        :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                        ]"
                      >
                        <!-- <svg
                          class="h-6 w-6"
                          :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                          ]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg> -->
                        <svg class="h-[20px] w-[18px]"
                          :class="[
                          isActive
                            ? 'text-black bg-white'
                            : 'text-faintGrey bg-black',
                          ]" width="18" height="18" viewBox="0 0 18 18" fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.99844 1.8C7.01021 1.8 5.39844 3.41177 5.39844 5.4C5.39844 7.38822 7.01021 9 8.99844 9C10.9867 9 12.5984 7.38822 12.5984 5.4C12.5984 3.41177 10.9867 1.8 8.99844 1.8ZM3.59844 5.4C3.59844 2.41766 6.0161 0 8.99844 0C11.9808 0 14.3984 2.41766 14.3984 5.4C14.3984 8.38234 11.9808 10.8 8.99844 10.8C6.0161 10.8 3.59844 8.38234 3.59844 5.4ZM5.39844 14.4C3.90727 14.4 2.69844 15.6088 2.69844 17.1C2.69844 17.5971 2.29549 18 1.79844 18C1.30138 18 0.898438 17.5971 0.898438 17.1C0.898438 14.6147 2.91316 12.6 5.39844 12.6H12.5984C15.0837 12.6 17.0984 14.6147 17.0984 17.1C17.0984 17.5971 16.6955 18 16.1984 18C15.7014 18 15.2984 17.5971 15.2984 17.1C15.2984 15.6088 14.0896 14.4 12.5984 14.4H5.39844Z" fill="currentColor"/>
                        </svg>
                        <!-- <span class="pl-[10px]">Account Settings</span> -->
                      </a>
                    </router-link>
                  </DashboardTooltip>
                </li>
              </ul>
            </nav>
          </div>
          <div class="flex mb-10 w-full">
            <button
              class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto rounded-[4px] text-red-600 cursor-pointer"
              @click="confirmLogout = !confirmLogout"
            >
              <svg
                class="h-6 w-6 text-red-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              <!-- <span class="pl-[10px]">Logout</span> -->
            </button>
          </div>
        </div>
    <div :class="sidebarOpen ? 'translate-x-[84px] ease-out transition duration-300' : '-translate-x-full ease-in transition duration-300' " class="fixed inset-y-0 left-0 z-[2000] md:static md:translate-x-0 h-full md:hidden block">
      <div class="flex flex-row h-[inherit]">

        <!-- render -->
        <div class="h-full w-[215px] overflow-y-scroll border-r bg-white">
          <div v-if="route.path === '/dashboard'">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Home</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Dashboard</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div v-if="route.path.includes('/dealflow')">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Dealflow</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Dealflow</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div v-if="route.path.includes('/collection')">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Collections</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Collections</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div v-if="route.path === '/memo'">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Memos</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Company Memos</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div v-if="route.path === '/companylist'">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Companies</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Company List</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div v-if="route.path === '/target-companies'">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Target Companies</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Target Companies</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div v-if="route.path.includes('/portfolio')">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Portfolio</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Portfolio</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div v-if="route.path === '/settings'">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Profile</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Account Settings</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

    </div>
    <div class="flex flex-1 flex-col min-w-0 pl-10 overflow-auto">
      <Modal v-show="confirmLogout === true">
        <div class="bg-white w-[399px] rounded-[6px] p-6 relative">
          <span class="block w-[48px] h-[48px] mb-[12px] mx-auto">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28Z" fill="#FEE4E2"/>
              <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28 48C16.9543 48 8 39.0457 8 28H0C0 43.464 12.536 56 28 56V48ZM48 28C48 39.0457 39.0457 48 28 48V56C43.464 56 56 43.464 56 28H48ZM28 8C39.0457 8 48 16.9543 48 28H56C56 12.536 43.464 0 28 0V8ZM28 0C12.536 0 0 12.536 0 28H8C8 16.9543 16.9543 8 28 8V0Z" fill="#FEF3F2"/>
            </svg>
          </span>
          <div class="flex flex-col items-start justify-center">
            <h3 class="font-medium font-Rubik text-[18px] leading-[24px] text-black mb-[12px] text-center w-full">
              Logout?
            </h3>
            <p class="font-Rubik text-[13px] leading-[20px] text-greyText max-w-[95%] text-center w-full"> Are you sure you want to log out? </p>
            <div class="flex items-center justify-between w-full">
              <button @click="confirmLogout = false" class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] px-[45px] text-[13px] text-black leading-[15.41px] rounded-[6px] mr-[24px] border-[0.6px] border-lineGray max-w-[170px] w-[50%]">Cancel</button>
              <button @click="signout" class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] px-[45px] text-white text-[13px] leading-[15.41px] rounded-[6px] bg-originsRed max-w-[170px] w-[50%]">Logout</button>
            </div>
          </div>
        </div>
      </Modal>
      <header class="flex justify-between lg:justify-end pr-10 sticky top-0 pb-4 z-[999] bg-white">
        <div class="flex items-center lg:hidden pt-10">
          <button class="flex justify-center items-center rounded-full bg-coolGray-100 h-10 w-10 mr-6" @click="sidebarOpen = true">
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
        <div class="flex items-center pt-10">
          <button @click="showSearchComponent = !showSearchComponent" class="flex justify-center items-center rounded-full bg-coolGray-100 h-10 w-10 mr-6">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
          </button>
          <button class="flex justify-center items-center rounded-full bg-coolGray-100 h-10 w-10 mr-6">
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
          </button>
          <button class="flex justify-center items-center rounded-full bg-coolGray-100 h-10 w-10 mr-6">
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
          </button>
          <h1 class="text-[16px] leading-[18.96px] font-Rubik">Welcome </h1>
        </div>
        <!-- v-if="alert.status" -->
        <div
          v-if="alert.status"
          :class="[alert.type === 'error' ? 'bg-red-50 border-red-200': 'bg-green-50 border-green-200']"
          class="absolute rounded-md bg-red-50 p-2 top-10 z-[5000] border"
          @click="closeAlert">
          <div class="flex items-center justify-between relative">
            <div
              :class="[alert.type === 'error' ? 'bg-red-600': 'bg-green-600']"
              class="mx-auto flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full">
              <svg
                v-if="alert.type === 'error'"
                class="h-4 w-4"
                :class="[alert.type === 'error' ? 'text-red-50': 'text-green-50']"
                viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
              <svg v-else
                class="h-4 w-4"
                :class="[alert.type === 'error' ? 'text-red-50': 'text-green-50']"
                viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3 my-auto">
              <p
                :class="[alert.type === 'error' ? 'text-red-800': 'text-green-800']"
              class="text-sm font-medium text-red-800">
                {{ alert.message }}
              </p>
            </div>
            <button class="w-6 h-6 flex items-center justify-center border ml-3 absolute top-[-20px] right-[-16px] rounded-full"
              :class="[alert.type === 'error' ? 'bg-red-50 border-red-200': 'bg-green-50 border-green-200']"
            >
            <span class="block text-[10px]">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.293031 1.29308C0.480558 1.10561 0.734866 1.00029 1.00003 1.00029C1.26519 1.00029 1.5195 1.10561 1.70703 1.29308L6.00003 5.58608L10.293 1.29308C10.3853 1.19757 10.4956 1.12139 10.6176 1.06898C10.7396 1.01657 10.8709 0.988985 11.0036 0.987831C11.1364 0.986677 11.2681 1.01198 11.391 1.06226C11.5139 1.11254 11.6255 1.18679 11.7194 1.28069C11.8133 1.37458 11.8876 1.48623 11.9379 1.60913C11.9881 1.73202 12.0134 1.8637 12.0123 1.99648C12.0111 2.12926 11.9835 2.26048 11.9311 2.38249C11.8787 2.50449 11.8025 2.61483 11.707 2.70708L7.41403 7.00008L11.707 11.2931C11.8892 11.4817 11.99 11.7343 11.9877 11.9965C11.9854 12.2587 11.8803 12.5095 11.6948 12.6949C11.5094 12.8803 11.2586 12.9855 10.9964 12.9878C10.7342 12.99 10.4816 12.8892 10.293 12.7071L6.00003 8.41408L1.70703 12.7071C1.51843 12.8892 1.26583 12.99 1.00363 12.9878C0.741432 12.9855 0.49062 12.8803 0.305212 12.6949C0.119804 12.5095 0.0146347 12.2587 0.0123563 11.9965C0.0100779 11.7343 0.110873 11.4817 0.293031 11.2931L4.58603 7.00008L0.293031 2.70708C0.10556 2.51955 0.000244141 2.26525 0.000244141 2.00008C0.000244141 1.73492 0.10556 1.48061 0.293031 1.29308Z" fill="#002266"/>
              </svg>
            </span>
          </button>
          </div>
        </div>
      </header>
      <router-view></router-view>
    </div>
    <div class="relative">
      <Search
        v-if="showSearchComponent"
        @toggleShow="(showSearchComponent) => updateSearchVisibility(showSearchComponent)"
        :open="showSearchComponent"
      />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import Search from '../../components/Search.vue'
import Modal from '@/components/Modal.vue'
import DashboardTooltip from '@/components/DashboardTooltip.vue'

export default {
  components: {
    Search,
    Modal,
    DashboardTooltip
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      count: 0
    })
    const confirmLogout = ref(false)
    const signout = () => {
      confirmLogout.value = true
      if (confirmLogout.value === true) {
        store.dispatch('logoutUser')
        store.dispatch('resetApp')
        router.push('/sign-in')
        router.push('/sign-in')
      } else {
        confirmLogout.value = false
      }
    }
    const sidebarOpen = ref(false)
    const showSearchComponent = ref(false)
    const updateSearchVisibility = (toggleStatus) => {
      showSearchComponent.value = toggleStatus
    }
    const closeAlert = () => {
      store.dispatch('setAlert', {
        status: false,
        message: null,
        color: null,
        type: null
      })
    }
    return {
      user: computed(() => store.state.auth.user),
      error: computed(() => store.state.app.error),
      alert: computed(() => store.state.app.alert),
      ...toRefs(state),
      sidebarOpen,
      signout,
      showSearchComponent,
      updateSearchVisibility,
      route,
      confirmLogout,
      closeAlert
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
