<template>
  <div v-if="getPresentation().length == 0" class="mr-16 my-4">
    <div class="flex flex-col justify-center items-center">
      <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.6">
          <circle cx="34" cy="34" r="34" fill="#828282"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5625 33.9998L29.5309 42.9645L47.4603 25.0352" fill="#828282"/>
          <path d="M20.5625 33.9998L29.5309 42.9645L47.4603 25.0352" stroke="white" stroke-width="5.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
      </svg>
      <p class="w-[210px] mt-4 font-Rubik text-[13px] leading-[20px] text-greyText text-center">No product files are available yet</p>
    </div>
  </div>
  <div v-else class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
    <div
      v-for="(file, index) in getPresentation()"
      :key="index"
      class="relative h-[230px] bg-faintGrey rounded box-shadow">
      <div class="flex justify-center mt-0 h-full min-h-[170px] overflow-hidden">
        <a v-if="Helpers.getFileExtension(file.file_name)"
          class="cursor-pointer w-full relative"
          :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
          :download="`${file.file_name}`"
          target="_blank"
        >
          <FilePreview :filePath="`${s3Bucket}${file.file_name}`" />
          <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
        </a>
        <a v-else
          class="cursor-pointer w-full relative"
          :href="`${s3Bucket}${file.file_name}`"
          :download="`${file.file_name}`"
          target="_blank"
        >
          <FilePreview :filePath="`${s3Bucket}${file.file_name}`" />
          <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
        </a>
      </div>
      <div class="flex items-center h-[70px] w-full bg-white absolute bottom-0">
        <div class="ml-4">
          <h3 class="text-[14px] text-black leading-[16.59px] font-medium font-Rubik">{{ file.file_name }}</h3>
          <p class="text-[13px] text-greyText leading-[15.41px] font-Rubik">{{getDate(file.created_at)}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Helpers from '@/utils/helpers'
import FilePreview from '../../components/FilePreview.vue'
export default {
  setup () {
    const store = useStore()
    const files = computed(() => store.state.app.files)
    const getPresentation = () => {
      return files.value.filter(file => file.document_type === 'PRODUCT AND TECH')
    }
    const getDate = (created) => {
      const date = new Date(created.split('T')[0])
      return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date)
    }
    const s3Bucket = process.env.VUE_APP_ORIGINS_S3_BUCKET
    return {
      getPresentation,
      getDate,
      Helpers,
      s3Bucket
    }
  },
  components: { FilePreview }
}
</script>

<style>
.box-shadow {
  box-shadow: 0px 1px 4px 0px rgba(143, 143, 143, 0.16);
}

</style>
