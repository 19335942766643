import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class AnalyticsService {
  static fetchTotalActiveDeals () {
    return axios.get(`${url}/admin/analytics/active-deals`)
  }

  static fetchEquityValue () {
    return axios.get(`${url}/admin/analytics/equity-value`)
  }

  static fetchCapitalRequestsAndAverageDealSize () {
    return axios.get(`${url}/admin/analytics/capital-requests`)
  }

  static fetchPendingReviews () {
    return axios.get(`${url}/admin/analytics/pending-reviews`)
  }

  static fetchIndustryDistribution () {
    return axios.get(`${url}/admin/analytics/industry-distribution`)
  }
}

export default AnalyticsService
