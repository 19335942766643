<template>
  <div class="mr-10">
    <h1 class="main-title text-[32px] font-Playfair mb-10">Account Settings</h1>

    <div class="relative">
      <TabGroup>
        <div class="mt-[6px] sticky top-[150px] z-[100] bg-white">
          <TabList class="flex bg-white pt-[13px] px-3 pl-0">
              <Tab
                as="template"
                v-slot="{ selected }"
              >
                <button
                  :class="[
                    'py-2.5 font-Rubik text-[16px] leading-[18.96px] font-medium',
                    'focus:outline-none',
                    'border-b-2 mr-4',
                    selected
                      ? 'border-b-black hover:border-b-black text-black'
                      : 'border-b-[transparent] text-greyText',
                  ]"
                >
                  Personal Details
                </button>
              </Tab>
              <Tab
                as="template"
                v-slot="{ selected }"
              >
                <button
                  :class="[
                    'py-2.5 text-[16px] leading-[18.96px] font-Rubik font-medium',
                    'focus:outline-none',
                    'border-b-2 mx-4',
                    selected
                      ? 'border-b-black hover:border-b-black text-black'
                      : 'border-b-[transparent] text-greyText',
                  ]"
                >
                Share Profile
                </button>
              </Tab>
          </TabList>
        </div>

        <TabPanels class="bg-neutral-50 mt-10 min-h-[50vh] overflow-hidden">
          <TabPanel>
            <div class="details flex flex-col items-center justify-center h-[max-content] overflow-y-auto">
              <!-- <div class="upload h-[100px] w-[100px] rounded-full bg-faintGrey flex items-center justify-center relative cursor-pointer">
                <span class="camera">
                  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5912 6.75917C12.8647 6.48568 13.2356 6.33203 13.6224 6.33203H22.3724C22.7592 6.33203 23.1301 6.48568 23.4036 6.75917L25.8931 9.2487H29.6641C31.2749 9.2487 32.5807 10.5545 32.5807 12.1654V26.7487C32.5807 28.3595 31.2749 29.6654 29.6641 29.6654H6.33073C4.7199 29.6654 3.41406 28.3595 3.41406 26.7487V12.1654C3.41406 10.5545 4.7199 9.2487 6.33073 9.2487H10.1017L12.5912 6.75917ZM14.2265 9.2487L11.7369 11.7382C11.4634 12.0117 11.0925 12.1654 10.7057 12.1654L6.33073 12.1654V26.7487H29.6641V12.1654H25.2891C24.9023 12.1654 24.5314 12.0117 24.2579 11.7382L21.7683 9.2487H14.2265ZM17.9974 15.8112C16.3866 15.8112 15.0807 17.117 15.0807 18.7279C15.0807 20.3387 16.3866 21.6445 17.9974 21.6445C19.6082 21.6445 20.9141 20.3387 20.9141 18.7279C20.9141 17.117 19.6082 15.8112 17.9974 15.8112ZM12.1641 18.7279C12.1641 15.5062 14.7757 12.8945 17.9974 12.8945C21.2191 12.8945 23.8307 15.5062 23.8307 18.7279C23.8307 21.9495 21.2191 24.5612 17.9974 24.5612C14.7757 24.5612 12.1641 21.9495 12.1641 18.7279Z" fill="#828282"/>
                  </svg>
                </span>
              </div> -->
              <div class="flex flex-row items-center relative">
                <label for="logo-upload" class="h-[100px] w-[100px] rounded-full overflow-hidden bg-faintGrey flex items-center justify-center relative cursor-pointer">
                  <input
                    id="term-file-upload"
                    name="term-file-upload"
                    type="file" class="sr-only"
                  >
                  <span v-if="!data.profile_image_upload" class="camera">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5912 6.75917C12.8647 6.48568 13.2356 6.33203 13.6224 6.33203H22.3724C22.7592 6.33203 23.1301 6.48568 23.4036 6.75917L25.8931 9.2487H29.6641C31.2749 9.2487 32.5807 10.5545 32.5807 12.1654V26.7487C32.5807 28.3595 31.2749 29.6654 29.6641 29.6654H6.33073C4.7199 29.6654 3.41406 28.3595 3.41406 26.7487V12.1654C3.41406 10.5545 4.7199 9.2487 6.33073 9.2487H10.1017L12.5912 6.75917ZM14.2265 9.2487L11.7369 11.7382C11.4634 12.0117 11.0925 12.1654 10.7057 12.1654L6.33073 12.1654V26.7487H29.6641V12.1654H25.2891C24.9023 12.1654 24.5314 12.0117 24.2579 11.7382L21.7683 9.2487H14.2265ZM17.9974 15.8112C16.3866 15.8112 15.0807 17.117 15.0807 18.7279C15.0807 20.3387 16.3866 21.6445 17.9974 21.6445C19.6082 21.6445 20.9141 20.3387 20.9141 18.7279C20.9141 17.117 19.6082 15.8112 17.9974 15.8112ZM12.1641 18.7279C12.1641 15.5062 14.7757 12.8945 17.9974 12.8945C21.2191 12.8945 23.8307 15.5062 23.8307 18.7279C23.8307 21.9495 21.2191 24.5612 17.9974 24.5612C14.7757 24.5612 12.1641 21.9495 12.1641 18.7279Z" fill="#828282"/>
                    </svg>
                  </span>
                  <span v-else>
                    <img src="../assets/netflix-logo.png" alt="" class="w-full h-full">
                  </span>
                </label>
                <!-- Loader -->
                <!-- <svg v-if="loading.state && loading.data === 'profile_image_upload'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <circle cx="50" cy="50" fill="none" stroke="#000000" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                  </circle>
                </svg> -->
                <span v-if="data.profile_image_upload" @click="removefile('profile_image_upload')" class="absolute w-full inset-y-0 right-0 flex items-center justify-center">
                  <svg class="h-5 w-5 cursor-pointer text-originsRed bg-white" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </span>
              </div>
              <span class="font-Rubik font-normal text-greyText text-[12px] leading-[14.22px] mt-2">Upload Image</span>
              <div class="relative">
                <div class="grid grid-cols-2 gap-12 mt-[56px]">
                  <div class="left">
                    <div class="firstname relative w-full mb-6">
                      <label
                        class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                        for="firstName"
                      >
                        First Name
                      </label>
                      <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik bg-white placeholder-greyText text-black text-sm w-full border-faintGrey focus:outline-none focus:ring-0 rounded-[2px]"
                        :class="[
                          v$.firstName.$error ? 'border-red-400' : 'border-grey-300'
                        ]"
                        placeholder="Toni"
                        style="transition: 'all .15s ease'"
                        v-model="v$.firstName.$model"
                        @blur="v$.firstName.$touch()"
                      />
                      <div v-if="v$.firstName.$error">
                        <p
                          v-if="v$.firstName.required.$invalid"
                          class="text-red-400 text-[10px] font-light"
                        >Enter your First Name</p>
                      </div>
                    </div>
                    <div class="email relative w-full mb-6">
                      <label
                        class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                        for="email"
                      >
                        Email
                      </label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik bg-white placeholder-greyText text-black text-sm w-full border-faintGrey focus:outline-none focus:ring-0 rounded-[2px]"
                        :class="[
                          v$.email.$error ? 'border-red-400' : 'border-grey-300'
                        ]"
                        placeholder="toni.c@kinfolk.com"
                        style="transition: 'all .15s ease'"
                        v-model="v$.email.$model"
                        @blur="v$.email.$touch()"
                      />
                      <div v-if="v$.email.$error">
                        <p
                          v-if="v$.email.required.$invalid"
                          class="text-red-400 text-[10px] font-light"
                        >Email is required</p>
                        <p
                          v-if="v$.email.email.$invalid"
                          class="text-red-400 text-[10px] font-light"
                        >Email is invalid</p>
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <div class="lastname relative w-full mb-6">
                      <label
                        class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                        for="lastName"
                      >
                        Last Name
                      </label>
                      <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        :class="[
                          v$.lastName.$error ? 'border-red-400' : 'border-grey-300'
                        ]"
                        class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik bg-white placeholder-greyText text-black text-sm w-full border-faintGrey focus:outline-none focus:ring-0 rounded-[2px]"
                        placeholder="Campbell"
                        style="transition: 'all .15s ease'"
                        v-model="v$.lastName.$model"
                        @blur="v$.lastName.$touch()"
                      />
                      <div v-if="v$.lastName.$error">
                        <p
                          v-if="v$.lastName.required.$invalid"
                          class="text-red-400 text-[10px] font-light"
                        >Enter your Last Name</p>
                      </div>
                    </div>
                    <div class="phonenumber relative w-full mb-6">
                      <label
                        class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                        for="phoneNumber"
                      >
                        Phone Number
                      </label>
                      <input
                        id="phoneNumber"
                        name="phoneNumber"
                        type="number"
                        min="10"
                        class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik bg-white placeholder-greyText text-black text-sm w-full border-faintGrey focus:outline-none focus:ring-0 rounded-[2px]"
                        :class="[
                          v$.phoneNumber.$error ? 'border-red-400' : 'border-grey-300'
                        ]"
                        placeholder="08143777882"
                        style="transition: 'all .15s ease'"
                        v-model="v$.phoneNumber.$model"
                        @blur="v$.phoneNumber.$touch()"
                      />
                      <div v-if="v$.phoneNumber.$error">
                        <p
                          v-if="v$.phoneNumber.required.$invalid && v$.phoneNumber.minValueValue.$response === true"
                          class="text-red-400 text-[10px] font-light"
                        >Enter phone number</p>
                        <p
                          v-if="v$.phoneNumber.minValueValue.$response === false"
                          class="text-red-400 text-[10px] font-light"
                        >The minimum value allowed is 0</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="relative flex justify-center items-center w-full mb-7 mt-[48px]">
                  <button :disabled="v$.email.$model === '' || v$.email.$error" class="bg-black font-medium text-white text-[13px] font-Rubik px-[56px] py-3 ml-8 rounded-[4px] outline-none focus:outline-none disabled:opacity-50">
                    Save
                  </button>
                </div>
              </div>
              <hr class="w-full" />

              <div class="relative mb-10">
                <div class="grid grid-cols-2 gap-12 mt-[56px]">
                  <div class="left">
                    <div class="oldpassword relative w-full mb-6">
                      <label
                        class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                        for="oldPassword"
                      >
                        Old Password
                      </label>
                      <input
                        id="oldPassword"
                        name="oldPassword"
                        type="password"
                        class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik bg-white placeholder-greyText text-black text-sm w-full border-faintGrey focus:outline-none focus:ring-0 rounded-[2px]"
                        placeholder="Enter old password"
                        style="transition: 'all .15s ease'"
                      />
                    </div>
                    <div class="confirmpassword relative w-full mb-6">
                      <label
                        class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                        for="confirmPassword"
                      >
                        Confirm Password
                      </label>
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik bg-white placeholder-greyText text-black text-sm w-full border-faintGrey focus:outline-none focus:ring-0 rounded-[2px]"
                        placeholder="Confirm new password"
                        style="transition: 'all .15s ease'"
                      />
                    </div>
                  </div>
                  <div class="right">
                    <div class="newpassword relative w-full mb-6">
                      <label
                        class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                        for="newPassword"
                      >
                        New Password
                      </label>
                      <input
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik bg-white placeholder-greyText text-black text-sm w-full border-faintGrey focus:outline-none focus:ring-0 rounded-[2px]"
                        placeholder="Enter new password"
                        style="transition: 'all .15s ease'"
                      />
                    </div>
                  </div>
                </div>
                <div class="relative flex justify-center items-center w-full mb-7 mt-[48px]">
                  <button class="bg-black font-medium text-white text-[13px] font-Rubik px-[16.5px] py-3 ml-8 rounded-[4px] outline-none focus:outline-none">
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div class="relative flex flex-col items-center justify-center h-[max-content] overflow-y-auto">
              <div class="grid grid-cols-2 gap-12 mt-[40px]">
                <div class="left">
                  <div class="clientemail relative w-full mb-6">
                    <label
                      class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                      for="clientEmail"
                    >
                      Email
                    </label>
                    <input
                      id="clientEmail"
                      name="clientEmail"
                      type="text"
                      class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik bg-white placeholder-black text-black text-sm w-full border-faintGrey focus:outline-none focus:ring-0 rounded-[2px]"
                      placeholder="toni.c@kinfolk.com"
                      style="transition: 'all .15s ease'"
                    />
                  </div>
                </div>
                <div class="right">
                  <div class="sharedclient relative w-full mb-6">
                    <label
                      class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                      for="sharedClient"
                    >
                      Shared Client
                    </label>
                    <input
                      id="sharedClient"
                      name="sharedClient"
                      type="text"
                      class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik bg-white placeholder-black text-black text-sm w-full border-faintGrey focus:outline-none focus:ring-0 rounded-[2px]"
                      placeholder="Alphabet Inc"
                      style="transition: 'all .15s ease'"
                    />
                  </div>
                </div>
              </div>
              <div class="relative flex justify-center items-center w-full mb-7 mt-[48px]">
                <button class="bg-black font-medium text-white text-[13px] font-Rubik px-[56px] py-3 ml-8 rounded-[4px] outline-none focus:outline-none">
                  Share
                </button>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>

    </div>
  </div>
</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
// eslint-disable-next-line no-unused-vars
import { computed, reactive, ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, minValue } from '@vuelidate/validators'

export default {
  name: 'Account Settings',
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel
  },
  setup () {
    // const profile_image_upload = ref(null)
    const data = reactive({
      profile_image_upload: '',
      firstName: 'Toni',
      lastName: 'Campbell',
      email: '',
      phoneNumber: '08143777882',
      oldPassword: '',
      newPassword: ''
    })

    const rules = computed(() => ({
      firstName: { required },
      lastName: { required },
      email: { email, required },
      phoneNumber: { minValueValue: minValue(0), required },
      password: { minLength: minLength(8), required }
    }))

    const v$ = useVuelidate(rules, data)

    return {
      data,
      v$
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
