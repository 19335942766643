<template>
  <div class="w-56 mx-2 my-1">
    <Listbox v-model="selectedPerson">
      <div class="relative mt-1">
        <ListboxButton
          class="relative w-full cursor-pointer rounded-md bg-white py-3 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        >
          <span class="block truncate">{{selectedPerson}}</span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <!-- <ChevronUpDownIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            /> -->
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-[10]"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="(item, index) in $props.list"
              :key="item +index"
              :value="item"
              as="template"
            >
              <li
                :class="[
                  active ? 'bg-black text-white' : 'text-gray-900',
                  'relative cursor-pointer select-none py-2 px-4',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                  >{{ item }}</span
                >
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-white"
                >
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script >
import { ref, defineComponent, watch } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
export default defineComponent({
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption
  },
  props: {
    list: {
      type: Array
    },
    type: {
      type: String
    }
  },
  setup (props, { emit }) {
    const selectedPerson = ref('Select ')
    selectedPerson.value = selectedPerson.value + props.type.replace('_', ' ')
    watch(() => selectedPerson, (cur) => {
      emit('handleSelected', props.type, cur)
    }, { deep: true })
    return {
      selectedPerson
    }
  }
})

</script>
