<template>
  <main class="dealflow mt-8 flex flex-1 flex-col min-w-0 overflow-hidden">
    <div class="flex justify-between pr-10">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Dealflow</h1>
    </div>
    <div class="flex flex-col relative">
      <div class="w-full flex items-center justify-between">
        <div class="w-full flex items-center">
          <div class="flex items-center mr-8 cursor-pointer hover:bg-faintGrey p-2 rounded-md" @click="handleAll">
            <h3 class="text-black mr-2 font-Rubik text-[16px] leading-[18.96px]">All Deals</h3>
            <span class="flex items-center justify-center bg-black text-white w-[33px] h-[31px] rounded text-[13px] leading-[15.41px] font-medium font-Rubik">{{ getStatusCount('All')}}</span>
          </div>
          <div class="flex items-center mr-8 cursor-pointer hover:bg-faintGrey p-2 rounded-md" @click="handleStageStatus('Approved')">
            <h3 class="text-originsGreen mr-2 font-Rubik text-[16px] leading-[18.96px]">Approved Deals</h3>
            <span class="flex items-center justify-center bg-lightGreen text-originsGreen w-[33px] h-[31px] rounded text-[13px] leading-[15.41px] font-medium font-Rubik">{{ getStatusCount('Approved')}}</span>
          </div>
          <div class="flex items-center mr-8 cursor-pointer hover:bg-faintGrey p-2 rounded-md" @click="handleStageStatus('Processing')">
            <h3 class="text-originsYellow mr-2 font-Rubik text-[16px] leading-[18.96px]">Processing Deals</h3>
            <span class="flex items-center justify-center bg-lightYellow text-originsYellow w-[33px] h-[31px] rounded text-[13px] leading-[15.41px] font-medium font-Rubik">{{ getStatusCount('Processing')}}</span>
          </div>
          <div class="flex items-center cursor-pointer hover:bg-faintGrey p-2 rounded-md" @click="handleStageStatus('Rejected')">
            <h3 class="text-originsRed mr-2 font-Rubik text-[16px] leading-[18.96px]">Rejected Deals</h3>
            <span class="flex items-center justify-center bg-lightRed text-originsRed w-[33px] h-[31px] rounded text-[13px] leading-[15.41px] font-medium font-Rubik">{{ getStatusCount('Rejected')}}</span>
          </div>
        </div>
        <button @click="moreFilters = !moreFilters" class="text-white bg-black rounded-md py-2 px-3 mx-8 text-xs whitespace-nowrap">{{!moreFilters ? 'Show filters' : 'Hide filters'}}</button>
      </div>
      <template v-if="moreFilters">
        <div class="w-full flex flex-row flex-wrap items-center justify-start py-4 absolute top-14 left-0 bg-white z-[20] shadow-xl border border-faintGrey rounded-md">

          <div class="self-end">
            <Cascader :list="filterAll.countryListCascader" @handleCountry="handleSelectCountry" @handleCity="handleselectCity"/>
          </div>
          <SelectFilter type="industry_type" v-if="filterAll.industryTypeList.length" :list="filterAll.industryTypeList" @handleSelected="handleSelected"/>
          <!-- <SelectFilter type="state" v-if="filterAll.stateList.length" :list="filterAll.stateList" @handleSelected="handleSelected"/> -->
          <SelectFilter type="funding_type" v-if="filterAll.fundingTypeList.length" :list="filterAll.fundingTypeList" @handleSelected="handleSelected"/>
          <SelectFilter type="funding_round" v-if="filterAll.fundingRoundList.length" :list="filterAll.fundingRoundList" @handleSelected="handleSelected"/>
          <SelectFilter type="year_founded" v-if="filterAll.yearFoundedList.length" :list="filterAll.yearFoundedList" @handleSelected="handleSelected"/>
          <div class="self-end mx-2 my-1">
            <button class="text-white bg-black rounded-md px-6 h-10 text-sm whitespace-nowrap" @click="handleAll">Reset</button>
          </div>
        </div>
      </template>
    </div>
    <!-- <div class="flex flex-col items-end mt-8 pr-10">
      <span class="block mb-2 text-sm">Filter by:</span>
      <Listbox v-model="selectedStatus">
        <div class="relative mt-1 w-48 z-10">
          <ListboxButton
            class="relative w-full cursor-pointer rounded-lg bg-white py-0 px-3 text-left shadow-md border border-faintGrey focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
          >
            <span class="block truncate w-full">
              <div class="flex items-center justify-between cursor-pointer p-2 rounded-md">
                <h3 class="text-black mr-2 font-Rubik text-[15px] leading-0">{{selectedStatus}}</h3>
                <span class="flex items-center justify-center bg-black text-white w-[27px] h-[25px] rounded text-xs font-medium font-Rubik">{{ getStatusCount(selectedStatus)}}</span>
              </div>
            </span>
          </ListboxButton>

          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
            >
              <ListboxOption
                v-slot="{ active, selected }"
                v-for="(label, idx) in statusLabels"
                :key="`status-label${[idx]}`"
                :value="label"
                as="template"
              >
                <li
                  :class="[
                    active ? 'bg-black text-white' : 'text-gray-900',
                    'relative cursor-default select-none py-0 px-3',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-medium' : 'font-normal',
                      'block truncate',
                    ]"
                    >
                      <div class="flex items-center justify-between cursor-pointer p-2 rounded-md" @click="statusFilter = label">
                        <h3
                          :class="[
                            active ? 'text-white' : 'text-black',
                          ]"
                         class="text-black mr-2 font-Rubik text-sm">{{label}}</h3>
                        <span
                          :class="[
                            active ? 'bg-white text-black' : 'text-white bg-black',
                            'relative cursor-default select-none py-0 px-4',
                          ]"
                         class="flex items-center justify-center w-[27px] h-[25px] rounded text-xs font-medium font-Rubik">{{ getStatusCount(label)}}</span>
                      </div>
                    </span
                  >
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

    </div> -->
    <div class="mt-6 overflow-auto pb-3">
      <main class="inline-flex h-full pr-3 overflow-hidden shadow-inner">
        <div
          v-for="(stage, index) in currenAllList"
          :key="stage.type"
          :class="index > 0 ? 'ml-4': 'ml-0' "
          class="flex-shrink-0 flex flex-col w-[250px] bg-faintGrey rounded"
        >
          <div class="px-3 flex flex-shrink-0 pt-3 justify-between items-center">
            <h3 class="font-Rubik text-[15px] leading-[17.78px]">{{stage.type}}</h3>
            <!-- <span class="font-Rubik text-[15px] leading-[17.78px] text-greyText">{{getStage(stage, statusFilter).length}}</span> -->
          </div>
          <div class="flex-1 overflow-y-scroll px-3 mb-2">
            <ul class="mt-4 pt-1 pb-1">
              <li
                v-for="(company, index) in stage.list"
                :key="company.id"
                @click="viewCompany(company.id)"
                :class="index > 0 ? 'mt-3': 'mt-0' "
                class="relative bg-white rounded shadow pt-[14px] pb-4 px-[10px] cursor-pointer">
                <div :class="getCompanyStatus(company.stage_status)" class=" absolute top-0 right-0 flex justify-center items-center bg-originsGreen text-white rounded-bl-full w-[68px] h-[20px] text-[10px] leading-[11.85px]">
                  {{company.stage_status}}
                </div>
                <div class="flex items-center">
                  <div class="mr-[6px] w-9 h-9">
                    <img class="rounded-full max-h-full max-w-full" :src="`${s3Bucket}${company.logo}`"/>
                  </div>
                  <div class="">
                    <h4 class="text-[13px] leading-[15.41px] font-Rubik mb-[2px]">{{ company.company_name}}</h4>
                    <p class="text-[11px] text-greyText leading-[13.04px] font-Rubik">Submitted on {{getDate(company.created_at)}}</p>
                  </div>
                </div>
                <div class="flex items-center justify-between mt-4">
                  <span class="flex justify-center items-center w-14 h-6 bg-faintGrey text-greyText text-[12px] leading-[16px] font-Rubik">{{ company.business_model}}</span>
                  <span class="flex justify-center items-center w-[145px] h-6 bg-faintGrey text-greyText text-[12px] leading-[16px] font-Rubik">${{ Helpers.numberWithCommas(company.min_amount) }} to ${{ Helpers.numberWithCommas(company.max_amount) }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  </main>
</template>

<script>
import CompanyService from '@/services/company.service'
import CollectionService from '@/services/collection.service'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Helpers from '@/utils/helpers'
import useDealFlow from '@/utils/useDealFlow.js'
import stages from '@/utils/stages.js'
import SelectFilter from '@/components/SelectFilter.vue'
import Cascader from '@/components/Cascader.vue'
import {
// Listbox,
// // ListboxLabel,
// ListboxButton,
// ListboxOptions,
// ListboxOption
} from '@headlessui/vue'
export default {
  components: {
    // Listbox,
    // // ListboxLabel,
    // ListboxButton,
    // ListboxOptions,
    // ListboxOption,
    SelectFilter,
    Cascader
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    // const companyData = ref(dealflow)
    const companyData = computed(() => store.state.app.regularCompanies)
    const moreFilters = ref(false)

    const allstages = ref(stages)
    const statusLabels = [
      'All',
      'Approved',
      'Processing',
      'Rejected'
    ]
    const selectedStatus = ref(statusLabels[0])
    const statusFilter = ref('All')
    const sortCompanyByStatus = (status, stage) => {
      const sortByStage = companyData.value.filter(company => company.current_stage === stage)
      const sortedByStatus = sortByStage.filter((company) => company.stage_status === status)
      return sortedByStatus
    }
    const getStage = (stage) => {
      const status = statusFilter.value
      if (status === 'All') {
        return companyData.value.filter(company => company.current_stage === stage)
      } else {
        return sortCompanyByStatus(status, stage)
      }
    }
    const getCompanyStatus = (status) => {
      if (status === 'Approved') return 'bg-originsGreen'
      else if (status === 'Processing') return 'bg-originsYellow'
      else if (status === 'Rejected') return 'bg-originsRed'
    }

    const getStatusCount = (status) => {
      if (status === 'All') {
        return companyData.value.length
      } else return companyData.value.filter(company => company.stage_status === status).length
    }
    const s3Bucket = process.env.VUE_APP_ORIGINS_S3_BUCKET

    const startDrag = (event, item) => {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('itemID', item.id)
    }

    // const onDrop = (event, item) => {
    //   event.preventDefault()
    //   const itemID = parseInt(event.dataTransfer.getData('itemID'))
    //   const itemIndex = companyData.value.findIndex(company => company.id === itemID)
    //   const itemStage = companyData.value[itemIndex].stage
    //   const stageIndex = allstages.value.findIndex(stage => stage === itemStage)
    //   const newStage = allstages.value[stageIndex + 1]
    //   companyData.value[itemIndex].stage = newStage
    // }

    const onDrop = (event, stage) => {
      const itemID = parseInt(event.dataTransfer.getData('itemID'))
      const company = companyData.value.find((company) => company.id === itemID)
      company.stage = stage
    }

    const getDate = (created) => {
      const date = new Date(created.split('T')[0])
      return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date)
    }

    const viewCompany = (id) => {
      router.push({ name: 'Company Dealflow', params: { id: id } })
    }
    const filterAll = ref({
      countryList: [],
      stateList: [],
      fundingTypeList: [],
      fundingRoundList: [],
      industryTypeList: [],
      yearFoundedList: []
    })
    const allDataInfo = ref(allstages.value.map(item => {
      return {
        type: item,
        list: []
      }
    }))
    const currenAllList = ref([])
    const getAllCompany = async () => {
      try {
        const response = await CompanyService.fetchOnlyRegularCompanies()
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setRegularCompany', response.data.data)
          const {
            countryList,
            stateList,
            fundingTypeList,
            fundingRoundList,
            industryTypeList,
            yearFoundedList, countryListCascader
          } = useDealFlow(response.data.data)
          filterAll.value.countryList = Array.from(countryList)
          filterAll.value.stateList = Array.from(stateList)
          filterAll.value.fundingTypeList = Array.from(fundingTypeList)
          filterAll.value.fundingRoundList = Array.from(fundingRoundList)
          filterAll.value.industryTypeList = Array.from(industryTypeList)
          filterAll.value.yearFoundedList = Array.from(yearFoundedList)
          filterAll.value.countryListCascader = countryListCascader
          allDataInfo.value.forEach((item) => {
            const current = response.data.data.filter((v) => {
              return item.type === v.current_stage
            })
            item.list = current
          })
          currenAllList.value = JSON.parse(JSON.stringify(allDataInfo.value))
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }
    const fetchAllCollection = async () => {
      try {
        const response = await CollectionService.fetchCollection()
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setCollection', response.data.data)
          // collection.value = response.data.data
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }
    getAllCompany()
    fetchAllCollection()
    const handleSelected = async (type, value) => {
      currenAllList.value = JSON.parse(JSON.stringify(allDataInfo.value))

      if (type === 'state') {
        currenAllList.value.forEach((item) => {
          item.list = item.list.filter((child) => {
            return child.address[type] === value.value
          })
        })
      } else {
        currenAllList.value.forEach((item) => {
          item.list = item.list.filter((child) => {
            return child[type] === value.value
          })
        })
      }
    }
    const handleAll = () => {
      currenAllList.value = JSON.parse(JSON.stringify(allDataInfo.value))
    }
    const handleStageStatus = async (status) => {
      currenAllList.value = JSON.parse(JSON.stringify(allDataInfo.value))
      // filter stage status
      currenAllList.value.forEach((item) => {
        item.list = item.list.filter((child) => {
          return child.stage_status === status
        })
      })
    }
    const handleselectCity = async (city) => {
      currenAllList.value = JSON.parse(JSON.stringify(allDataInfo.value))
      // filter cities
      currenAllList.value.forEach((item) => {
        item.list = item.list.filter((child) => {
          return child.address.city === city
        })
      })
    }
    const handleSelectCountry = async (country) => {
      currenAllList.value = JSON.parse(JSON.stringify(allDataInfo.value))
      currenAllList.value.forEach((item) => {
        item.list = item.list.filter((child) => {
          return child.address.country === country
        })
      })
    // filter country
    }
    return {
      // selectListData,
      handleSelectCountry,
      handleselectCity,
      currenAllList,
      handleAll,
      allDataInfo,
      handleSelected,
      filterAll,
      getStage,
      allstages,
      getCompanyStatus,
      startDrag,
      onDrop,
      getStatusCount,
      viewCompany,
      getDate,
      s3Bucket,
      Helpers,
      statusFilter,
      statusLabels,
      selectedStatus,
      moreFilters,
      handleStageStatus
    }
  }
}
</script>
