import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class CollectionService {
  static createCollection (data) {
    return axios.post(`${url}/admin/collection/`, data)
  }

  static fetchCollection () {
    return axios.get(`${url}/admin/collection/`)
  }

  static fetchCompaniesNotInCollection (collectionId) {
    return axios.get(`${url}/admin/company/fetch?not-in-collection=${collectionId}`)
  }

  static fetchCollectionById (collectionId) {
    return axios.get(`${url}/admin/collection/${collectionId}`)
  }

  static addCompanytoCollection (data) {
    return axios.put(`${url}/admin/collection/company`, data)
  }

  static updateCollection (collectionId, data) {
    return axios.put(`${url}/admin/collection/${collectionId}`, data)
  }

  static deleteCompanyFromCollection (collectionId, companyId) {
    return axios.delete(`${url}/admin/collection/${collectionId}/company/${companyId}`)
  }

  static deleteCollection (collectionId) {
    return axios.delete(`${url}/admin/collection/${collectionId}`)
  }
}

export default CollectionService
