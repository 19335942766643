<template>
                <div id="balance-sheet-table" class="w-full border-[1px] border-faintGrey rounded-[4px] p-4">
                <table id="balance_sheet" class="w-full border-[red] overflow-hidden">
                  <thead class="border-b-[1px] border-faintGrey">
                    <tr>
                      <th class="w-[200px]" colspan="3"></th>
                      <th class="w-[150px] font-medium text-sm py-2 px-2 text-right" v-for="(year, index) in balanceSheetYears" :key="index" colspan="1">{{ year }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-sm mt-3">
                    <tr>
                      <td class="font-medium pl-2" colspan="6">
                        <button class="flex items-center py-3 font-medium" @click="toggleAssets = !toggleAssets">
                          ASSETS
                          <span class="ml-3" :class="[toggleAssets ? '' : 'transform -rotate-90']">
                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                            </svg>
                          </span>
                        </button>

                        <table class="c1 ml-4 w-[-webkit-fill-available] hidden" :class="toggleAssets ? 'show' : ''">
                          <thead>
                            <tr>
                              <th class="w-[200px]" colspan="3"></th>
                              <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-show="fixedAssetsTransactions.filter((fa) => fa.c1 === 'Fixed Assets').length > 0">
                              <td class="font-medium pl-2" colspan="6">
                                <button class="flex items-center parent-btn">
                                  Fixed Assets
                                  <span class="icon ml-3 transform -rotate-90">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                    </svg>
                                  </span>
                                </button>
                                <table class="c2 parent hidden ml-4 w-[-webkit-fill-available]">
                                  <thead>
                                    <tr>
                                      <th class="w-[200px]" colspan="3"></th>
                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                    </tr>
                                  </thead>
                                  <tbody class="ml-4">
                                    <tr v-show="fixedAssetsTransactions.filter((fa) => fa.c2 === 'Property, Plant & Equipment').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="flex items-center child-btn">
                                          Property, Plant & Equipment
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category child hidden ml-4 w-[-webkit-fill-available]">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(_, index) in balanceSheetYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="fixedAssetsTransactions.filter((fa) => fa.category === 'Computers').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Computers
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(fixedAssets, fAIndx) in fixedAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="fixedAssets.category === 'Computers'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{fixedAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ fixedAssets.year === year && fixedAssets.amount ? Helpers.numberWithCommas(fixedAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="fixedAssetsTransactions.filter((fa) => fa.category === 'Furniture').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Furniture
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(fixedAssets, fAIndx) in fixedAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="fixedAssets.category === 'Furniture'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{fixedAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ fixedAssets.year === year && fixedAssets.amount ? Helpers.numberWithCommas(fixedAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="fixedAssetsTransactions.filter((fa) => fa.category === 'Vehicles').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Vehicles
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(fixedAssets, fAIndx) in fixedAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="fixedAssets.category === 'Vehicles'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{fixedAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ fixedAssets.year === year && fixedAssets.amount ? Helpers.numberWithCommas(fixedAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr v-show="fixedAssetsTransactions.filter((fa) => fa.c1 === 'Fixed Assets').length > 0" class="total border-b-[1px] border-white bg-faintGrey">
                              <td class="font-medium pl-2" colspan="3">
                                Net Fixed Assets
                              </td>
                              <td v-for="(total, totalIndx) in calculateNetFixedAssets" :key="totalIndx" class="font-medium pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ total.year === 2021 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2022 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2023 && total.amount ? Helpers.numberWithCommas(total.amount) : '0.00' }}</td>
                            </tr>
                            <tr v-show="currentAssetsTransactions.filter((ca) => ca.c1 === 'Current Assets').length > 0">
                              <td class="font-medium pl-2" colspan="6">
                                <button class="flex items-center parent-btn">
                                  Current Assets
                                  <span class="icon ml-3 transform -rotate-90">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                    </svg>
                                  </span>
                                </button>
                                <table class="c2 parent hidden ml-4 w-[-webkit-fill-available]">
                                  <thead>
                                    <tr>
                                      <th class="w-[200px]" colspan="3"></th>
                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                    </tr>
                                  </thead>
                                  <tbody class="ml-4">
                                    <tr v-show="currentAssetsTransactions.filter((ca) => ca.c2 === 'Cash & Cash Equivalents').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="flex items-center child-btn">
                                          Cash & Cash Equivalents
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category child hidden ml-4 w-[-webkit-fill-available]">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in balanceSheetYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="currentAssetsTransactions.filter((fa) => fa.category === 'Checking / Current').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Checking/Current
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentAssets, fAIndx) in currentAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="currentAssets.category === 'Checking / Current'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentAssets.year === year && currentAssets.amount ? Helpers.numberWithCommas(currentAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="currentAssetsTransactions.filter((fa) => fa.category === 'Certificate of Deposit').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Certificate of Deposit
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentAssets, fAIndx) in currentAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="currentAssets.category === 'Certificate of Deposit'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentAssets.year === year && currentAssets.amount ? Helpers.numberWithCommas(currentAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="currentAssetsTransactions.filter((fa) => fa.category === 'Money Market Account').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Money Market Account
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentAssets, fAIndx) in currentAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="currentAssets.category === 'Money Market Account'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentAssets.year === year && currentAssets.amount ? Helpers.numberWithCommas(currentAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="currentAssetsTransactions.filter((fa) => fa.category === 'Commericial Paper').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Commericial Paper
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentAssets, fAIndx) in currentAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="currentAssets.category === 'Commericial Paper'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentAssets.year === year && currentAssets.amount ? Helpers.numberWithCommas(currentAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="currentAssetsTransactions.filter((fa) => fa.category === 'Savings').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Savings
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentAssets, fAIndx) in currentAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="currentAssets.category === 'Savings'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentAssets.year === year && currentAssets.amount ? Helpers.numberWithCommas(currentAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="currentAssetsTransactions.filter((fa) => fa.category === 'Brokerage Account').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Brokerage Account
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentAssets, fAIndx) in currentAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="currentAssets.category === 'Brokerage Account'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentAssets.year === year && currentAssets.amount ? Helpers.numberWithCommas(currentAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="currentAssetsTransactions.filter((ca) => ca.c2 === 'Accounts Receivable').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="flex items-center child-btn">
                                          Accounts Receivable
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category child hidden ml-4 w-[-webkit-fill-available]">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in balanceSheetYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="currentAssetsTransactions.filter((fa) => fa.category === 'Accounts Recieveable for Product X').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Accounts Recieveable for Product X
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentAssets, fAIndx) in currentAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="currentAssets.category === 'Accounts Recieveable for Product X'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentAssets.year === year && currentAssets.amount ? Helpers.numberWithCommas(currentAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="currentAssetsTransactions.filter((ca) => ca.c2 === 'Prepaid Expenses').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="flex items-center child-btn">
                                          Prepaid Expenses
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category child hidden ml-4 w-[-webkit-fill-available]">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in balanceSheetYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="currentAssetsTransactions.filter((fa) => fa.category === 'Prepaid Software').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Prepaid Software
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentAssets, fAIndx) in currentAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="currentAssets.category === 'Prepaid Software'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentAssets.year === year && currentAssets.amount ? Helpers.numberWithCommas(currentAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="currentAssetsTransactions.filter((ca) => ca.c2 === 'Other Assets').length > 0">
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="flex items-center child-btn">
                                          Other Assets
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category child hidden ml-4 w-[-webkit-fill-available]">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in balanceSheetYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="currentAssetsTransactions.filter((fa) => fa.category === 'Security Deposit').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Security Deposit
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentAssets, fAIndx) in currentAssetsTransactions" :key="fAIndx">
                                                    <tr v-if="currentAssets.category === 'Security Deposit'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentAssets.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentAssets.year === year && currentAssets.amount ? Helpers.numberWithCommas(currentAssets.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr v-show="currentAssetsTransactions.filter((ca) => ca.c1 === 'Current Assets').length > 0" class="total border-b-[1px] border-white bg-faintGrey">
                              <td class="font-medium pl-2" colspan="3">
                                Total Current Assets
                              </td>
                              <td v-for="(total, totalIndx) in calculateNetCurrentAssets" :key="totalIndx" class="font-medium pr-2 text-right w-[150px]" colspan="1">{{ total.year === 2021 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2022 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2023 && total.amount ? Helpers.numberWithCommas(total.amount) : '0.00' }}</td>
                            </tr>
                            <tr>
                              <td class="font-medium pl-2" colspan="6">
                                <button class="flex items-center parent-btn">
                                  Uncategorized Assets
                                  <span class="icon ml-3 transform -rotate-90">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                    </svg>
                                  </span>
                                </button>
                                <table class="c2 parent hidden ml-4 w-[-webkit-fill-available]">
                                  <thead>
                                    <tr>
                                      <th class="w-[200px]" colspan="3"></th>
                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                    </tr>
                                  </thead>
                                  <tbody class="ml-4">
                                    <tr>
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="flex items-center child-btn">
                                          Uncategorized Assets
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category child hidden ml-4 w-[-webkit-fill-available]">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in balanceSheetYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="uncategorizedAssetsTransactions.filter((fa) => fa.category === 'Uncategorized Assets').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Uncategorized Assets
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">Unknown</td>
                                                      <td v-for="(uncategorized, uncatIndx) in uncategorizedAssetsTransactions" :key="uncatIndx" class="font-normal pr-2 text-right w-[150px]" colspan="1">{{ uncategorized.year === 2021 && uncategorized.amount ? Helpers.numberWithCommas(uncategorized.amount) : uncategorized.year === 2022 && uncategorized.amount ? Helpers.numberWithCommas(uncategorized.amount) : uncategorized.year === 2023 && uncategorized.amount ? Helpers.numberWithCommas(uncategorized.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr class="total border-b-[1px] border-white bg-faintGrey">
                      <td class="font-medium pl-2" colspan="3">
                        Total Assets
                      </td>
                      <td v-for="(total, totalIndx) in calculateTotalAssets" :key="totalIndx" class="font-medium pr-2 text-right w-[150px]" colspan="1">{{ total.year === 2021 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2022 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2023 && total.amount ? Helpers.numberWithCommas(total.amount) : '0.00' }}</td>
                    </tr>
                    <tr>
                      <td class="font-medium pl-2" colspan="6">
                        <button class="flex items-center py-3 font-medium" @click="toggleLiabilities = !toggleLiabilities">
                          LIABILITIES
                          <span class="ml-3 transform" :class="[toggleLiabilities ? '' : 'transform -rotate-90']">
                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                            </svg>
                          </span>
                        </button>

                        <table class="c1 ml-4 w-[-webkit-fill-available] hidden" :class="toggleLiabilities ? 'show' : ''">
                          <tbody>
                            <tr v-show="currentLiabilitiesTransactions.filter((ca) => ca.c1 === 'Current Liabilities').length > 0">
                              <td class="font-medium pl-2" colspan="6">
                                <button class="flex items-center parent-btn">
                                  Current Liabilities
                                  <span class="icon ml-3 transform -rotate-90">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                    </svg>
                                  </span>
                                </button>
                                <table class="c2 parent hidden ml-4 w-[-webkit-fill-available]">
                                  <thead>
                                    <tr>
                                      <th class="w-[200px]" colspan="3"></th>
                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                    </tr>
                                  </thead>
                                  <tbody class="ml-4">
                                    <tr>
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="flex items-center child-btn">
                                          Credit Cards
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category child hidden ml-4 w-[-webkit-fill-available]">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in balanceSheetYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="currentLiabilitiesTransactions.filter((fa) => fa.category === 'USD Credit Card').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  USD Credit Card
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentLiability, fAIndx) in currentLiabilitiesTransactions" :key="fAIndx">
                                                    <tr v-if="currentLiability.category === 'USD Credit Card'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentLiability.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentLiability.year === year && currentLiability.amount ? Helpers.numberWithCommas(currentLiability.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="currentLiabilitiesTransactions.filter((fa) => fa.category === 'GBP Credit Card').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  GBP Credit Card
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentLiability, fAIndx) in currentLiabilitiesTransactions" :key="fAIndx">
                                                    <tr v-if="currentLiability.category === 'GBP Credit Card'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentLiability.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentLiability.year === year && currentLiability.amount ? Helpers.numberWithCommas(currentLiability.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="currentLiabilitiesTransactions.filter((fa) => fa.category === 'ZAR Credit Card').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  ZAR Credit Card
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentLiability, fAIndx) in currentLiabilitiesTransactions" :key="fAIndx">
                                                    <tr v-if="currentLiability.category === 'ZAR Credit Card'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentLiability.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentLiability.year === year && currentLiability.amount ? Helpers.numberWithCommas(currentLiability.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="flex items-center child-btn">
                                          Accounts Payable
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category child hidden ml-4 w-[-webkit-fill-available]">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in balanceSheetYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="currentLiabilitiesTransactions.filter((fa) => fa.category === 'Accounts Payable for Expense Y').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Accounts Payable for Expense Y
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentLiability, fAIndx) in currentLiabilitiesTransactions" :key="fAIndx">
                                                    <tr v-if="currentLiability.category === 'Accounts Payable for Expense Y'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentLiability.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentLiability.year === year && currentLiability.amount ? Helpers.numberWithCommas(currentLiability.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="font-medium pl-2" colspan="6">
                                        <button class="flex items-center child-btn">
                                          Accrued Expenses
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category child hidden ml-4 w-[-webkit-fill-available]">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in balanceSheetYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="currentLiabilitiesTransactions.filter((fa) => fa.category === 'Accrued Payroll').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Accrued Payroll
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(currentLiability, fAIndx) in currentLiabilitiesTransactions" :key="fAIndx">
                                                    <tr v-if="currentLiability.category === 'Accrued Payroll'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{currentLiability.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ currentLiability.year === year && currentLiability.amount ? Helpers.numberWithCommas(currentLiability.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr v-show="currentLiabilitiesTransactions.filter((ca) => ca.c1 === 'Long Term Liabilities').length > 0">
                              <td class="font-medium pl-2" colspan="6">
                                <button class="flex items-center parent-btn">
                                  Long Term Liabilities
                                  <span class="icon ml-3 transform -rotate-90">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                    </svg>
                                  </span>
                                </button>

                                <table class="c2 parent hidden ml-4 w-[-webkit-fill-available] ">
                                  <tbody class="ml-4">
                                    <tr>
                                      <td colspan="6">
                                        <button class="flex items-center child-btn">
                                          Notes Payable
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in balanceSheetYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td class="font-medium pl-2" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  36 Month Bank Loan
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(longTermLiability, cLIndx) in longTermLiabilitiesTransactions" :key="cLIndx">
                                                    <tr v-if="longTermLiability.category === '36 Month Bank Loan'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{longTermLiability.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ longTermLiability.year === year && longTermLiability.amount ? Helpers.numberWithCommas(longTermLiability.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td class="font-medium pl-2" colspan="6">
                                <button class="flex items-center parent-btn">
                                  Uncategorized Liabilities
                                  <span class="icon ml-3 transform -rotate-90">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                    </svg>
                                  </span>
                                </button>

                                <table class="c2 parent hidden ml-4 w-[-webkit-fill-available] ">
                                  <tbody class="ml-4">
                                    <tr>
                                      <td colspan="6">
                                        <button class="flex items-center child-btn">
                                          Uncategorized Liabilities
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category child hidden ml-4 w-[-webkit-fill-available] ">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="(year, index) in balanceSheetYears" :key="index" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td class="font-medium pl-2" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Uncategorized Liabilities
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>
                                                <table class="stakeholder nested-child hidden ml-4 w-[-webkit-fill-available] ">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">Unknown</td>
                                                      <td v-for="(uncategorized, uncaIndx) in uncategorizedLiabilitiesTransactions" :key="uncaIndx" class="font-normal pr-2 text-right w-[150px]" colspan="1">{{ uncategorized.year === 2021 && uncategorized.amount ? Helpers.numberWithCommas(uncategorized.amount) : uncategorized.year === 2022 && uncategorized.amount ? Helpers.numberWithCommas(uncategorized.amount) : uncategorized.year === 2023 && uncategorized.amount ? Helpers.numberWithCommas(uncategorized.amount) : 0.00 }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr class="total border-b-[1px] border-white bg-faintGrey">
                      <td class="font-medium pl-2" colspan="3">
                        Total Liabilities
                      </td>
                      <td v-for="(total, totalIndx) in calculateTotalLiabilities" :key="totalIndx" class="font-medium pr-2 text-right w-[150px]" colspan="1">{{ total.year === 2021 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2022 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2023 && total.amount ? Helpers.numberWithCommas(total.amount) : '0.00' }}</td>
                    </tr>
                    <tr v-show="equityTransactions.filter((fa) => fa.c1 === 'Equity').length > 0">
                      <td class="font-medium pl-2" colspan="6">
                        <button class="flex items-center py-3 font-medium" @click="toggleEquity = !toggleEquity">
                          EQUITY
                          <span class="ml-3" :class="[toggleEquity ? '' : 'transform -rotate-90']">
                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                            </svg>
                          </span>
                        </button>

                        <table class="c1 ml-4 w-[-webkit-fill-available] hidden" :class="toggleEquity ? 'show' : ''">
                          <thead>
                            <tr>
                              <th class="w-[200px]" colspan="3"></th>
                              <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="font-medium pl-2" colspan="6">
                                <button class="flex items-center parent-btn">
                                  Equity
                                  <span class="icon ml-3 transform -rotate-90">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                    </svg>
                                  </span>
                                </button>

                                <table class="c2 ml-4 w-[-webkit-fill-available] hidden parent">
                                  <tbody class="ml-4">
                                    <tr v-show="equityTransactions.filter((fa) => fa.c2 === 'Paid-In Capital').length > 0">
                                      <td colspan="6">
                                        <button class="flex items-center child-btn">
                                          Paid-In Capital
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category ml-4 w-[-webkit-fill-available] hidden child">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="equityTransactions.filter((fa) => fa.category === 'Investment - Convertible Note').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Investment - Convertible Note
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder ml-4 w-[-webkit-fill-available] hidden nested-child">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(equity, eqIndx) in equityTransactions" :key="eqIndx">
                                                    <tr v-if="equity.category === 'Investment - Convertible Note'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{equity.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ equity.year === year && equity.amount ? Helpers.numberWithCommas(equity.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="equityTransactions.filter((fa) => fa.category === 'Investment - Common Shares').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Investment - Common Shares
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder ml-4 w-[-webkit-fill-available] hidden nested-child">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(equity, eqIndx) in equityTransactions" :key="eqIndx">
                                                    <tr v-if="equity.category === 'Investment - Common Shares'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{equity.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ equity.year === year && equity.amount ? Helpers.numberWithCommas(equity.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="equityTransactions.filter((fa) => fa.category === 'Investment - Preferred Shares').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Investment - Preferred Shares
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder ml-4 w-[-webkit-fill-available] hidden nested-child">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(equity, eqIndx) in equityTransactions" :key="eqIndx">
                                                    <tr v-if="equity.category === 'Investment - Preferred Shares'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{equity.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ equity.year === year && equity.amount ? Helpers.numberWithCommas(equity.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="equityTransactions.filter((fa) => fa.category === 'Investment - SAFE Note').length > 0" class="border-b-[1px] border-faintGrey">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Investment - SAFE Note
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder ml-4 w-[-webkit-fill-available] hidden nested-child">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(equity, eqIndx) in equityTransactions" :key="eqIndx">
                                                    <tr v-if="equity.category === 'Investment - SAFE Note'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{equity.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ equity.year === year && equity.amount ? Helpers.numberWithCommas(equity.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="equityTransactions.filter((fa) => fa.c2 === 'Retained Earnings').length > 0">
                                      <td colspan="6">
                                        <button class="flex items-center child-btn">
                                          Retained Earnings
                                          <span class="icon ml-3 transform -rotate-90">
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                            </svg>
                                          </span>
                                        </button>
                                        <table class="category ml-4 w-[-webkit-fill-available] hidden child">
                                          <thead>
                                            <tr>
                                              <th class="w-[200px]" colspan="3"></th>
                                              <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-show="equityTransactions.filter((fa) => fa.category === 'Opening Balance Equity').length > 0">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Opening Balance Equity
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder ml-4 w-[-webkit-fill-available] hidden nested-child">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(retainedEarning, reIndx) in equityTransactions" :key="reIndx">
                                                    <tr v-if="retainedEarning.category === 'Opening Balance Equity'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{retainedEarning.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ retainedEarning.year === year && retainedEarning.amount ? Helpers.numberWithCommas(retainedEarning.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr v-show="equityTransactions.filter((fa) => fa.category === 'Retained Earnings').length > 0">
                                              <td class="font-normal" colspan="6">
                                                <button class="flex items-center nested-child-btn">
                                                  Retained Earnings
                                                  <span class="icon ml-3 transform -rotate-90">
                                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5.43431 5.43431L1.36569 1.36569C0.861713 0.861713 1.21865 0 1.93137 0L10.0686 0C10.7814 0 11.1383 0.861714 10.6343 1.36569L6.56569 5.43431C6.25327 5.74673 5.74673 5.74673 5.43431 5.43431Z" fill="black"/>
                                                    </svg>
                                                  </span>
                                                </button>

                                                <table class="stakeholder ml-4 w-[-webkit-fill-available] hidden nested-child">
                                                  <thead>
                                                    <tr>
                                                      <th class="w-[200px]" colspan="3"></th>
                                                      <th v-for="_ in balanceSheetYears" :key="_" class="w-[150px] font-normal" colspan="1"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-for="(retainedEarning, fAIndx) in equityTransactions" :key="fAIndx">
                                                    <tr v-if="retainedEarning.category === 'Retained Earnings'" class="border-b-[1px] border-faintGrey">
                                                      <td class="font-normal" colspan="3">{{retainedEarning.stakeholder}}</td>
                                                      <td v-for="(year, yrIndx) in balanceSheetYears" :key="yrIndx" class="font-normal pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ retainedEarning.year === year && retainedEarning.amount ? Helpers.numberWithCommas(retainedEarning.amount) : '0.00' }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr v-show="equityTransactions.filter((fa) => fa.category === 'Retained Earnings').length > 0" class="total border-b-[1px] border-faintGrey bg-faintGrey rounded-[6px]">
                                      <td class="font-medium pl-2" colspan="3">
                                        Total Retained Earnings
                                      </td>
                                      <td v-for="(total, totalIndx) in calculateRetainedEarnings" :key="totalIndx" class="font-medium pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ total.year === 2021 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2022 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2023 && total.amount ? Helpers.numberWithCommas(total.amount) : '0.00' }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr class="total border-b-[1px] border-white bg-faintGrey">
                      <td class="font-medium pl-2" colspan="3">
                        Total Equity
                      </td>
                      <td v-for="(total, totalIndx) in calculateTotalEquity" :key="totalIndx" class="font-medium pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ total.year === 2021 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2022 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2023 && total.amount ? Helpers.numberWithCommas(total.amount) : '0.00' }}</td>
                    </tr>
                    <tr class="total border-b-[1px] border-white bg-faintGrey">
                      <td class="font-medium pl-2" colspan="3">
                        Total Liabilities & Equity
                      </td>
                      <td v-for="(total, totalIndx) in totalLiabilitiesAndEquity" :key="totalIndx" class="font-medium pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ total.year === 2021 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2022 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2023 && total.amount ? Helpers.numberWithCommas(total.amount) : '0.00' }}</td>
                    </tr>
                    <tr class="total border-b-[1px] border-white bg-faintGrey">
                      <td class="font-medium pl-2" colspan="3">
                        Net Working Capital Balance
                      </td>
                      <td v-for="(total, totalIndx) in netWorkingCapital" :key="totalIndx" class="font-medium pr-2 text-right w-[150px]" :colspan="`${balanceSheetYears.length > 1 ? 1 : (balanceSheetYears.length + 1)}`">{{ total.year === 2021 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2022 && total.amount ? Helpers.numberWithCommas(total.amount) : total.year === 2023 && total.amount ? Helpers.numberWithCommas(total.amount) : '0.00' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import FinancialService from '@/services/financials.service'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Helpers from '../utils/helpers'

export default {
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const balanceSheetTransactions = ref(null)
    const balanceSheetYears = ref([])
    const consolidatedBalanceSheetTransactions = ref([])
    const toggleAssets = ref(false)
    const toggleLiabilities = ref(false)
    const toggleEquity = ref(false)
    const fixedAssetsTransactions = ref([])
    const currentAssetsTransactions = ref([])
    const currentLiabilitiesTransactions = ref([])
    const longTermLiabilitiesTransactions = ref([])
    const uncategorizedLiabilitiesTransactions = ref([])
    const uncategorizedAssetsTransactions = ref([])
    const equityTransactions = ref([])
    const balanceSheet = computed(() => store.state.app.balanceSheet)

    const fetchBalanceSheetByYear = async () => {
      const companyId = route.params.id
      const statementType = 'balance_sheet'
      const period = 'annual'
      try {
        const response = await FinancialService.getFinancialStatements(companyId, statementType, period)
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setBalanceSheet', response.data.data)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }
    fetchBalanceSheetByYear()

    const selectBalanceSheetYear = () => {
      balanceSheet.value.map((obj) => {
        balanceSheetYears.value.push(obj.year)
      })
    }
    selectBalanceSheetYear()

    const consolidateBalanceSheet = () => {
      // consolidate transactions and add their years to the individual objects
      const consolidatedData = []
      if (balanceSheet.value) {
        for (const yearData of balanceSheet.value) {
          const year = yearData.year

          for (const entry of yearData.financials) {
            const consolidated = {
              year,
              financial_statement: entry.financial_statement,
              c1: entry.c1,
              c2: entry.c2,
              category: entry.category,
              stakeholder: entry.stakeholder,
              amount: entry.amount.toFixed(2)
            }

            consolidatedData.push(consolidated)
          }
        }
        consolidatedBalanceSheetTransactions.value = consolidatedData

        fixedAssetsTransactions.value = consolidatedBalanceSheetTransactions.value.filter((transaction) => transaction.c1 === 'Fixed Assets')
        currentAssetsTransactions.value = consolidatedBalanceSheetTransactions.value.filter((transaction) => transaction.c1 === 'Current Assets')
        currentLiabilitiesTransactions.value = consolidatedBalanceSheetTransactions.value.filter((transaction) => transaction.c1 === 'Current Liabilities')
        longTermLiabilitiesTransactions.value = consolidatedBalanceSheetTransactions.value.filter((transaction) => transaction.c1 === 'Long Term Liabilities')
        equityTransactions.value = consolidatedBalanceSheetTransactions.value.filter((transaction) => transaction.c1 === 'Equity')
        uncategorizedAssetsTransactions.value = consolidatedBalanceSheetTransactions.value.filter((transaction) => transaction.c1 === 'Uncategorized Assets')
        uncategorizedLiabilitiesTransactions.value = consolidatedBalanceSheetTransactions.value.filter((transaction) => transaction.c1 === 'Uncategorized Liabilities')
      }
    }
    consolidateBalanceSheet()

    const calculateNetFixedAssets = computed(() => {
      const result = balanceSheetYears.value.map((year, i) => {
        const grossFixedAssets = fixedAssetsTransactions.value.filter(fa => fa.year === year)
        const total = grossFixedAssets.reduce((tot, transaction) => tot + Number(transaction.amount), 0.00)

        return {
          year,
          amount: total.toFixed(2)
        }
      })

      return [...result]
    })

    const calculateNetCurrentAssets = computed(() => {
      const result = balanceSheetYears.value.map((year, i) => {
        const grossCurrentAssets = currentAssetsTransactions.value.filter(fa => fa.year === year)
        const total = grossCurrentAssets.reduce((tot, transaction) => tot + Number(transaction.amount), 0.00)
        return {
          year,
          amount: total.toFixed(2)
        }
      })

      return [...result]
    })

    const calculateTotalAssets = computed(() => {
      const result = balanceSheetYears.value.map((year, i) => {
        const grossCurrentAssets = currentAssetsTransactions.value.filter(currAss => currAss.year === year)
        const total = grossCurrentAssets.reduce((ass, currAss) => ass + Number(currAss.amount), 0)
        const uncategorizedTotalForYear = uncategorizedAssetsTransactions.value[i]?.amount !== undefined ? Number(uncategorizedAssetsTransactions.value[i]?.amount) : 0.00
        const totalAmount = uncategorizedTotalForYear !== undefined ? total + uncategorizedTotalForYear : total

        return {
          year,
          amount: totalAmount.toFixed(2)
        }
      })

      return [...result]
    })
    const calculateTotalCurrentLiabilities = computed(() => {
      const result = balanceSheetYears.value.map((year, i) => {
        const grossCurrentLiabilities = currentLiabilitiesTransactions.value.filter(liab => liab.year === year)
        const total = grossCurrentLiabilities.reduce((current, next) => current + Number(next.amount), 0.00)

        return {
          year,
          amount: total.toFixed(2)
        }
      })

      return [...result]
    })
    const calculateTotalLongTermLiabilities = computed(() => {
      const result = balanceSheetYears.value.map((year, i) => {
        const grossLongTermLiabilities = longTermLiabilitiesTransactions.value.filter(liab => liab.year === year)
        const total = grossLongTermLiabilities.reduce((current, next) => current + Number(next.amount), 0.00)

        return {
          year,
          amount: total.toFixed(2)
        }
      })

      return [...result]
    })
    const calculateTotalLiabilities = computed(() => {
      const result = balanceSheetYears.value.map((year, i) => {
        const currentLiabilitiesTotal = calculateTotalCurrentLiabilities.value.filter(cl => cl.year === year)
        const longTermLiabilitiesTotal = calculateTotalLongTermLiabilities.value.filter(ltl => ltl.year === year)
        const uncategorizedTotalForYear = uncategorizedLiabilitiesTransactions.value[i]?.amount !== undefined ? Number(uncategorizedLiabilitiesTransactions.value[i]?.amount) : 0.00
        const total = (Number(currentLiabilitiesTotal[0].amount) + Number(longTermLiabilitiesTotal[0].amount))

        return {
          year,
          amount: (total + uncategorizedTotalForYear).toFixed(2)
        }
      })

      return [...result]
    })
    const calculateRetainedEarnings = computed(() => {
      const result = balanceSheetYears.value.map((year, i) => {
        const retainedEarningsTransactions = equityTransactions.value.filter(re => re.c2 === 'Retained Earnings')
        const retainedEarningsTotal = retainedEarningsTransactions.filter(re => re.year === year)
        const total = retainedEarningsTotal.reduce((curr, nxt) => curr + Number(nxt.amount), 0.00)

        return {
          year,
          amount: total.toFixed(2)
        }
      })

      return [...result]
    })
    const calculateTotalEquity = computed(() => {
      const result = balanceSheetYears.value.map((year, i) => {
        const equityTotal = equityTransactions.value.filter(eq => eq.year === year)
        const total = equityTotal.length > 0 ? equityTotal.reduce((curr, nxt) => curr + Number(nxt.amount), 0.00) : 0.00

        return {
          year,
          amount: total.toFixed(2)
        }
      })

      return [...result]
    })
    const totalLiabilitiesAndEquity = computed(() => {
      const result = calculateTotalLiabilities.value.map(liability => {
        const equity = calculateTotalEquity.value.find(equity => equity.year === liability.year)
        const equityAmount = equity ? Number(equity.amount) : 0.00
        const liabilitiesAmount = Number(liability.amount)
        const sum = (liabilitiesAmount + equityAmount)

        return {
          year: liability.year,
          amount: sum.toFixed(2)
        }
      })
      return [...result]
    })
    const netWorkingCapital = computed(() => {
      const result = calculateNetCurrentAssets.value.map(ca => {
        const currentLiabilities = calculateTotalCurrentLiabilities.value.find(currentLiability => currentLiability.year === ca.year)
        const currentLiabilityAmount = currentLiabilities ? Number(currentLiabilities.amount) : 0.00
        const currentAssetsAmount = Number(ca.amount)
        const difference = (currentAssetsAmount - currentLiabilityAmount)

        return {
          year: ca.year,
          amount: difference.toFixed(2)
        }
      })
      return [...result]
    })

    onMounted(() => {
      // eslint-disable-next-line no-unused-vars
      const balanceSheetWrapper = document.querySelector('#balance-sheet-table')
      const parentTriggers = balanceSheetWrapper.querySelectorAll('.parent-btn')
      const parentRows = balanceSheetWrapper.querySelectorAll('.parent')
      const childrenRows = balanceSheetWrapper.querySelectorAll('.child')
      const childrenTriggers = balanceSheetWrapper.querySelectorAll('.child-btn')
      const nestedChildrenRows = balanceSheetWrapper.querySelectorAll('.nested-child')
      const nestedChildrenTriggers = balanceSheetWrapper.querySelectorAll('.nested-child-btn')

      parentTriggers.forEach((row, parentIndex) => {
        row.addEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')

          if (parentRows[parentIndex]) {
            parentRows[parentIndex].classList.toggle('show')
          }
        })
      })
      childrenTriggers.forEach((row, childIndex) => {
        row.addEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')

          if (childrenRows[childIndex]) {
            childrenRows[childIndex].classList.toggle('show')
          }
        })
      })
      nestedChildrenTriggers.forEach((row, nestedChildIndex) => {
        row.addEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')

          if (nestedChildrenRows[nestedChildIndex]) {
            nestedChildrenRows[nestedChildIndex].classList.toggle('show')
          }
        })
      })
    })

    onBeforeUnmount(() => {
      const balanceSheetWrapper = document.querySelector('#balance-sheet-table')
      const parentTriggers = balanceSheetWrapper.querySelectorAll('.parent-btn')
      const childrenTriggers = balanceSheetWrapper.querySelectorAll('.child-btn')
      const nestedChildrenTriggers = balanceSheetWrapper.querySelectorAll('.nested-child-btn')
      const parentRows = balanceSheetWrapper.querySelectorAll('.parent')
      const childrenRows = balanceSheetWrapper.querySelectorAll('.child')
      const nestedChildrenRows = balanceSheetWrapper.querySelectorAll('.nested-child')

      parentTriggers.forEach((row, parentIndex) => {
        row.removeEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')

          if (parentRows[parentIndex]) {
            parentRows[parentIndex].classList.toggle('show')
          }
        })
      })
      childrenTriggers.forEach((row, childIndex) => {
        row.removeEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')

          if (childrenRows[childIndex]) {
            childrenRows[childIndex].classList.toggle('show')
          }
        })
      })
      nestedChildrenTriggers.forEach((row, nestedChildIndex) => {
        row.removeEventListener('click', () => {
          row.querySelector('.icon').classList.toggle('-rotate-90')

          if (nestedChildrenRows[nestedChildIndex]) {
            nestedChildrenRows[nestedChildIndex].classList.toggle('show')
          }
        })
      })
    })

    return {
      route,
      router,
      store,
      balanceSheetTransactions,
      balanceSheetYears,
      consolidatedBalanceSheetTransactions,
      toggleAssets,
      toggleLiabilities,
      toggleEquity,
      fixedAssetsTransactions,
      currentAssetsTransactions,
      currentLiabilitiesTransactions,
      longTermLiabilitiesTransactions,
      uncategorizedLiabilitiesTransactions,
      uncategorizedAssetsTransactions,
      equityTransactions,
      calculateNetFixedAssets,
      calculateNetCurrentAssets,
      calculateTotalAssets,
      calculateTotalCurrentLiabilities,
      calculateTotalLongTermLiabilities,
      calculateTotalLiabilities,
      calculateRetainedEarnings,
      calculateTotalEquity,
      totalLiabilitiesAndEquity,
      netWorkingCapital,
      Helpers
    }
  }
}
</script>

<style scoped>
  table {
    border-color: none;
  }
  table.show {
    display: table !important
  }

  /* .icon {
    transform: rotate(-90deg)
  } */
  table.rotate {
    transform: rotate(0) !important
  }
</style>
