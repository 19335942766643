<template>
  <main class="dealflow mt-8 flex flex-1 flex-col min-w-0">
    <div class="flex flex-col pr-10">
      <div class="flex items-center justify-end mb-8">
        <!-- <h1 class="font-Playfair text-[32px] leading-[42.66px]">Metrics and Financials</h1> -->
        <button @click="isTransactionReporting = !isTransactionReporting" class="py-[2px] px-[2px] text-sm relative ml-auto border-b-[1px] border-greyText">{{ isTransactionReporting ? 'View transactions' : 'View report' }}</button>
      </div>

      <div v-if="!isTransactionReporting">
        <div class="mt-20" v-if="!dataloaded">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(241, 242, 243); display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <g transform="translate(26.666666666666668,26.666666666666668)">
              <rect x="-20" y="-20" width="40" height="40" fill="#000000">
                <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.3s"></animateTransform>
              </rect>
            </g>
            <g transform="translate(73.33333333333333,26.666666666666668)">
              <rect x="-20" y="-20" width="40" height="40" fill="#636363">
                <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.2s"></animateTransform>
              </rect>
            </g>
            <g transform="translate(26.666666666666668,73.33333333333333)">
              <rect x="-20" y="-20" width="40" height="40" fill="#e1e1e1">
                <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="0s"></animateTransform>
              </rect>
            </g>
            <g transform="translate(73.33333333333333,73.33333333333333)">
              <rect x="-20" y="-20" width="40" height="40" fill="#545454">
                <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.1s"></animateTransform>
              </rect>
            </g>
          </svg>
        </div>

        <div v-else>
          <div class="grid xl:grid-cols-5 sm:grid-cols-3 gap-4 text-black mb-8" v-if="accounts.length !== 0">
            <div v-for="(account, i) in accounts" :key=i class="relative flex items-center space-x-3">
              <div v-if="account.provider === 'mono'" @click="getMonoTransactions(account.id, i)" class="account border-[2px] border-faintGrey hover:border-black p-4 rounded-[4px] cursor-pointer flex flex-col items-center justify-center w-full h-full relative" :class="{ active: i === selectedAccount }">
                <span class="selected-account absolute top-1 right-1 invisible" :class="{ active: i === selectedAccount }">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
                <div class="flex-shrink-0">
                  <img class="h-14 w-14 rounded-full" :src="getMonoInstitution(account.institution_code).icon" alt="" />
                </div>
                <div class="min-w-0 flex-1 flex-col items-center">
                  <p class="truncate text-sm my-1 text-center">{{ getMonoInstitution(account.institution_code)?.name }} </p>
                  <p class="text-xs text-greyText mt-1">Account ending in {{ account.account_ending_with }}</p>
                  <!-- <span class="block text-center w-[max-content] mx-auto my-1 rounded-full bg-[#DBF0DB] px-2.5 py-0.5 text-xs font-medium text-[#359735]">
                    Synced
                  </span> -->
                </div>
              </div>
              <div v-if="account.provider === 'plaid'" @click="getPlaidTransactions(account.id, i)" class="account border-[2px] border-faintGrey hover:border-black p-4 rounded-[4px] cursor-pointer flex flex-col items-center justify-center w-full h-full relative" :class="{ active: i === selectedAccount }">
                <span class="selected-account absolute top-1 right-1 invisible" :class="{ active: i === selectedAccount }">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
                <div class="flex-shrink-0">
                  <img class="h-14 w-14 rounded-full" :src="'data:image/png;base64,' + `${getPlaidInstitution(account.institution_code)?.logo}`" alt="" />
                </div>
                <div class="min-w-0 flex-1 flex-col items-center">
                  <p class="truncate text-sm my-1 text-center">{{ getPlaidInstitution(account.institution_code)?.name }} </p>
                  <p class="text-xs text-greyText mt-1">Account ending in {{ account.account_ending_with }}</p>
                  <!-- <span class="block text-center w-[max-content] mx-auto my-1 rounded-full bg-[#DBF0DB] px-2.5 py-0.5 text-xs font-medium text-[#359735]">
                    Synced
                  </span> -->
                </div>
              </div>
            </div>
          </div>
          <div v-else class="mx-auto text-sm text-greyText w-full">
            <p class="text-center">No available accounts</p>
          </div>
        </div>

        <template v-if="showAccountTransactions === 'mono'">
          <div class="flex items-center content-between h-[max-content] mb-2 bg-white w-full">
            <div class="flex flex-row border rounded-[4px] justify-between px-4 items-center border-gray-200 h-[96px] w-full mr-4">
              <div class="flex flex-col">
                <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Total Amount received</p>
                <span v-if="data.metadata" class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">
                  {{Helpers.numberWithCommas(data.metadata.total_amount_received)}}
                  <span class="text-sm text-greyText">NGN</span>
                </span>
              </div>
              <svg
                class="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
              </svg>
            </div>
            <div class="flex flex-row border rounded-sm justify-between px-4 items-center border-gray-200 h-[96px] w-full mr-0">
              <div class="flex flex-col">
                <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Total Amount Sent</p>
                <span v-if="data.metadata" class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">
                  {{Helpers.numberWithCommas(data.metadata.total_amount_sent)}}
                  <span class="text-sm text-greyText">NGN</span>
                </span>
              </div>
              <svg
                class="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
              </svg>
            </div>
          </div>
          <div v-show="!isTransactionReporting">
            <TransactionEventTable
              :config="monoTableConfig"
              :theData="monoTransactions"
              :isLoading="loading"
              :isBottomNav="true"
              :pagination="true"
              @nextPage="(newPage) => updatePage('mono', newPage)"
              @previousPage="(newPage) => updatePage('mono', newPage)"
              @reset="() => resetPagination()"
              @updateTable="() => getMonoTransactions(accountId)"
              :plaid="false"
              :categories="allCategories"
              :selectedCategory="selectedCategory"
              :accountId="accountId"
              :selectedAccount="selectedAccount"
              :totalRecords="monoTransactions.length"
              :metadata="data.metadata"
              :nextCursor="data.metadata.next_cursor"
              :previousCursor="data.metadata.previous_cursor"
              @sortBy="(newData) => sortColumn('mono', newData)"
              v-model="data.page"
              v-if="monoTransactions.length > 0"
            />
            <div v-else class="w-full mx-auto text-sm text-greyText text-center">No available transactions currently. Check again later</div>
          </div>

        </template>

        <template v-if="showAccountTransactions === 'plaid'">
          <div class="flex items-center content-between h-[max-content] mb-2 bg-white w-full">
            <div class="flex flex-row border rounded-[4px] justify-between px-4 items-center border-gray-200 h-[96px] w-full mr-4">
              <div class="flex flex-col">
                <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Total Amount received</p>
                <span v-if="data.metadata" class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">
                  <!-- {{data.metadata.total_amount_received.replace('-', '')}} -->
                  {{Helpers.numberWithCommas(data.metadata.total_amount_received.replace('-', ''))}}
                  <span class="text-sm text-greyText">{{selectedAccountCurrency}}</span>
                </span>
              </div>
              <svg
                class="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
              </svg>
            </div>
            <div class="flex flex-row border rounded-sm justify-between px-4 items-center border-gray-200 h-[96px] w-full mr-0">
              <div class="flex flex-col">
                <p class="text-greyText text-[13px] leading-[15.41px] font-Rubik">Total Amount Sent</p>
                <span v-if="data.metadata" class="text-[24px] leading-[28.44px] mt-[6px] font-Rubik">
                  {{Helpers.numberWithCommas(data.metadata.total_amount_sent)}}
                  <span class="text-sm text-greyText">{{selectedAccountCurrency}}</span>
                </span>
              </div>
              <svg
                class="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
              </svg>
            </div>
          </div>
          <div v-show="!isTransactionReporting">
            <TransactionEventTable
              :config="plaidTableConfig"
              :theData="plaidTransactions"
              :isLoading="loading"
              :isBottomNav="true"
              :pagination="true"
              @nextPage="(newPage) => updatePage('plaid', newPage)"
              @previousPage="(newPage) => updatePage('plaid', newPage)"
              @reset="() => resetPagination()"
              @updateTable="() => getPlaidTransactions(accountId)"
              :plaid="true"
              :categories="allCategories"
              :selectedCategory="selectedCategory"
              :accountId="accountId"
              :selectedAccount="selectedAccount"
              :totalRecords="plaidTransactions.length"
              :metadata="data.metadata"
              :nextCursor="data.metadata.next_cursor"
              :previousCursor="data.metadata.previous_cursor"
              @sortBy="(newData) => sortColumn('plaid', newData)"
              v-model="data.page"
              v-if="plaidTransactions.length > 0"
            />
            <div v-else class="w-full mx-auto text-sm text-greyText text-center">No available transactions currently. Check again later</div>
          </div>

        </template>
      </div>
      <div v-if="isTransactionReporting">
          <TransactionsReporting />
      </div>

    </div>
  </main>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { computed, ref, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'
import Helpers from '@/utils/helpers'
import FinancialService from '@/services/financials.service'
import PlaidService from '@/services/plaid.service'
import MonoService from '@/services/mono.service'
import TransactionEventTable from '@/components/TransactionEventTable'
import TransactionsReporting from '@/components/TransactionsReporting'
import {
// Menu,
// MenuButton,
// MenuItems,
// MenuItem,
// Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
// Listbox,
// ListboxButton,
// ListboxOptions,
// ListboxOption,
// ListboxLabel
} from '@headlessui/vue'
// import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

export default {
  components: {
    // Menu,
    // MenuButton,
    // MenuItems,
    // MenuItem,
    // Dialog,
    // DialogPanel,
    // DialogTitle,
    // TransitionChild,
    // TransitionRoot,
    // Listbox,
    // ListboxButton,
    // ListboxOptions,
    // ListboxOption,
    // ListboxLabel,
    // CheckIcon,
    // SelectorIcon,
    TransactionEventTable,
    TransactionsReporting
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const accounts = ref([])
    const plaidInstitution = ref([])
    const monoTransactions = ref([])
    const plaidTransactions = ref([])
    const showAccountTransactions = ref('')
    const categorizeMonoTransaction = ref(false)
    const categorizePlaidTransaction = ref(false)
    const financials = ref(false)
    const allCategories = ref([])
    const selectedCategory = ref(null)
    const selectedTransaction = ref(null)
    const accountId = ref(null)
    const loading = ref(false)
    const selectedAccount = ref(null)
    const dataloaded = ref(false)
    const selectedAccountCurrency = ref('')
    const isTransactionReporting = ref(false)

    const data = reactive({
      page: 1,
      comments: '',
      perPage: 20,
      metadata: null,
      nextCursor: null,
      previousCursor: null
    })

    const searchDropData = reactive({
      searchQuery: '',
      selectedItem: null,
      isVisible: false,
      stakeholdersArray: []
    })

    const monoTableConfig = ref([
      {
        key: 'date',
        title: 'DATE',
        type: 'date',
        sortable: true
      },
      {
        key: 'narration',
        title: 'DESCRIPTION',
        type: 'description',
        sortable: false
      },
      {
        key: 'amount',
        title: 'AMOUNT',
        type: 'amount',
        sortable: true
      },
      {
        key: 'type',
        title: 'TYPE',
        type: 'type',
        sortable: true
      },
      {
        key: 'admin_defined_category',
        title: 'CATEGORY',
        type: 'category',
        sortable: false
      }
    ])

    const plaidTableConfig = ref([
      {
        key: 'date',
        title: 'DATE',
        type: 'date',
        sortable: true
      },
      {
        key: 'name',
        title: 'DESCRIPTION',
        type: 'description',
        sortable: true
      },
      {
        key: 'amount',
        title: 'AMOUNT',
        type: 'amount',
        sortable: true
      },
      {
        key: 'type',
        title: 'TYPE',
        type: 'type',
        sortable: true
      },
      {
        key: 'admin_defined_category',
        title: 'CATEGORY',
        type: 'category',
        sortable: false
      }
    ])

    const updatePage = async (accountType, newPage) => {
      // to update, grab newPage as it is passed from the child component
      switch (newPage.pageDirection) {
        case 'next':
          if (accountType === 'mono') {
            try {
              const response = await MonoService.getNextBatchOfMonoTransactions(accountId.value, newPage.pageDirection, newPage.cursor, newPage.sortBy, newPage.order, newPage.limit)
              if (response.status === 200 && response.data.status === 'success') {
                data.metadata = response.data.data.metadata
                monoTransactions.value = response.data.data.transactions
              }
            } catch (e) {
              Helpers.processError(e, store, router)
            }
          } else {
            try {
              const response = await PlaidService.getNextBatchOfPlaidTransactions(accountId.value, newPage.pageDirection, newPage.cursor, newPage.sortBy, newPage.order, newPage.limit)
              if (response.status === 200 && response.data.status === 'success') {
                data.metadata = response.data.data.metadata
                plaidTransactions.value = response.data.data.transactions
              }
            } catch (e) {
              Helpers.processError(e, store, router)
            }
          }
          break
        case 'previous':
          if (accountType === 'mono') {
            try {
              const response = await MonoService.getNextBatchOfMonoTransactions(accountId.value, newPage.pageDirection, newPage.cursor, newPage.sortBy, newPage.order, newPage.limit)
              if (response.status === 200 && response.data.status === 'success') {
                data.metadata = response.data.data.metadata
                monoTransactions.value = response.data.data.transactions
              }
            } catch (e) {
              Helpers.processError(e, store, router)
            }
          } else {
            try {
              const response = await PlaidService.getNextBatchOfPlaidTransactions(accountId.value, newPage.pageDirection, newPage.cursor, newPage.sortBy, newPage.order, newPage.limit)
              if (response.status === 200 && response.data.status === 'success') {
                data.metadata = response.data.data.metadata
                plaidTransactions.value = response.data.data.transactions
              }
            } catch (e) {
              Helpers.processError(e, store, router)
            }
          }
          break
      }
    }

    const resetPagination = () => {
      data.page = 1
    }

    const getDate = (created) => {
      const date = new Date(created.split('T')[0])
      return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date)
    }

    const getConnectedAccounts = async () => {
      const companyId = route.params.id
      try {
        const response = await FinancialService.getConnectedAccounts(companyId)
        if (response.status === 200 && response.data.status === 'success') {
          accounts.value = response.data.data
          dataloaded.value = true
          fetchPlaidInstitution()
        }
      } catch (e) {
        dataloaded.value = false
        // Helpers.processError(e, store, router)
      }
    }
    getConnectedAccounts()

    const institutions = computed(() => store.state.app.institutions)

    const getMonoInstitution = (bankCode) => {
      return institutions.value.find(institution => institution.bank_code === bankCode)
    }

    const fetchPlaidInstitution = async () => {
      const plaidaccounts = accounts.value.filter(account => account.provider === 'plaid')
      try {
        const result = await Promise.all(
          plaidaccounts.map(async (account) => {
            const response = await PlaidService.getPlaidInstitutions(account.institution_code)
            return response.data.data.institution
          })
        )
        plaidInstitution.value = result
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const getPlaidInstitution = (bankCode) => {
      return plaidInstitution.value.find(institution => institution.institution_id === bankCode)
    }

    // eslint-disable-next-line camelcase
    const getMonoTransactions = async (id, index) => {
      showAccountTransactions.value = 'mono'
      accountId.value = id
      plaidTransactions.value = []
      selectedAccount.value = index
      selectedAccountCurrency.value = ''

      try {
        const response = await MonoService.getMonoTransactions(id)
        if (response.status === 200 && response.data.status === 'success') {
          data.metadata = response.data.data.metadata
          monoTransactions.value = response.data.data.transactions
          selectedAccountCurrency.value = response.data.data.transactions[0].currency
          resetPagination()
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const getPlaidTransactions = async (id, index) => {
      showAccountTransactions.value = 'plaid'
      accountId.value = id
      monoTransactions.value = []
      selectedAccount.value = index
      selectedAccountCurrency.value = ''
      try {
        const response = await PlaidService.getPlaidTransactions(id)
        if (response.status === 200 && response.data.status === 'success') {
          data.metadata = response.data.data.metadata
          plaidTransactions.value = response.data.data.transactions
          selectedAccountCurrency.value = response.data.data.transactions[0].iso_currency_code
          resetPagination()
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const getSelectedTransaction = (transactionId) => {
      selectedTransaction.value = transactionId
    }

    const fetchStakeholders = async () => {
      try {
        const response = await FinancialService.getStakeholders()
        if (response.status === 200 && response.data.status === 'success') {
          searchDropData.stakeholdersArray = response.data.data
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const fetchCategories = async () => {
      try {
        const response = await FinancialService.getCategories()
        if (response.status === 200 && response.data.status === 'success') {
          allCategories.value = response.data.data
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const closeModal = () => {
      categorizeMonoTransaction.value = false
      categorizePlaidTransaction.value = false
      searchDropData.isVisible = false
      searchDropData.searchQuery = ''
      financials.value = false
    }

    const createStakeholder = async () => {
      const payload = {
        name: searchDropData.searchQuery
      }
      try {
        const response = await FinancialService.createStakeholder(payload)
        if (response.status === 200 && response.data.status === 'success') {
          fetchStakeholders()
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const selectItem = (item) => {
      searchDropData.selectedItem = item
      searchDropData.isVisible = false
    }

    const sortColumn = async (accountType, input) => {
      const sortBy = input.sortBy
      const order = input.order
      const limit = Number(input.limit)

      if (accountType === 'plaid') {
        try {
          const response = await PlaidService.sortPlaidTransactions(accountId.value, sortBy, order, limit)
          if (response.status === 200 && response.data.status === 'success') {
            data.metadata = response.data.data.metadata
            plaidTransactions.value = response.data.data.transactions
          }
        } catch (e) {
          Helpers.processError(e, store, router)
        }
      } else {
        try {
          const response = await MonoService.sortMonoTransactions(accountId.value, sortBy, order, limit)
          if (response.status === 200 && response.data.status === 'success') {
            data.metadata = response.data.data.metadata
            monoTransactions.value = response.data.data.transactions
          }
        } catch (e) {
          Helpers.processError(e, store, router)
        }
      }
    }

    const fetchIncomeStatementByYear = async () => {
      const companyId = route.params.id
      const statementType = 'income_statement'
      const period = 'annual'
      try {
        const response = await FinancialService.getFinancialStatements(companyId, statementType, period)
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setIncomeStatement', response.data.data)
          dataloaded.value = true
        }
      } catch (e) {
        dataloaded.value = false
        Helpers.processError(e, store, router)
      }
    }
    const fetchBalanceSheetByYear = async () => {
      const companyId = route.params.id
      const statementType = 'balance_sheet'
      const period = 'annual'
      try {
        const response = await FinancialService.getFinancialStatements(companyId, statementType, period)
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setBalanceSheet', response.data.data)
          dataloaded.value = true
        }
      } catch (e) {
        dataloaded.value = false
        Helpers.processError(e, store, router)
      }
    }

    fetchIncomeStatementByYear()
    fetchBalanceSheetByYear()

    // Lifecycles

    onBeforeMount(() => {
      fetchCategories()
    })

    return {
      getDate,
      getMonoInstitution,
      accounts,
      institutions,
      monoTransactions,
      plaidTransactions,
      fetchPlaidInstitution,
      getPlaidInstitution,
      showAccountTransactions,
      getMonoTransactions,
      getPlaidTransactions,
      Helpers,
      categorizeMonoTransaction,
      closeModal,
      financials,
      allCategories,
      selectedCategory,
      searchDropData,
      getSelectedTransaction,
      createStakeholder,
      selectItem,
      categorizePlaidTransaction,
      data,
      selectedAccount,
      dataloaded,
      monoTableConfig,
      plaidTableConfig,
      loading,
      updatePage,
      accountId,
      // perPageOptions,
      resetPagination,
      sortColumn,
      selectedAccountCurrency,
      isTransactionReporting
    }
  }
}
</script>

<style>
.selected-account.active {
  visibility: visible;
}
.account.active {
  border-color: black;
}
</style>
